<ng-container *ngIf="hasTranslations && bid?.createTranslation">
  <div class="participation-buttons" *ngIf="bid?.isParticipationAccepted === null">
    <button
      nz-button
      nzType="primary"
      nzSize="small"
      [disabled]="submittingParticipationAcceptance | async"
      [nzLoading]="submittingParticipationAcceptance | async"
      (click)="setParticipation(true)"
      style="margin-bottom: 5px"
    >
      {{ 'LOT_INFO.APPROVE_PARTICIPATION' | translate }}
    </button>
    <!-- <button
    nz-button
    nzDanger
    nzType="primary"
    [disabled]="submittingParticipationAcceptance | async"
    [nzLoading]="submittingParticipationAcceptance | async"
    (click)="setParticipation(false)"
    style="margin-bottom: 5px"
  >
    {{ 'LOT_INFO.DENY_PARTICIPATION' | translate }}
  </button> -->
  </div>

  <nz-tag *ngIf="bid?.isParticipationAccepted" nzColor="success">
    <i nz-icon nzType="check-circle"></i>
    {{ 'LOT_INFO.PARTICIPATION_ACCEPTED' | translate }}
  </nz-tag>
  <nz-tag *ngIf="bid?.isParticipationAccepted === false" nzColor="warning">
    {{ 'LOT_INFO.PARTICIPATION_CANCELED' | translate }}
  </nz-tag>
</ng-container>
