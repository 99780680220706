import { ConfigService } from '@akebono/core';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { from, iif, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { map, startWith, switchMap, takeUntil, tap, toArray } from 'rxjs/operators';

import { LotTranslation } from '../../graphql/service/graphql-auto-main-service';
import { AudioTranslationSpeedService } from './services/audio-translation-speed.service';

@Component({
  selector: 'app-lot-translations',
  templateUrl: './lot-translations.component.html',
  styleUrls: ['./lot-translations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotTranslationsComponent implements OnInit, AfterViewInit, OnDestroy {
  @HostBinding('style.display') display: 'none' | 'block' = 'none';

  translations$ = new ReplaySubject<LotTranslation[] | undefined>(1);
  translationPanels$: Observable<
    Array<{
      translation: LotTranslation;
      active: boolean;
    }>
  > = of([]);

  @Input()
  set translations(value: LotTranslation[] | undefined) {
    this.translations$.next(value);
  }

  @ViewChildren('audio')
  audios: QueryList<ElementRef<HTMLAudioElement>>;

  private readonly destroy$ = new Subject<void>();

  constructor(
    private configService: ConfigService,
    private audioTranslationSpeedService: AudioTranslationSpeedService,
  ) {}

  ngOnInit(): void {
    this.translationPanels$ = this.translations$.pipe(
      map((translations) => translations ?? []),
      tap((translations) => (this.display = translations.length ? 'block' : 'none')),
      switchMap((translations) =>
        this.configService.getCurrentLanguage$().pipe(
          map((lang) => lang.localeId),
          switchMap((lang) =>
            from(translations).pipe(
              map((translation) => ({
                translation,
                active: lang === translation.language,
              })),
              toArray(),
            ),
          ),
          switchMap((panels) =>
            iif(
              () => panels.every((panel) => panel.active === false),
              from(panels).pipe(
                map((panel) => ({ ...panel, active: true })),
                toArray(),
              ),
              of(panels),
            ),
          ),
        ),
      ),
    );
  }

  ngAfterViewInit(): void {
    this.audioTranslationSpeedService.changes$
      .pipe(startWith(this.audioTranslationSpeedService.speed), takeUntil(this.destroy$))
      .subscribe((speed) => {
        this.audios.forEach((audio) => (audio.nativeElement.playbackRate = speed));
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
