import { MutationHandlingServiceV2, QueryHandlingService } from '@akebono/core';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map, mapTo, shareReplay, startWith, switchMap } from 'rxjs/operators';
import {
  NotificationSettingInput,
  NotificationSettingsUpdateGQL,
  UserSettingsGQL,
  UserSettingsQuery,
  UserUpdateGQL,
  UserUpdateInput,
} from 'src/app/modules/graphql/service/graphql-auto-main-service';
import { CurrentUserIsEcarjpBotConnectedGQL } from 'src/app/modules/graphql/service/graphql-shared-familiar-service';

import { AudioTranslationSpeedService } from '../../../lot-translations/services/audio-translation-speed.service';
import { SettingsChanges } from '../../types/settings-changes.type';

@Component({
  selector: 'app-settings-modal',
  templateUrl: './settings-modal.component.html',
  styleUrls: ['./settings-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsModalComponent implements OnInit {
  @Input() selectedIndex: 0 | 1 | 2 = 0;

  loading$: Observable<boolean>;
  isEcarBotConnected$: Observable<boolean>;
  currentUser$: Observable<UserSettingsQuery['currentUser']>;

  notificationsInputs: NotificationSettingInput[] = [];
  userSettingsInput: UserUpdateInput = {};

  settingsChanges: Partial<SettingsChanges> = {};

  saving$ = of(false);

  constructor(
    public modal: NzModalRef,
    private qhs: QueryHandlingService,
    private mhs: MutationHandlingServiceV2,
    private audioTranslationSpeedService: AudioTranslationSpeedService,
    private userSettingsGQL: UserSettingsGQL,
    private currentUserIsEcarJpBotConnectedGQL: CurrentUserIsEcarjpBotConnectedGQL,
    private userUpdateGQL: UserUpdateGQL,
    private notificationSettingsUpdateGQL: NotificationSettingsUpdateGQL,
  ) {}

  ngOnInit(): void {
    const queryRef = this.qhs.watch(this.userSettingsGQL);
    const ecarBotDataQueryRef = this.qhs.watch(
      this.currentUserIsEcarJpBotConnectedGQL,
      {},
      'network-only',
    );

    this.currentUser$ = queryRef.data.pipe(
      map((data) => data.currentUser),
      shareReplay(1),
    );
    this.isEcarBotConnected$ = ecarBotDataQueryRef.data.pipe(
      map((data) => data.currentUser?.isBotEcarJpAuc || false),
    );

    this.loading$ = combineLatest([queryRef.loading, ecarBotDataQueryRef.loading]).pipe(
      map((loadings) => loadings.some((l) => l)),
    );
  }

  onSettingsChange(settingsName: keyof SettingsChanges, value: any): void {
    this.settingsChanges[settingsName] = value;
  }

  save(): void {
    const notificationSettingsUpdate$ = this.updateNotificationSettings();
    const userSettingsUpdate$ = this.updateUserSettings();
    const otherSettingsUpdate$ = this.updateOtherSettings();

    this.saving$ = combineLatest([
      notificationSettingsUpdate$,
      userSettingsUpdate$,
      otherSettingsUpdate$,
    ]).pipe(
      catchError(() => of(null)),
      switchMap(() => this.mhs.refetchQueries(this.userSettingsGQL.client)),
      mapTo(false),
      startWith(true),
    );
  }

  private updateNotificationSettings(): Observable<unknown> {
    return this.mhs.mutate(this.notificationSettingsUpdateGQL, {
      input: {
        settings: this.settingsChanges.notification ?? [],
      },
    }).data;
  }

  private updateUserSettings(): Observable<unknown> {
    const input: UserUpdateInput = {
      preferredLang: this.settingsChanges.translation.preferredLang,
      defaultTranslationMediaType: this.settingsChanges.translation.defaultTranslationMediaType,
      bidTranslationByDefault: this.settingsChanges.bid.bidTranslationByDefault,
      ignoreSmallBidWarning: this.settingsChanges.bid.ignoreSmallBidWarning,
    };

    return this.mhs.mutate(this.userUpdateGQL, {
      input,
    }).data;
  }

  private updateOtherSettings(): Observable<unknown> {
    this.audioTranslationSpeedService.speed =
      this.settingsChanges.translation.defaultAudioTranslationSpeed ?? 1;

    return of({});
  }
}
