<ng-container *akbCheck="['cars.bids']">
  <button
    *ngIf="canRenderPlaceBidButton$ | async"
    nz-button
    nzSize="large"
    nzType="primary"
    [nzLoading]="limits.loading$ | async"
    [disabled]="isPlaceBidDisabled$ | async"
    nz-tooltip
    [nzTooltipTitle]="placeBidButtonDisabledReasons"
    [nzTooltipTrigger]="(isPlaceBidDisabled$ | async) ? 'hover' : null"
    (click)="placeBid()"
  >
    {{ 'LOT_BIDDING_MODAL.PLACE_YOUR_BID' | translate }}
    <ng-template #placeBidButtonDisabledReasons>
      <div *ngFor="let reason of placeBidButtonDisabledReasons$ | async" class="disabled-reason">
        {{ 'LOT_BIDDING_MODAL.DISABLED_REASONS.' + reason | translate }}
      </div>
    </ng-template>
  </button>

  <!-- Если ставка есть и достигнут лимит ставок -->
  <button
    *ngIf="canRenderLastChanceButton$ | async"
    nz-button
    nzSize="large"
    class="one-more-chance-btn"
    [disabled]="isLastChanceDisabled$ | async"
    nz-tooltip
    [nzTooltipTitle]="lastChanceButtonDisabledReasons"
    [nzTooltipTrigger]="(isLastChanceDisabled$ | async) ? 'hover' : null"
    (click)="showChanceConfirm = true"
  >
    {{ 'ONE_MORE_CHANCE' | translate }}
    <ng-template #lastChanceButtonDisabledReasons>
      <div *ngFor="let reason of lastChangeButtonDisabledReasons$ | async" class="disabled-reason">
        {{ 'LOT_BIDDING_MODAL.DISABLED_REASONS.' + reason | translate }}
      </div>
    </ng-template>
  </button>
</ng-container>

<nz-modal
  [(nzVisible)]="showChanceConfirm"
  (nzOnCancel)="showChanceConfirm = false"
  [nzOkDisabled]="!withLimitsOverride"
  nzOkText="Разместить ставку"
  (nzOnOk)="showChanceConfirm = false; placeBid()"
>
  <ng-container *nzModalContent>
    <p>
      Хотите сделать еще одну дополнительную ставку на лот в обмен на дополнительную комиссию в 1%
      от стоимости покупки в случае выигрыша лота (не менее 10 000 йен)?
    </p>
    <label nz-checkbox [(ngModel)]="withLimitsOverride">
      <strong>Да, хочу сделать еще одну ставку, с дополнительной комиссией согласен</strong>
    </label>
  </ng-container>
</nz-modal>
