import { DateTime } from 'luxon';

import { ExcelRowColumns } from '../enums/excel-row-columns.enum';
import { ColumnParsingException } from '../exception/column-parsing.exception';
import { BidGroupInfo } from '../types/bid-group-info.type';
import { ExcelBid } from '../types/excel-bid.type';
import { ExcelLot } from '../types/excel-lot.type';

export abstract class ExcelBidFactory {
  static create(rawRow: string[]): ExcelBid {
    const lot: ExcelLot = {
      name: this.extractString(rawRow, ExcelRowColumns.Name),
      rate: this.parseRate(rawRow),
      year: this.extractNumber(rawRow, ExcelRowColumns.Year),
      color: this.extractString(rawRow, ExcelRowColumns.Color),
      chassis: this.extractString(rawRow, ExcelRowColumns.Chassis),
      mileage: this.extractNumber(rawRow, ExcelRowColumns.Mileage),
      lotNumber: this.extractString(rawRow, ExcelRowColumns.LotNumber),
      auctionName: this.extractString(rawRow, ExcelRowColumns.AuctionName),
      auctionDate: this.parseAuctionDate(rawRow),
      auctionDateTime: this.parseAuctionDateTime(rawRow),
    };

    const bid: ExcelBid = {
      user: this.extractString(rawRow, ExcelRowColumns.Username, true),
      group: this.parseGroup(rawRow),
      amount: this.parseAmount(rawRow),
      lot: Object.freeze(lot),
    };

    return Object.freeze(bid);
  }

  private static parseAuctionDate(rawRow: string[]): DateTime {
    const rawValue = this.extractString(rawRow, ExcelRowColumns.AuctionDateTime);
    const [date] = rawValue.split(' ');
    const auctionDate = DateTime.fromFormat(date, 'dd.MM.yyyy', { zone: 'UTC' });

    if (!auctionDate.isValid) {
      throw new ColumnParsingException(ExcelRowColumns.AuctionDateTime);
    }

    return auctionDate;
  }

  private static parseAuctionDateTime(rawRow: string[]): DateTime | null {
    const rawValue = this.extractString(rawRow, ExcelRowColumns.AuctionDateTime);
    const auctionDateTime = DateTime.fromFormat(rawValue, 'dd.MM.yyyy [HH:mm]', {
      zone: 'Asia/Tokyo',
    });

    return auctionDateTime.isValid ? auctionDateTime : null;
  }

  private static parseAmount(rawRow: string[]): number {
    const rawValue = this.extractString(rawRow, ExcelRowColumns.Amount);
    const [amount] = rawValue.split(' ');
    const amountNumber = Number(amount);

    if (Number.isNaN(amountNumber)) {
      throw new ColumnParsingException(ExcelRowColumns.Rate);
    }

    return amountNumber;
  }

  private static parseRate(rawRow: string[]): number {
    const value = this.extractString(rawRow, ExcelRowColumns.Rate);
    const rate = Number(value.replace(',', '.'));

    if (Number.isNaN(value)) {
      throw new ColumnParsingException(ExcelRowColumns.Rate);
    }

    return rate;
  }

  private static parseGroup(rawRow: string[]): BidGroupInfo | null {
    const rawValue = this.extractString(rawRow, ExcelRowColumns.Group, true);
    if (!rawValue) {
      return null;
    }

    const [letter, count] = rawValue.split(' ');
    const countNumber = Number(count);

    if (!letter || Number.isNaN(countNumber)) {
      throw new ColumnParsingException(ExcelRowColumns.Group);
    }

    const group: BidGroupInfo = {
      letter,
      count: countNumber,
      auctionDate: this.parseAuctionDate(rawRow),
    };

    return Object.freeze(group);
  }

  private static extractString(rawRow: string[], column: ExcelRowColumns): string;
  private static extractString(rawRow: string[], column: ExcelRowColumns, optional: false): string;
  private static extractString(
    rawRow: string[],
    column: ExcelRowColumns,
    optional: true,
  ): string | null;
  private static extractString(
    rawRow: string[],
    column: ExcelRowColumns,
    optional: boolean,
  ): string | null;
  private static extractString(
    rawRow: string[],
    column: ExcelRowColumns,
    optional = false,
  ): string | null {
    const rawValue = rawRow[column]?.trim();

    if (!optional && !rawValue) {
      throw new ColumnParsingException(column);
    }

    return rawValue || null;
  }

  private static extractNumber(rawRow: string[], column: ExcelRowColumns): number;
  private static extractNumber(rawRow: string[], column: ExcelRowColumns, optional: false): number;
  private static extractNumber(
    rawRow: string[],
    column: ExcelRowColumns,
    optional: true,
  ): number | null;
  private static extractNumber(
    rawRow: string[],
    column: ExcelRowColumns,
    optional = false,
  ): number | null {
    const rawValue = this.extractString(rawRow, column, optional);
    const numberValue = Number(rawValue);

    if (!optional && Number.isNaN(numberValue)) {
      throw new ColumnParsingException(column);
    }

    return numberValue || null;
  }
}
