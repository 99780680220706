import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';

import { PurchaseReportData } from '../../types/purchase-report-data.type';

@Component({
  selector: 'app-purchase-report-template',
  templateUrl: './purchase-report-template.component.html',
  styleUrls: ['./purchase-report-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PurchaseReportTemplateComponent {
  @Input() reportData: PurchaseReportData;

  readonly printSectionId = 'purchase-report-section';

  @ViewChild('printButton')
  private readonly printButton: ElementRef<HTMLButtonElement>;

  get additionalImageUrls(): string[] {
    return [this.reportData.firstAdditionalPhotoUrl, this.reportData.secondAdditionalPhotoUrl];
  }

  get registrYearFormat(): string {
    switch (this.reportData.registrYearType) {
      case 'date':
        return 'yyyy-MM-dd';
      case 'year':
        return 'yyyy';
    }
  }

  get productYearFormat(): string {
    switch (this.reportData.productYearType) {
      case 'date':
        return 'yyyy-MM-dd';
      case 'year':
        return 'yyyy';
    }
  }

  print(): void {
    this.printButton.nativeElement.click();
  }
}
