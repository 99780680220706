<a class="card-body" [routerLink]="['/news', news.id]">
  <div class="header">
    <nz-row [nzGutter]="10">
      <nz-col nzFlex="auto" class="title">
        {{ title }}
      </nz-col>
      <nz-col nzFlex="100px" class="info">
        <nz-row nzJustify="end" nzAlign="middle">
          <nz-col>
            <nz-badge
              [nzStatus]="news.isRead ? 'default' : 'error'"
              [nzStandalone]="true"
            ></nz-badge>
          </nz-col>
          <nz-col>
            {{ news.publishedAt | date: 'dd.MM.yyyy' }}
          </nz-col>
        </nz-row>
      </nz-col>
    </nz-row>
  </div>
  <div class="content">
    <p nz-typography nzEllipsis [nzEllipsisRows]="5" [innerHtml]="content"></p>
  </div>
</a>
