<main>
  <p>{{ 'LOT_BIDDING_MODAL.PRIORITY_PASS_PARAGRAPHS.1' | translate }}</p>
  <p>{{ 'LOT_BIDDING_MODAL.PRIORITY_PASS_PARAGRAPHS.2' | translate }}</p>
  <p>{{ 'LOT_BIDDING_MODAL.PRIORITY_PASS_PARAGRAPHS.3' | translate }}</p>
  <p>{{ 'LOT_BIDDING_MODAL.PRIORITY_PASS_PARAGRAPHS.4' | translate }}</p>
  <p>{{ 'LOT_BIDDING_MODAL.PRIORITY_PASS_PARAGRAPHS.5' | translate }}</p>
  <p class="notes">
    {{ 'LOT_BIDDING_MODAL.PRIORITY_PASS_PARAGRAPHS.NOTES.1' | translate }}<br />
    {{ 'LOT_BIDDING_MODAL.PRIORITY_PASS_PARAGRAPHS.NOTES.2' | translate }}
  </p>
</main>

<footer *nzModalFooter>
  <button nz-button (click)="close()">Ok</button>
</footer>
