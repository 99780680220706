import { NotificationService } from '@akebono/core';
import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { of } from 'rxjs';

import { ColumnParsingException } from '../../exception/column-parsing.exception';
import { ExcelBidsParserService } from '../../services/excel-bids-parser.service';
import { ExcelBidParsingResult } from '../../types/excel-bid-parse-result.type';

@Component({
  selector: 'app-import-file-upload',
  templateUrl: './import-file-upload.component.html',
  styleUrls: ['./import-file-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImportFileUploadComponent {
  @Output() parsingComplete = new EventEmitter<ExcelBidParsingResult>();

  readonly excelImportBids = (file: NzUploadFile) => {
    this.excelBidsParserService
      .parse(file)
      .then((result) => this.parsingComplete.emit(result))
      .catch((error) => {
        console.log(error, error instanceof ColumnParsingException);
        if (error instanceof ColumnParsingException) {
          const message = this.translate.instant('IMPORT_BIDS.PARSING_ERRORS.INVALID_FIELD', {
            field: error.columnName,
          });
          this.notification.renderError(message);
        } else {
          this.notification.renderError('IMPORT_BIDS.PARSING_ERRORS.UNEXPECTED_ERROR');
        }
      });
    return of(false);
  };

  constructor(
    private readonly translate: TranslateService,
    private readonly notification: NotificationService,
    private readonly excelBidsParserService: ExcelBidsParserService,
  ) {}
}
