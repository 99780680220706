import { MutationHandlingServiceV2, QueryHandlingService, WatchQueryRef } from '@akebono/core';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Observable, of, Subject } from 'rxjs';
import { catchError, finalize, mapTo, startWith, takeUntil } from 'rxjs/operators';
import { ObservableLoadings, ReactiveLoadings } from 'src/app/const';
import {
  MarkedLotsGQL,
  MarkedLotsQuery,
  MarkedLotsQueryVariables,
  UserChannel,
  UserLotMark,
  UserLotMarkEnum,
  UserLotMarksList,
  UserLotMarksListCreateGQL,
  UserLotMarksListDeleteGQL,
  UserLotMarksListUpdateGQL,
} from 'src/app/modules/graphql/service/graphql-auto-main-service';
import {
  LotInterestingMutationService,
} from 'src/app/modules/shared-components/lot-interesting/services/lot-interesting-mutation.service';
import { environment } from 'src/environments/environment';

import { UserChannelDeleteGQL, UserLotMarksUpdateGQL } from '../../modules/graphql/service/graphql-auto-main-service';
import { UserChannelModalComponent } from '../user-channel-modal/user-channel-modal.component';

@Component({
  selector: 'app-selections-lots',
  templateUrl: './selections-lots.component.html',
  styleUrls: ['./selections-lots.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectionsLotsComponent implements OnInit, OnDestroy {
  queryRef?: WatchQueryRef<MarkedLotsQuery, MarkedLotsQueryVariables>;

  deleting: ReactiveLoadings = {};

  page = 0;
  pageSize = 20;
  titleNewList = '';
  activeListMarkId: string;
  addingListMark$: Observable<boolean>;
  updatingListMark$: Observable<boolean>;
  deletingListMark$: ObservableLoadings = {};
  deletingChannel$: Observable<boolean>;
  updatingListMark2$: ObservableLoadings = {};
  private destroy$ = new Subject();
  setOfCheckedId = new Set<string>();
  newUserLotMarksListId: string;
  checkedAllMarks: boolean = false;

  constructor(
    private title: Title,
    private router: Router,
    private route: ActivatedRoute,
    private qhs: QueryHandlingService,
    private translate: TranslateService,
    private markedLotsGQL: MarkedLotsGQL,
    private modalService: NzModalService,
    private mhs: MutationHandlingServiceV2,
    private userChannelDeleteGQL: UserChannelDeleteGQL,
    private userLotMarksUpdateGQL: UserLotMarksUpdateGQL,
    private userLotMarksListDeleteGQL: UserLotMarksListDeleteGQL,
    private userLotMarksListCreateGQL: UserLotMarksListCreateGQL,
    private userLotMarksListUpdateGQL: UserLotMarksListUpdateGQL,
    private interestingMutations: LotInterestingMutationService,
  ) {
    this.title.setTitle('Selections');
  }

  ngOnInit(): void {
    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      this.setOfCheckedId.clear();
      this.checkedAllMarks = false;
      this.page = Number(params.pageIndex) || 1;
      this.pageSize = Number(params.pageSize) || 20;
      this.activeListMarkId = params.markListId || null;

      const variables: MarkedLotsQueryVariables = {
        first: this.pageSize,
        offset: (this.page - 1) * this.pageSize,
        filter: {
          userLotMark: {
            eq: UserLotMarkEnum.Shared,
          },
          userLotMarksListId: {
            eq: this.activeListMarkId,
          },
        },
      };

      if (this.queryRef) {
        this.queryRef.refetch(variables);
      } else {
        this.queryRef = this.qhs.watch(this.markedLotsGQL, variables);
      }
    });
  }

  createModal(userChannel: UserChannel = null): void {
    this.modalService.create({
      nzTitle: this.translate.instant('CHANNEL.' + (userChannel ? 'EDIT' : 'CREATE')),
      nzContent: UserChannelModalComponent,
      nzComponentParams: {
        userChannel,
      },
    });
  }

  updateCheckedObject(checked: boolean, id: string): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }

  updateCheckedAllObjects(checked: boolean, marks: readonly UserLotMark[]): void {
    this.setOfCheckedId.clear();
    marks.forEach((mark) => this.updateCheckedObject(checked, mark.id));
  }

  setFilterMarkListById(markListId: number = null): void {
    this.setOfCheckedId.clear();

    this.router.navigate([], {
      queryParams: {
        markListId,
      },
      queryParamsHandling: 'merge',
    });
  }

  userLotMarksListCreate(): void {
    this.addingListMark$ = this.mhs
      .mutate(
        this.userLotMarksListCreateGQL,
        {
          input: {
            title: this.titleNewList,
            publishedAt: new Date().toISOString(),
          },
        },
        {
          refetch: true,
          successTranslationKey: 'MUTATION_SUCCEEDED',
          failureTranslationKey: 'MUTATION_FAILED',
        },
      )
      .loading.pipe(finalize(() => (this.titleNewList = '')));
  }

  userLotMarksListDelete(id: string = null): void {
    this.deletingListMark$[id] = this.mhs.mutate(
      this.userLotMarksListDeleteGQL,
      {
        input: { id },
      },
      {
        refetch: true,
        successTranslationKey: 'MUTATION_SUCCEEDED',
        failureTranslationKey: 'MUTATION_FAILED',
      },
    ).loading;
  }

  updateLotMarksListUpdate(id: string, date = new Date().getTime()): void {
    this.updatingListMark2$[id] = this.mhs.mutate(
      this.userLotMarksListUpdateGQL,
      {
        input: {
          id: id,
          publishedAt: date,
        },
      },
      {
        refetch: true,
        successTranslationKey: 'MUTATION_SUCCEEDED',
        failureTranslationKey: 'MUTATION_FAILED',
      },
    ).loading;
  }

  userChannelDelete(id: string): void {
    this.deletingChannel$ = this.mhs.mutate(
      this.userChannelDeleteGQL,
      {
        input: {
          id,
        },
      },
      {
        refetch: true,
        successTranslationKey: 'MUTATION_SUCCEEDED',
        failureTranslationKey: 'MUTATION_FAILED',
      },
    ).loading;

    this.setOfCheckedId.clear();
    this.newUserLotMarksListId = null;
  }

  userLotMarksUpdate(): void {
    this.updatingListMark$ = this.mhs.mutate(
      this.userLotMarksUpdateGQL,
      {
        input: {
          ids: [...this.setOfCheckedId],
          mark: UserLotMarkEnum.Shared,
          userLotMarksListId: this.newUserLotMarksListId,
        },
      },
      {
        refetch: true,
        successTranslationKey: 'MUTATION_SUCCEEDED',
        failureTranslationKey: 'MUTATION_FAILED',
      },
    ).loading;
  }

  remove(mark: UserLotMark): void {
    this.deleting[mark.id] = this.interestingMutations
      .delete(
        {
          id: mark.id,
        },
        true,
      )
      .pipe(
        mapTo(false),
        catchError(() => of(false)),
        startWith(true),
      );
  }

  getListLink(userLotMarksList: UserLotMarksList): string {
    return userLotMarksList.publishedAt
      ? `https://${environment.domain}/list/${userLotMarksList.id}`
      : '';
  }

  getSharedLink(mark: UserLotMark): string {
    return `https://${environment.domain}/shared/${mark.id} ${mark.lot.auctionName} ${mark.lot.code}`;
  }

  getChannelLink(path: string): string {
    return `https://${environment.domain}/channel/${path}`;
  }

  changePage(pageIndex: number): void {
    this.router.navigate([], {
      queryParams: {
        pageIndex,
        pageSize: this.pageSize,
      },
      queryParamsHandling: 'merge',
    });
  }

  changePageSize(pageSize: number): void {
    this.pageSize = pageSize;

    this.router.navigate([], {
      queryParams: {
        pageSize,
        pageIndex: 1,
      },
      queryParamsHandling: 'merge',
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
