<nz-spin [nzSpinning]="queryRef$?.loading | async">
  <ng-container *ngIf="data$ | async as data">
    <div *ngIf="data?.length">
      <nz-divider style="margin: 10px 0"></nz-divider>
      <ng-container *ngFor="let item of data">
        <nz-tag nzColor="blue" *ngIf="item" style="font-size: 14px">{{ item }}</nz-tag>
      </ng-container>
      <nz-divider style="margin: 10px 0"></nz-divider>
    </div>
  </ng-container>
</nz-spin>
