export enum ExcelRowColumns {
  AuctionDateTime,
  LotNumber,
  AuctionName,
  Name,
  Amount,
  Username,
  Group,
  Chassis,
  Year,
  Color,
  Mileage,
  Rate,
}
