import { AkebonoCoreModule } from '@akebono/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzSpinModule } from 'ng-zorro-antd/spin';

import { ConnectTelegramModalComponent } from './connect-telegram-modal.component';
import { ConnectTelegramService } from './connect-telegram.service';

@NgModule({
  declarations: [ConnectTelegramModalComponent],
  imports: [
    CommonModule,
    AkebonoCoreModule,
    TranslateModule,
    NzModalModule,
    NzButtonModule,
    NzGridModule,
    NzSpinModule,
    NzIconModule,
  ],
  providers: [ConnectTelegramService],
})
export class ConnectTelegramModule {}
