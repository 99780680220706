import { AkebonoCoreModule } from '@akebono/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

import { LotBiddingModalModule } from '../lot-bidding-modal/lot-bidding-modal.module';
import { LotPlaceBidButtonComponent } from './lot-place-bid-button.component';

@NgModule({
  declarations: [LotPlaceBidButtonComponent],
  imports: [
    CommonModule,
    AkebonoCoreModule,
    LotBiddingModalModule,
    TranslateModule,
    NzButtonModule,
    NzModalModule,
    NzCheckboxModule,
    NzToolTipModule,
    FormsModule,
  ],
  exports: [LotPlaceBidButtonComponent],
})
export class LotPlaceBidButtonModule {}
