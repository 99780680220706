import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzRadioModule } from 'ng-zorro-antd/radio';

import { CreateUserRequestModalModule } from '../create-user-request-modal/create-user-request-modal.module';
import { SettingsModalModule } from '../settings-modal/settings-modal.module';
import { TranslationTimeModule } from '../translation-time/translation-time.module';
import { LotTranslateRequestCreateModalComponent } from './components/lot-translate-request-create-modal/lot-translate-request-create-modal.component';
import { LotCreateTranslationRequestButtonComponent } from './lot-create-translation-request-button.component';

@NgModule({
  declarations: [
    LotTranslateRequestCreateModalComponent,
    LotCreateTranslationRequestButtonComponent,
  ],
  imports: [
    FormsModule,
    CommonModule,
    NzFormModule,
    NzAlertModule,
    BrowserModule,
    NzRadioModule,
    NzModalModule,
    NzInputModule,
    NzButtonModule,
    TranslateModule,
    SettingsModalModule,
    ReactiveFormsModule,
    TranslationTimeModule,
    BrowserAnimationsModule,
    CreateUserRequestModalModule,
  ],
  exports: [LotCreateTranslationRequestButtonComponent],
})
export class LotCreateTranslationRequestButtonModule {}
