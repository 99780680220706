<button
  nz-button
  nzSize="small"
  nz-popover
  nzPopoverTrigger="click"
  [(nzPopoverVisible)]="showPopover"
  [nzPopoverTitle]="changeEngineVolumePopoverTitle"
  [nzPopoverContent]="changeEngineVolumePopoverBody"
  (nzPopoverVisibleChange)="showPopover = $event"
>
  <i nz-icon nzType="edit" nzTheme="outline"></i>
</button>

<ng-template #changeEngineVolumePopoverTitle>
  <nz-row nzJustify="space-between">
    <nz-col>{{ 'ENGINE_VOLUME' | translate }}</nz-col>
    <nz-col>
      <i nz-icon class="pointer" nzType="close" nzTheme="outline" (click)="showPopover = false"></i>
    </nz-col>
  </nz-row>
</ng-template>

<ng-template #changeEngineVolumePopoverBody>
  <section>
    <nz-input-number
      [(ngModel)]="engineVolumeValue"
      [nzMin]="1"
      [nzStep]="10000"
      nzSize="small"
    ></nz-input-number>
  </section>
  <section>
    <button
      nz-button
      nzSize="small"
      nzType="primary"
      [nzLoading]="saving | async"
      (click)="saveEngineVolume()"
    >
      {{ 'SAVE' | translate }}
    </button>
  </section>
</ng-template>
