<form [formGroup]="form">
  <nz-table
    #table
    nzSize="small"
    [nzLoading]="loading"
    [nzTotal]="1"
    [nzData]="[1]"
    [nzShowPagination]="false"
    [nzFrontPagination]="false"
  >
    <thead>
      <tr>
        <th>{{ 'SETTINGS.NAME' | translate }}</th>
        <th nzWidth="1px"></th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          {{ 'BIDS_SETTINGS.KEYS.subscribeToViewBestBid' | translate }}
        </td>
        <td>
          <app-subscribe-to-best-bid-button
            *ngIf="settings"
            [buttonTextType]="'short'"
            [renderCounter]="true"
            [subscriptionData]="settings"
          ></app-subscribe-to-best-bid-button>
        </td>
      </tr>
      <tr>
        <td>{{ 'BIDS_SETTINGS.KEYS.bidTranslationByDefault' | translate }}</td>
        <td>
          <nz-switch
            formControlName="bidTranslationByDefault"
            [nzCheckedChildren]="checkedTemplate"
            [nzUnCheckedChildren]="unCheckedTemplate"
          ></nz-switch>
        </td>
      </tr>
      <tr>
        <td>{{ 'BIDS_SETTINGS.KEYS.ignoreSmallBidWarning' | translate }}</td>
        <td>
          <nz-switch
            formControlName="ignoreSmallBidWarning"
            [nzCheckedChildren]="checkedTemplate"
            [nzUnCheckedChildren]="unCheckedTemplate"
          ></nz-switch>
        </td>
      </tr>
    </tbody>
  </nz-table>
</form>

<ng-template #checkedTemplate><i nz-icon nzType="check"></i></ng-template>
<ng-template #unCheckedTemplate><i nz-icon nzType="close"></i></ng-template>
