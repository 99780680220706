<nz-row class="table-title" [nzGutter]="20" nzJustify="space-between" nzAlign="middle">
  <nz-col>
    <span class="filename">{{ parsingResult?.filename }}</span>

    <nz-tag *ngIf="parsingResult?.bids.length as bidsCount" class="bids-found-tag">
      {{ 'IMPORT_BIDS.TABLE_TITLE.PARSED_BIDS_COUNT' | translate }} - {{ bidsCount }}
    </nz-tag>
  </nz-col>

  <nz-col>
    <nz-space>
      <button
        *nzSpaceItem
        nz-button
        nzType="primary"
        [disabled]="!availableBidsCount"
        (click)="placeAvailableBids.emit()"
      >
        {{ 'IMPORT_BIDS.TABLE_TITLE.PLACE_BIDS' | translate }}
        <ng-container *ngIf="availableBidsCount as count"> ({{ count }}) </ng-container>
      </button>

      <button
        *nzSpaceItem
        nz-button
        nzType="primary"
        [disabled]="!selectedBidsCount"
        (click)="placeSelectedBids.emit()"
      >
        {{ 'IMPORT_BIDS.TABLE_TITLE.PLACE_SELECTED_BIDS' | translate }}
        <ng-container *ngIf="selectedBidsCount as count"> ({{ count }}) </ng-container>
      </button>

      <button *nzSpaceItem nz-button [disabled]="!parsingResult" (click)="reset.emit()">
        {{ 'IMPORT_BIDS.TABLE_TITLE.RESET' | translate }}
      </button>
    </nz-space>
  </nz-col>
</nz-row>
