import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NzTagModule } from 'ng-zorro-antd/tag';

import { LotLastVisitDateComponent } from './lot-last-visit-date.component';

@NgModule({
  declarations: [LotLastVisitDateComponent],
  exports: [LotLastVisitDateComponent],
  imports: [CommonModule, NzTagModule, TranslateModule],
})
export class LotLastVisitDateModule {}
