import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { IAlbum, Lightbox } from 'ngx-lightbox';
import { environment } from '../../../../environments/environment';
import { Lot } from '../../graphql/service/graphql-auto-main-service';

@Component({
  selector: 'app-lot-mini-gallery',
  templateUrl: './lot-mini-gallery.component.html',
  styleUrls: ['./lot-mini-gallery.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotMiniGalleryComponent implements OnChanges {
  @Input() lot: Lot;
  @Input() countImages = 3;
  @Input() height = 50;

  albums: IAlbum[] = [];

  constructor(private lightbox: Lightbox) {}

  open(index: number): void {
    this.lightbox.open(this.albums, index, {
      fadeDuration: 0.01,
      resizeDuration: 0.01,
    });
  }

  getFullUrl(urlStr: string): string {
    if (urlStr.startsWith('/assets')) {
      return urlStr;
    }

    const url = new URL(urlStr);

    if (url.searchParams.toString()) {
      url.searchParams.append('h', this.height.toString());
    }

    if (url.hostname.includes('ajes.com')) {
      return url.toString() + '&h=' + (this.height <= 50 ? 50 : 320);
    }

    return url.toString();
  }

  ngOnChanges(): void {
    if (this.lot && this.countImages && this.height) {
      let images: string[] = [];

      if (this.lot.thumbnails?.length) {
        images = this.lot.thumbnails;
      } else if (Array.isArray(this.lot.images) && this.lot.images?.length) {
        images = this.lot.images;
      } else if (typeof this.lot.images === 'string') {
        images = (this.lot.images as string)
          .split('#')
          .filter((url) => !!url)
          .map((url) => url.replace('https://p3.aleado.com/', environment.imageProxy));
      }

      this.albums = images.slice(0, this.countImages).map((img) => ({
        src: img,
        caption: `${this.lot.companyEn} ${this.lot.modelEn ?? this.lot.modelDetailsEn}`,
        thumb: img,
      }));
    }
  }
}
