<nz-collapse [nzBordered]="false">
  <nz-collapse-panel
    [nzHeader]="'FILTERS' | translate"
    [nzActive]="isActiveFilters"
    class="mobile-scrollable"
  >
    <nz-skeleton *ngIf="loadingFilters" [nzActive]="true"></nz-skeleton>
    <div
      *ngIf="!loadingFilters"
      valign="top"
      class="aj_tglob"
      style="padding: 0 12px; max-height: 80%"
    >
      <form
        id="poisk"
        class="poisk"
        style="margin: 0"
        method="post"
        [formGroup]="form"
        nz-row
        nzJustify="center"
        nzAlign="center"
      >
        <table border="0" cellpadding="0" cellspacing="0" class="sd_srh_tbl">
          <tbody>
            <tr>
              <td valign="top">
                <table
                  border="0"
                  class="bg_tbl"
                  height="151px"
                  style="border-top: solid 1px #cccccd; background-color: #e8e8e8"
                  cellpadding="0"
                  cellspacing="0"
                >
                  <colgroup>
                    <col width="147px" />
                    <col width="194px" />
                    <col width="109px" />
                    <col width="124px" />
                    <col width="68px" />
                    <col width="145px" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <td
                        class="bg_sch"
                        valign="top"
                        style="
                          min-width: 147px;
                          max-width: 147px;
                          border-left: solid 1px #cccccd;
                          padding-top: 1px;
                          padding-left: 4px;
                        "
                      >
                        <span style="font-size: 2px">&nbsp;</span>{{ 'Производитель' | translate
                        }}<br />
                        <div class="sl">
                          <select
                            class="sel"
                            size="8"
                            style="height: 138px; font-size: 14px; font-family: Arial; width: 148px"
                            formControlName="company"
                          >
                            <option
                              value="any"
                              style="
                                color: rgb(102, 102, 102);
                                background-color: rgb(238, 238, 238);
                              "
                            >
                              {{ 'ANY_M' | translate }}
                            </option>
                            <option *ngFor="let item of mainCompaniesList" [value]="item.id">
                              {{ item.name }}
                            </option>
                            <option *ngFor="let item of otherCompaniesList" [value]="item.id">
                              {{ item.name }}
                            </option>
                          </select>
                        </div>
                      </td>
                      <td
                        class="bg_sch"
                        valign="top"
                        style="
                          min-width: 194px;
                          max-width: 194px;
                          padding-top: 1px;
                          padding-left: 4px;
                        "
                      >
                        <span style="font-size: 2px">&nbsp;</span>{{ 'Модель' | translate }}<br />
                        <div class="sl">
                          <select
                            class="sel"
                            size="8"
                            style="height: 138px; font-size: 14px; font-family: Arial; width: 195px"
                            formControlName="model"
                          >
                            <option
                              value="any"
                              style="
                                color: rgb(102, 102, 102);
                                background-color: rgb(238, 238, 238);
                              "
                            >
                              {{ 'ANY' | translate }}
                            </option>
                            <ng-container *ngFor="let m of modelsList">
                              <option
                                *ngIf="
                                  !form.controls.company.value ||
                                  form.controls.company.value === 'any' ||
                                  m.companyId == form.controls.company.value
                                "
                                [value]="m.id"
                              >
                                {{ m.name }} ({{ m.lotsCount }})
                              </option>
                            </ng-container>
                          </select>
                        </div>
                      </td>
                      <td
                        class="bg_sch"
                        valign="top"
                        style="
                          min-width: 109px;
                          max-width: 109px;
                          padding-top: 1px;
                          padding-left: 4px;
                        "
                      >
                        <span
                          ><span style="font-size: 2px">&nbsp;</span>{{ 'Кузов' | translate }}</span
                        ><br />
                        <nz-spin [nzSpinning]="loadingFrames">
                          <div class="sl" id="div_kuzov2">
                            <select
                              class="sel"
                              size="8"
                              id="kuzov"
                              formControlName="inclusionFrame"
                              type="text"
                              multiple="multiple"
                              style="font-size: 14px; font-family: Arial; width: 110px"
                            >
                              <option
                                value="any"
                                style="
                                  color: rgb(102, 102, 102);
                                  background-color: rgb(238, 238, 238);
                                "
                              >
                                {{ 'ANY_M' | translate }}
                              </option>
                              <option
                                *ngFor="let option of filtersData.frames"
                                [value]="option.name"
                                innerHTML="{{ option.name }} ({{ option.count }})"
                              ></option>
                            </select>
                          </div>
                        </nz-spin>
                      </td>
                      <td
                        class="bg_sch"
                        valign="top"
                        style="
                          min-width: 124px;
                          max-width: 124px;
                          padding-top: 1px;
                          padding-left: 4px;
                        "
                      >
                        <span>
                          <span style="font-size: 2px">&nbsp;</span
                          >{{ 'Аукцион' | translate }}&nbsp;<span
                            style="color: #999; font-size: 11px"
                            alt="удерживайте CTRL для выбора нескольких аукционов"
                            >[?]</span
                          > </span
                        ><br />
                        <nz-spin [nzSpinning]="loadingFrames">
                          <div class="sl" id="div_auct_name">
                            <select
                              class="sel"
                              size="8"
                              formControlName="inclusionAuction"
                              type="text"
                              multiple="multiple"
                              style="font-size: 14px; font-family: Arial; width: 125px"
                            >
                              <option style="background: #eee; color: #666" value="any">
                                {{ 'ANY_M' | translate }}
                              </option>
                              <option
                                *ngFor="let option of auctRefOptions"
                                [value]="option.id"
                                [innerHTML]="option.name"
                              ></option>
                            </select>
                          </div>
                        </nz-spin>
                      </td>
                      <td
                        class="bg_sch"
                        valign="top"
                        style="
                          min-width: 68px;
                          max-width: 68px;
                          padding-top: 1px;
                          padding-left: 4px;
                        "
                      >
                        <span translate>Оценка</span><br />
                        <nz-spin [nzSpinning]="loadingFrames">
                          <div class="sl" id="div_rate">
                            <select
                              class="sel"
                              size="8"
                              formControlName="inclusionScore"
                              type="text"
                              multiple="multiple"
                              style="font-size: 14px; font-family: Arial; width: 68px"
                            >
                              <option style="background: #eee; color: #666" value="any" selected="">
                                {{ 'ANY' | translate }}
                              </option>
                              <option
                                *ngFor="let option of filtersData.scores.slice().reverse()"
                                [value]="option"
                              >
                                {{ option }}
                              </option>
                            </select>
                          </div>
                        </nz-spin>
                      </td>
                      <td
                        class="bg_sch"
                        valign="top"
                        style="
                          min-width: 145px;
                          max-width: 145px;
                          padding-top: 1px;
                          padding-left: 4px;
                        "
                      >
                        <span translate>Цвет</span><br />
                        <nz-spin [nzSpinning]="loadingFrames">
                          <div class="sl">
                            <select
                              class="sel"
                              size="8"
                              formControlName="inclusionColor"
                              type="text"
                              multiple="multiple"
                              style="font-size: 14px; font-family: Arial; width: 145px"
                            >
                              <option style="background: #eee; color: #666" value="any" selected="">
                                {{ 'ANY_M' | translate }}
                              </option>
                              <option *ngFor="let option of filtersData.colors" [value]="option">
                                {{ option | uppercase }}
                              </option>
                            </select>
                          </div>
                        </nz-spin>
                      </td>
                      <td
                        class="bg_sch"
                        valign="top"
                        style="
                          min-width: 68px;
                          max-width: 68px;
                          padding-top: 1px;
                          padding-left: 4px;
                        "
                      >
                        <span translate>КПП</span><br />
                        <nz-spin [nzSpinning]="loadingFrames">
                          <div class="sl">
                            <select
                              class="sel"
                              size="8"
                              formControlName="inclusionTransmission"
                              type="text"
                              multiple="multiple"
                              style="font-size: 14px; font-family: Arial; width: 68px"
                            >
                              <option style="background: #eee; color: #666" value="any" selected="">
                                {{ 'ANY' | translate }}
                              </option>
                              <option
                                *ngFor="let option of filtersData.transmissions"
                                [value]="option"
                                [innerHTML]="option"
                              ></option>
                            </select>
                          </div>
                        </nz-spin>
                      </td>
                      <td
                        width="130px"
                        class="bg_sch"
                        style="
                          min-width: 145px;
                          max-width: 145px;
                          padding: 1px 2px 0 4px;
                          border-right: solid 1px #cccccd;
                        "
                        valign="top"
                      >
                        <!-- SCH TABLE -->
                        <table
                          border="0"
                          height="146px"
                          class="aj_sm"
                          style="margin: 0px"
                          cellpadding="0"
                          cellspacing="0"
                        >
                          <tbody>
                            <tr>
                              <td
                                colspan="2"
                                valign="top"
                                class="mz_fix"
                                height="1px"
                                style="padding: 1px 0px 0px 1px"
                                translate
                              >
                                год от, до
                              </td>
                            </tr>
                            <tr>
                              <td valign="top" style="padding: 0px 0px 0px 1px">
                                <div class="op_fix">
                                  <div class="sl">
                                    <select
                                      name="year"
                                      type="text"
                                      class="sl_sch"
                                      formControlName="yearFrom"
                                    >
                                      <option value="" selected="">&nbsp;</option>
                                      <option *ngFor="let year of years" [value]="year">
                                        {{ year }}
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </td>
                              <td valign="top" style="padding: 0px 0px 0px 2px">
                                <div class="op_fix">
                                  <div class="sl">
                                    <select
                                      name="year2"
                                      type="text"
                                      class="sl_sch"
                                      formControlName="yearTo"
                                    >
                                      <option value="" selected="">&nbsp;</option>
                                      <option *ngFor="let year of years" [value]="year">
                                        {{ year }}
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td colspan="2" height="17px" style="padding: 4px 0px 1px 1px">
                                <span translate>Пробег от, до (тыс.км)</span>
                              </td>
                            </tr>
                            <tr>
                              <td class="aj_sm">
                                <input
                                  formControlName="mileageFrom"
                                  value=""
                                  type="text"
                                  class="inp_sch"
                                  (focus)="clearValue('mileageFrom')"
                                />
                              </td>
                              <td class="aj_sm">
                                <input
                                  formControlName="mileageTo"
                                  style="margin-left: 1px"
                                  name="probeg2"
                                  value=""
                                  type="text"
                                  class="inp_sch"
                                  (focus)="clearValue('mileageTo')"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td
                                colspan="2"
                                height="17px"
                                style="padding: 3px 0px 1px 1px"
                                translate
                              >
                                Объем двигат. от, до
                              </td>
                            </tr>
                            <tr>
                              <td class="aj_sm">
                                <input
                                  formControlName="engineVolumeFrom"
                                  value=""
                                  type="text"
                                  class="inp_sch"
                                  (focus)="clearValue('engineVolumeFrom')"
                                />
                              </td>
                              <td class="aj_sm">
                                <input
                                  style="margin-left: 1px"
                                  formControlName="engineVolumeTo"
                                  value=""
                                  type="text"
                                  class="inp_sch"
                                  (focus)="clearValue('engineVolumeTo')"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td colspan="2" style="padding: 2px 0 0 1px">
                                <input
                                  formControlName="bid"
                                  type="text"
                                  class="inp_sch"
                                  (focus)="clearValue('bid')"
                                  style="width: 122px"
                                />
                              </td>
                            </tr>
                            <tr></tr>
                          </tbody>
                        </table>
                        <!-- /SCH TABLE -->
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td
                valign="top"
                align="center"
                style="padding: 46px 20px 0 12px; line-height: 0.5em"
              >
                <button
                  nz-button
                  class="i_but"
                  type="button"
                  style="margin: 0 0 10px; width: 70px; border-color: rgb(139, 144, 154)"
                  (click)="resetFilter()"
                >
                  {{ 'Сброс' | translate }}
                </button>
                <br />
                <button
                  nz-button
                  nzType="primary"
                  class="i_but"
                  style="margin: 0 0 10px 0; width: 70px"
                  (click)="filter()"
                >
                  {{ 'Поиск' | translate }}
                </button>
                <br />
                <a
                  style="line-height: 0.7em; font-weight: 400; text-decoration: none"
                  href="javascript:void(0)"
                  (click)="extendedSearchVisible = !extendedSearchVisible"
                >
                  <span style="font-size: 11px; font-family: Tahoma" translate> </span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </div>
  </nz-collapse-panel>
</nz-collapse>
