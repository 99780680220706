<div class="auc_list_shame" id="auc_list_shame_6">
  <img src="/assets/images/auctions/{{ lang }}/ARAI.png" class="shame_img" alt="ARAI">
  <div
    class="shame_blocks shame_block_1"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.LOT_NUM' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_2"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.FIRST_REG_DATE' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_3"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.CAR_OPTIONS' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_4"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.MODEL_YEAR' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_5"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.ENGINE_CAPACITY' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_6"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.CHASSIS' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_7"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.CARRYING_CAPACITY' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_8"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.SEATS' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_9"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.GRADE' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_10"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.INTERIOR_GRADE' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_11"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.EXTERIOR_GRADE' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_12"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.USE_TYPE' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_13"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.TRANSMISSION' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_14"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.TECH_INSP' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_15"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.CONDITIONER' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_16"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.MILEAGE' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_17"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.FUEL_TYPE.TITLE' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_18"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.VEHICLE_COLOR' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_19"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.INTERIOR_COLOR' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_20"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.COLOR_CODE' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_21"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.ARAI.RIGHT_STEERING_WHEEL' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_22"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.ORIGINAL_EQUIPMENT' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_23"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.SELLER_NOTES' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_24"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.RE_REG_DEADLINE.TITLE' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div class="shame_blocks shame_block_25"></div>
  <div
    class="shame_blocks shame_block_26"
    nz-tooltip
    [nzTooltipTitle]="shameblock26"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  >
    <ng-template #shameblock26>
      <p>{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.ARAI.INSPECTOR_NOTES_DESCR_1' | translate }}</p>
      <p>{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.ARAI.INSPECTOR_NOTES_DESCR_2' | translate }}</p>
      <p>{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.ARAI.INSPECTOR_NOTES_DESCR_3' | translate }}</p>
    </ng-template>
  </div>
  <div class="shame_blocks shame_block_27"></div>
  <div
    class="shame_blocks shame_block_28"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.RE_REG_DEADLINE.TITLE' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_29"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.RECYCLING_FEE' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_31"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.PLATE_NUM' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_32"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.CHASSIS_NUM' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_33"
    nz-tooltip
    [nzTooltipTitle]="shameblock33"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  >
    <ng-template #shameblock33>
      <div class="header">
        {{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.DAMAGE_SCHEME.TITLE' | translate }}
      </div>
      <p>
        {{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.DAMAGE_SCHEME.DESCR' | translate }}
        <a href="https://ecarjp.com/article/sheet" target="_blank">
          {{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.DAMAGE_SCHEME.LINK' | translate }}
        </a>
      </p>
    </ng-template>
  </div>
  <div
    class="shame_blocks shame_block_34"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.WILL_BE_SENT_LATER.TITLE' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
</div>
