import { QueryHandlingService } from '@akebono/core';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { defer, iif, Observable, of } from 'rxjs';
import { first, pluck, shareReplay, switchMap } from 'rxjs/operators';
import { Auction } from 'src/app/modules/graphql/service/graphql-directory-service';

import { AuctionService } from '../../../../../services/auction.service';
import { AuctionRatingGQL } from '../../../../graphql/service/graphql-directory-service';
import { AuctionReviewsComponent } from '../auction-reviews/auction-reviews.component';

@Component({
  selector: 'app-lot-auction-rating',
  templateUrl: './auction-rating.component.html',
  styleUrls: ['./auction-rating.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuctionRatingComponent implements OnInit {
  @Input() auctionName: string;
  @Input() showComments: boolean = false;

  auction$: Observable<Auction | null>;

  constructor(
    private translate: TranslateService,
    private modalService: NzModalService,
    private qhs: QueryHandlingService,
    private auctionService: AuctionService,
    private auctionRatingGQL: AuctionRatingGQL,
  ) {}

  ngOnInit(): void {
    this.auction$ = this.auctionService.getByName(this.auctionName).pipe(
      switchMap((auction) =>
        iif(
          () => Boolean(auction?.id),
          defer(() =>
            this.qhs
              .fetch(this.auctionRatingGQL, { auctionId: auction.id }, 'network-only')
              .data.pipe(pluck('auction')),
          ),
          of(null),
        ),
      ),
      shareReplay(1),
    );
  }

  async openReviews(): Promise<void> {
    const auction = await this.auction$.pipe(first()).toPromise();

    this.modalService.create({
      nzTitle: this.translate.instant('AUCTION_RATING.REVIEWS_AUCTION', {
        auctionName: this.auctionName,
      }),
      nzContent: AuctionReviewsComponent,
      nzFooter: null,
      nzBodyStyle: {
        padding: '10px',
      },
      nzComponentParams: {
        auctionId: auction.id,
      },
    });
  }
}
