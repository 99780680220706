import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { LotBidAmountComponent } from './lot-bid-amount.component';

@NgModule({
  declarations: [LotBidAmountComponent],
  imports: [CommonModule, TranslateModule],
  exports: [LotBidAmountComponent],
})
export class LotBidAmountModule {}
