<nz-layout style="min-height: 100vh">
  <nz-content class="wrap">
    <div *akbCheck="['cars.bids']">{{ navigateToMain() }}</div>
    <router-outlet></router-outlet>
    <nz-result
      nzStatus="403"
      [nzTitle]="'FORBIDDEN_TITLE' | translate"
      [nzSubTitle]="'FORBIDDEN_MESSAGE' | translate"
    >
      <div nz-result-extra nz-row nzJustify="center" [nzGutter]="[0, 10]">
        <nz-col [nzSpan]="24">
          <nz-spin nzSize="large" [nzSpinning]="loading$ | async">
            <div *ngIf="loading$ | async" style="height: 50px"></div>
          </nz-spin>
          <p *ngIf="hasActiveGuidanceRequest$ | async">
            {{ 'GUIDANCE_REQUEST_ALREADY_EXISTS' | translate }}
          </p>
          <button
            *ngIf="
              (loading$ | async) === false &&
              (hasLotsPermission$ | async) === false &&
              (hasActiveGuidanceRequest$ | async) === false
            "
            nz-button
            nzType="primary"
            [nzLoading]="creatingRequest$ | async"
            (click)="createGuidanceRequest()"
          >
            {{ 'SEND_REQUEST_TO_ACCESS' | translate }}
          </button>
        </nz-col>
        <nz-col [nzSpan]="24">
          <button nz-button nzDanger (click)="singOut()">{{ 'SIGN_OUT' | translate }}</button>
        </nz-col>
      </div>
    </nz-result>
  </nz-content>
</nz-layout>
