import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { AutoLotView } from '../../../const';

@Component({
  selector: 'app-lot-title-full',
  templateUrl: './lot-title-full.component.html',
  styleUrls: ['./lot-title-full.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotTitleFullComponent {
  @Input() lot: AutoLotView;
}
