import { MutationHandlingServiceV2, NotificationService } from '@akebono/core';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Mutation } from 'apollo-angular';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { of } from 'rxjs';
import { finalize } from 'rxjs/operators';

import {
  BidDeleteRequestCreateGQL,
  BidDeleteRequestCreateInput,
  UserRequestTypeEnum,
} from '../../graphql/service/graphql-auto-main-service';

@Component({
  selector: 'app-create-user-request-modal',
  templateUrl: './create-user-request-modal.component.html',
  styleUrls: ['./create-user-request-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateUserRequestModalComponent {
  @Input() type: UserRequestTypeEnum;
  @Input() refetch = false;
  @Input() input: Omit<BidDeleteRequestCreateInput, 'message'>;

  message: string = null;

  creating$ = of(false);

  UserRequestTypeEnum = UserRequestTypeEnum;

  constructor(
    public modal: NzModalRef,
    private translate: TranslateService,
    private mhs: MutationHandlingServiceV2,
    private notification: NotificationService,
    private bidDeleteRequestCreateGQL: BidDeleteRequestCreateGQL,
  ) {}

  create(): void {
    switch (this.type) {
      case UserRequestTypeEnum.BidDeleteRequest:
        this.mutate(this.bidDeleteRequestCreateGQL, {
          input: {
            ...(this.input as Omit<BidDeleteRequestCreateInput, 'message'>),
            message: this.message,
          },
        });
        break;
      default:
        this.notification.renderError(this.translate.instant('REQUEST_TYPE_NOT_SUPPORTED'));
        throw new Error(this.translate.instant('REQUEST_TYPE_NOT_SUPPORTED'));
    }
  }

  private mutate<T, R>(mutation: Mutation<T, R>, input: R): void {
    this.creating$ = this.mhs
      .mutate(mutation, input, {
        refetch: this.refetch,
        successTranslationKey: 'USER_REQUESTS.CREATE_SUCCESS',
        failureTranslationKey: 'USER_REQUESTS.CREATE_FAIL',
      })
      .loading.pipe(finalize(() => this.modal.destroy()));
  }
}
