<div>
  <div class="subscribe-container" *ngIf="isLoading">
    <nz-spin nzSimple> </nz-spin>
  </div>
  <nz-row class="center">
    <div #canvas></div>
  </nz-row>
  <nz-row class="center" *ngIf="showButton">
    <a
      nz-button
      nzType="primary"
      nzShape="round"
      [href]="getLink()"
      target="_blank"
    >
      <span nz-icon nzType="link" nzTheme="outline"></span>
      {{ "PROFILE.CONNECT_SUBSCRIBE" | translate }}
    </a>
  </nz-row>
</div>
