<div class="switch-box" [ngClass]="{ 'switch-box-checked': value }">
  <nz-switch
    class="switch green-corner-switch"
    [nzDisabled]="(isBoundary$ | async) || isInvalidBidCondition || isDisabled"
    [(ngModel)]="value"
  ></nz-switch>
  <span class="switch-text">{{ 'LOT_BIDDING_MODAL.GREEN_CORNER' | translate }}</span>
</div>
<nz-alert
  class="switch-alert"
  nzType="warning"
  *ngIf="isBoundary$ | async"
  [nzMessage]="'LOT_BIDDING_MODAL.GREEN_CORNER_WARNING' | translate"
></nz-alert>
