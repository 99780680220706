import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, combineLatest, iif, of, Subject } from 'rxjs';
import { map, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';
import {
  AutoLotView,
  BoundaryStatus,
  getCalcData,
  getLotSourceFromParams,
  mergeSavedLotToAutoLotView,
  ReactiveLotData,
} from 'src/app/const';
import {
  Lot,
  LotTypeEnum,
  UserLotGQL,
  UserLotQuery,
} from 'src/app/modules/graphql/service/graphql-auto-main-service';
import {
  LotSource,
  OnepriceLotGQL,
  OnepriceLotQuery,
} from 'src/app/modules/graphql/service/graphql-cars-default-service';
import { SanctionsService } from 'src/app/services/sanctions.service';

import { environment } from '../../../../../environments/environment';
import { hasCurrentLanguageTranslation } from '../../../../const';
import { LimitsService } from '../../../../services/limits.service';
import { LotViewsIncrementService } from '../../../../services/lot-views-increment.service';

@Component({
  selector: 'app-oneprice-lot',
  templateUrl: './oneprice-lot.component.html',
  styleUrls: ['./oneprice-lot.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotComponent implements OnInit {
  data$: ReactiveLotData = of({});
  loading$ = new BehaviorSubject<boolean>(true);
  refetch$ = new Subject<void>();
  lotNotFound$ = of(false);

  activeSlide: number = 1;

  LotSource = LotSource;
  BoundaryStatus = BoundaryStatus;

  statisticsOpen = false;

  constructor(
    private title: Title,
    private route: ActivatedRoute,
    private limitsService: LimitsService,
    private sanctionsService: SanctionsService,
    private lotViewsIncrementService: LotViewsIncrementService,
    private autoLotGQL: OnepriceLotGQL,
    private userLotGQL: UserLotGQL,
  ) {}

  ngOnInit(): void {
    this.data$ = combineLatest([
      this.route.params,
      this.refetch$.asObservable().pipe(startWith(true)),
    ]).pipe(
      tap(([_, refetch]) => console.log('refect', refetch)),
      tap(() => this.loading$.next(true)),
      map(([params]) => ({
        lotId: params.lot,
        lotType: LotTypeEnum.Oneprice,
        lotSource: getLotSourceFromParams(params),
      })),
      tap(({ lotType, lotId, lotSource }) =>
        this.lotViewsIncrementService.increment(lotId, lotType, lotSource),
      ),
      switchMap((initData) =>
        combineLatest([
          // this.autoLotGQL.fetch({ id: initData.lotId || 0 }, { fetchPolicy: 'network-only' }),
          this.userLotGQL.fetch(
            {
              id: initData.lotId,
              lotSource: initData.lotSource,
              subgroup: initData.lotType,
            },
            { fetchPolicy: 'network-only' },
          ),
        ]).pipe(
          map(([userResult]) => ({
            initData,
            userResult,
          })),
        ),
      ),
      switchMap((data) =>
        iif(
          () => Boolean(data.userResult.data.lot),
          of(data).pipe(
            map((data) => ({
              ...data,
              lot: this.compileAutoLotView(
                'const',
                null,
                data.userResult.data.lot,
                data.userResult.data.lotViewsCount,
              ),
            })),
            map((data) => ({
              ...data.initData,
              lot: data.lot,
              savedLot: data.userResult.data?.lot,
              icons: data.userResult.data?.lot?.icons ?? [],
              currentUser: data.userResult.data.currentUser,
              currentUserLastBid: data.userResult.data?.lot?.currentUserLastBid,
              currentUserLimitsOverrideAvailable:
                data.userResult.data?.lot?.currentUserLimitsOverrideAvailable,
              currentUserLotMarks: data.userResult.data?.lot?.currentUserLotMarks || [],
              bestBid: data.userResult.data?.lot?.bestBid,
              hasBestBid: data.userResult.data?.lot?.hasBestBid,
              translations: data.userResult.data?.lot?.translations,
              isUsingSavedImages: Boolean(data.userResult.data.lot),
              hasTranslations: hasCurrentLanguageTranslation(
                data.userResult.data?.lot?.translations,
                data.userResult.data.currentUser,
              ),
              fullVin: Boolean(data.userResult.data?.lot?.vin),
              limits: this.limitsService.getLimits(data.lot, data.userResult.data?.lot as Lot),
              calcData: getCalcData(
                data.lot,
                'oneprice',
                data.userResult.data?.lot?.currentUserLastBid?.amount,
              ),
              isSanctioned: this.sanctionsService.isLotSanctioned(
                data.lot,
                data.initData.lotType,
                data.userResult.data.currentUser.countryIso,
                data.userResult.data.currentUser.isAuctioneer ||
                  data.userResult.data.currentUser.isTranslator,
              ),
            })),
            tap((data) => {
              this.title.setTitle(`ECar JP Lot #${data.lot.bid}`);
            }),
          ),
          of(data.initData),
        ),
      ),
      tap(() => this.loading$.next(false)),
      shareReplay(1),
    );

    this.lotNotFound$ = combineLatest([this.loading$, this.data$]).pipe(
      map(([loading, data]) => !loading && !data.lot),
    );
  }

  compileAutoLotView(
    dumpVersion: string,
    autoLot?: OnepriceLotQuery['onepriceLot'],
    savedLot?: UserLotQuery['lot'],
    viewsCount?: UserLotQuery['lotViewsCount'],
  ): AutoLotView {
    let lot: AutoLotView;
    if (autoLot) {
      lot = {
        ...autoLot,
        images: autoLot.images
          .split('#')
          .map((url) => url.replace('https://p3.aleado.com/', environment.imageProxy)),
        auctionName: autoLot.autoAuction?.name || 'MISSING',
        companyId: autoLot.companyRef,
        modelId: autoLot.modelNameRef,
        dumpVersion,
        viewsCount: viewsCount.viewsCount,
        vin: autoLot.vin || autoLot.frame + '-',
      };
    }

    if (savedLot) {
      lot = this.updateAutoLotViewBySavedData(lot, savedLot);
    }
    return lot;
  }

  updateAutoLotViewBySavedData(lot: AutoLotView, savedLot?: UserLotQuery['lot']): AutoLotView {
    try {
      lot = mergeSavedLotToAutoLotView(lot, savedLot);
    } catch (e) {
      console.error(e);
    }

    lot.productionDate = savedLot.productionDate;
    lot.vin = savedLot.vin || lot?.vin;
    lot.images = lot?.images?.length > savedLot?.images?.length ? lot?.images : savedLot?.images;
    lot.negotiationsPrice = savedLot.negotiationsPrice;
    return lot;
  }

  goToFirstSlide(isUsingSavedImages: boolean): void {
    if (!isUsingSavedImages) {
      this.activeSlide = 1;
    }
  }

  scrollToTranslations(): void {
    document.getElementById('lotTranslations').scrollIntoView({ behavior: 'smooth' });
  }

  scrollToPreviousSales(): void {
    document.getElementById('previousSales').scrollIntoView({ behavior: 'smooth' });
    this.statisticsOpen = true;
  }
}
