import { AkebonoCoreModule } from '@akebono/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CarOutline, ClockCircleOutline, MessageOutline, UserOutline } from '@ant-design/icons-angular/icons';
import { TranslateModule } from '@ngx-translate/core';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzRateModule } from 'ng-zorro-antd/rate';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzTagModule } from 'ng-zorro-antd/tag';

import { AuctionRatingComponent } from './components/auction-rating/auction-rating.component';
import { AuctionReviewsComponent } from './components/auction-reviews/auction-reviews.component';

@NgModule({
  declarations: [AuctionRatingComponent, AuctionReviewsComponent],
  imports: [
    CommonModule,
    FormsModule,
    AkebonoCoreModule,
    TranslateModule,
    NzListModule,
    NzRateModule,
    NzButtonModule,
    NzTagModule,
    NzPaginationModule,
    NzAvatarModule,
    NzModalModule,
    NzGridModule,
    NzBadgeModule,
    NzSpaceModule,
    NzIconModule.forRoot([UserOutline, CarOutline, ClockCircleOutline, MessageOutline]),
  ],
  exports: [AuctionRatingComponent],
})
export class LotAuctionFeedbackModule {}
