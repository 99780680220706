<section #listSection>
  <nz-spin [nzSpinning]="loading$ | async" nzSize="large">
    <nz-row [nzGutter]="[30, 30]">
      <nz-col *ngFor="let news of news$ | async" [nzXs]="24" [nzMd]="12" [nzXl]="8">
        <app-news-card [news]="news" [language]="language$ | async"></app-news-card>
      </nz-col>
      <nz-col *ngIf="isNoNews$ | async" [nzSpan]="24">
        <nz-empty></nz-empty>
      </nz-col>
    </nz-row>
  </nz-spin>
</section>

<section *ngIf="total$ | async">
  <nz-pagination
    nzSize="small"
    [nzPageIndex]="page"
    [nzPageSize]="pageSize"
    [nzTotal]="total$ | async"
    nzShowSizeChanger
    (nzPageIndexChange)="changePage($event)"
    (nzPageSizeChange)="changePageSize($event)"
  ></nz-pagination>
</section>
