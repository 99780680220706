<nz-row [nzGutter]="[5, 5]">
  <nz-col *ngFor="let url of photoUrls">
    <div
      class="photo-container"
      [class.selected]="url === selectedPhotoUrl"
      (click)="selectPhoto(url)"
    >
      <img [src]="url" />
    </div>
  </nz-col>
</nz-row>
