import { PermissionsGuard } from '@akebono/core';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProtectedGuard } from 'ngx-auth';
import { PERMISSION_LOTS, PERMISSION_ONEPRICE_LOTS } from 'src/app/const';

import { LayoutComponent } from '../../components/layout/layout.component';
import { LotComponent } from './components/oneprice-lot/oneprice-lot.component';
import { ListComponent } from './components/oneprice-lots-list/oneprice-lots-list.component';

const routes: Routes = [
  {
    path: 'oneprice',
    component: LayoutComponent,
    children: [
      {
        path: '',
        component: ListComponent,
        canActivate: [ProtectedGuard, PermissionsGuard],
        data: {
          permissions: [PERMISSION_LOTS, PERMISSION_ONEPRICE_LOTS],
          breadcrumb: 'Lots',
        },
      },
      {
        path: 'lot/:lot',
        component: LotComponent,
        canActivate: [PermissionsGuard, ProtectedGuard],
        data: {
          permissions: [PERMISSION_LOTS, PERMISSION_ONEPRICE_LOTS],
          breadcrumb: 'Lot',
        },
      },
    ],
    canActivate: [PermissionsGuard, ProtectedGuard],
    data: {
      permissions: [PERMISSION_LOTS, PERMISSION_ONEPRICE_LOTS],
      breadcrumb: 'One Price',
    },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AuctionsOnepriceRoutingModule {}
