import { Currency, CurrencyService, QueryHandlingService } from '@akebono/core';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { DateTime } from 'luxon';
import { Observable, of, timer } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import {
  AutoLotView,
  GearboxType,
  mileageToInterval,
  normalizeAuctionStartDatetime,
} from 'src/app/const';
import { gearboxTypeMap } from 'src/app/gearbox-types';
import {
  BidAmountRecommendationGQL,
  LotSource,
  LotStatusEnum,
  LotTypeEnum,
  UserLotQuery,
} from 'src/app/modules/graphql/service/graphql-auto-main-service';
import { AveragePriceByRatingGQL } from 'src/app/modules/graphql/service/graphql-stats-auto-service';

@Component({
  selector: 'app-lot-main-info',
  templateUrl: './lot-main-info.component.html',
  styleUrls: ['./lot-main-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotMainInfoComponent implements OnInit {
  @Input() forShared = false;
  @Input() lot: AutoLotView;
  @Input() lotType: LotTypeEnum;
  @Input() lotSource: LotSource;
  @Input() hasTranslations: boolean;
  @Input() currentUser: UserLotQuery['currentUser'];
  @Input() currentUserLastBid?: UserLotQuery['lot']['currentUserLastBid'];
  @Input() bestBid?: UserLotQuery['lot']['bestBid'];
  @Input() hasBestBid?: boolean;
  @Input() mileagePostfix: string = '000 km';
  @Output() actionComplete = new EventEmitter<void>();

  recommendedBid$: Observable<number | null>;
  isAveragePrice = false;

  isEndSoon$ = of(false);
  isActiveLot$ = of(false);
  deadlineLotTime = 0;
  currentCurrency$: Observable<Currency>;

  rowGutters: [number, number] = [20, 10];
  innerRowGutters: [number, number] = [20, 0];

  LotType = LotTypeEnum;

  constructor(
    currencyService: CurrencyService,
    private qhs: QueryHandlingService,
    private bidAmountRecommendationGQL: BidAmountRecommendationGQL,
    private averagePriceByRatingGQL: AveragePriceByRatingGQL,
  ) {
    this.currentCurrency$ = currencyService.currentCurrency$;
  }

  ngOnInit(): void {
    const nowTimer$ = timer(0, 1000).pipe(map(() => DateTime.now()));
    const auctionStart = normalizeAuctionStartDatetime(this.lot.time);
    this.deadlineLotTime = auctionStart.toMillis();

    this.isActiveLot$ = nowTimer$.pipe(
      map((now) => now <= auctionStart),
      distinctUntilChanged(),
    );

    this.isEndSoon$ = nowTimer$.pipe(
      map((now) => now >= auctionStart.minus({ minute: 5 })),
      distinctUntilChanged(),
    );

    const recommendedBidVariables = {
      companyEn: this.lot.company,
      modelEn: this.lot.modelName,
      modelTypeEn: this.lot.frame,
      year: Number(this.lot.year),
      mileage: Number(this.lot.mileage),
      ratingEn: this.lot.rate,
      noOfDays: 180,
    };

    const bidRecommendationRef = this.qhs.watch(
      this.bidAmountRecommendationGQL,
      recommendedBidVariables,
      'cache-first',
    );

    this.recommendedBid$ = bidRecommendationRef.data.pipe(
      map((result) => result.weightedHarmonicMean),
      switchMap((value) => {
        if (value) {
          return of(value);
        } else {
          if (recommendedBidVariables.noOfDays < 730) {
            recommendedBidVariables.noOfDays = 730;
            bidRecommendationRef.refetch(recommendedBidVariables);
            return this.recommendedBid$;
          } else {
            return this.qhs
              .fetch(
                this.averagePriceByRatingGQL,
                {
                  companyEn: recommendedBidVariables.companyEn,
                  modelEn: recommendedBidVariables.modelEn,
                  modelTypeEn: recommendedBidVariables.modelTypeEn,
                },
                'cache-first',
              )
              .data.pipe(
                map((x) => {
                  this.isAveragePrice = true;
                  const lotMilage = Number(mileageToInterval(this.lot.mileage));
                  const lotYear = Number(this.lot.year);
                  return x.averagePriceByRating.find(
                    (z) =>
                      z.score == this.lot.rate &&
                      z.year == lotYear &&
                      z.mileageInterval == lotMilage,
                  )?.avg;
                }),
              );
          }
        }
      }),
    );
  }

  get gearboxType(): GearboxType | null {
    const transmission = this.lot.transmission?.trim();
    const gearboxType = gearboxTypeMap.get(transmission);

    return gearboxType ?? null;
  }

  get canREnderNegotiationsPrice(): boolean {
    return (
      this.lotType !== LotTypeEnum.Moto &&
      Boolean(this.lot.negotiationsPrice) &&
      this.lot.status === LotStatusEnum.Negotiations
    );
  }

  get canRenderBestBid(): boolean {
    return this.currentUser.canSeeBestBid && this.bestBid && !this.bestBid.isByCurrentUser;
  }
}
