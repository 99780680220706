import { MutationHandlingServiceV2, QueryHandlingService, WatchQueryRef } from '@akebono/core';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ReactiveLoadings } from 'src/app/const';
import {
  UserFilterPreset,
  UserFilterPresetDeleteGQL,
  UserFilterPresetsGQL,
  UserFilterPresetsQuery,
  UserFilterPresetsQueryVariables,
  UserFilterPresetUpdateGQL,
} from 'src/app/modules/graphql/service/graphql-auto-main-service';

import {
  CreateUserFilterPresetModalComponent,
} from '../create-user-filter-preset-modal/create-user-filter-preset-modal.component';

@Component({
  selector: 'app-user-filter-presets',
  templateUrl: './user-filter-presets.component.html',
  styleUrls: ['./user-filter-presets.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserFilterPresetsComponent implements OnInit {
  @Input() presetContent: any;
  @Output() presetSelected = new EventEmitter<UserFilterPreset>();

  queryRef: WatchQueryRef<UserFilterPresetsQuery, UserFilterPresetsQueryVariables>;

  deleting: ReactiveLoadings = {};
  updating: ReactiveLoadings = {};

  constructor(
    private qhs: QueryHandlingService,
    private translate: TranslateService,
    private modalService: NzModalService,
    private mhs: MutationHandlingServiceV2,
    private userFilterPresetsGQL: UserFilterPresetsGQL,
    private userFilterPresetUpdateGQL: UserFilterPresetUpdateGQL,
    private userFilterPresetDeleteGQL: UserFilterPresetDeleteGQL,
  ) {}

  ngOnInit(): void {
    this.queryRef = this.qhs.watch(this.userFilterPresetsGQL);
  }

  openCreateUserFilterPresetModal(): void {
    this.modalService.create({
      nzTitle: this.translate.instant('CREATE_USER_FILTER_PRESET'),
      nzContent: CreateUserFilterPresetModalComponent,
      nzComponentParams: {
        presetContent: this.presetContent,
      },
    });
  }

  deletePreset(preset: UserFilterPreset): void {
    this.deleting[preset.id] = this.mhs.mutate(
      this.userFilterPresetDeleteGQL,
      {
        input: {
          id: preset.id,
        },
      },
      { refetch: true },
    ).loading;
  }

  toggleEmailCompilations(preset: UserFilterPreset): void {
    this.updating[preset.id] = this.mhs.mutate(
      this.userFilterPresetUpdateGQL,
      {
        input: {
          id: preset.id,
          compilationEmailEnabled: !Boolean(preset.compilationEmailEnabled),
        },
      },
      { refetch: true },
    ).loading;
  }
}
