import { QueryHandlingService } from '@akebono/core';
import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { combineLatest, Observable } from 'rxjs';
import { map, shareReplay, startWith, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import {
  NewsCounterGQL,
  NewsCounterQueryVariables,
} from '../../graphql/service/graphql-content-service';
import { CurrentUserNewsDataService } from './current-user-news-data.service';

@Injectable()
export class NewsCounterService {
  readonly loading$: Observable<boolean>;
  readonly hasUnreadNews$: Observable<boolean>;
  readonly unreadNewsCount$: Observable<number>;

  constructor(
    qhs: QueryHandlingService,
    currentUserNewsDataGQL: CurrentUserNewsDataService,
    newsCounterGQL: NewsCounterGQL,
  ) {
    const queryRef$ = currentUserNewsDataGQL.userNewsCountry$.pipe(
      map(
        (newsCountry) =>
          <NewsCounterQueryVariables>{
            country: newsCountry,
            domain: environment.newsDomain,
            limitDate: DateTime.now().minus({ weeks: 3 }).toISO(),
          },
      ),
      map((variables) => qhs.watch(newsCounterGQL, variables, 'network-only')),
      shareReplay(1),
    );

    this.loading$ = combineLatest([
      queryRef$.pipe(
        switchMap((ref) => ref.loading),
        startWith(true),
      ),
      currentUserNewsDataGQL.loading$,
    ]).pipe(
      map((loadings) => loadings.some((v) => v)),
      shareReplay(1),
    );

    this.unreadNewsCount$ = queryRef$.pipe(
      switchMap((ref) => ref.data),
      map((data) => data.unreadNewsCount?.totalCount || 0),
      shareReplay(1),
    );
    this.hasUnreadNews$ = this.unreadNewsCount$.pipe(map((count) => !!count));
  }
}
