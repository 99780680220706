<h3 translate>TRANSLATION</h3>
<nz-collapse>
  <nz-collapse-panel
    *ngFor="let panel of translationPanels$ | async"
    [nzActive]="panel.active"
    nzHeader="{{ panel.translation.language | uppercase | translate }}"
  >
    <p style="margin: 0; white-space: pre-line" [innerHtml]="panel.translation.message"></p>
    <br />
    <audio
      #audio
      *ngIf="panel.translation.file"
      [src]="panel.translation.file?.fullPath"
      controls
    ></audio>
  </nz-collapse-panel>
</nz-collapse>
