import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

import { ConnectTelegramModalComponent } from './connect-telegram-modal.component';

@Injectable()
export class ConnectTelegramService {
  public constructor(
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private modalService: NzModalService,
  ) {}

  public watch(queryParamName: string, queryParamValue: string): Observable<void> {
    return this.route.queryParamMap.pipe(
      filter((params) => params.has(queryParamName)),
      map((params) => params.get(queryParamName)),
      filter((paramValue) => queryParamValue === paramValue),
      tap(() => this.openModal()),
      tap(() => this.router.navigate([])),
      map(() => {}),
    );
  }

  public openModal(): NzModalRef {
    return this.modalService.create({
      nzTitle: this.translate.instant('CONNECT_TELEGRAM.TITLE'),
      nzContent: ConnectTelegramModalComponent,
      nzFooter: null,
      nzWidth: '400px',
    });
  }
}
