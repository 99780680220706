<button
  nz-button
  nzSize="small"
  nz-popover
  nzPopoverTrigger="click"
  [(nzPopoverVisible)]="showPopover"
  [nzPopoverTitle]="title"
  [nzPopoverContent]="body"
  (nzPopoverVisibleChange)="showPopover = $event"
>
  <i nz-icon nzType="edit" nzTheme="outline"></i>
</button>
<ng-template #title>
  <nz-row nzJustify="space-between">
    <nz-col>{{ 'COMPANY_MODEL' | translate }}</nz-col>
    <nz-col>
      <i nz-icon class="pointer" nzType="close" nzTheme="outline" (click)="showPopover = false"></i>
    </nz-col>
  </nz-row>
</ng-template>
<ng-template #body>
  <nz-descriptions nzBordered nzSize="small">
    <nz-descriptions-item nzTitle="{{ 'COMPANY' | translate }}">
      <input nz-input [(ngModel)]="company" />
    </nz-descriptions-item>
    <nz-descriptions-item nzTitle="{{ 'MODEL' | translate }}">
      <input nz-input [(ngModel)]="model" />
    </nz-descriptions-item>
  </nz-descriptions>
  <section>
    <button nz-button nzSize="small" nzType="primary" [nzLoading]="saving | async" (click)="save()">
      {{ 'SAVE' | translate }}
    </button>
  </section>
</ng-template>
