<span *ngIf="amount > 5000">
  {{ 'SCORE.TITLE' | translate }}:

  <span *rxLet="score$ as score" [title]="'SCORE.RATE_' + score | translate">
    <span *ngIf="(loading$ | async) === false">
      <span *ngIf="score === null">-</span>
      <span *ngIf="score === 0">{{ 'SCORE.RATE_' + score | translate | lowercase }}</span>
      <span *ngIf="score > 0">⭐</span>
      <span *ngIf="score > 30">⭐</span>
      <span *ngIf="score > 40">⭐</span>
      <span *ngIf="score > 60">⭐</span>
      <span *ngIf="score > 85">⭐</span>
    </span>
  </span>
  <span *ngIf="loading$ | async">{{ dots$ | async }}</span>
</span>
