<button
  *ngIf="!complete"
  nz-button
  nzSize="large"
  [nzBlock]="fullSize"
  [nzLoading]="loading$ | async"
  (click)="sendGetPhotosRequest()"
>
  {{ 'LOT_PHOTO_REQUEST.SEND' | translate }}
</button>
