import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { LetModule } from '@rx-angular/template';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzStatisticModule } from 'ng-zorro-antd/statistic';

import { UserPurchaseStatisticsService } from './services/user-purchase-statistics.service';
import { UserPurchaseStatisticsComponent } from './user-purchase-statistics.component';

@NgModule({
  declarations: [UserPurchaseStatisticsComponent],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    NzStatisticModule,
    NzSpinModule,
    NzGridModule,
    LetModule,
  ],
  providers: [UserPurchaseStatisticsService],
  exports: [UserPurchaseStatisticsComponent],
})
export class UserPurchaseStatisticsModule {}
