import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NzTagModule } from 'ng-zorro-antd/tag';

import { BidPriorityPassStatusComponent } from './bid-priority-pass-status.component';

@NgModule({
  declarations: [BidPriorityPassStatusComponent],
  imports: [CommonModule, TranslateModule, NzTagModule],
  exports: [BidPriorityPassStatusComponent],
})
export class BidPriorityPassStatusModule {}
