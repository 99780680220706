import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-max-bid-amount-alert',
  templateUrl: './max-bid-amount-alert.component.html',
  styleUrls: ['./max-bid-amount-alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaxBidAmountAlertComponent implements OnInit {
  @Input()
  set maxBidAmountLimit(maxBidAmountLimit: number | null) {
    if (maxBidAmountLimit === null) {
      this.display = 'none';
    } else {
      this.display = 'block';
      this.maxAmount = maxBidAmountLimit;
    }
  }

  maxAmount: number;

  @HostBinding('style.display') display: 'none' | 'block' = 'none';

  constructor() {}

  ngOnInit(): void {}
}
