import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, ReplaySubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { TITLE_MAIN_PAGE } from 'src/app/const';

@Injectable()
export class LayoutService {
  public titlePageHeader$: Observable<string>;
  public isShowBackIcon$: Observable<boolean>;

  constructor(router: Router) {
    const navigationEnd$ = new ReplaySubject<NavigationEnd>();
    router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(navigationEnd$);

    this.isShowBackIcon$ = navigationEnd$.pipe(map((event: NavigationEnd) => event.url !== '/'));

    this.titlePageHeader$ = navigationEnd$.pipe(
      map((event: NavigationEnd) => {
        if (event.url.startsWith('/moto')) {
          return 'MOTO';
        } else if (event.url.startsWith('/auto')) {
          return 'AUTO';
        } else if (event.url.startsWith('/oneprice')) {
          return 'ONEPRICE';
        } else if (event.url.startsWith('/stats-auto')) {
          return 'SELLING_STATISTICS';
        } else {
          return TITLE_MAIN_PAGE;
        }
      }),
    );
  }
}
