<nz-content>
  <main class="inner-content">
    <nz-result
      *ngIf="!(queryRef?.data | push)?.article && !(queryRef?.loading | push)"
      nzStatus="404"
      [nzTitle]="'ARTICLE.ARTICLE_NOT_FOUND' | translate"
    ></nz-result>
    <!-- <nz-result
      *ngIf="
        (queryRef?.data | push)?.article &&
        !(queryRef?.data | push)?.existTranslate && 
        !(queryRef?.loading | push)
      "
      nzStatus="info"
      [nzTitle]="'ARTICLE.TRANSLATE_NOT_FOUND' | translate"
    ></nz-result> -->

    <nz-skeleton [nzLoading]="queryRef?.loading | push" [nzActive]="true">
      <div
        nz-row
        *ngIf="(queryRef?.data | push)?.article && (queryRef?.data | push)"
      >
        <h2>{{ (queryRef?.data | push)?.article?.title[(language$ | push)] }}</h2>
        <div nz-col [nzXs]="24">
          <div [innerHTML]="(queryRef?.data | push)?.article?.content[(language$ | push)]"></div>
        </div>
      </div>
    </nz-skeleton>
  </main>
</nz-content>
