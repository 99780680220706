import { CalculatorModule } from '@akebono/calculator';
import { AkebonoCoreModule } from '@akebono/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';
import { LetModule } from '@rx-angular/template';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

import { AlertsModule } from '../alerts-list/alerts.module';
import { SettingsModalModule } from '../settings-modal/settings-modal.module';
import { BidAmountRecommendedComponent } from './components/bid-amount-recommended/bid-amount-recommended.component';
import { BidAmountScoreComponent } from './components/bid-amount-score/bid-amount-score.component';
import { CommissionAgreementControlComponent } from './components/commission-agreement-control/commission-agreement-control.component';
import { GreenCornerSwitchComponent } from './components/green-corner-switch/green-corner-switch.component';
import { MaxBidAmountAlertComponent } from './components/max-bid-amount-alert/max-bid-amount-alert.component';
import { NavisRulesModalComponent } from './components/navis-rules-modal/navis-rules-modal.component';
import { PriorityPassInfoComponent } from './components/priority-pass-info/priority-pass-info.component';
import { SetBirthdateModalComponent } from './components/set-birthdate-modal/set-birthdate-modal.component';
import { LotBiddingModalComponent } from './lot-bidding-modal.component';
import { DriverModule } from '../driver/driver.module';

@NgModule({
  declarations: [
    LotBiddingModalComponent,
    NavisRulesModalComponent,
    CommissionAgreementControlComponent,
    BidAmountRecommendedComponent,
    BidAmountScoreComponent,
    SetBirthdateModalComponent,
    PriorityPassInfoComponent,
    MaxBidAmountAlertComponent,
    GreenCornerSwitchComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AkebonoCoreModule,
    TranslateModule,
    BrowserAnimationsModule,
    AlertsModule,
    NzButtonModule,
    NzModalModule,
    NzSelectModule,
    NzFormModule,
    NzSpinModule,
    NzInputNumberModule,
    NzCheckboxModule,
    NzInputModule,
    NzIconModule,
    NzAlertModule,
    NzListModule,
    NzPopconfirmModule,
    SettingsModalModule,
    LetModule,
    NzRadioModule,
    NzToolTipModule,
    NzDatePickerModule,
    CalculatorModule,
    NzSwitchModule,
    NzToolTipModule,
    DriverModule,
  ],
  exports: [LotBiddingModalComponent],
})
export class LotBiddingModalModule {}
