<div style="margin-bottom: 15px">
  <nz-row [nzGutter]="[10, 10]">
    <nz-col *ngFor="let item of items">
      <div
        class="item"
        nz-dropdown
        nzPlacement="bottomCenter"
        (click)="addModel(item.label)"
        [nzDropdownMenu]="item.models.length ? menu : null"
      >
        <img [src]="item.img" alt="" />
        <p class="title">{{ 'ARAI_KENKI.LABELS.' + item.label | translate }}</p>

        <nz-dropdown-menu #menu="nzDropdownMenu">
          <ul nz-menu nzSelectable class="list">
            <li nz-menu-item *ngFor="let model of item.models" (click)="addModel(model.value)">
              {{ 'ARAI_KENKI.LABELS.' + model.label | translate }}
            </li>
          </ul>
        </nz-dropdown-menu>
      </div>
    </nz-col>
  </nz-row>
</div>
