import { ConfigService, ObservableHandlingService, QueryHandlingService } from '@akebono/core';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { map, pluck, switchMap, take } from 'rxjs/operators';
import { Article, ArticleGQL } from 'src/app/modules/graphql/service/graphql-content-service';
import { environment } from 'src/environments/environment';

export type Data = {
  article?: Article;
  existTranslate: boolean;
};

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArticleComponent implements OnInit {
  language$: Observable<string>;
  queryRef;

  constructor(
    private title: Title,
    private route: ActivatedRoute,
    private qhs: QueryHandlingService,
    private configService: ConfigService,
    private ohs: ObservableHandlingService,
    private articleGGL: ArticleGQL,
  ) {
    this.title.setTitle('ECar JP Lots');
    this.language$ = this.configService.getCurrentLanguage$().pipe(pluck('localeId'));
  }

  ngOnInit(): void {
    const data$ = combineLatest([this.language$, this.route.params.pipe(pluck('page'))]).pipe(
      take(1),
      switchMap(([lang, page]) =>
        this.qhs
          .fetch(
            this.articleGGL,
            {
              where: {
                page: { eq: page },
                site: { domain: { eq: environment.domain } },
                // site: { domain: { eq: `lots-staging.link` } },
                // country: { eq: 'another' },
              },
            },
            'network-only',
          )
          .data.pipe(
            map((result) => result?.article),
            map((article) => ({
              article,
              existTranslate: Boolean(article?.title[lang] && article?.content[lang]),
            })),
          ),
      ),
    );

    this.queryRef = this.ohs.toReference(data$);
  }
}
