<nz-alert
  *ngFor="let lot of notFoundLots; trackBy: lotInfoIdentity"
  nzType="warning"
  [nzMessage]="alertMessage"
>
  <ng-template #alertMessage>
    {{ 'LOT' | translate }}
    <nz-tag>
      {{ lot.lotNumber }} | {{ lot.auctionDate | date: 'yyyy-MM-dd' }} | {{ lot.auctionName }} |
      {{ lot.name }}
    </nz-tag>
    {{ 'IMPORT_BIDS.LOT_NOT_FOUND' | translate }}
  </ng-template>
</nz-alert>
