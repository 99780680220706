import { AkebonoCoreModule } from '@akebono/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';

import { LotTranslateButtonComponent } from './lot-translate-button.component';

@NgModule({
  declarations: [LotTranslateButtonComponent],
  imports: [CommonModule, TranslateModule, AkebonoCoreModule, NzButtonModule, NzIconModule],
  exports: [LotTranslateButtonComponent],
})
export class LotTranslateButtonModule {}
