import { ColorPoolConfiguration } from './color-pool-configuration.type';

export class ColorPool {
  private readonly colors: ReadonlyArray<string> = [];
  private colorPool: string[] = [];
  private ifEmptyReturn: 'null' | 'exception' | 'random' = 'exception';

  constructor(config: ColorPoolConfiguration) {
    this.colors = config.colors.slice();
    this.colorPool = config.colors.slice();
    if (config.ifEmptyReturn) {
      this.ifEmptyReturn = config.ifEmptyReturn;
    }
  }

  public static get randomColor(): string {
    return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
  }

  public get(): string | null {
    if (this.hasColors === false) {
      return this.handleEmpty();
    }

    return this.colorPool.pop();
  }

  public get hasColors(): boolean {
    return this.colorPool.length > 0;
  }

  public reset(): void {
    this.colorPool = this.colors.slice();
  }

  private handleEmpty(): string | null {
    switch (this.ifEmptyReturn) {
      case 'exception':
        throw new Error('Color poll is empty');
      case 'null':
        return null;
      case 'random':
        return ColorPool.randomColor;
    }
  }
}
