import { ExcelRowColumns } from '../enums/excel-row-columns.enum';

export class ColumnParsingException extends Error {
  readonly columnName: string;
  readonly errorColumn: ExcelRowColumns;

  private static readonly nameByColumn: Record<ExcelRowColumns, string> = {
    [ExcelRowColumns.Name]: 'Model',
    [ExcelRowColumns.Rate]: 'Condition',
    [ExcelRowColumns.Year]: 'Year',
    [ExcelRowColumns.Color]: 'Colour',
    [ExcelRowColumns.Chassis]: 'Chassis',
    [ExcelRowColumns.Mileage]: 'Mileage',
    [ExcelRowColumns.LotNumber]: 'Lot number',
    [ExcelRowColumns.AuctionName]: 'Auction',
    [ExcelRowColumns.AuctionDateTime]: 'Auction date',
    [ExcelRowColumns.Username]: 'User',
    [ExcelRowColumns.Group]: 'Group',
    [ExcelRowColumns.Amount]: 'BID',
  };

  constructor(column: ExcelRowColumns) {
    super(
      `Field ${ColumnParsingException.nameByColumn[column]} it is not specified or has an incorrect format`,
    );
    this.errorColumn = column;
    this.columnName = ColumnParsingException.nameByColumn[this.errorColumn];

    Object.setPrototypeOf(this, ColumnParsingException.prototype);
  }
}
