import { AkebonoCoreModule } from '@akebono/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NgxJsonViewerModule } from 'ngx-json-viewer';

import { LotEventsModalComponent } from './lot-events-modal.component';

@NgModule({
  declarations: [LotEventsModalComponent],
  imports: [
    CommonModule,
    AkebonoCoreModule,
    TranslateModule,
    FormsModule,
    NgxJsonViewerModule,
    NzTableModule,
    NzTagModule,
    NzSwitchModule,
    NzModalModule,
    NzGridModule,
    NzButtonModule,
    NzIconModule,
  ],
  exports: [LotEventsModalComponent],
})
export class LotEventsModalModule {}
