import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';

import { AutoLotView } from '../../../const';
import { BoundaryStatus } from '../../../const';
import {
  LotBoundaryInfo,
  LotBoundaryInfoService,
} from '../../../services/lot-boundary-info.service';

@Component({
  selector: 'app-lot-boundary-info',
  templateUrl: './lot-boundary-info.component.html',
  styleUrls: ['./lot-boundary-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotBoundaryInfoComponent implements OnInit {
  @Input() lot: AutoLotView = null;
  @Input() style: 'alert' | 'text' = 'text';
  @Input() excludeStatuses: BoundaryStatus[] = [];
  @HostBinding('style.display') display: 'none' | 'block' = 'none';

  earlyDate: Date | null = null;
  overdueDate: Date | null = null;
  status: BoundaryStatus | null = null;

  redColorStatuses = [BoundaryStatus.Boundary, BoundaryStatus.Overdue];

  BoundaryStatus = BoundaryStatus;
  constructor(private lotBoundaryInfoService: LotBoundaryInfoService) {}
  ngOnInit(): void {
    const boundaryStatus: LotBoundaryInfo = this.lotBoundaryInfoService.getLotBoundaryInfo(
      this.lot,
    );
    this.earlyDate = boundaryStatus?.earlyDate;
    this.overdueDate = boundaryStatus?.overdueDate;
    this.status = boundaryStatus?.status;

    this.display = this.excludeStatuses.includes(this.status) || !this.status ? 'none' : 'block';
  }
}
