<main nz-row [nzGutter]="[0, 20]" nzJustify="center">
  <section *ngIf="loading$ | async" nz-col [nzSpan]="24">
    <nz-spin nzSize="large">
      <div class="spin-payload"></div>
    </nz-spin>
  </section>
  <section nz-col #qrSection></section>
  <section nz-col>
    <a
      *ngIf="link$ | async as link"
      nz-button
      nzType="primary"
      nzShape="round"
      [href]="link"
      target="_blank"
    >
      <i nz-icon nzType="link" nzTheme="outline"></i>
      {{ 'CONNECT_TELEGRAM.CONNECT_BUTTON' | translate }}
    </a>
  </section>
</main>
