<nz-spin [nzSpinning]="loading$ | async" nzSize="large" [nzTip]="'PRESET_UNSUBSCRIBE.UNSUBSCRIBING' | translate">
  <div *ngIf="loading$ | async" class="payload"></div>
</nz-spin>

<nz-result
  *ngIf="(success$ | async) === true"
  nzStatus="success"
  [nzTitle]="'PRESET_UNSUBSCRIBE.SUCCESS' | translate"
>
  <div nz-result-extra>
    <a nz-button nzType="primary" routerLink="/">
      {{ 'PRESET_UNSUBSCRIBE.GO_HOME' | translate }}
    </a>
  </div>
</nz-result>

<nz-result
  *ngIf="(success$ | async) === false"
  nzStatus="error"
  [nzTitle]="'PRESET_UNSUBSCRIBE.FAIL' | translate"
>
  <div nz-result-extra>
    <a nz-button nzType="primary" routerLink="/">
      {{ 'PRESET_UNSUBSCRIBE.GO_HOME' | translate }}
    </a>
  </div>
</nz-result>
