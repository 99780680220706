import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DateTime } from 'luxon';

import { BoundaryLotsFilter } from '../../modules/graphql/service/graphql-auto-main-service';

@Component({
  selector: 'app-boundary-lots-filter',
  templateUrl: './boundary-lots-filter.component.html',
  styleUrls: ['./boundary-lots-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoundaryLotsFilterComponent implements OnInit {
  @Input() initFilter?: BoundaryLotsFilter;

  form: FormGroup;

  constructor(private fb: FormBuilder, private router: Router, private route: ActivatedRoute) {
    this.form = this.fb.group({
      lotAuctionDate: [null],
    });
  }

  ngOnInit(): void {
    const date = this.initFilter?.lotAuctionDate?.eq
      ? new Date(this.initFilter.lotAuctionDate.eq)
      : new Date();

    this.form.patchValue({
      lotAuctionDate: date,
    });
  }

  filter(): void {
    console.log(this.form.value);
    this.router.navigate([], {
      queryParams: {
        lotAuctionDate: this.form.value.lotAuctionDate
          ? DateTime.fromJSDate(this.form.value.lotAuctionDate).toFormat('yyyy-MM-dd')
          : undefined,
      },
    });
  }

  resetFilter() {
    this.router.navigate([], {
      queryParams: {
        page: this.route.snapshot.queryParams.page,
        pageSize: this.route.snapshot.queryParams.pageSize,
      },
    });
  }
}
