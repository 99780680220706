import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { MileagePipe } from '../../../pipes/mileage.pipe';
import { LotCountersModule } from '../lot-counters/lot-counters.module';
import { LotTitleFullComponent } from './lot-title-full.component';

@NgModule({
  declarations: [LotTitleFullComponent, MileagePipe],
  imports: [CommonModule, TranslateModule, LotCountersModule],
  exports: [LotTitleFullComponent],
})
export class LotTitleFullModule {}
