<nz-collapse>
  <nz-collapse-panel
    [nzActive]="statisticsOpen"
    (nzActiveChange)="initStatistics()"
    nzHeader="{{ 'SELLING_STATISTICS' | translate }}"
  >
    <ng-container *rxLet="statsData$ as stats">
      <section>
        <nz-alert [nzMessage]="'LOT_STATISTICS.ALERT' | translate"></nz-alert>
      </section>

      <section>
        <nz-spin [nzSpinning]="statsLoading$ | async">
          <form
            class="ant-advanced-search-form"
            nz-form
            [formGroup]="form"
            (ngSubmit)="loadStats()"
          >
            <div nz-row [nzGutter]="12">
              <div nz-col nzXs="24" nzSm="5">
                <nz-form-item>
                  <nz-form-label [nzSpan]="24">{{ 'LOT_INFO.YEAR' | translate }}</nz-form-label>
                  <nz-form-control>
                    <nz-select formControlName="year" [nzPlaceHolder]="'LOT_INFO.YEAR' | translate">
                      <nz-option
                        *ngFor="let year of stats?.years"
                        [nzValue]="year"
                        [nzLabel]="year"
                      ></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col nzXs="24" nzSm="5">
                <nz-form-item>
                  <nz-form-label [nzSpan]="24">{{ 'LOT_INFO.SCORE' | translate }}:</nz-form-label>
                  <nz-form-control>
                    <nz-select
                      formControlName="score"
                      [nzPlaceHolder]="'LOT_INFO.SCORE' | translate"
                    >
                      <nz-option
                        *ngFor="let score of stats?.scores"
                        [nzValue]="score"
                        [nzLabel]="score"
                      ></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col nzXs="24" nzSm="5">
                <nz-form-item>
                  <nz-form-label [nzSpan]="24">{{ 'LOT_INFO.MILEAGE' | translate }}:</nz-form-label>
                  <nz-form-control>
                    <nz-select
                      formControlName="mileage"
                      [nzPlaceHolder]="'LOT_INFO.MILEAGE' | translate"
                    >
                      <nz-option
                        *ngFor="let interval of mileageIntervals | keyvalue"
                        [nzValue]="interval.key"
                        [nzLabel]="interval.value | translate"
                      ></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col nzXs="24" nzSm="5">
                <nz-form-item>
                  <nz-form-label [nzSpan]="24">{{ 'FRAME_MODEL' | translate }}:</nz-form-label>
                  <nz-form-control>
                    <nz-select formControlName="frame" [nzPlaceHolder]="'FRAME_MODEL' | translate">
                      <nz-option
                        *ngFor="let frame of stats?.frames"
                        [nzValue]="frame.name"
                        [nzLabel]="frame.name"
                      ></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col>
                <nz-form-item>
                  <nz-form-label [nzSpan]="24">&nbsp;</nz-form-label>
                  <nz-form-control>
                    <button nzType="primary" nz-button>{{ 'SELECT' | translate }}</button>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
          </form>
        </nz-spin>
      </section>

      <section>
        <nz-table
          #statsTable
          nzSimple
          nzTemplateMode
          nzSize="small"
          nzBordered
          nzOuterBordered
          [nzLoading]="statsLoading$ | async"
          nzShowPagination="false"
          [nzData]="stats?.stats"
        >
          <thead>
            <tr>
              <th nzAlign="center" translate nzWidth="190px">LOT</th>
              <th nzAlign="center" translate>LOT_INFO.AUCTION</th>
              <th nzAlign="center">
                <div>{{ 'LOT_STATISTICS.ENGINE_VOLUME' | translate }}</div>
                <div>{{ 'LOT_INFO.MILEAGE' | translate }}</div>
              </th>
              <th nzAlign="center" translate>FRAME_MODEL</th>
              <th nzAlign="center" translate>EQUIPMENT</th>
              <th nzAlign="center" translate>LOT_INFO.SCORE</th>
              <th nzAlign="center">
                {{ 'LOT_INFO.START_PRICE' | translate }}<br />{{ 'LOT_INFO.SOLD_FOR' | translate }}
              </th>
              <th nzAlign="center" translate>LOT_INFO.COLOR</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of statsTable.data">
              <td>
                <app-lot-mini-gallery [lot]="data"></app-lot-mini-gallery>
                <app-lot-vin-image [lot]="data" [width]="186" [height]="50"></app-lot-vin-image>
              </td>
              <td nzAlign="center">
                <a
                  target="_blank"
                  href="/{{ dateToday <= data.date ? 'auto' : 'stats-auto' }}/lot/{{ data.id }}"
                  nz-button
                  nzSize="small"
                  [nzType]="dateToday <= data.date ? 'primary' : 'default'"
                  >{{ data.bid }}</a
                ><br />
                {{ data.auctionName }}<br />
                {{ data.date }}
              </td>
              <td nzAlign="center">
                <div>{{ data.engineVolume }}</div>
                <div>{{ data.mileage }}{{ data.mileage > 0 ? '000' : '' }}</div>
              </td>
              <td nzAlign="center">{{ data.frame }}</td>
              <td nzAlign="center">
                <span innerHTML="{{ data.transmission }} {{ data.modelGrade }}"></span>
                <br />
                {{ data.equip }}<br />
              </td>
              <td nzAlign="center">{{ data.rate }}</td>
              <td nzAlign="right">
                {{ data.startPrice | currency: 'JPY':'symbol-narrow' }}<br />
                {{ data.finishPrice | currency: 'JPY':'symbol-narrow' }}
              </td>
              <td nzAlign="center">{{ data.color }}</td>
            </tr>
          </tbody>
        </nz-table>
      </section>

      <section nz-row [nzGutter]="20">
        <nz-col nzSpan="8">
          <nz-table
            #yearsTable
            nzSimple
            nzSize="small"
            nzBordered
            nzOuterBordered
            [nzLoading]="statsLoading$ | async"
            [nzData]="stats?.pricesPerYear"
          >
            <thead>
              <tr>
                <th nzAlign="center" translate>LOT_INFO.YEAR</th>
                <th nzAlign="center" translate>LOT_INFO.AVERAGE_PRICE</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="let data of yearsTable.data"
                (click)="selectYear(data.year.toString())"
                class="year-row{{ form.value.year == data.year ? '-active' : '' }}"
              >
                <td nzAlign="center">
                  {{ data.year }}
                </td>
                <td
                  nzAlign="center"
                  style="cursor: pointer; background-color: rgba(0, 255, 0, {{
                    data.count / 1000
                  }})"
                >
                  <span nz-tooltip nzTooltipTitle="Average by {{ data.count }} units sold">
                    {{ data.avg | currency: 'JPY':'symbol-narrow' }}
                  </span>
                </td>
              </tr>
            </tbody>
          </nz-table>
        </nz-col>
        <nz-col nzSpan="16">
          <nz-table
            #ratesTable
            nzSimple
            nzSize="small"
            nzBordered
            nzOuterBordered
            [nzLoading]="statsLoading$ | async"
            nzShowPagination="false"
            [nzData]="stats?.pricesPerRating[form.value.year] | keyvalue"
          >
            <thead>
              <tr>
                <th nzAlign="center" translate>LOT_INFO.SCORE</th>
                <th nzAlign="center" translate>0-25</th>
                <th nzAlign="center" translate>25-50</th>
                <th nzAlign="center" translate>50-75</th>
                <th nzAlign="center" translate>75-100</th>
                <th nzAlign="center" translate>100-125</th>
                <th nzAlign="center" translate>125-150</th>
                <th nzAlign="center" translate>>150</th>
                <th nzAlign="center" translate>TOTAL</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of ratesTable.data">
                <td nzAlign="center">
                  {{ data.key }}
                </td>
                <td
                  nzAlign="center"
                  *ngFor="let interval of mileageIntervals | keyvalue"
                  [class]="
                    form.value.score === data.key && interval.key === form.value.mileage
                      ? 'selected-cell'
                      : ''
                  "
                  style="cursor: pointer; background-color: rgba(0, 255, 0, {{
                    data.value[interval.key]?.count / 255
                  }})"
                  (click)="selectInterval(data.key, interval.key)"
                >
                  <span
                    nz-tooltip
                    nzTooltipTitle="Average by {{ data.value[interval.key]?.count }} units sold"
                  >
                    {{ data.value[interval.key]?.avg | currency: 'JPY':'symbol-narrow' }}
                  </span>
                </td>
              </tr>
            </tbody>
          </nz-table>
        </nz-col>
      </section>
    </ng-container>
  </nz-collapse-panel>
</nz-collapse>
