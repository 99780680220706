import { DateTime } from 'luxon';

import { ImportedLotFragment } from '../../graphql/service/graphql-auto-main-service';
import { ExcelLot } from '../types/excel-lot.type';

export abstract class LotIdBuilder {
  static build(lotInfo: ExcelLot): string;
  static build(lot: ImportedLotFragment): string;
  static build(lotOrLotInfo: ExcelLot | ImportedLotFragment): string {
    if (this.isImportedLot(lotOrLotInfo)) {
      return this.buildImportedLotId(lotOrLotInfo);
    } else {
      return this.buildLotInfoId(lotOrLotInfo);
    }
  }

  private static buildLotInfoId(lotInfo: ExcelLot): string {
    const auctionDate = lotInfo.auctionDate.toFormat('yyyy-MM-dd');
    return `${lotInfo.lotNumber}_${auctionDate}_${lotInfo.auctionName.toLowerCase()}`;
  }

  private static buildImportedLotId(lot: ImportedLotFragment): string {
    const auctionDate = DateTime.fromISO(lot.auctionDate, { zone: 'UTC' }).toFormat('yyyy-MM-dd');
    return `${lot.code}_${auctionDate}_${lot.auctionName.toLowerCase()}`;
  }

  private static isImportedLot(
    lotOrLotInfo: ExcelLot | ImportedLotFragment,
  ): lotOrLotInfo is ImportedLotFragment {
    return 'companyEn' in lotOrLotInfo;
  }
}
