import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { combineLatest, of, ReplaySubject, Subscription } from 'rxjs';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';

import { AutoLotView } from '../../../../../const';

@Component({
  selector: 'app-commission-agreement-control',
  templateUrl: './commission-agreement-control.component.html',
  styleUrls: ['./commission-agreement-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CommissionAgreementControlComponent,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommissionAgreementControlComponent
  implements ControlValueAccessor, OnInit, OnChanges, OnDestroy
{
  @Input() lot: AutoLotView;
  @Input() amount: number;
  @Input() isOtherCountriesUser: boolean;
  @Input() maxBidAmount?: number;
  @Input() minBidCommission?: number;
  @Output() maxAmountReached = new EventEmitter<boolean>();

  amount$ = new ReplaySubject<number>(1);
  isOtherCountriesUser$ = new ReplaySubject<boolean>(1);
  maxBidAmount$ = new ReplaySubject<number>(1);
  minBidCommission$ = new ReplaySubject<number>(1);

  agree = false;

  onChange = (agree) => {};
  onTouched = () => {};

  realAmount$ = of(0);
  dealerCommission$ = of(0);

  maxBidLimitReached$ = of(false);
  minimumCommissionReached$ = of(false);

  private subscription = Subscription.EMPTY;

  ngOnInit(): void {
    this.maxBidLimitReached$ = combineLatest([
      this.minBidCommission$,
      this.amount$,
      this.maxBidAmount$,
      this.isOtherCountriesUser$,
    ]).pipe(
      map(([minBidCommission, amount, maxBidAmount, isOtherCountriesUser]) => {
        console.log('isOtherCountriesUser:', isOtherCountriesUser)
        if (maxBidAmount && amount && !isOtherCountriesUser) {
          return minBidCommission ? amount >= maxBidAmount : amount > maxBidAmount;
        } else {
          return false;
        }
      }),
      distinctUntilChanged(),
      shareReplay(1),
    );

    this.realAmount$ = combineLatest([this.amount$, this.maxBidAmount$]).pipe(
      map(([amount, maxBidAmount]) => {
        if (this.maxBidAmount) {
          return amount > maxBidAmount ? maxBidAmount : amount;
        } else {
          return amount;
        }
      }),
      distinctUntilChanged(),
      shareReplay(1),
    );

    this.dealerCommission$ = combineLatest([this.amount$, this.maxBidAmount$]).pipe(
      map(([amount, maxBidAmount]) => {
        if (this.maxBidAmount) {
          return amount > maxBidAmount ? amount - maxBidAmount : 0;
        } else {
          return 0;
        }
      }),
      distinctUntilChanged(),
      shareReplay(1),
    );

    this.minimumCommissionReached$ = combineLatest([
      this.maxBidLimitReached$,
      this.dealerCommission$,
      this.minBidCommission$,
    ]).pipe(
      map(([maxAmountReached, commission, minBidCommission]) => {
        if (maxAmountReached) {
          return minBidCommission ? commission >= minBidCommission : true;
        } else {
          return false;
        }
      }),
      distinctUntilChanged(),
      shareReplay(1),
    );

    this.subscription = this.maxBidLimitReached$.subscribe((maxAmountReached) => {
      this.maxAmountReached.emit(maxAmountReached);
      this.agree = false;
      this.onChange(false);
    });
  }

  writeValue(agree?: boolean): void {
    this.agree = Boolean(agree);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  agreeStateChange(value: boolean): void {
    this.onChange(value);
  }

  ngOnChanges(): void {
    this.maxBidAmount$.next(this.maxBidAmount);
    this.minBidCommission$.next(this.minBidCommission);

    if (this.amount != null && this.amount != undefined) {
      this.amount$.next(this.amount);
    }

    if (this.isOtherCountriesUser !== null) {
      this.isOtherCountriesUser$.next(this.isOtherCountriesUser);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
