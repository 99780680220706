import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { filter, switchMap, toArray } from 'rxjs/operators';
import {
  BidCondition,
  BidManualDataQuery,
  CurrentUser,
  LotTypeEnum,
} from 'src/app/modules/graphql/service/graphql-auto-main-service';

import {
  AutoLotView,
  BID_CONDITION_INTACT,
  BID_CONDITION_PALLET,
  BID_CONDITION_UTILIZATION,
  bidConditionsOrderMap,
  isOtherCountriesCondition,
  ManualLotData,
} from '../const';
import { isHybridOrElectric } from '../hybrids';
import { AuctionService } from './auction.service';

@Injectable()
export class BidConditionService {
  constructor(private auctionService: AuctionService) {}

  public sort(conditions: BidCondition[]): BidCondition[] {
    return Array.from(conditions).sort((a, b) => {
      const orderA = bidConditionsOrderMap[Number(a.id)] ?? Number.MAX_SAFE_INTEGER;
      const orderB = bidConditionsOrderMap[Number(b.id)] ?? Number.MAX_SAFE_INTEGER;
      return orderA - orderB;
    });
  }

  public getDefault(
    conditions: BidCondition[],
    currentUser: CurrentUser,
  ): BidCondition | undefined {
    if (currentUser.defaultBidConditionId) {
      return conditions.find((condition) => condition.id === currentUser.defaultBidConditionId);
    } else if (currentUser.officeBranch.isWorldwide) {
      return conditions.find((condition) => isOtherCountriesCondition(condition));
    } else {
      return conditions.find((condition) => Number(condition.id) === BID_CONDITION_INTACT);
    }
  }

  public filterForLot(
    conditions: BidCondition[],
    lot: AutoLotView,
    lotType: LotTypeEnum,
    currentUser: CurrentUser,
  ): Observable<BidCondition[]> {
    return this.auctionService.auctions$.pipe(
      switchMap((auctions) =>
        from(conditions).pipe(
          filter(
            (condition) =>
              this.checkIfAvailableForOtherCountries(condition, currentUser) &&
              this.checkIfConditionIsApplied(condition, lotType) &&
              this.checkIfMinYearAvailable(condition, lot) &&
              this.checkIfAuctionPalletAvailable(condition, lot, auctions) &&
              this.checkIfAvailableForBranchOffice(condition, currentUser) &&
              this.checkIfAvailableForModel(condition, lot, lotType, currentUser),
          ),
        ),
      ),
      toArray(),
    );
  }

  public filterForManualLot(
    conditions: BidCondition[],
    lot: ManualLotData,
    currentUser: BidManualDataQuery['currentUser'],
  ): BidCondition[] {
    if (currentUser.isManager) {
      return conditions.filter((condition) =>
        this.checkIfConditionIsApplied(condition, lot.lotType),
      );
    } else {
      return conditions.filter(
        (condition) =>
          this.checkIfAvailableForOtherCountries(condition, currentUser) &&
          this.checkIfConditionIsApplied(condition, lot.lotType) &&
          this.checkIfMinYearAvailable(condition, lot) &&
          this.checkIfAvailableForBranchOffice(condition, currentUser),
      );
    }
  }

  private checkIfMinYearAvailable(
    condition: BidCondition,
    lot: AutoLotView | ManualLotData,
  ): boolean {
    return !(condition.minYear && condition.minYear > Number(lot.year));
  }

  private checkIfAvailableForOtherCountries(condition: BidCondition, user: CurrentUser): boolean {
    if (user.isOtherCountries) {
      return isOtherCountriesCondition(condition);
    } else {
      return true;
    }
  }

  private checkIfConditionIsApplied(condition: BidCondition, lotType: LotTypeEnum): boolean {
    switch (lotType) {
      case LotTypeEnum.Auto:
        return condition.isAuto;
      case LotTypeEnum.Moto:
        return condition.isMoto;
      case LotTypeEnum.Oneprice:
        return condition.isOnePrice;
      default:
        return false;
    }
  }

  private checkIfAuctionPalletAvailable(
    condition: BidCondition,
    lot: AutoLotView,
    auctions: any[],
  ): boolean {
    if (Number(condition.id) === BID_CONDITION_PALLET) {
      const auction = auctions.find(
        (auction) => auction.name.toUpperCase() === lot.auctionName.toUpperCase(),
      );
      if (auction && auction.auctionData && auction.auctionData.isNoPallet) {
        return false;
      }
    }
    return true;
  }

  private checkIfAvailableForBranchOffice(
    condition: BidCondition,
    currentUser: CurrentUser,
  ): boolean {
    if (isOtherCountriesCondition(condition)) {
      return currentUser.officeBranch?.isWorldwide && currentUser.isOtherCountries;
    } else {
      return true;
    }
  }

  private checkIfAvailableForModel(
    condition: BidCondition,
    lot: AutoLotView,
    lotType: LotTypeEnum,
    currentUser: CurrentUser,
  ): boolean {
    // TODO: add electric hybrid check
    if (
      (lotType == LotTypeEnum.Auto || lotType == LotTypeEnum.Oneprice) &&
      !currentUser.isOtherCountries &&
      Number(condition.id) != BID_CONDITION_UTILIZATION &&
      (Number(lot.engineVolume) >= 1900 || Number(lot.engineVolume) < 85 || isHybridOrElectric(lot))
    ) {
      return false;
    }

    return true;
  }
}
