import { ConfigService } from '@akebono/core';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AutoLotView } from '../../../const';

type LotExtraInfo = string | Record<string, string>;

@Component({
  selector: 'app-lot-extra-info',
  templateUrl: './lot-extra-info.component.html',
  styleUrls: ['./lot-extra-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotExtraInfoComponent implements OnInit {
  @Input() lot: AutoLotView;
  @Input() active = false;

  info$: Observable<LotExtraInfo> = null;
  switchSubject$ = new BehaviorSubject<Boolean>(true);

  constructor(private configService: ConfigService) {}

  ngOnInit(): void {
    this.info$ = combineLatest([
      this.configService.getCurrentLanguage$(),
      this.switchSubject$,
    ]).pipe(
      map(([lang, translated]) => {
        let infoString: string | null = null;
        if (!translated) {
          infoString = this.lot.parsedData;
        } else if (lang.localeId === 'ru' && this.lot.parsedDataRu) {
          infoString = this.lot.parsedDataRu;
        } else {
          infoString = this.lot.parsedDataEn ?? this.lot.parsedData;
        }

        try {
          return JSON.parse(infoString?.replace('<![CDATA', '')?.replace('}]]>', '}]'))[0];
        } catch {
          return infoString;
        }
      }),
    );
  }

  switchSource(translated: boolean) {
    if (translated) {
    }
  }

  getInfoString(lang: string) {}

  isRecordInfo(info: LotExtraInfo): boolean {
    return typeof info === 'object';
  }
}
