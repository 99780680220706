import { CurrencyService } from '@akebono/core';
import { Component } from '@angular/core';
import { Angulartics2Mixpanel } from 'angulartics2/mixpanel';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent {
  constructor(private mixpanel: Angulartics2Mixpanel, private currencyService: CurrencyService) {
    this.mixpanel.startTracking();
    this.currencyService.setCurrency('JPY');
  }
}
