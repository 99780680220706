import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-ju-auction',
  templateUrl: './ju-auction.component.html',
  styleUrls: ['./ju-auction.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JuAuctionComponent {
  @Input() lang = 'ru';
}
