import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-caa-auction',
  templateUrl: './caa-auction.component.html',
  styleUrls: ['./caa-auction.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CaaAuctionComponent {
  @Input() lang = 'ru';
}
