<nz-spin nzSize="large" [nzSpinning]="loading$ | async">
  <nz-row>
    <a
      *rxLet="boughtToday$ as boughtToday"
      nz-col
      [nzSpan]="8"
      [routerLink]="bidsRoute"
      [queryParams]="bidsFilterQueryParams.today"
    >
      <nz-statistic
        [class.has-purchase]="boughtToday"
        [nzTitle]="'USER_PURCHASE_STATISTICS.BOUGHT_TODAY' | translate"
        [nzValue]="boughtToday | number"
      ></nz-statistic>
    </a>

    <a
      *rxLet="boughtYesterday$ as boughtYesterday"
      nz-col
      [nzSpan]="8"
      [routerLink]="bidsRoute"
      [queryParams]="bidsFilterQueryParams.yesterday"
    >
      <nz-statistic
        [class.has-purchase]="boughtYesterday"
        [nzTitle]="'USER_PURCHASE_STATISTICS.BOUGHT_YESTERDAY' | translate"
        [nzValue]="boughtYesterday | number"
      ></nz-statistic>
    </a>

    <a
      *rxLet="boughtWeek$ as boughtWeek"
      nz-col
      [nzSpan]="8"
      [routerLink]="bidsRoute"
      [queryParams]="bidsFilterQueryParams.week"
    >
      <nz-statistic
        [class.has-purchase]="boughtWeek"
        [nzTitle]="'USER_PURCHASE_STATISTICS.BOUGHT_WEEK' | translate"
        [nzValue]="boughtWeek | number"
      ></nz-statistic>
    </a>
  </nz-row>
</nz-spin>
