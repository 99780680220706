<nz-alert
  *ngFor="let alert of alerts$ | async"
  nzShowIcon
  [nzType]="alert.style"
  [nzMessage]="'ATTENTION' | translate"
  [nzDescription]="alertBody"
>
  <ng-template #alertBody>
    <span [innerHtml]="alert.content"></span>
  </ng-template>
</nz-alert>
