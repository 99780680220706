<nz-layout>
  <nz-content class="wrap">
    <!-- <p
      style="
        text-align: center;
        color: white;
        font-weight: bold;
        font-size: 32px;
        background-color: black;
      "
    >
      Технические работы
    </p> -->
    <div class="outer-content">
      <nz-avatar
        nz-page-header-avatar
        nzSrc="/assets/logo_transparent.png"
        [nzSize]="200"
        class="logo-avatar"
      ></nz-avatar>
      <div class="inner-content">
        <div nz-row>
          <div nz-col nzSpan="24">
            <h3 nz-title>{{ 'Sign in' | translate }}</h3>
            <!-- <p style="width: 300px">
              {{ 'REGISTRATION' | translate }}
              <a href="https://id.akebono.world/sign-up" target="_blank">akebono.world</a>.
            </p>
            <p style="width: 300px">{{ 'FOR_AUTH_USE_AKEOBONO_WORLD_ACCOUNT' | translate }}</p> -->
          </div>
        </div>

        <form
          (ngSubmit)="submitForm()"
          *ngIf="!isSensSms"
          [formGroup]="form"
          autocomplete="on"
          class="login-form"
          nz-form
        >
          <nz-form-item>
            <nz-form-control nzErrorTip="Input login or email">
              <nz-input-group nzPrefixIcon="user">
                <input
                  autocomplete="username"
                  formControlName="username"
                  id="username"
                  name="username"
                  nz-input
                  placeholder="Login or email"
                  nzSize="large"
                  type="text"
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-control nzErrorTip="Input password">
              <nz-input-group nzPrefixIcon="lock">
                <input
                  autocomplete="password"
                  formControlName="password"
                  name="password"
                  nz-input
                  placeholder="Password"
                  nzSize="large"
                  type="password"
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-control>
              <button
                [nzLoading]="isCheckUserLoading"
                [nzType]="'primary'"
                class="login-form-button"
                nz-button
              >
                {{ 'Sign in' | translate }}
              </button>
            </nz-form-control>
          </nz-form-item>
        </form>

        <form
          *ngIf="isSensSms"
          [formGroup]="formAfterSendMfa"
          autocomplete="on"
          class="login-form"
          nz-form
        >
          <nz-form-item>
            <nz-form-control nzErrorTip="Input confirm code">
              <nz-input-group>
                <input
                  formControlName="code"
                  name="code"
                  nz-input
                  nzSize="large"
                  placeholder="Confirm code"
                  type="text"
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-control>
              <button
                (click)="submitCode()"
                [nzLoading]="isCheckUserLoading"
                [nzType]="'primary'"
                class="login-form-button"
                nz-button
              >
                {{ 'Sign in' | translate }}
              </button>
            </nz-form-control>
          </nz-form-item>
          <nz-countdown
            *ngIf="disabledTimer"
            [nzFormat]="'mm:ss'"
            [nzPrefix]="prefixTimer"
            [nzValue]="deadline"
          >
          </nz-countdown>
          <div>
            <a (click)="sendNewSms()" *ngIf="disabledNewSms">{{
              'Send new password' | translate
            }}</a>
          </div>
        </form>
      </div>
    </div>
  </nz-content>
</nz-layout>
