import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AutoLotView } from 'src/app/const';
import { SanctionsService } from 'src/app/services/sanctions.service';

import {
  LotsListLotLinkDataFragment,
  LotTypeEnum,
  UserLotMarkFragment,
} from '../../graphql/service/graphql-auto-main-service';
import { AutoLot } from '../../graphql/service/graphql-cars-default-service';

@Component({
  selector: 'app-lots-list-lot-link',
  templateUrl: './lots-list-lot-link.component.html',
  styleUrls: ['./lots-list-lot-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotsListLotLinkComponent implements OnInit {
  @Input() lot: AutoLot | AutoLotView;
  @Input() lotType: LotTypeEnum;
  @Input() link: string | string[];
  @Input() lotData?: LotsListLotLinkDataFragment;

  isLotSanctioned$: Observable<boolean>;
  isLotNotSanctioned$: Observable<boolean>;
  loaded$: Observable<boolean>;

  constructor(private readonly sanctionsService: SanctionsService) {}

  ngOnInit(): void {
    this.isLotSanctioned$ = this.sanctionsService.isLotSanctioned$(this.lot, this.lotType);
    this.isLotNotSanctioned$ = this.isLotSanctioned$.pipe(map((v) => !v));
    this.loaded$ = combineLatest([this.isLotSanctioned$, this.isLotNotSanctioned$]).pipe(
      map((values) => values.some((v) => v)),
    );
  }

  get hasBid(): boolean {
    return !!this.lotData?.currentUserLastBid?.id;
  }

  get marks(): UserLotMarkFragment[] {
    return this.lotData?.currentUserLotMarks ?? [];
  }
}
