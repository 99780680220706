<button
  nz-button
  nzSize="small"
  nz-popover
  nzPopoverTrigger="click"
  [(nzPopoverVisible)]="showPopover"
  [nzPopoverTitle]="title"
  [nzPopoverContent]="body"
  (nzPopoverVisibleChange)="showPopover = $event"
>
  <i nz-icon nzType="edit" nzTheme="outline"></i>
</button>
<ng-template #title>
  <nz-row nzJustify="space-between">
    <nz-col>{{ 'LOT_INFO.YEAR' | translate }}</nz-col>
    <nz-col>
      <i nz-icon class="pointer" nzType="close" nzTheme="outline" (click)="showPopover = false"></i>
    </nz-col>
  </nz-row>
</ng-template>
<ng-template #body>
  <section>
    <nz-input-number [nzMin]="1885" nzSize="small" [(ngModel)]="year"></nz-input-number>
  </section>
  <section>
    <button nz-button nzSize="small" nzType="primary" [nzLoading]="saving | async" (click)="save()">
      {{ 'SAVE' | translate }}
    </button>
  </section>
</ng-template>
