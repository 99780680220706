import { QueryHandlingService } from '@akebono/core';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import {
  CodeDateAuctionNameInput,
  ImportedLotFragment,
  ImportedLotsGQL,
} from '../../graphql/service/graphql-auto-main-service';
import { LotIdBuilder } from '../classes/lot-id-builder.class';
import { ExcelLot } from '../types/excel-lot.type';
import { FetchLotsResult } from '../types/fetch-lots-result.type';

@Injectable()
export class LotFetcherService {
  private readonly EMPTY_RESULT: FetchLotsResult = {
    foundLots: [],
    notFoundLots: [],
  };

  constructor(
    private readonly qhs: QueryHandlingService,
    private readonly importedLotsGQL: ImportedLotsGQL,
  ) {}

  fetch(excelLots: ExcelLot[]): Observable<FetchLotsResult> {
    const lotFilters = this.excelBidToLotsFilter(excelLots);
    const queryRef = this.qhs.fetch(this.importedLotsGQL, { lotFilters }, 'network-only');
    const lots$ = queryRef.data.pipe(map((data) => data.lotsByKeys || []));
    const fetchResult$ = lots$.pipe(
      map(
        (lots) =>
          <FetchLotsResult>{
            foundLots: lots,
            notFoundLots: this.getNotFoundLotInfos(lots, excelLots),
          },
      ),
      catchError(() => of(this.EMPTY_RESULT)),
    );

    if (excelLots.length) {
      return fetchResult$;
    } else {
      return of(this.EMPTY_RESULT);
    }
  }

  private excelBidToLotsFilter(lotInfos: ExcelLot[]): CodeDateAuctionNameInput[] {
    return lotInfos.map(
      (bid) =>
        <CodeDateAuctionNameInput>{
          code: bid.lotNumber,
          auctionName: bid.auctionName,
          date: bid.auctionDate.toFormat('yyyy-MM-dd'),
        },
    );
  }

  private getNotFoundLotInfos(lots: ImportedLotFragment[], excelLots: ExcelLot[]): ExcelLot[] {
    const importedLotIdsSet = new Set(lots.map((lot) => LotIdBuilder.build(lot)));
    return excelLots.filter((lot) => !importedLotIdsSet.has(LotIdBuilder.build(lot)));
  }
}
