import { AkebonoCoreModule } from '@akebono/core';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CopyOutline, LinkOutline } from '@ant-design/icons-angular/icons';
import { TranslateModule } from '@ngx-translate/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

import { ListShareButtonComponent } from './components/list-share-button/list-share-button.component';
import { LotShareButtonComponent } from './components/lot-share-button/lot-share-button.component';

@NgModule({
  declarations: [LotShareButtonComponent, ListShareButtonComponent],
  exports: [LotShareButtonComponent, ListShareButtonComponent],
  imports: [
    CommonModule,
    AkebonoCoreModule,
    TranslateModule,
    NzButtonModule,
    NzModalModule,
    NzSelectModule,
    NzFormModule,
    FormsModule,
    ReactiveFormsModule,
    NzInputModule,
    NzPopoverModule,
    NzToolTipModule,
    ClipboardModule,
    NzMessageModule,
    NzTagModule,
    NzIconModule.forRoot([LinkOutline, CopyOutline]),
  ],
})
export class ShareButtonsModule {}
