import { CalculatorModule } from '@akebono/calculator';
import { AkebonoCoreModule } from '@akebono/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzResultModule } from 'ng-zorro-antd/result';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTypographyModule } from 'ng-zorro-antd/typography';

import { AlertsModule } from '../alerts-list/alerts.module';
import { LanguageSelectorModule } from '../language-selector/language-selector.module';
import { LotBodyDefectsInfoModule } from '../lot-body-defects-info/lot-body-defects-info.module';
import { LotCarouselModule } from '../lot-carousel/lot-carousel.module';
import { LotExtraInfoModule } from '../lot-extra-info/lot-extra-info.module';
import { LotLastVisitDateModule } from '../lot-last-visit-date/lot-last-visit-date.module';
import { LotMainInfoModule } from '../lot-main-info/lot-main-info.module';
import { LotMiniGalleryModule } from '../lot-mini-gallery/lot-mini-gallery.module';
import { LotNotationsButtonModule } from '../lot-notations-button/lot-notations-button.module';
import { LotSharedIconModule } from '../lot-shared-icon/lot-shared-icon.module';
import { LotTitleFullModule } from '../lot-title-full/lot-title-full.module';
import { LotTranslationsModule } from '../lot-translations/lot-translations.module';
import { SharedChannelComponent } from './components/shared-channel/shared-channel.component';
import { SharedLotComponent } from './components/shared-lot/shared-lot.component';
import { SharedSelectionsComponent } from './components/shared-selections/shared-selections.component';

@NgModule({
  declarations: [SharedSelectionsComponent, SharedLotComponent, SharedChannelComponent],
  exports: [SharedSelectionsComponent, SharedLotComponent, SharedChannelComponent],
  imports: [
    CommonModule,
    AkebonoCoreModule,
    TranslateModule,
    NzButtonModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    RouterModule,
    NzGridModule,
    NzTableModule,
    LotMiniGalleryModule,
    NzTypographyModule,
    NzLayoutModule,
    NzDividerModule,
    AlertsModule,
    LotTranslationsModule,
    LotNotationsButtonModule,
    LotExtraInfoModule,
    LotMainInfoModule,
    LotCarouselModule,
    LotLastVisitDateModule,
    CalculatorModule,
    NzCollapseModule,
    NzAlertModule,
    NzSpinModule,
    NzResultModule,
    LotSharedIconModule,
    NzPageHeaderModule,
    NzAvatarModule,
    LotTitleFullModule,
    LanguageSelectorModule,
    LotBodyDefectsInfoModule,
  ],
})
export class SharedPagesModule {}
