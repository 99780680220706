import { QueryHandlingService } from '@akebono/core';
import { Injectable, OnDestroy } from '@angular/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import { first, map, shareReplay, takeUntil } from 'rxjs/operators';

import {
  Auction as AliasAuction,
  AuctionAliasesGQL,
  AuctionSheetZoneFragment,
} from '../modules/graphql/service/graphql-auto-main-service';
import { Auction, AuctionsGQL } from '../modules/graphql/service/graphql-directory-service';

@Injectable()
export class AuctionService implements OnDestroy {
  public readonly auctions$: Observable<Auction[]>;
  public readonly auctionAliases$: Observable<AliasAuction[]>;

  private readonly destroy$ = new Subject<void>();

  constructor(
    private queryHandlingService: QueryHandlingService,
    private auctionsGQL: AuctionsGQL,
    private auctionAliasesGQL: AuctionAliasesGQL,
  ) {
    this.auctions$ = this.queryHandlingService.fetch(this.auctionsGQL, {}, 'cache-first').data.pipe(
      map((data) => data.auctions || []),
      shareReplay(1),
    );

    this.auctionAliases$ = this.queryHandlingService
      .fetch(this.auctionAliasesGQL, {}, 'cache-first')
      .data.pipe(
        map((data) => data.auctions || []),
        shareReplay(1),
      );

    combineLatest([this.auctions$, this.auctionAliases$])
      .pipe(first(), takeUntil(this.destroy$))
      .subscribe();
  }

  getByName(auctionName: string): Observable<Auction | undefined> {
    return combineLatest([this.auctions$, this.getAliasAuctionByName(auctionName)]).pipe(
      map(([ctAuctions, aliasAuction]) =>
        ctAuctions.find((auction) => String(auction.id) === aliasAuction?.cartrackAuctionId),
      ),
    );
  }

  getAliasAuctionByName(auctionName: string): Observable<AliasAuction | undefined> {
    return this.auctionAliases$.pipe(
      map((aliasAuctions) =>
        aliasAuctions.find(
          (aliasAuction) =>
            aliasAuction.title == auctionName ||
            aliasAuction.auctionAliases.find((alias) => alias.title == auctionName),
        ),
      ),
    );
  }

  getAuctionSheetZones(auctionName: string): Observable<AuctionSheetZoneFragment[]> {
    return this.getAliasAuctionByName(auctionName).pipe(
      map((auction) => auction?.auctionSheetZones || []),
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
