<nz-modal
  *ngFor="let alert of alerts$ | async"
  [(nzVisible)]="alert.visible"
  [nzTitle]="'ATTENTION' | translate"
  nzClassName="alert-modal-content"
>
  <ng-container *nzModalContent>
    <div [innerHtml]="alert.content | safe: 'html'"></div>
  </ng-container>
  <div *nzModalFooter>
    <button nz-button nzType="primary" (click)="alert.visible = false">OK</button>
  </div>
</nz-modal>
