import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NzAlertModule } from 'ng-zorro-antd/alert';

import { LotBoundaryInfoComponent } from './lot-boundary-info.component';

@NgModule({
  declarations: [LotBoundaryInfoComponent],
  imports: [CommonModule, TranslateModule, NzAlertModule],
  exports: [LotBoundaryInfoComponent],
})
export class LotBoundaryInfoModule {}
