import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { DriverGuideDirective } from './directives/driver-guide.directive';
import { DriverStepDirective } from './directives/driver-step.directive';
import { DriverService } from './driver.service';

@NgModule({
  declarations: [DriverGuideDirective, DriverStepDirective],
  imports: [CommonModule, TranslateModule],
  providers: [DriverService],
  exports: [DriverGuideDirective, DriverStepDirective],
})
export class DriverModule {}
