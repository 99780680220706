import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { ExcelBidParsingResult } from '../../types/excel-bid-parse-result.type';

@Component({
  selector: 'app-import-bids-dashboard-title',
  templateUrl: './import-bids-dashboard-title.component.html',
  styleUrls: ['./import-bids-dashboard-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImportBidsDashboardTitleComponent {
  @Input() parsingResult: ExcelBidParsingResult | null = null;
  @Input() selectedBidsCount = 0;
  @Input() availableBidsCount = 0;
  @Output() reset = new EventEmitter<void>();
  @Output() placeSelectedBids = new EventEmitter<void>();
  @Output() placeAvailableBids = new EventEmitter<void>();
}
