
  <main id="lots-check-production-date-result">
    <ng-container *ngIf="payload$ | async as payload">
      <ng-container *ngIf="payload.length">
        <section>
          <nz-alert nzType="info" [nzMessage]="info">
            <ng-template #info>
              <div *ngFor="let data of payload">
                <b>{{ 'PRODUCTION_DATE_PAYLOAD.' + data.key | translate }}:</b>
                {{ data.value }}
              </div>
            </ng-template>
          </nz-alert>
        </section>
        <section>
          <nz-alert nzType="warning" [nzMessage]="'DATE_OF_MANUFACTURE_HINT' | translate"></nz-alert>
        </section>
        <section>
          <nz-alert nzType="warning" [nzMessage]="'DATE_OF_MANUFACTURE_WARNING' | translate"></nz-alert>
        </section>
      </ng-container>
    </ng-container>
  </main>
