import { AkebonoCoreModule } from '@akebono/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CheckOutline, CloseOutline, PlusOutline } from '@ant-design/icons-angular/icons';
import { TranslateModule } from '@ngx-translate/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

import { ConnectTelegramModule } from '../connect-telegram/connect-telegram.module';
import { LotTranslationsModule } from '../lot-translations/lot-translations.module';
import { SubscribeToBestBidButtonModule } from '../subscribe-to-best-bid-button/subscribe-to-best-bid-button.module';
import { BidSettingsComponent } from './components/bid-settings/bid-settings.component';
import { NotificationSettingsComponent } from './components/notification-settings/notification-settings.component';
import { SettingsModalComponent } from './components/settings-modal/settings-modal.component';
import { TranslationSettingsComponent } from './components/translation-settings/translation-settings.component';

@NgModule({
  declarations: [
    BidSettingsComponent,
    SettingsModalComponent,
    TranslationSettingsComponent,
    NotificationSettingsComponent,
  ],
  exports: [SettingsModalComponent],
  imports: [
    SubscribeToBestBidButtonModule,
    FormsModule,
    CommonModule,
    LotTranslationsModule,
    NzFormModule,
    NzTabsModule,
    NzTableModule,
    NzRadioModule,
    NzInputModule,
    NzModalModule,
    NzButtonModule,
    NzSelectModule,
    NzSwitchModule,
    NzToolTipModule,
    TranslateModule,
    AkebonoCoreModule,
    ReactiveFormsModule,
    ConnectTelegramModule,
    NzIconModule.forRoot([CheckOutline, CloseOutline, PlusOutline]),
  ],
})
export class SettingsModalModule {}
