import { AuthenticationService } from '@akebono/core';
import { ChangeDetectionStrategy, Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  AutoLotView,
  BID_CONDITION_INTACT,
  BoundaryStatus,
  PERMISSION_IGNORE_BOUNDARY_GREEN_CORNER,
} from '../../../../../const';
import { LotBoundaryInfoService } from '../../../../../services/lot-boundary-info.service';

@Component({
  selector: 'app-green-corner-switch',
  templateUrl: './green-corner-switch.component.html',
  styleUrls: ['./green-corner-switch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GreenCornerSwitchComponent),
      multi: true,
    },
  ],
})
export class GreenCornerSwitchComponent implements OnInit, ControlValueAccessor {
  @Input() lot: AutoLotView;
  @Input()
  set bidConditionId(bidConditionId: string) {
    this.isInvalidBidCondition = bidConditionId !== BID_CONDITION_INTACT.toString();
    if (this.isInvalidBidCondition) {
      this.value = false;
    }
  }

  isBoundary$: Observable<boolean> = of(false);
  isDisabled: boolean;
  isInvalidBidCondition = false;
  private checked: boolean;

  onChange: Function = () => {};
  onTouch: Function = () => {};

  constructor(
    private lotBoundaryInfoService: LotBoundaryInfoService,
    private authService: AuthenticationService,
  ) {}

  ngOnInit() {
    this.isBoundary$ = this.authService
      .checkPermissions([PERMISSION_IGNORE_BOUNDARY_GREEN_CORNER])
      .pipe(
        map((val: boolean): boolean => {
          if (!val) {
            return (
              this.lotBoundaryInfoService.getLotBoundaryInfo(this.lot)?.status ===
              BoundaryStatus.Boundary
            );
          }
          return false;
        }),
      );
  }

  get(): boolean {
    return this.bidConditionId !== BID_CONDITION_INTACT.toString();
  }

  get value() {
    return this.checked;
  }

  set value(val: boolean) {
    this.checked = val;
    this.onChange(val);
    this.onTouch(val);
  }

  registerOnChange(fn: Function): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(val: boolean): void {
    this.value = val;
  }
}
