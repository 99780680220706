<nz-alert
  *ngIf="isBiddable$ | async"
  nzType="success"
  [nzMessage]="'IMPORT_BIDS.BIDDABLE' | translate"
></nz-alert>

<nz-alert
  *ngIf="isPlaced$ | async"
  [nzMessage]="'IMPORT_BIDS.SUCCESSFULLY_PLACED' | translate"
></nz-alert>

<nz-alert
  *ngIf="mutationError$ | async as errorMessage"
  nzType="error"
  [nzMessage]="'IMPORT_BIDS.MUTATION_ERROR' | translate: { error: errorMessage }"
></nz-alert>

<nz-alert
  *ngFor="let error of errors$ | async"
  nzType="error"
  [nzMessage]="'IMPORT_BIDS.ERRORS.' + error | translate"
></nz-alert>
