<nz-alert
  nzType="warning"
  [nzMessage]="'ATTENTION' | translate"
  [nzDescription]="'BIRTHDATE_MODAL.ALERT' | translate"
  nzShowIcon
></nz-alert>

<form nz-form [formGroup]="form" nzLayout="inline">
  <nz-form-item>
    <nz-form-label nzRequired>{{ 'BIRTHDATE_MODAL.TITLE' | translate }}</nz-form-label>
    <nz-form-control>
      <nz-date-picker formControlName="birthdate" [nzAllowClear]="false"></nz-date-picker>
    </nz-form-control>
  </nz-form-item>
</form>

<footer *nzModalFooter>
  <button nz-button nzType="primary" [nzLoading]="loading$ | async" (click)="setBirthdate()">
    {{ 'SAVE' | translate }}
  </button>
</footer>
