import { ConfigService } from '@akebono/core';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { filter, map, switchMap, toArray } from 'rxjs/operators';

import { AlertFragment } from '../../../../graphql/service/graphql-auto-main-service';
import { AlertData } from '../../types/alert-data.type';

@Component({
  selector: 'app-alerts-list-manual',
  templateUrl: './alerts-list-manual.component.html',
  styleUrls: ['./alerts-list-manual.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertsListManualComponent {
  alerts$: Observable<AlertData[]> = of([]);

  constructor(private configService: ConfigService) {}

  @Input()
  set alerts(alerts: AlertFragment[] | null | undefined) {
    this.alerts$ = this.configService.getCurrentLanguage$().pipe(
      map((language) => language.localeId),
      switchMap((lang) =>
        from(alerts ?? []).pipe(
          filter((alert) => (lang === 'ru' ? !!alert.contentRu : !!alert.contentEn)),
          map((alert) => ({
            style: alert.style,
            content: lang === 'ru' ? alert.contentRu : alert.contentEn,
          })),
          toArray(),
        ),
      ),
    );
  }
}
