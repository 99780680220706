import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { map, takeWhile } from 'rxjs/operators';

import { UserPurchaseStatisticsService } from './services/user-purchase-statistics.service';
import { UserPurchaseStatisticsBidsFilterQueryParams } from './types/user-purchase-statistics-bids-filter-query-params.type';

@Component({
  selector: 'app-user-purchase-statistics',
  templateUrl: './user-purchase-statistics.component.html',
  styleUrls: ['./user-purchase-statistics.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserPurchaseStatisticsComponent {
  loading$: Observable<boolean>;

  boughtToday$: Observable<number>;
  boughtYesterday$: Observable<number>;
  boughtWeek$: Observable<number>;

  constructor(private readonly userPurchaseStatisticsService: UserPurchaseStatisticsService) {
    this.loading$ = userPurchaseStatisticsService.loading$.pipe(takeWhile((value) => value, true));

    const statistics$ = userPurchaseStatisticsService.statistics$;
    this.boughtToday$ = statistics$.pipe(map((stat) => stat.today));
    this.boughtYesterday$ = statistics$.pipe(map((stat) => stat.yesterday));
    this.boughtWeek$ = statistics$.pipe(map((stat) => stat.week));
  }

  get bidsRoute(): string[] {
    return ['/bids'];
  }

  get bidsFilterQueryParams(): UserPurchaseStatisticsBidsFilterQueryParams {
    const dates = this.userPurchaseStatisticsService.statisticsDates;

    return {
      today: {
        bidLotAuctionDateFrom: dates.today.toFormat('yyyy-MM-dd'),
        tab: 'won',
      },
      yesterday: {
        bidLotAuctionDateFrom: dates.yesterday.toFormat('yyyy-MM-dd'),
        tab: 'won',
      },
      week: {
        // plus 1 day - is fix for 'gte' filter
        bidLotAuctionDateFrom: dates.weekStart.plus({ day: 1 }).toFormat('yyyy-MM-dd'),
        tab: 'won',
      },
    };
  }
}
