import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';

import { UserLotMark, UserLotMarkEnum } from '../../graphql/service/graphql-auto-main-service';

@Component({
  selector: 'app-shared-icon',
  templateUrl: './lot-shared-icon.component.html',
  styleUrls: ['./lot-shared-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotSharedIconComponent implements OnChanges {
  @Input() marks: UserLotMark[] = [];
  @Input() lotId: number;

  visible = false;

  ngOnChanges(): void {
    this.visible = !!this.marks
      ?.filter((mark) => mark.mark === UserLotMarkEnum.Shared)
      ?.some((mark) => this.lotId === mark.lot.id);
  }
}
