import { ConfigService } from '@akebono/core';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { filter, map, switchMap, toArray } from 'rxjs/operators';

import {
  AlertFragment,
  AlertStyleEnum,
  AlertTargetEnum,
} from '../../graphql/service/graphql-auto-main-service';

type BiddingAlert = {
  style: AlertStyleEnum;
  content: string;
};

@Component({
  selector: 'app-main-alerts',
  templateUrl: './main-alerts.component.html',
  styleUrls: ['./main-alerts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainAlertsComponent {
  alerts$: Observable<BiddingAlert[]> = of([]);

  constructor(private configService: ConfigService) {}

  @Input()
  set alerts(alerts: AlertFragment[] | null | undefined) {
    this.alerts$ = this.configService.getCurrentLanguage$().pipe(
      map((language) => language.localeId),
      switchMap((lang) =>
        from(alerts ?? []).pipe(
          filter((alert) => alert.target === AlertTargetEnum.MainPage),
          filter((alert) => (lang === 'ru' ? !!alert.contentRu : !!alert.contentEn)),
          map((alert) => ({
            style: alert.style,
            content: lang === 'ru' ? alert.contentRu : alert.contentEn,
            visible: this.checkAlertVisibility(alert),
          })),
          toArray(),
        ),
      ),
    );
  }

  checkAlertVisibility(alert: AlertFragment) {
    if (alert.isOnetime) {
      const isVisible = !localStorage.getItem(`alerted-${alert.id}`);
      if (isVisible) {
        localStorage.setItem(`alerted-${alert.id}`, '1');
      }
      return isVisible;
    }
    return true;
  }
}
