import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { BidAmountFragment } from '../../graphql/service/graphql-auto-main-service';

@Component({
  selector: 'app-lot-bid-amount',
  templateUrl: './lot-bid-amount.component.html',
  styleUrls: ['./lot-bid-amount.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotBidAmountComponent {
  @Input() bid: BidAmountFragment;
  @Input() renderColon = true;
  @Input() renderSpace = true;
  @Input() commissionPrefix = 'MAX_BID_COMMISSION.COMMISSION';
}
