import { QueryHandlingService } from '@akebono/core';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import QRCodeStyling from 'qr-code-styling';
import { of, Subscription } from 'rxjs';
import { map, pluck } from 'rxjs/operators';

import { EcarJpBotSubscribeUrlGQL } from '../../graphql/service/graphql-shared-familiar-service';

@Component({
  selector: 'app-connect-telegram-modal',
  templateUrl: './connect-telegram-modal.component.html',
  styleUrls: ['./connect-telegram-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConnectTelegramModalComponent implements OnDestroy, AfterViewInit {
  @ViewChild('qrSection', { static: true }) qrSection: ElementRef;

  loading$ = of(false);
  link$ = of('');

  private subscription = Subscription.EMPTY;

  constructor(
    private qhs: QueryHandlingService,
    private ecarJpBotSubscribeUrlGQL: EcarJpBotSubscribeUrlGQL,
  ) {
    const ref = this.qhs.fetch(this.ecarJpBotSubscribeUrlGQL);

    this.loading$ = ref.loading;
    this.link$ = ref.data.pipe(pluck('ecarJpBotSubscribeUrl'));
  }

  ngAfterViewInit(): void {
    this.subscription = this.link$
      .pipe(map((link) => this.generateQrCode(link)))
      .subscribe((qr) => qr.append(this.qrSection.nativeElement));
  }

  private generateQrCode(link: string): QRCodeStyling {
    return new QRCodeStyling({
      width: 280,
      height: 280,
      data: link,
      margin: 0,
      qrOptions: {
        typeNumber: 0,
        mode: 'Byte',
        errorCorrectionLevel: 'Q',
      },
      dotsOptions: {
        type: 'extra-rounded',
        color: '#6a1a4c',
        gradient: {
          type: 'radial',
          rotation: 0.12217304763960307,
          colorStops: [
            { offset: 0, color: '#00b3ff' },
            { offset: 1, color: '#d90889' },
          ],
        },
      },
      backgroundOptions: { color: '#ffffff', gradient: null },
      cornersSquareOptions: {
        type: 'extra-rounded',
        color: '#000000',
        gradient: {
          type: 'linear',
          rotation: 0,
          colorStops: [
            { offset: 0, color: '#194de6' },
            { offset: 1, color: '#d90889' },
          ],
        },
      },
      cornersDotOptions: { type: 'dot', color: '#194de6', gradient: null },
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
