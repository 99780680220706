import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NewsCardDataFragment } from 'src/app/modules/graphql/service/graphql-content-service';

@Component({
  selector: 'app-news-card',
  templateUrl: './news-card.component.html',
  styleUrls: ['./news-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewsCardComponent {
  @Input() news: NewsCardDataFragment;
  @Input() language: 'ru' | 'en';

  get title(): string | null | undefined {
    if (this.language === 'ru') {
      return this.news.title?.ru || this.news.title?.en;
    } else {
      return this.news.title?.en || this.news.title?.ru;
    }
  }

  get content(): string | null | undefined {
    if (this.language === 'ru') {
      return this.news.content?.ru || this.news.content?.en;
    } else {
      return this.news.content?.en || this.news.content?.ru;
    }
  }
}
