import { ConfigService } from '@akebono/core';
import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ExcelBidParsingResult } from './types/excel-bid-parse-result.type';

@Component({
  selector: 'app-import-bids',
  templateUrl: './import-bids.component.html',
  styleUrls: ['./import-bids.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImportBidsComponent implements OnDestroy {
  parsingResult?: ExcelBidParsingResult;

  private readonly destroy$ = new Subject<void>();

  constructor(title: Title, translate: TranslateService, configService: ConfigService) {
    configService
      .getCurrentLanguage$()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => title.setTitle(translate.instant('IMPORT_BIDS.TITLE')));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
