import { PermissionsGuard } from '@akebono/core';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProtectedGuard } from 'ngx-auth';
import { LayoutComponent } from 'src/app/components/layout/layout.component';
import { PERMISSION_LOTS } from 'src/app/const';

import { MotoLotComponent } from './components/moto-lot/moto-lot.component';
import { MotoLotsComponent } from './components/moto-lots-list/moto-lots-list.component';

const haveLotsAccess = {
  canActivate: [PermissionsGuard, ProtectedGuard],
  data: { permissions: [PERMISSION_LOTS] },
};

const routes: Routes = [
  {
    path: 'moto',
    component: LayoutComponent,
    children: [
      { path: '', component: MotoLotsComponent },
      { path: 'lot/:lot', component: MotoLotComponent, data: { breadcrumb: 'Moto lot' } },
      { path: 'lot/:source/:lot', component: MotoLotComponent, data: { breadcrumb: 'Moto lot' } },
    ],
    ...haveLotsAccess,
    data: { breadcrumb: 'Moto lots' },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AuctionsMotoRoutingModule {}
