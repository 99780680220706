<div class="list-wrapper">
  <nz-input-group>
    <nz-select
      nzShowSearch
      [nzDropdownRender]="renderTemplate"
      [(ngModel)]="userLotMarksListId"
      [nzPlaceHolder]="'SHARED.SELECT_LIST' | translate"
      [nzLoading]="queryRef.loading | async"
      [nzDropdownMatchSelectWidth]="false"
    >
      <nz-option
        *ngFor="let item of (queryRef.data | async)?.currentUser?.lotMarksLists"
        [nzLabel]="item?.title"
        [nzValue]="item?.id"
      ></nz-option>
    </nz-select>
    <button
      nz-button
      nzType="primary"
      [disabled]="listCreating$ | async"
      [nzLoading]="markCreating$ | async"
      (click)="userLotMarkCreate()"
    >
      {{ 'SHARED.LIST_ADD' | translate }}
    </button>
  </nz-input-group>
</div>

<ng-template #renderTemplate>
  <nz-spin [nzSpinning]="listCreating$ | async">
    <div class="new-item">
      <input
        [(ngModel)]="title"
        type="text"
        [placeholder]="'SHARED.ADD_NEW_LIST' | translate"
        nz-input
      />
      <button nz-button nzType="primary" (click)="userLotMarksListCreate()">
        <i nz-icon nzType="plus" nzTheme="outline"></i>
      </button>
    </div>
  </nz-spin>
</ng-template>
