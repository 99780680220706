<button
  nz-button
  [nzSize]="nzSize"
  [nzType]="fullSize ? 'default' : 'primary'"
  [nzBlock]="fullSize"
  [nzLoading]="loading$ | async"
  nz-popover
  [nzPopoverTrigger]="popoverTrigger"
  [nzPopoverContent]="shareLotResult"
  [(nzPopoverVisible)]="popoverVisible"
  (click)="share()"
>
  <i nz-icon nzType="link" nzTheme="outline"></i>
  <span *ngIf="fullSize">
    {{ 'SHARED.SHARE_LOT' | translate }}
  </span>
</button>

<ng-template #shareLotResult>
  <nz-tag (click)="copyLink()">
    {{ shareLotLink }}
    <i nz-icon nzType="copy" nzTheme="outline" nzSize="small"></i>
  </nz-tag>
</ng-template>
