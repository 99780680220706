import { ConfigService, MutationHandlingServiceV2, QueryHandlingService } from '@akebono/core';
import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Observable, Subject } from 'rxjs';
import { filter, map, shareReplay, switchMap, takeUntil } from 'rxjs/operators';
import {
  NewsEntryGQL,
  NewsEntryQuery,
  UserNewsViewCreateGQL,
} from 'src/app/modules/graphql/service/graphql-content-service';

@Component({
  selector: 'app-news-entry',
  templateUrl: './news-entry.component.html',
  styleUrls: ['./news-entry.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewsEntryComponent implements OnDestroy {
  loading$: Observable<boolean>;

  title$: Observable<string>;
  content$: Observable<string>;
  publishedAt$: Observable<string>;

  private readonly destroy$ = new Subject<void>();

  constructor(
    route: ActivatedRoute,
    qhs: QueryHandlingService,
    configService: ConfigService,
    mhs: MutationHandlingServiceV2,
    newsEntryGQL: NewsEntryGQL,
    userNewsViewCreateGQL: UserNewsViewCreateGQL,
  ) {
    const newsId$ = route.params.pipe(
      map((params) => params['id']),
      map((newsId) => (newsId ? Number(newsId) : null)),
      filter((newsId) => !!newsId),
    );

    const newsRef$ = newsId$.pipe(
      map((newsId) => qhs.fetch(newsEntryGQL, { newsId })),
      shareReplay(1),
    );

    this.loading$ = newsRef$.pipe(switchMap((ref) => ref.loading));
    const news$ = newsRef$.pipe(
      switchMap((ref) => ref.data),
      map((data) => data.newsEntry),
      shareReplay(1),
    );

    const language$ = configService
      .getCurrentLanguage$()
      .pipe(map((lang) => lang.localeId as 'ru' | 'en'));

    this.publishedAt$ = news$.pipe(map((news) => news.publishedAt));
    this.title$ = combineLatest([language$, news$]).pipe(
      map(([lang, news]) => this.extractByLanguage(news, 'title', lang)),
    );
    this.content$ = combineLatest([language$, news$]).pipe(
      map(([lang, news]) => this.extractByLanguage(news, 'content', lang)),
    );

    news$
      .pipe(
        filter((news) => !!news?.id),
        filter((news) => !news.isRead),
        map((news) =>
          mhs.mutate(
            userNewsViewCreateGQL,
            {
              input: {
                id: news!.id,
              },
            },
            { refetch: true, renderError: false, renderSuccess: false },
          ),
        ),
        switchMap((ref) => ref.data),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private extractByLanguage(
    news: NewsEntryQuery['newsEntry'],
    field: 'title' | 'content',
    language: 'ru' | 'en',
  ): string {
    switch (language) {
      case 'ru':
        return news[field]?.ru || news[field]?.en;
      default:
        return news[field]?.en || news[field]?.ru;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
