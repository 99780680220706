<ng-container *ngIf="shareLotLink; else create">
  <button nz-button nzType="dashed" (click)="copyLinkClipboard()">
    {{ shareLotLink }}
    <i nz-icon nzType="copy" nzTheme="outline" nzSize="small"></i>
  </button>
</ng-container>

<ng-template #create>
  <button
    nz-button
    nzType="dashed"
    [nzLoading]="refList$?.loading | async"
    (click)="userLotListUpdate()"
  >
    {{ 'SHARED.SHARE_LIST' | translate }}
  </button>
</ng-template>
