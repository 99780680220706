import { Injectable } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AutoLotView } from 'src/app/const';
import { LotTypeEnum } from 'src/app/modules/graphql/service/graphql-auto-main-service';

import { PurchaseReportFormModalComponent } from '../purchase-report-form-modal.component';

@Injectable()
export class PurchaseReportFormModalService {
  constructor(private readonly modalService: NzModalService) {}

  openPurchaseReportFromModal(lot: AutoLotView, lotType: LotTypeEnum): void {
    this.modalService.create({
      nzTitle: 'Purchase report',
      nzContent: PurchaseReportFormModalComponent,
      nzCentered: true,
      nzComponentParams: {
        lot,
        lotType,
      },
    });
  }
}
