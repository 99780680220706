<from nz-form nzLayout="vertical">
  <nz-form-item>
    <nz-form-control>
      <input nz-input [(ngModel)]="title" />
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-control>
      <label nz-checkbox [(ngModel)]="notificationsEnabled">
        {{ 'ENABLE_NEW_LOTS_NOTIFICATION' | translate }}
      </label>
    </nz-form-control>
  </nz-form-item>
</from>


<footer *nzModalFooter>
  <button nz-button (click)="modal.destroy()">
    {{ 'CANCEL' | translate }}
  </button>
  <button nz-button nzType="primary" [nzLoading]="creating$ | async" (click)="save()">
    {{ 'SAVE' | translate }}
  </button>
</footer>
