import { QueryHandlingService } from '@akebono/core';
import { Inject, Injectable } from '@angular/core';
import { from } from 'rxjs';
import { find, map, switchMap } from 'rxjs/operators';
import { AutoLotView } from 'src/app/const';
import {
  LotSource,
  LotTypeEnum,
  UserLotMarkDataFragment,
  UserLotMarkEnum,
  UserLotMarkGQL,
  UserLotMarksGQL,
} from 'src/app/modules/graphql/service/graphql-auto-main-service';
import { AutoLot } from 'src/app/modules/graphql/service/graphql-cars-default-service';

import { INTERESTING_LIMIT } from '../../constants';
import { LotInterestingStorageFactoryService } from '../lot-interesting-store/lot-interesting-store-factory.service';
import { LotInterestingManager } from './lot-interesting-manager';

@Injectable()
export class LotInterestingManagerFactory {
  constructor(
    @Inject(INTERESTING_LIMIT)
    private readonly INTERESTING_LOT_LIMIT: number,
    private qhs: QueryHandlingService,
    private interestingStoreFactory: LotInterestingStorageFactoryService,
    private userLotMarksGQL: UserLotMarksGQL,
    private userLotMarkGQL: UserLotMarkGQL,
  ) {}

  public createAll(
    lot: AutoLot | AutoLotView,
    lotType: LotTypeEnum,
    lotSource: LotSource,
  ): LotInterestingManager {
    const source = this.qhs
      .fetch(this.userLotMarksGQL, { first: this.INTERESTING_LOT_LIMIT }, 'cache-first')
      .data.pipe(map((result) => result.currentUser.lotMarks.nodes));
    const store = this.interestingStoreFactory.create(source);
    return new LotInterestingManager(store, lot, lotType, lotSource);
  }

  public createSingle(
    lot: AutoLot | AutoLotView,
    lotType: LotTypeEnum,
    lotSource: LotSource,
    source?: UserLotMarkDataFragment[],
  ): LotInterestingManager {
    let sourceObs = null;

    if (source !== undefined) {
      sourceObs = from(source).pipe(
        find(
          (item) =>
            item.mark === UserLotMarkEnum.Interesting ||
            item.mark === UserLotMarkEnum.Uninteresting,
        ),
      );
    } else {
      sourceObs = this.qhs
        .fetch(this.userLotMarkGQL, {
          id: lot.id,
          lotSource,
          subgroup: lotType,
        })
        .data.pipe(
          map((result) => result.lot.currentUserLotMarks),
          switchMap((result) =>
            from(result).pipe(
              find(
                (item) =>
                  item.mark === UserLotMarkEnum.Interesting ||
                  item.mark === UserLotMarkEnum.Uninteresting,
              ),
            ),
          ),
        );
    }

    const store = this.interestingStoreFactory.create(sourceObs);
    return new LotInterestingManager(store, lot, lotType, lotSource);
  }
}
