import { from, iif, Observable } from 'rxjs';
import { filter, last, map, mapTo, pluck, startWith, switchMap } from 'rxjs/operators';
import { AutoLotView } from 'src/app/const';
import {
  LotSource,
  LotTypeEnum,
  UserLotMark,
  UserLotMarkEnum,
} from 'src/app/modules/graphql/service/graphql-auto-main-service';
import { AutoLot } from 'src/app/modules/graphql/service/graphql-cars-default-service';

import { LotInterestingStore } from '../lot-interesting-store/lot-intresting-store';

export class LotInterestingManager {
  constructor(
    private store: LotInterestingStore,
    private lot: AutoLot | AutoLotView,
    private lotType: LotTypeEnum,
    private lotSource: LotSource,
  ) {}

  public isInteresting(): Observable<UserLotMarkEnum | null>;
  public isInteresting(marks: UserLotMark[]): Observable<UserLotMarkEnum | null>;
  public isInteresting(marks?: UserLotMark[]): Observable<UserLotMarkEnum | null> {
    if (marks) {
      return from(marks).pipe(
        filter((mark) => mark.lot.id === this.lot.id),
        pluck('mark'),
        filter(
          (mark) => mark === UserLotMarkEnum.Interesting || mark === UserLotMarkEnum.Uninteresting,
        ),
        last(),
        startWith(null as UserLotMarkEnum | null),
      );
    } else {
      return this.store
        .get(this.lot.id, this.lotType)
        .pipe(map((entry) => entry?.interesting ?? null));
    }
  }

  public interesting(): Observable<void> {
    return this.store.exists(this.lot.id, this.lotType).pipe(
      switchMap((exists) =>
        iif(
          () => exists,
          this.store.update(this.lot.id, this.lotType, UserLotMarkEnum.Interesting),
          this.store.create(this.lot.id, this.lotType, this.lotSource, UserLotMarkEnum.Interesting),
        ),
      ),
      mapTo(null as void),
    );
  }

  public uninteresting(): Observable<void> {
    return this.store.exists(this.lot.id, this.lotType).pipe(
      switchMap((exists) =>
        iif(
          () => exists,
          this.store.update(this.lot.id, this.lotType, UserLotMarkEnum.Uninteresting),
          this.store.create(
            this.lot.id,
            this.lotType,
            this.lotSource,
            UserLotMarkEnum.Uninteresting,
          ),
        ),
      ),
      mapTo(null as void),
    );
  }

  public remove(): Observable<void> {
    return this.store.exists(this.lot.id, this.lotType).pipe(
      switchMap((exists) => iif(() => exists, this.store.delete(this.lot.id, this.lotType))),
      mapTo(null as void),
    );
  }

  public get changes$(): Observable<UserLotMarkEnum | null> {
    return this.store.changes$.pipe(
      filter(({ lotId, lotType: type }) => this.lot.id === lotId && this.lotType === type),
      pluck('interesting'),
    );
  }
}
