import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-laa-auction',
  templateUrl: './laa-auction.component.html',
  styleUrls: ['./laa-auction.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LaaAuctionComponent {
  @Input() lang = 'ru';
}
