import {
  AuthenticationService,
  MutationHandlingServiceV2,
  QueryHandlingService,
} from '@akebono/core';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { from, of } from 'rxjs';
import { pluck, shareReplay } from 'rxjs/operators';
import {
  GuidanceRequestCreateGQL,
  HasActiveGuidanceRequestGQL,
} from 'src/app/modules/graphql/service/graphql-auto-main-service';

@Component({
  selector: 'app-forbidden',
  templateUrl: './forbidden.component.html',
  styleUrls: ['./forbidden.component.scss'],
})
export class ForbiddenComponent implements OnInit {
  hasLotsPermission$ = of(false);
  hasActiveGuidanceRequest$ = of(false);

  loading$ = of(false);
  navigating = false;
  creatingRequest$ = of(false);

  constructor(
    private router: Router,
    private qhs: QueryHandlingService,
    private mhs: MutationHandlingServiceV2,
    private authService: AuthenticationService,
    private guidanceRequestCreateGQL: GuidanceRequestCreateGQL,
    private hasActiveGuidanceRequestGQL: HasActiveGuidanceRequestGQL,
  ) {}

  ngOnInit(): void {
    const queryRef = this.qhs.watch(this.hasActiveGuidanceRequestGQL);

    this.loading$ = queryRef.loading.pipe(shareReplay(1));
    this.hasActiveGuidanceRequest$ = queryRef.data.pipe(
      shareReplay(1),
      pluck('currentUser'),
      pluck('hasActiveGuidanceRequest'),
    );

    this.hasLotsPermission$ = from(this.authService.checkPermissions(['cars.lots']));
  }

  createGuidanceRequest(): void {
    this.creatingRequest$ = this.mhs.mutate(
      this.guidanceRequestCreateGQL,
      {
        input: {},
      },
      {
        refetch: true,
        successTranslationKey: 'GUIDANCE_REQUEST_CREATED',
      },
    ).loading;
  }

  navigateToMain() {
    if (!this.navigating) {
      this.navigating = true;
      const t = setTimeout(() => {
        this.router.navigateByUrl('/');
        clearTimeout(t);
      }, 3000);
    }
  }

  singOut(): void {
    this.authService.signOut();
    this.router.navigateByUrl('/sign-in');
  }
}
