<button
  *ngIf="currentUserLastBid && currentUserLastBid?.isNegotiationsAccepted === null"
  nz-button
  nzType="primary"
  nzSize="small"
  (click)="confirmNegotiations1()"
>
  {{ 'LOT_INFO.APPROVE_PARTICIPATION_IN_NEGOTIATIONS' | translate }}
</button>

<nz-tag *ngIf="currentUserLastBid?.isNegotiationsAccepted" nzColor="success">
  <i nz-icon nzType="check-circle"></i>
  {{ 'LOT_INFO.NEGOTIATIONS_ACCEPTED' | translate }}
</nz-tag>

<ng-template #content2>
  <div class="modal-text">
    {{
      'NEGOTIATIONS_CONFIRM_2'
        | translate
          : {
              sum: negotiationsPrice | currency: 'JPY':'symbol-narrow'
            }
    }}:
  </div>
  <form [formGroup]="form">
    <nz-form-item>
      <nz-form-control nzHasFeedback [nzErrorTip]="amountErrorTpl">
        <nz-input-group nzPrefix="￥">
          <input
            nz-input
            type="number"
            formControlName="amount"
            [placeholder]="'LOT_BIDDING_MODAL.YOUR_BID' | translate"
          />
          <ng-template #amountErrorTpl let-control>
            <div *ngIf="control.errors?.['required']">
              {{ 'NEGOTIATIONS_BID_REQUIRED_ERROR' | translate }}
            </div>
            <div *ngIf="control.errors?.['min']">
              {{
                'NEGOTIATIONS_BID_TOO_SMALL_ERROR'
                  | translate
                    : {
                        amount: control.errors.min.min | currency: 'JPY':'symbol-narrow'
                      }
              }}
            </div>
            <div *ngIf="control.errors?.['max']">
              {{
                'NEGOTIATIONS_BID_TOO_LARGE_ERROR'
                  | translate
                    : {
                        amount: control.errors.max.max | currency: 'JPY':'symbol-narrow'
                      }
              }}
            </div>
          </ng-template>
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
  </form>
</ng-template>
