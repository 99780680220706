import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-news-layout',
  templateUrl: './news-layout.component.html',
  styleUrls: ['./news-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewsLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
