import { AkebonoCoreModule } from '@akebono/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzModalModule } from 'ng-zorro-antd/modal';

import { LotBiddingModalModule } from '../lot-bidding-modal/lot-bidding-modal.module';
import { LotPlaceBidButtonDumbComponent } from './lot-place-bid-button-dumb.component';

@NgModule({
  declarations: [LotPlaceBidButtonDumbComponent],
  imports: [
    CommonModule,
    AkebonoCoreModule,
    LotBiddingModalModule,
    TranslateModule,
    NzButtonModule,
    NzModalModule,
    NzIconModule,
  ],
  exports: [LotPlaceBidButtonDumbComponent],
})
export class LotPlaceBidButtonDumbModule {}
