import { AkebonoCoreModule } from '@akebono/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzTypographyModule } from 'ng-zorro-antd/typography';

import { NewsCardComponent } from './components/news-card/news-card.component';
import { NewsCounterComponent } from './components/news-counter/news-counter.component';
import { NewsEntryComponent } from './components/news-entry/news-entry.component';
import { NewsLayoutComponent } from './components/news-layout/news-layout.component';
import { NewsListComponent } from './components/news-list/news-list.component';
import { CurrentUserNewsDataService } from './services/current-user-news-data.service';
import { NewsCounterService } from './services/news-counter.service';

@NgModule({
  declarations: [
    NewsListComponent,
    NewsEntryComponent,
    NewsLayoutComponent,
    NewsCounterComponent,
    NewsCardComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    AkebonoCoreModule,
    NzLayoutModule,
    NzGridModule,
    NzSpinModule,
    NzPaginationModule,
    NzEmptyModule,
    NzBadgeModule,
    NzSkeletonModule,
    NzTypographyModule,
  ],
  providers: [CurrentUserNewsDataService, NewsCounterService],
  exports: [NewsCounterComponent],
})
export class NewsModule {}
