<ng-container *akbCheck="['cars.bids']">
  <button
    *ngIf="showBidButton$ | async"
    nz-button
    nzSize="small"
    nzType="text"
    [nzLoading]="loading$ | async"
    (click)="placeBid()"
  >
    <i nz-icon nzType="account-book" nzTheme="outline"></i>
  </button>
</ng-container>
