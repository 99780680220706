<button
  *ngIf="!hasBestBidPermission && !subscriptionActive"
  nz-button
  nzSize="small"
  nz-tooltip
  [nzTooltipTitle]="confirmTitle"
  [(nzTooltipVisible)]="tooltipVisible"
  (click)="openModal()"
>
  <span *ngIf="buttonTextType === 'full'">
    {{ 'SUBSCRIBE_TO_BEST_BID.TITLE' | translate }}
  </span>
  <span *ngIf="buttonTextType === 'short'">
    {{ 'SUBSCRIBE_TO_BEST_BID.OK_TEXT' | translate }}
  </span>
</button>

<div *ngIf="renderCounter && hasBestBidPermission">
  <div *ngIf="subscriptionActive">
    <span *ngIf="subDaysLeft">
      {{ 'SUBSCRIBE_TO_BEST_BID.DAYS_LEFT' | translate: { days: subDaysLeft } }}
    </span>
    <span *ngIf="!subDaysLeft">
      {{ 'SUBSCRIBE_TO_BEST_BID.SUB_ENDS_TODAY' | translate }}
    </span>
  </div>
  <div *ngIf="!subscriptionActive">
    {{ 'SUBSCRIBE_TO_BEST_BID.NO_ACTIVE_SUB' | translate }}
  </div>
</div>

<ng-template #confirmTitle>
  <div>
    <div>{{ 'SUBSCRIBE_TO_BEST_BID.CONFIRMATION_1' | translate }}</div>
    <div [innerHtml]="'SUBSCRIBE_TO_BEST_BID.CONFIRMATION_2' | translate"></div>
  </div>
</ng-template>
