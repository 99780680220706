import { QueryHandlingService, WatchQueryRef } from '@akebono/core';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  BoundaryLotsFilter,
  BoundaryLotsGQL,
  BoundaryLotsQuery,
  BoundaryLotsQueryVariables,
} from '../../modules/graphql/service/graphql-auto-main-service';

@Component({
  selector: 'app-boundary-lots',
  templateUrl: './boundary-lots.component.html',
  styleUrls: ['./boundary-lots.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoundaryLotsComponent implements OnInit, OnDestroy {
  queryRef?: WatchQueryRef<BoundaryLotsQuery, BoundaryLotsQueryVariables>;
  filter?: BoundaryLotsFilter = null;

  page = 0;
  pageSize = 20;

  private destroy$ = new Subject();

  constructor(
    private title: Title,
    private route: ActivatedRoute,
    private qhs: QueryHandlingService,
    private translate: TranslateService,
    private boundaryLotsGQL: BoundaryLotsGQL,
  ) {
    this.title.setTitle('Boundary lots');
  }

  ngOnInit(): void {
    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      this.page = Number(params.pageIndex) || 1;
      this.pageSize = Number(params.pageSize) || 20;
      const date = params.lotAuctionDate ? new Date(params.lotAuctionDate) : null;

      this.filter = {
        lotAuctionDate: {
          eq: date && DateTime.fromJSDate(date, { zone: 'UTC' }).startOf('day').toISO(),
        },
      };

      const variables: BoundaryLotsQueryVariables = {
        first: this.pageSize,
        offset: (this.page - 1) * this.pageSize,
        filter: this.filter,
      };

      if (this.queryRef) {
        this.queryRef.refetch(variables);
      } else {
        this.queryRef = this.qhs.watch(this.boundaryLotsGQL, variables);
      }
    });
  }

  getTitle(total?: number, onDateTotal?: number): string {
    const totalBoundaries = this.translate.instant('TOTAL_BOUNDARY', { count: total ?? 0 });

    if (this.filter?.lotAuctionDate?.eq) {
      const date = DateTime.fromISO(this.filter.lotAuctionDate.eq, { zone: 'UTC' }).toFormat(
        'yyyy-MM-dd',
      );
      const boundariesOnDate = this.translate.instant('ON_DATE_BOUNDARY', {
        date,
        count: onDateTotal ?? 0,
      });

      return `${totalBoundaries}. ${boundariesOnDate}`;
    } else {
      return totalBoundaries;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
