import { AutoLotView, ManualLotData } from './const';
import { electricCars } from './electric-cars';
import { hybrids } from './hybrids-manual';
import { trucks } from './trucks';

interface HybridData {
  company: string;
  model: string;
  frame: string;
  reason: string;
  reasonvalue: string;
}

export const hybridsAndElectro: HybridData[] = [
  {
    company: 'ACURA',
    model: 'NSX',
    frame: 'NC1',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'ACURA',
    model: 'RLX',
    frame: 'KC1',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'ACURA',
    model: 'RLX',
    frame: 'KC2',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'AUDI',
    model: 'A3',
    frame: '8V1',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'AUDI',
    model: 'A3',
    frame: '8VA',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'AUDI',
    model: 'A3',
    frame: '8VCJSF, 8VCPT, 8VCXS, 8VCUK',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'AUDI',
    model: 'A3',
    frame: 'GYDLA, GYDNNF',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'AUDI',
    model: 'A4',
    frame: '8W2',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'AUDI',
    model: 'A4',
    frame: '8WDDWF, 8WDEM, 8WDEZ, 8WDTPF, 8WDETF',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'AUDI',
    model: 'A5',
    frame: 'F53',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'AUDI',
    model: 'A5',
    frame: 'F57',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'AUDI',
    model: 'A5',
    frame: 'F5A',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'AUDI',
    model: 'A5',
    frame: 'F5DDWF, F5DTPF, F5DETF',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'AUDI',
    model: 'A5',
    frame: 'F5DDWL, F5DEZL, F5DTPL, F5DETL',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'AUDI',
    model: 'A6',
    frame: '4G2',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'AUDI',
    model: 'A6',
    frame: '4GCGWS, 4GCHVS, 4GCHJS, 4GCDN',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'AUDI',
    model: 'A6',
    frame: '4K',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'AUDI',
    model: 'A6',
    frame: '4K2',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'AUDI',
    model: 'A6',
    frame: '4K5',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'AUDI',
    model: 'A6',
    frame: 'F2DKNF, F2DLZF, F2DFBF',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'AUDI',
    model: 'A6 ALLROAD QUATTRO',
    frame: null,
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'AUDI',
    model: 'A7',
    frame: 'F2DKNS, F2DLZS, F2DFBS',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'AUDI',
    model: 'A8',
    frame: '4H2,  4H8, 4HC,  4HL',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'AUDI',
    model: 'A8',
    frame: 'D5',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'AUDI',
    model: 'E-TRON',
    frame: '',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'AUDI',
    model: 'E-TRON',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'AUDI',
    model: 'E-TRON GT',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'AUDI',
    model: 'E-TRON S',
    frame: '',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'AUDI',
    model: 'E-TRON S',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'AUDI',
    model: 'E-TRON SPORTBACK',
    frame: '',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'AUDI',
    model: 'E-TRON SPORTBACK',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'AUDI',
    model: 'E-TRON SPORTBACK S',
    frame: '',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'AUDI',
    model: 'E-TRON SPORTBACK S',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'AUDI',
    model: 'Q2 E-TRON',
    frame: 'GA',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'AUDI',
    model: 'Q3',
    frame: 'F3DFY, F3DPC, F3DFGF',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'AUDI',
    model: 'Q4 E-TRON',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'AUDI',
    model: 'Q4 SPORTBACK E-TRON',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'AUDI',
    model: 'Q5',
    frame: '8RB',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'AUDI',
    model: 'Q5',
    frame: 'FYB',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'AUDI',
    model: 'Q5',
    frame: 'FYDGKS, FYDTPS',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'AUDI',
    model: 'Q5 SPORTBACK',
    frame: 'FYB',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'AUDI',
    model: 'Q7',
    frame: '4MB',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'AUDI',
    model: 'Q7',
    frame: '4MDCBA, 4MDCBS, 4MCYRA',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'AUDI',
    model: 'Q8',
    frame: '4MN',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'AUDI',
    model: 'R8',
    frame: '422,  423',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'AUDI',
    model: 'RS6',
    frame: '4K4/C8',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'AUDI',
    model: 'RS E-TRON GT',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'AUDI',
    model: 'RS Q8',
    frame: '4MN',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'AUDI',
    model: 'S4',
    frame: '8W2',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'AUDI',
    model: 'S4',
    frame: '8W5',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'AUDI',
    model: 'S5',
    frame: 'F57',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'AUDI',
    model: 'S6',
    frame: '4K/C8',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'AUDI',
    model: 'S8',
    frame: 'D5',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'AUDI',
    model: 'SQ8',
    frame: '4MN',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'BAIC ',
    model: 'EU5',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'BENTLEY',
    model: 'BENTAYGA',
    frame: '4V1',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'BENTLEY',
    model: 'FLYING SPUR',
    frame: 'ZG2, ZG21BB',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'BMW',
    model: '2-SERIES ACTIVE TOURER',
    frame: '2A15, 2A20, 2C15, 2C20',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'BMW',
    model: '2-SERIES ACTIVE TOURER',
    frame: '6S15W, 6T20, 6Y15, 6S15, 2C15, 2C20',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'BMW',
    model: '2-SERIES ACTIVE TOURER',
    frame: 'F45',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'BMW',
    model: '3-SERIES',
    frame: '3F30, 3A20, 3B20, 3D20',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'BMW',
    model: '3-SERIES',
    frame: '5F20, 5U30, 5V20, 5X20',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'BMW',
    model: '3-SERIES',
    frame: '8A20, 8B30, 8E15, 8E20, 3D20, 8C20',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'BMW',
    model: '3-SERIES',
    frame: 'F30',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'BMW',
    model: '3-SERIES',
    frame: 'F31',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'BMW',
    model: '3-SERIES',
    frame: 'G20',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'BMW',
    model: '3-SERIES',
    frame: 'G21',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'BMW',
    model: '5-SERIES',
    frame: 'F10',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'BMW',
    model: '5-SERIES',
    frame: 'FR44, FZ35, FP25, FR30, FR35, XG20, XG28, FW20',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'BMW',
    model: '5-SERIES',
    frame: 'G30',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'BMW',
    model: '5-SERIES',
    frame: 'G31',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'BMW',
    model: '5-SERIES',
    frame: 'JR20S, JS30, JS44, JF20, JA20PH',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'BMW',
    model: '5-SERIES',
    frame: 'JR20S, JS30, JS44, JF20, JR20, JA20P, JA20, JB30, JC20',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'BMW',
    model: '5-SERIES',
    frame: 'KN44, FZ35, FR35, XG20, XG28, FW20',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'BMW',
    model: '7-SERIES',
    frame: '',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'BMW',
    model: '7-SERIES',
    frame: '7A44, 7F44, 7H66, 7A30, 7E30, 7D20, 7C30, 7G30',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'BMW',
    model: '7-SERIES',
    frame: '7R30, 7R44, 7T30, 7U44, 7U66, 7S30, 7V30, 7D30, 7W30',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'BMW',
    model: '7-SERIES',
    frame: 'F01, F01LCI, F02',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'BMW',
    model: '7-SERIES',
    frame: 'F01, F02, F04',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'BMW',
    model: '7-SERIES',
    frame: 'G11, G12',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'BMW',
    model: '7-SERIES',
    frame: 'YA44, YE44, YG60, YA30, YE30',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'BMW',
    model: 'I3',
    frame: '',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'BMW',
    model: 'I3',
    frame: '1Z06, 1Z00',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'BMW',
    model: 'I3',
    frame: '1Z06, 1Z00',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'BMW',
    model: 'I3',
    frame: '8P06, 1Z06, 1Z00, 8P00',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'BMW',
    model: 'I3',
    frame: '8P06, 1Z06, 1Z00, 8P00',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'BMW',
    model: 'I3',
    frame: 'I01',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'BMW',
    model: 'I3',
    frame: 'I01',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'BMW',
    model: 'I4',
    frame: 'G26',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'BMW',
    model: 'I7',
    frame: '',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'BMW',
    model: 'I7',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'BMW',
    model: 'I8',
    frame: 'I12',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'BMW',
    model: 'I8',
    frame: 'I15',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'BMW',
    model: 'IX',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'BMW',
    model: 'IX1',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'BMW',
    model: 'IX3',
    frame: '',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'BMW',
    model: 'IX3',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'BMW',
    model: 'X1',
    frame: 'F48, F49',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'BMW',
    model: 'X1',
    frame: 'U11',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'BMW',
    model: 'X2',
    frame: 'F39',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'BMW',
    model: 'X3',
    frame: 'F97, G01',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'BMW',
    model: 'X3',
    frame: 'G01',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'BMW',
    model: 'X3',
    frame: 'TS30, TY20, TY30, UZ7230, UZ20, TS20',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'BMW',
    model: 'X3',
    frame: 'TS30, TY20, TY30, UZ7230, UZ20, TS20, TR20, TX20, TX30',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'BMW',
    model: 'X5',
    frame: 'F15',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'BMW',
    model: 'X5',
    frame: 'F15, F85',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'BMW',
    model: 'X5',
    frame: 'JU44A, JU44M, JU44S, JU8230A, JU8230S, CV30A, CV30S, TA30',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'BMW',
    model: 'X5',
    frame: 'KT44, KR44, KR44S, KT20, KR30, KR30S, KS30, KS30S',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'BMW',
    model: 'X6',
    frame: 'CY44, JU44M, GT8230S, GT30',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'BMW',
    model: 'X6',
    frame: 'E71, E72',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'BMW',
    model: 'XM',
    frame: null,
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'BUICK',
    model: 'LACROSSE',
    frame: '',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'BUICK',
    model: 'LACROSSE',
    frame: null,
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'BUICK',
    model: 'VELITE 6',
    frame: '',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'BUICK',
    model: 'VELITE 6',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'BYD',
    model: 'E6',
    frame: '',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'BYD',
    model: 'E6',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'BYD',
    model: 'HAN',
    frame: '',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'BYD',
    model: 'HAN',
    frame: '',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'BYD',
    model: 'HAN',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'CADILLAC',
    model: 'CT6',
    frame: '',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'CADILLAC',
    model: 'ELR',
    frame: null,
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'CADILLAC',
    model: 'ESCALADE',
    frame: 'GMT900',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'CADILLAC',
    model: 'ESCALADE',
    frame: 'GMT926',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'CHANGAN',
    model: 'CS15 EV',
    frame: '',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'CHANGAN',
    model: 'CS15 EV',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'CHANGAN',
    model: 'UNI-K',
    frame: '',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'CHERY',
    model: 'BIG ANT EQ5',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'CHERY',
    model: 'TIGGO E',
    frame: '',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'CHERY',
    model: 'TIGGO E',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'CHEVROLET',
    model: 'BOLT',
    frame: '',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'CHEVROLET',
    model: 'BOLT',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'CHEVROLET',
    model: 'IMPALA',
    frame: null,
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'CHEVROLET',
    model: 'MALIBU',
    frame: '',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'CHEVROLET',
    model: 'MENLO',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'CHEVROLET',
    model: 'SILVERADO',
    frame: '',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'CHEVROLET',
    model: 'SPARK',
    frame: 'M300',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'CHEVROLET',
    model: 'VOLT',
    frame: '',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'CHEVROLET',
    model: 'VOLT',
    frame: null,
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'CHRYSLER',
    model: 'PACIFICA',
    frame: '',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'CITROEN',
    model: 'C-ZERO',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'CITROEN',
    model: 'E-MEHARI',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'CITROEN',
    model: 'SAXO',
    frame: 'S0',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'DAIHATSU',
    model: 'ALTIS',
    frame: 'AVV50N',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'DAIHATSU',
    model: 'ALTIS',
    frame: 'AXVH70N',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'DAIHATSU',
    model: 'ALTIS',
    frame: 'AXVH70N, AXVH75N',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'DAIHATSU',
    model: 'HIJET',
    frame: 'S200V, S210V',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'DAIHATSU',
    model: 'HIJET',
    frame: 'S320V, S330V',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'DAIHATSU',
    model: 'MEBIUS',
    frame: 'ZVW41N',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'DAIHATSU',
    model: 'ROCKY',
    frame: 'A210S, A202S, A200S, A201S',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'DODGE',
    model: 'DURANGO',
    frame: '',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'FAW',
    model: 'BESTURN B30',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'FAW',
    model: 'BESTURN X40 EV',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'FERRARI',
    model: '296 GTB',
    frame: null,
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'FERRARI',
    model: '296 GTS',
    frame: null,
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'FERRARI',
    model: 'LAFERRARI',
    frame: '',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'FERRARI',
    model: 'LAFERRARI',
    frame: null,
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'FERRARI',
    model: 'SF90 STRADALE',
    frame: '',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'FERRARI',
    model: 'SF90 STRADALE',
    frame: null,
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'FIAT',
    model: 'SEICENTO',
    frame: '187',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'FISKER',
    model: 'KARMA',
    frame: null,
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'FORD',
    model: 'C-MAX',
    frame: 'C344',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'FORD',
    model: 'ESCAPE',
    frame: '',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'FORD',
    model: 'F150',
    frame: null,
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'FORD',
    model: 'FIESTA',
    frame: '',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'FORD',
    model: 'FOCUS',
    frame: 'BK',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'FORD',
    model: 'FOCUS',
    frame: 'CEW',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'FORD',
    model: 'FUSION',
    frame: '',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'FORD',
    model: 'GALAXY',
    frame: 'CD390',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'FORD',
    model: 'MONDEO',
    frame: 'СD',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'FORD',
    model: 'MONDEO',
    frame: 'СF',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'FORD',
    model: 'MUSTANG MACH-E',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'FORD',
    model: 'PUMA',
    frame: null,
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'FORD',
    model: 'TERRITORY',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'FORD',
    model: 'TH!NK',
    frame: '',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'FORD',
    model: 'TH!NK',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'GEELY',
    model: 'ATLAS PRO',
    frame: '3BT, 3BY',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'GEELY',
    model: 'GEOMETRY A GE11',
    frame: '',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'GEELY',
    model: 'GEOMETRY A GE11',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'GMC',
    model: 'HUMMER EV',
    frame: '',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'GMC',
    model: 'HUMMER EV',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'GMC',
    model: 'YUKON',
    frame: 'GMT922, GMT932',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'HAVAL',
    model: 'H6',
    frame: '',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'HAWTAI',
    model: 'IEV230',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'HAWTAI',
    model: 'XEV260',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'HIPHI',
    model: 'X',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'HIPHI',
    model: 'Z',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'HONDA',
    model: 'ACCORD',
    frame: 'CM5, CM6',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'HONDA',
    model: 'ACCORD',
    frame: 'CR2, CR3',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'HONDA',
    model: 'ACCORD',
    frame: 'CR2, CR3, CR6',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'HONDA',
    model: 'ACCORD',
    frame: 'CR5',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'HONDA',
    model: 'ACCORD',
    frame: 'CR6',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'HONDA',
    model: 'ACCORD',
    frame: 'CR7',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'HONDA',
    model: 'ACCORD',
    frame: 'CV1, CV2, CV3',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'HONDA',
    model: 'ACCORD',
    frame: 'CV3',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'HONDA',
    model: 'CIVIC',
    frame: 'EN2, ES9',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'HONDA',
    model: 'CIVIC',
    frame: 'ES1, ES9',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'HONDA',
    model: 'CIVIC',
    frame: 'ES9, EN2',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'HONDA',
    model: 'CIVIC',
    frame: 'FA1, FA3, FA5',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'HONDA',
    model: 'CIVIC',
    frame: 'FB2, FB4, FB8',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'HONDA',
    model: 'CIVIC',
    frame: 'FD1, FD3',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'HONDA',
    model: 'CIVIC',
    frame: 'FD3, FD1, FD2',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'HONDA',
    model: 'CIVIC',
    frame: 'FL4, FL1',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'HONDA',
    model: 'CLARITY',
    frame: '',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'HONDA',
    model: 'CLARITY',
    frame: '',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'HONDA',
    model: 'CLARITY',
    frame: 'ZC5, ZC4',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'HONDA',
    model: 'CLARITY',
    frame: 'ZC5, ZC4',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'HONDA',
    model: 'CR-V',
    frame: 'RS',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'HONDA',
    model: 'CR-V',
    frame: 'RT5, RT6, RW1, RW2',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'HONDA',
    model: 'CR-V',
    frame: 'RW',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'HONDA',
    model: 'CR-Z',
    frame: 'ZF1',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'HONDA',
    model: 'CR-Z',
    frame: 'ZF2',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'HONDA',
    model: 'E',
    frame: '',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'HONDA',
    model: 'E',
    frame: 'ZC7',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'HONDA',
    model: 'E:NP1',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'HONDA',
    model: 'E:NS1',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'HONDA',
    model: 'FIT',
    frame: 'GE',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'HONDA',
    model: 'FIT',
    frame: 'GP1, GE6, GE7, GE8, GE9',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'HONDA',
    model: 'FIT',
    frame: 'GP1, GP4, GE6, GE7, GE8, GE9',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'HONDA',
    model: 'FIT',
    frame: 'GP5, GP6, GK3, GK4, GK5, GK6',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'HONDA',
    model: 'FIT',
    frame: 'GR3, GR4, GR6, GR8, GR1, GR2, GR5, GR7',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'HONDA',
    model: 'FIT',
    frame: 'GS4, GS5, GS6, GS7, GR3, GR4, GR6, GR8',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'HONDA',
    model: 'FIT SHUTTLE',
    frame: 'GP2, GG7, GG8',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'HONDA',
    model: 'FREED',
    frame: 'GB5, GB6, GB7, GB8',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'HONDA',
    model: 'FREED',
    frame: 'GB7, GB8, GB5, GB6',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'HONDA',
    model: 'FREED',
    frame: 'GP3, GB3, GB4',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'HONDA',
    model: 'FREED+',
    frame: 'GB5, GB6, GB7, GB8',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'HONDA',
    model: 'FREED+',
    frame: 'GB7, GB8, GB5, GB6',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'HONDA',
    model: 'FREED SPIKE',
    frame: 'GP3, GB3, GB4',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'HONDA',
    model: 'GRACE',
    frame: 'GM4, GM5, GM6, GM9',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'HONDA',
    model: 'HR-V',
    frame: 'RV',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'HONDA',
    model: 'INSIGHT',
    frame: 'ZE1',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'HONDA',
    model: 'INSIGHT',
    frame: 'ZE2',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'HONDA',
    model: 'INSIGHT',
    frame: 'ZE2, ZE3',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'HONDA',
    model: 'INSIGHT',
    frame: 'ZE4',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'HONDA',
    model: 'INSIGHT',
    frame: 'ZE5',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'HONDA',
    model: 'JADE',
    frame: 'FR4, FR5',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'HONDA',
    model: 'JAZZ',
    frame: 'GE1, GE6, GP1',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'HONDA',
    model: 'JAZZ',
    frame: 'GR3, GR6',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'HONDA',
    model: 'LEGEND',
    frame: 'KC2',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'HONDA',
    model: 'MN-V',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'HONDA',
    model: 'NSX',
    frame: 'NC1',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'HONDA',
    model: 'ODYSSEY',
    frame: 'RC4, RC1, RC2',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'HONDA',
    model: 'SHUTTLE',
    frame: 'GP7, GP8, GK8, GK9',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'HONDA',
    model: 'STEPWGN',
    frame: 'RP5, RP1, RP2, RP3, RP4',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'HONDA',
    model: 'STEPWGN',
    frame: 'RP6, RP7, RP8',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'HONDA',
    model: 'VEZEL',
    frame: 'RU3, RU4, RU1, RU2',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'HONDA',
    model: 'VEZEL',
    frame: 'RV5, RV6, RV3, RV4',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'HONDA',
    model: 'ZR-V',
    frame: 'RZ3, RZ5, RZ4, RZ6',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'HONGQI',
    model: 'E-HS9',
    frame: 'E115',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'HONGQI',
    model: 'H5',
    frame: null,
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'HOZON',
    model: 'NETA U',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'HOZON',
    model: 'NETA V',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'HYUNDAI',
    model: 'IONIQ',
    frame: 'AE',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'HYUNDAI',
    model: 'IONIQ',
    frame: 'AE',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'HYUNDAI',
    model: 'KONA ELECTRIC',
    frame: 'OS',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'HYUNDAI',
    model: 'SONATA',
    frame: 'LF',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'HYUNDAI',
    model: 'SONATA',
    frame: 'YF',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'INFINITI',
    model: 'Q50',
    frame: 'V37',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'INFINITI',
    model: 'QX60',
    frame: 'L50',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'JAC',
    model: 'IEV7S',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'JAGUAR',
    model: 'I-PACE',
    frame: '',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'JAGUAR',
    model: 'I-PACE',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'JEEP',
    model: 'AVENGER',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'JEEP',
    model: 'COMMANDER',
    frame: '',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'JEEP',
    model: 'GRAND CHEROKEE',
    frame: 'WL',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'JEEP',
    model: 'GRAND COMMANDER',
    frame: '',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'JEEP',
    model: 'GRAND COMMANDER',
    frame: null,
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'KIA',
    model: 'K3',
    frame: 'BD',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'KIA',
    model: 'K3',
    frame: 'BD',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'KIA',
    model: 'NIRO',
    frame: 'DE',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'KIA',
    model: 'NIRO',
    frame: 'DE',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'KIA',
    model: 'NIRO',
    frame: 'SG2',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'KIA',
    model: 'NIRO',
    frame: 'SG2',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'KIA',
    model: 'OPTIMA',
    frame: 'JF',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'KIA',
    model: 'OPTIMA',
    frame: 'TF',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'KIA',
    model: 'SOUL',
    frame: 'PS',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'KIA',
    model: 'SPORTAGE',
    frame: '',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'LAND ROVER',
    model: 'DEFENDER',
    frame: '',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'LAND ROVER',
    model: 'DEFENDER',
    frame: null,
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'LAND ROVER',
    model: 'RANGE ROVER',
    frame: 'L405',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'LAND ROVER',
    model: 'RANGE ROVER',
    frame: 'L460',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'LAND ROVER',
    model: 'RANGE ROVER EVOQUE',
    frame: 'L551',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'LAND ROVER',
    model: 'RANGE ROVER SPORT',
    frame: 'L461',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'LAND ROVER',
    model: 'RANGE ROVER SPORT',
    frame: 'L494',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'LAND ROVER',
    model: 'RANGE ROVER VELAR',
    frame: 'L560',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'LEXUS',
    model: 'CT200H',
    frame: 'ZWA10',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'LEXUS',
    model: 'ES300H',
    frame: 'AVV60',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'LEXUS',
    model: 'ES300H',
    frame: 'AXZH10',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'LEXUS',
    model: 'GS300H',
    frame: 'AWL10',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'LEXUS',
    model: 'GS450H',
    frame: 'GWL10',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'LEXUS',
    model: 'GS450H',
    frame: 'GWS191',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'LEXUS',
    model: 'HS250H',
    frame: 'ANF10',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'LEXUS',
    model: 'IS300H',
    frame: 'AVE30',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'LEXUS',
    model: 'IS300H',
    frame: 'AVE30, AVE35',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'LEXUS',
    model: 'LC500H',
    frame: 'GWZ100',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'LEXUS',
    model: 'LM300H',
    frame: 'AYH30',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'LEXUS',
    model: 'LS500H',
    frame: 'GVF50, GVF55',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'LEXUS',
    model: 'LS600H',
    frame: 'UVF45',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'LEXUS',
    model: 'LS600H',
    frame: 'UVF46',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'LEXUS',
    model: 'LS600HL',
    frame: 'UVF46',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'LEXUS',
    model: 'NX300H',
    frame: 'AYZ10, AYZ15',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'LEXUS',
    model: 'NX300H',
    frame: 'AYZ15',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'LEXUS',
    model: 'NX350H',
    frame: 'AAZH20, AAZH25',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'LEXUS',
    model: 'NX450H+',
    frame: 'AAZH26',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'LEXUS',
    model: 'RC300H',
    frame: 'AVC10',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'LEXUS',
    model: 'RX350H',
    frame: 'AL35',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'LEXUS',
    model: 'RX400H',
    frame: 'MHU38',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'LEXUS',
    model: 'RX450H',
    frame: 'GYL10, GYL15',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'LEXUS',
    model: 'RX450H',
    frame: 'GYL10W, GYL15W, GYL16W',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'LEXUS',
    model: 'RX450H',
    frame: 'GYL15',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'LEXUS',
    model: 'RX450H',
    frame: 'GYL20W, GYL25W',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'LEXUS',
    model: 'RX450H',
    frame: 'GYL25',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'LEXUS',
    model: 'RX450H+',
    frame: 'AALH16',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'LEXUS',
    model: 'RX450HL',
    frame: 'GYL26',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'LEXUS',
    model: 'RX450HL',
    frame: 'GYL26W',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'LEXUS',
    model: 'RX500H',
    frame: 'TALH17',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'LEXUS',
    model: 'RZ450E',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'LEXUS',
    model: 'UX250H',
    frame: 'MZAH10, MZAH15',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'LEXUS',
    model: 'UX250H',
    frame: 'MZAH15',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'LEXUS',
    model: 'UX300E',
    frame: '',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'LEXUS',
    model: 'UX300E',
    frame: 'KMA10',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'LI',
    model: 'L7',
    frame: null,
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'LI',
    model: 'L8',
    frame: null,
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'LI',
    model: 'L9',
    frame: null,
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'LI',
    model: 'ONE',
    frame: '',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'LI',
    model: 'ONE',
    frame: null,
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'LIFAN',
    model: '650 EV',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'LINCOLN',
    model: 'AVIATOR',
    frame: null,
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'LINCOLN',
    model: 'CORSAIR',
    frame: null,
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'LINCOLN',
    model: 'MKZ',
    frame: '',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'LINCOLN',
    model: 'MKZ',
    frame: null,
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'LUCID',
    model: 'AIR',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'MASERATI',
    model: 'GHIBLI',
    frame: 'M157',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MASERATI',
    model: 'GRANTURISMO',
    frame: 'M189',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'MASERATI',
    model: 'LEVANTE',
    frame: 'M161',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MAZDA',
    model: 'AXELA',
    frame: 'BM6FJ, BYEFP, BM5AP, BM5FP, BM2FP',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MAZDA',
    model: 'AXELA',
    frame: 'BYEFP, BM5AP, BM5FP, BM2AP, BM2FP, BMLFP',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MAZDA',
    model: 'CAROL',
    frame: 'HB97S, HB37S',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MAZDA',
    model: 'CX-30',
    frame: 'DM, DM5W76, DM5W7A',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MAZDA',
    model: 'CX-30',
    frame: 'DM, DM5W76, DM5W7A',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'MAZDA',
    model: 'CX-30',
    frame: 'DM, DM5W76, DM5W7A, DMEW76, DMEW7A',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MAZDA',
    model: 'CX-30',
    frame: 'DMFP, DM8P, DMEJ3P, DMEP',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MAZDA',
    model: 'CX-60',
    frame: '',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MAZDA',
    model: 'CX-60',
    frame: 'KH3R3P, KH3P, KH5P, KH5S3P',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MAZDA',
    model: 'FAMILIA',
    frame: 'NCP165M, NCP160M, NHP160M',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MAZDA',
    model: 'FLAIR',
    frame: 'MJ44S, MJ34S',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MAZDA',
    model: 'FLAIR',
    frame: 'MJ55S, MJ95S',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MAZDA',
    model: 'FLAIR',
    frame: 'MJ95S, MJ55S',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MAZDA',
    model: 'FLAIR CROSSOVER',
    frame: 'MS41S, MS31S',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MAZDA',
    model: 'FLAIR CROSSOVER',
    frame: 'MS52S, MS92S',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MAZDA',
    model: 'FLAIR WAGON',
    frame: 'MM42S, MM32S',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MAZDA',
    model: 'FLAIR WAGON',
    frame: 'MM53S',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MAZDA',
    model: 'MAZDA2',
    frame: 'DJ',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MAZDA',
    model: 'MAZDA2',
    frame: 'XP21',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MAZDA',
    model: 'MAZDA3',
    frame: 'BPEP, BP8P, BPFJ3P, BPFP, BP5P',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MAZDA',
    model: 'MX-30',
    frame: '',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'MAZDA',
    model: 'MX-30',
    frame: 'DREJ3P, DRH3P',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MAZDA',
    model: 'MX-30',
    frame: 'DREJ3P, DRH3P',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'MCLAREN',
    model: 'P1',
    frame: '',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MCLAREN',
    model: 'P1',
    frame: null,
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MERCEDES-BENZ',
    model: 'A-CLASS',
    frame: '177012, 177084, 177086',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MERCEDES-BENZ',
    model: 'A-CLASS',
    frame: '177112, 177147M, 177184, 177186',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MERCEDES-BENZ',
    model: 'AMG GT',
    frame: 'X290',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MERCEDES-BENZ',
    model: 'B-CLASS',
    frame:
      'T242.848, T242.890, T246.202, T246.203, T246.205, T246.208, T246.211, T246.212, T246.241, T246.242, T246.243, T246.244, T246.246',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'MERCEDES-BENZ',
    model: 'B-CLASS',
    frame: 'T242.890',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'MERCEDES-BENZ',
    model: 'C-CLASS',
    frame: '',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MERCEDES-BENZ',
    model: 'C-CLASS',
    frame: '205014C, 205077, 205077C, 205078, 205078C, 205076C, 205053, 205053C, 205040C, 205014',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MERCEDES-BENZ',
    model: 'C-CLASS',
    frame: '205040C, 205043, 205043C, 205047, 205004, 205004C, 205042, 205042C, 205045',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MERCEDES-BENZ',
    model: 'C-CLASS',
    frame: '205240C, 205243, 205243C, 205247, 205204, 205204C, 205242, 205242C, 205245',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MERCEDES-BENZ',
    model: 'C-CLASS',
    frame: '205277, 205277C, 205278, 205278C, 205276C, 205240C, 205214, 205214C',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MERCEDES-BENZ',
    model: 'C-CLASS',
    frame: '206004C, 206041C, 206042C, 206043C',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MERCEDES-BENZ',
    model: 'C-CLASS',
    frame: '206204C, 206214C, 206241C, 206242C',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MERCEDES-BENZ',
    model: 'C-CLASS',
    frame: 'A205.442, A205.464',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MERCEDES-BENZ',
    model: 'C-CLASS',
    frame: 'C205.340, C205.342, C205.364, C205.379, C205.387',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MERCEDES-BENZ',
    model: 'C-CLASS',
    frame:
      'W205.003, W205.004, W205.005, W205.007, W205.008, W205.009, W205.012, W205.036, W205.037, W205.040, W205.042, W205.043, W205.044, W205.045, W205.047, W205.048, W205.064, W205.066, W205.086, W205.087',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MERCEDES-BENZ',
    model: 'C-CLASS',
    frame:
      'W205.009, W205.012, W205.040, W205.043, W205.045, W205.047, W205.064, W205.086, W205.087',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MERCEDES-BENZ',
    model: 'C-CLASS',
    frame: 'W205.040, W205.043, W205.047, W205.048, W205.064, W205.087',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MERCEDES-BENZ',
    model: 'C-CLASS',
    frame: 'W205.047, W205.048, W205.064, W205.066, W205.086, W205.087',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MERCEDES-BENZ',
    model: 'C-CLASS',
    frame: null,
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MERCEDES-BENZ',
    model: 'CLS-CLASS',
    frame: '257314, 257314C, 257359',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MERCEDES-BENZ',
    model: 'CLS-CLASS',
    frame: 'C257',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MERCEDES-BENZ',
    model: 'E-CLASS',
    frame:
      '212074, 212075, 212095C, 212073, 212001C, 212026C, 212061C, 212036C, 212055C, 212059C, 212080C',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MERCEDES-BENZ',
    model: 'E-CLASS',
    frame: '213004C, 213016, 213061, 213077C, 213078C, 213059, 213083, 213053',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MERCEDES-BENZ',
    model: 'E-CLASS',
    frame:
      '213077C, 213078C, 213083C, 213053C, 213061, 213064, 213088, 213089, 213043C, 213068, 213050C, 213004C, 213042C, 213045C, 213071',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MERCEDES-BENZ',
    model: 'E-CLASS',
    frame: '213204C, 213217, 213277C, 213278C, 213259, 213283',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MERCEDES-BENZ',
    model: 'E-CLASS',
    frame: '213277C, 213278C, 213283C, 213243C, 213268, 213204C, 213217, 213242C, 213245C, 213271',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MERCEDES-BENZ',
    model: 'E-CLASS',
    frame: '238361, 238377C, 238359, 238383',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MERCEDES-BENZ',
    model: 'E-CLASS',
    frame: '238377C, 238383C, 238348C, 238366, 238368, 238342C',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MERCEDES-BENZ',
    model: 'E-CLASS',
    frame: '238461, 238477C, 238483',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MERCEDES-BENZ',
    model: 'E-CLASS',
    frame: '238477C, 238483C, 238442C',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MERCEDES-BENZ',
    model: 'E-CLASS',
    frame: 'A238',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MERCEDES-BENZ',
    model: 'E-CLASS',
    frame: 'C238',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MERCEDES-BENZ',
    model: 'E-CLASS',
    frame: 'S212',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MERCEDES-BENZ',
    model: 'E-CLASS',
    frame: 'W212',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MERCEDES-BENZ',
    model: 'E-CLASS',
    frame: 'W213',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MERCEDES-BENZ',
    model: 'EQA',
    frame: 'H243',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'MERCEDES-BENZ',
    model: 'EQB',
    frame: 'X243',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'MERCEDES-BENZ',
    model: 'EQC',
    frame: 'N293',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'MERCEDES-BENZ',
    model: 'EQE',
    frame: '295.111, 295.112, 295.122, 295.125, 295.132, 295.153',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'MERCEDES-BENZ',
    model: 'EQS',
    frame: 'V297',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'MERCEDES-BENZ',
    model: 'EQS SUV',
    frame: '296.623, 296.624, 296.634, 296.644',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'MERCEDES-BENZ',
    model: 'EQS SUV',
    frame: '296.623, 296.624, 296.644',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'MERCEDES-BENZ',
    model: 'EQV',
    frame: 'W447',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'MERCEDES-BENZ',
    model: 'GLC',
    frame:
      '253.911, 253.915, 253.916, 253.919, 253.923, 253.953, 253.964, 253.981, 253.984, 253.988, 253.989',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MERCEDES-BENZ',
    model: 'GLC',
    frame: '253915, 253915C, 253984, 253953',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MERCEDES-BENZ',
    model: 'GLC',
    frame: '253942C, 253946, 253946C, 253954, 253905C',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MERCEDES-BENZ',
    model: 'GLC',
    frame: '254.605, 254.607, 254.647, 254.651',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MERCEDES-BENZ',
    model: 'GLC',
    frame: 'X253',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MERCEDES-BENZ',
    model: 'GLC COUPE',
    frame: 'C253',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MERCEDES-BENZ',
    model: 'GLE',
    frame: 'W166',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MERCEDES-BENZ',
    model: 'GLE',
    frame: 'W167',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MERCEDES-BENZ',
    model: 'GLE COUPE',
    frame: 'C167',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MERCEDES-BENZ',
    model: 'GLS-CLASS',
    frame: null,
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MERCEDES-BENZ',
    model: 'S-CLASS',
    frame: '222058, 222158, 222066, 222183, 222173, 222034, 222134',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MERCEDES-BENZ',
    model: 'S-CLASS',
    frame: '222177C, 222057, 222182, 222182C, 222163, 222004, 222104',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MERCEDES-BENZ',
    model: 'S-CLASS',
    frame: '223033, 223133, 223076, 223176, 223063, 223163',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MERCEDES-BENZ',
    model: 'S-CLASS',
    frame: 'V221, W221',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MERCEDES-BENZ',
    model: 'S-CLASS',
    frame: 'V222',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MERCEDES-BENZ',
    model: 'S-CLASS',
    frame: 'V222, W222',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MERCEDES-BENZ',
    model: 'SLS AMG',
    frame: 'C197',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'MERCURY',
    model: 'MARINER',
    frame: '',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MERCURY',
    model: 'MARINER',
    frame: null,
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MERCURY',
    model: 'MILAN',
    frame: null,
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MINI',
    model: 'COUNTRYMAN',
    frame: 'F60',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MINI',
    model: 'CROSSOVER',
    frame: '12BR15, 42BT20, 22BS15, 32BS20',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MINI',
    model: 'CROSSOVER',
    frame: 'YW15, YW20, YZ20, YU15T, YU15, YS15, YS20, YT20',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MINI',
    model: 'HATCH',
    frame: 'F56',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'MITSUBISHI',
    model: 'DELICA D:2',
    frame: 'MB36S, MB46S',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MITSUBISHI',
    model: 'DELICA D:2',
    frame: 'MB37S',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MITSUBISHI',
    model: 'DIGNITY',
    frame: 'BHGY51',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MITSUBISHI',
    model: 'ECLIPSE CROSS',
    frame: 'GK1W, GL3W',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MITSUBISHI',
    model: 'EK SPACE',
    frame: 'B35A, B38A, B34A, B37A',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MITSUBISHI',
    model: 'EK WAGON',
    frame: 'B35W, B38W, B34W, B37W, B33W, B36W',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MITSUBISHI',
    model: 'EK X EV',
    frame: 'B5AW',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'MITSUBISHI',
    model: 'I-MIEV',
    frame: 'HA',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'MITSUBISHI',
    model: 'I-MIEV',
    frame: 'HA3W, HA4WLDD, HA4WLDDB',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'MITSUBISHI',
    model: 'I-MIEV',
    frame: 'HD4WLDD',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'MITSUBISHI',
    model: 'MINICAB MIEV',
    frame: 'U67V, U68V',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'MITSUBISHI',
    model: 'OUTLANDER',
    frame: 'GF2W, GF3W, GF4W, GF7W, GG2W',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MITSUBISHI',
    model: 'OUTLANDER',
    frame: 'GF3W, GF4W, GG2W',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MITSUBISHI',
    model: 'OUTLANDER',
    frame: 'GF7W',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MITSUBISHI',
    model: 'OUTLANDER',
    frame: 'GF7W, GF8W, GG2W',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MITSUBISHI',
    model: 'OUTLANDER',
    frame: 'GG3W, GF7W, GF8W',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MITSUBISHI',
    model: 'OUTLANDER',
    frame: 'GN0W',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MITSUOKA',
    model: 'BUDDY',
    frame: 'AXAH52, AXAH54, MXAA52, MXAA54',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MITSUOKA',
    model: 'LIKE',
    frame: 'HA3W',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'MITSUOKA',
    model: 'MICRO CAR',
    frame: 'MC1, 2, ME1',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'MITSUOKA',
    model: 'RYUGI',
    frame: 'NZE161G, NZE164G, NRE161G, NKE165G',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'MITSUOKA',
    model: 'RYUGI',
    frame: 'NZE161, NZE164, NRE161, NKE165',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'NIO',
    model: 'EC6',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'NIO',
    model: 'ES6',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'NIO',
    model: 'ES7',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'NIO',
    model: 'ES8',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'NIO',
    model: 'ET5',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'NIO',
    model: 'ET7',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'NISSAN',
    model: 'ALTIMA',
    frame: 'L32',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'NISSAN',
    model: 'ARIYA',
    frame: '',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'NISSAN',
    model: 'ARIYA',
    frame: 'FE0',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'NISSAN',
    model: 'AURA',
    frame: 'FE13, FSNE13',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'NISSAN',
    model: 'CIMA',
    frame: 'HGY51',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'NISSAN',
    model: 'DAYZ',
    frame: 'B45W, B48W, B44W, B47W, B43W, B46W',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'NISSAN',
    model: 'E-NV200',
    frame: 'ME0',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'NISSAN',
    model: 'FUGA',
    frame: 'HY51, KNY51, KY51, Y51',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'NISSAN',
    model: 'HYPERMINI',
    frame: 'EA0',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'NISSAN',
    model: 'KICKS',
    frame: 'P15, RP15, SNP15',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'NISSAN',
    model: 'LEAF',
    frame: 'AZE0, ZE0',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'NISSAN',
    model: 'LEAF',
    frame: 'ZE1',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'NISSAN',
    model: 'MURANO',
    frame: 'Z52',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'NISSAN',
    model: 'NOTE',
    frame: 'E13, SNE13',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'NISSAN',
    model: 'NOTE',
    frame: 'HE12, SNE12, E12, NE12',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'NISSAN',
    model: 'PATHFINDER',
    frame: 'R52',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'NISSAN',
    model: 'PATHFINDER',
    frame: 'R52R',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'NISSAN',
    model: 'ROOX',
    frame: 'B45A, B48A, B44A, B47A',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'NISSAN',
    model: 'SAKURA',
    frame: 'B6AW',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'NISSAN',
    model: 'SERENA',
    frame: 'C28, FC28, FNC28, NC28, GC28, GFC28',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'NISSAN',
    model: 'SERENA',
    frame: 'GC27, GFC27, GFNC27, GNC27, HC27, HFC27',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'NISSAN',
    model: 'SERENA',
    frame: 'GC27, GFC27, GFNC27, GNC27, HC27, HFC27, C27',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'NISSAN',
    model: 'SERENA',
    frame: 'HC26, HFC26, C26, FC26, FNC26, NC26',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'NISSAN',
    model: 'SKYLINE',
    frame: 'HNV37, HV37, RV37',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'NISSAN',
    model: 'SKYLINE',
    frame: 'HNV37, HV37, YV37, ZV37',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'NISSAN',
    model: 'SKYLINE',
    frame: 'HNV3, HNV37, HV37, YV37',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'NISSAN',
    model: 'TINO',
    frame: 'HV10, V10, PV10',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'NISSAN',
    model: 'X-TRAIL',
    frame: 'HNT32, HT32, NT32, T32',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'NISSAN',
    model: 'X-TRAIL',
    frame: 'SNT33, T33',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'OPEL',
    model: 'AMPERA',
    frame: '',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'OPEL',
    model: 'AMPERA',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'OPEL',
    model: 'GRANDLAND X',
    frame: 'A18',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'OPEL',
    model: 'VIVARO',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'OPEL',
    model: 'ZAFIRA LIFE',
    frame: '',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'PEUGEOT',
    model: '106',
    frame: '1A,  1C',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'PEUGEOT',
    model: '2008',
    frame: '',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'PEUGEOT',
    model: '208',
    frame: '',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'PEUGEOT',
    model: '308',
    frame: 'P51YH01, P515G06H, P51HN05',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'PEUGEOT',
    model: '308',
    frame: 'P52YH01, P525G06H, P52HN05',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'PEUGEOT',
    model: '408',
    frame: '',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'PEUGEOT',
    model: '508',
    frame: '',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'PEUGEOT',
    model: 'ION',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'PIAGGIO',
    model: 'PORTER',
    frame: '',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'PIAGGIO',
    model: 'PORTER',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'POLESTAR',
    model: '2',
    frame: '',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'POLESTAR',
    model: '2',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'POLESTAR',
    model: '3',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'PORSCHE',
    model: '918',
    frame: '',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'PORSCHE',
    model: '918',
    frame: null,
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'PORSCHE',
    model: 'CAYENNE',
    frame: '958',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'PORSCHE',
    model: 'CAYENNE',
    frame: 'PO536',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'PORSCHE',
    model: 'CAYENNE COUPE',
    frame: 'PO536',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'PORSCHE',
    model: 'PANAMERA',
    frame: '970',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'PORSCHE',
    model: 'PANAMERA',
    frame: '971',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'PORSCHE',
    model: 'TAYCAN',
    frame: '',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'PORSCHE',
    model: 'TAYCAN',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'RAM',
    model: '1500',
    frame: 'DT',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'RENAULT',
    model: 'ARKANA',
    frame: '',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'RENAULT',
    model: 'AUSTRAL',
    frame: null,
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'RENAULT',
    model: 'FLUENCE Z.E.',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'RENAULT',
    model: 'GRAND SCENIC',
    frame: 'JFA',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'RENAULT',
    model: 'KANGOO Z.E.',
    frame: 'KW0',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'RENAULT',
    model: 'SCENIC',
    frame: 'JFA',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'RENAULT',
    model: 'TWIZY',
    frame: 'MAM',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'RENAULT',
    model: 'ZOE',
    frame: 'AG0V0Y, AGVYA0, AGVYB0, AGVYC0, AGVYC1, AGVYE0, AGVYF0, AGVYF1',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'RIMAC',
    model: 'NEVERA',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'RIVIAN',
    model: 'R1T',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'ROEWE',
    model: 'EI5',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'SATURN',
    model: 'AURA',
    frame: null,
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'SATURN',
    model: 'VUE',
    frame: '',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'SATURN',
    model: 'VUE',
    frame: null,
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'SKODA',
    model: 'CITIGO',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'SKODA',
    model: 'ENYAQ IV',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'SKODA',
    model: 'OCTAVIA',
    frame: 'NX3',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'SKODA',
    model: 'OCTAVIA',
    frame: 'NX5',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'SKYWELL',
    model: 'ET5',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'SMART',
    model: '#1',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'SMART',
    model: 'FORFOUR',
    frame: 'W453',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'SMART',
    model: 'FORTWO',
    frame: 'A451.400, A451.431, A451.432, A451.433',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'SMART',
    model: 'FORTWO',
    frame: 'A451.431, A451.432',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'SMART',
    model: 'FORTWO',
    frame: 'C451.300, C451.330, C451.331, C451.332, C451.333',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'SMART',
    model: 'FORTWO',
    frame: 'C451.331',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'SMART',
    model: 'FORTWO',
    frame: 'C453',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'SMART',
    model: 'FORTWO',
    frame: 'W450.300, W450.330, W450.332, W450.333',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'SMART',
    model: 'FORTWO',
    frame: 'W451',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'SUBARU',
    model: 'CROSSTREK',
    frame: 'GT',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'SUBARU',
    model: 'CROSSTREK',
    frame: 'GUD, GUE',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'SUBARU',
    model: 'FORESTER',
    frame: 'SK5, SKE',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'SUBARU',
    model: 'FORESTER',
    frame: 'SK5, SKE, SK9',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'SUBARU',
    model: 'IMPREZA',
    frame: 'GPE, GP2, GP3, GP6, GP7',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'SUBARU',
    model: 'IMPREZA',
    frame: 'GT2, GT3, GT6, GT7, GTE',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'SUBARU',
    model: 'SAMBAR',
    frame: 'KV3, KV4',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'SUBARU',
    model: 'SOLTERRA',
    frame: 'XEAM10X, XEAM15X',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'SUBARU',
    model: 'XV',
    frame: 'GPE, GP7',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'SUBARU',
    model: 'XV',
    frame: 'GT3, GTE',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'SUBARU',
    model: 'XV',
    frame: 'GTE, GT3, GT7',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'SUZUKI',
    model: 'ALTO',
    frame: 'HA37S, HA97S',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'SUZUKI',
    model: 'ESCUDO',
    frame: 'YEA1S, YEH1S',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'SUZUKI',
    model: 'HUSTLER',
    frame: 'MR41S, MR31S',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'SUZUKI',
    model: 'HUSTLER',
    frame: 'MR52S, MR92S',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'SUZUKI',
    model: 'IGNIS',
    frame: 'FF21S',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'SUZUKI',
    model: 'LANDY',
    frame: 'SGC27, SGNC27, SC27',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'SUZUKI',
    model: 'LANDY',
    frame: 'SHC26, SC26, SNC26',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'SUZUKI',
    model: 'LANDY',
    frame: 'ZWR90C, ZWR95C, MZRA90C, MZRA95C',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'SUZUKI',
    model: 'SOLIO',
    frame: 'MA36S, MA46S, MA26S',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'SUZUKI',
    model: 'SOLIO',
    frame: 'MA37S, MA47S, MA27S',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'SUZUKI',
    model: 'SPACIA',
    frame: 'MK42S, MK32S',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'SUZUKI',
    model: 'SPACIA',
    frame: 'MK53S, MK33V',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'SUZUKI',
    model: 'SWIFT',
    frame: '',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'SUZUKI',
    model: 'SWIFT',
    frame: 'ZC13S, ZC23S, ZC33S, ZC83S, ZD83S',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'SUZUKI',
    model: 'SWIFT',
    frame: 'ZC33S, ZC83S, ZC43S, ZC53S, ZD53S, ZD83S, ZC13S',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'SUZUKI',
    model: 'TWIN',
    frame: 'EC22S',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'SUZUKI',
    model: 'WAGON R',
    frame: 'MH44S, MH34S',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'SUZUKI',
    model: 'WAGON R',
    frame: 'MH55S, MH95S, MH85S',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'SUZUKI',
    model: 'WAGON R',
    frame: 'MH55S, MH95S, MH85S, MH35S',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'SUZUKI',
    model: 'WAGON R SMILE',
    frame: 'MX91S, MX81S',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'SUZUKI',
    model: 'XBEE',
    frame: 'MN71S',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TANK',
    model: '500',
    frame: null,
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TESLA',
    model: 'CYBERTRUCK',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'TESLA',
    model: 'MODEL S',
    frame: '',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'TESLA',
    model: 'MODEL S',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'TESLA',
    model: 'MODEL X',
    frame: '',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'TESLA',
    model: 'MODEL X',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'TESLA',
    model: 'MODEL Y',
    frame: '',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'TESLA',
    model: 'MODEL Y',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'TESLA',
    model: 'ROADSTER',
    frame: '',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'TESLA',
    model: 'ROADSTER',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'TOYOTA',
    model: 'ALPHARD',
    frame: 'AGH30W, AGH35W, GGH30W, GGH35W, AYH30W',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'ALPHARD',
    frame: 'ATH10W, ANH10W, ANH15W, MNH10W, MNH15W',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'ALPHARD',
    frame: 'ATH10W, MNH10W, MNH15W, ANH10W, ANH15W',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'ALPHARD',
    frame: 'ATH20W, ANH20W, ANH25W, GGH20W, GGH25W',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'ALPHARD',
    frame: 'AYH30W, AGH30W, AGH35W, GGH30W, GGH35W',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'AQUA',
    frame: 'MXPK10, MXPK11, MXPK15, MXPK16',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'AQUA',
    frame: 'NHP10',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'AQUA',
    frame: 'NHP10, NHP10H',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'AURIS',
    frame: 'ADE150, ADE151, NDE150, NRE150, ZRE151, ZWE150',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'AURIS',
    frame: 'ADE180, NDE180, NRE180, ZRE151, ZRE181, ZWE180',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'AURIS',
    frame: 'NDE180, NRE180, NRE185, WWE185, ZWE180',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'AURIS',
    frame: 'ZWE186H, NRE185H, NZE181H, NZE184H, ZRE186H',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'AVALON',
    frame: 'AVX40, GSX40',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'AVALON',
    frame: 'AXXA50, AXXH50, MXXA50',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'AVALON',
    frame: 'AXXH50, GSX50',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'BZ3',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'TOYOTA',
    model: 'BZ4X',
    frame: '',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'TOYOTA',
    model: 'BZ4X',
    frame: 'XEAM10, XEAM15',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'TOYOTA',
    model: 'CAMRY',
    frame: 'ACV40, AHV40, GSV40',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'CAMRY',
    frame: 'AHV40, ASV40, GSV40',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'CAMRY',
    frame: 'ASV50, AVV50, GSV50',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'CAMRY',
    frame: 'ASV70, GSV70',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'CAMRY',
    frame: 'AVV50',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'CAMRY',
    frame: 'AXVH70, AXVH75',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'CENTURY',
    frame: 'UWG60',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'C-HR',
    frame: 'KPX10, MAXA10',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'TOYOTA',
    model: 'C-HR',
    frame: 'MAXA10, MAXH10',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'C-HR',
    frame: 'MAXH10, NGX10, ZYX10',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'C-HR',
    frame: 'NGX10, NGX50, ZYX11',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'C-HR',
    frame: 'NGX10, ZYX10',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'C-HR',
    frame: 'ZYX10, NGX10, NGX50',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'COMS',
    frame: 'TAK30',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'TOYOTA',
    model: 'COROLLA',
    frame: 'GZEA14H, ZWE219H, MZEA12H',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'COROLLA',
    frame: 'MZEA12, ZRE211, ZRE212',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'COROLLA',
    frame: 'MZEA17W, ZWE215W, ZWE219W',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'COROLLA',
    frame: 'MZEA17, ZWE215, ZWE219',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'COROLLA',
    frame: 'NRE210H, NRE214H, ZWE211H, ZWE213H',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'COROLLA',
    frame: 'NRE210W, ZRE212W, ZWE211W, ZWE214W, MZEA12W',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'COROLLA',
    frame: 'NRE210, ZRE212, ZWE211, ZWE214',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'COROLLA AXIO',
    frame: 'NKE165, NRE160, NRE161, NZE161, NZE164',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'COROLLA AXIO',
    frame: 'NKE165, NRE160, NZE161, NZE164',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'COROLLA AXIO',
    frame: 'NZE161, NZE164, NRE161, NKE165, NRE160',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'COROLLA CROSS',
    frame: 'ZSG10, ZVG11, ZVG15',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'COROLLA FIELDER',
    frame: 'NKE165G, NRE161G, NZE161G, NZE164G, ZRE162G',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'COROLLA FIELDER',
    frame: 'NKE165G, NZE161G, NZE164G, ZRE162G',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'COROLLA FIELDER',
    frame: 'NZE161G, NZE164G, NRE161G, NKE165G, ZRE162G',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'C+POD',
    frame: 'RMV12',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'TOYOTA',
    model: 'CROWN',
    frame: 'ARS220, AZSH20, AZSH21, GWS224',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'CROWN',
    frame: 'AWS210, AWS211, ARS210, GRS210, GRS211, GRS214',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'CROWN',
    frame: 'AWS210, AWS211, GRS210, GRS211, GRS214',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'CROWN',
    frame: 'AZSH35, TZSH35',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'CROWN',
    frame: 'GWS204, GRS200, GRS201, GRS202, GRS203, GRS204',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'CROWN',
    frame: 'JZS171, GS171, JZS173, JZS175, JZS179, JKS175',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'CROWN',
    frame: 'TZSH35, AZSH35',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'CROWN',
    frame: 'YXS10, YXS10H, GBS12, TSS10, TSS10H, GXS12',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'CROWN MAJESTA',
    frame: 'AWS215, GWS214',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'ESQUIRE',
    frame: 'ZRR80G, ZRR85G, ZWR80G',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'ESQUIRE',
    frame: 'ZWR80G, ZRR80G, ZRR85G',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'ESTIMA',
    frame: 'ACR30W, ACR40W, MCR30W, MCR40W, AHR10W',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'ESTIMA',
    frame: 'AHR20W, ACR50W, ACR55W',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'ESTIMA',
    frame: 'AHR20W, ACR50W, ACR55W, GSR50W, GSR55W',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'HARRIER',
    frame: 'ACU30W, ACU35W, MCU30W, MCU31W, MCU35W, MCU36W, MHU38W, GSU30W, GSU31W, GSU35W, GSU36W',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'HARRIER',
    frame: 'AVU65W, ASU60W, ASU65W, ZSU60W, ZSU65W',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'HARRIER',
    frame: 'AVU65W, ZSU60W, ZSU65W',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'HARRIER',
    frame: 'AXUH80, AXUH85, MXUA80, MXUA85, AXUP85',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'IZOA',
    frame: 'KPX10, MAXA10',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'TOYOTA',
    model: 'IZOA',
    frame: 'MAXA10, MAXH10',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'JPN TAXI',
    frame: 'NTP10',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'KLUGER V',
    frame: 'ACU20W, ACU25W, MCU20W, MCU25W, MHU28W',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'MIRAI',
    frame: 'JPD10',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'MIRAI',
    frame: 'JPD10',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'TOYOTA',
    model: 'MIRAI',
    frame: 'JPD20',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'MIRAI',
    frame: 'JPD20',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'TOYOTA',
    model: 'NOAH',
    frame: 'ZRR80G, ZRR80W, ZRR85G, ZRR85W, ZWR80G, ZWR80W',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'NOAH',
    frame: 'ZWR80G, ZWR80W, ZRR80G, ZRR80W, ZRR85G, ZRR85W',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'NOAH',
    frame: 'ZWR90W, ZWR95W, MZRA90W, MZRA95W',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'PRIUS',
    frame: 'MXWH60, MXWH65, ZVW60, ZVW65',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'PRIUS',
    frame: 'NHW10',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'PRIUS',
    frame: 'NHW11',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'PRIUS',
    frame: 'NHW20',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'PRIUS',
    frame: 'ZVW30',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'PRIUS',
    frame: 'ZVW30L',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'PRIUS',
    frame: 'ZVW30, ZVW35',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'PRIUS',
    frame: 'ZVW50L',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'PRIUS',
    frame: 'ZVW50L, ZVW51L',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'PRIUS',
    frame: 'ZVW50, ZVW51, ZVW55',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'PRIUS',
    frame: 'ZVW51L, ZVW55L',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'PRIUS',
    frame: 'ZVW51, ZVW55',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'PRIUS ALPHA',
    frame: 'ZVW40W, ZVW41W',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'PRIUS ALPHA',
    frame: 'ZVW41W, ZVW40W',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'PRIUS C',
    frame: 'NHP10',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'PRIUS PHV',
    frame: 'MXWH60',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'PRIUS PHV',
    frame: 'ZVW30L',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'PRIUS PHV',
    frame: 'ZVW35',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'PRIUS PHV',
    frame: 'ZVW52',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'PRIUS PHV',
    frame: 'ZVW52L',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'PRIUS PLUS',
    frame: 'ZFW40',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'PRIUS PRIME',
    frame: 'ZVW52L',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'PRIUS V',
    frame: 'ZFW41',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'PROBOX',
    frame: 'NCP165V, NCP160V, NSP160V, NHP160V',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'RAIZE',
    frame: 'A210A, A202A, A200A, A201A',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'RAV4',
    frame: 'ASA33, ASA38, GSA33, GSA38, QEA38',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'TOYOTA',
    model: 'RAV4',
    frame: 'ASA42, ASA44, AVA44',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'RAV4',
    frame: 'AVA42, AVA44, WWA42, ZSA44',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'RAV4',
    frame: 'AXAA52, AXAA54, AXAH54',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'RAV4',
    frame: 'AXAA52, AXAA54, AXAP54, MXAA52, MXAA54',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'RAV4',
    frame: 'AXAH52, AXAH54, MXAA52, MXAA54',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'RAV4',
    frame: 'AXAH52, AXAH54, MXAA52, MXAA54, AXAP54',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'RAV4',
    frame: 'BEA11, SXA11, SXA16',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'TOYOTA',
    model: 'SAI',
    frame: 'AZK10',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'SIENNA',
    frame: 'ASL40',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'SIENTA',
    frame: 'MXPC10G, MXPC12G, MXPL10G, MXPL12G, MXPL15G',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'SIENTA',
    frame: 'NCP175G, NSP170G, NSP172G, NHP170G',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'SIENTA',
    frame: 'NHP170G, NCP175G, NSP170G, NSP172G',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'SUCCEED',
    frame: 'NHP160V, NCP160V, NCP165V',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'VELLFIRE',
    frame: 'AGH30W, AGH35W, GGH30W, GGH35W, AYH30W',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'VELLFIRE',
    frame: 'ATH20W, ANH20W, ANH25W, GGH20W, GGH25W',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'VELLFIRE',
    frame: 'AYH30W, AGH30W, AGH35W, GGH30W, GGH35W',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'VENZA',
    frame: 'AXUH85',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'VITZ',
    frame: 'NHP130, KSP130, NCP131, NSP130, NSP135',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'VOXY',
    frame: 'ZRR80W, ZRR85W, ZWR80W, ZWR80G, ZRR80G, ZRR85G',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'VOXY',
    frame: 'ZWR80G, ZWR80W, ZRR80G, ZRR80W, ZRR85G, ZRR85W',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'VOXY',
    frame: 'ZWR90W, ZWR95W, MZRA90W, MZRA95W',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'YARIS',
    frame: 'KSP130, NHP130, NLP130, NSP130',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'YARIS',
    frame: 'KSP130, NHP130, NSP131',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'YARIS',
    frame: 'KSP210, MXPA10, MXPA15, MXPH10, MXPH15',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'YARIS',
    frame: 'KSP210, MXPA10, MXPH10',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'TOYOTA',
    model: 'YARIS CROSS',
    frame: 'MXPB10, MXPB15, MXPJ10, MXPJ15',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'VOLKSWAGEN',
    model: 'E-LAVIDA',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'VOLKSWAGEN',
    model: 'GOLF',
    frame: '5G1',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'VOLKSWAGEN',
    model: 'GOLF',
    frame: '5G1',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'VOLKSWAGEN',
    model: 'GOLF',
    frame: 'AUCHH, AUCJX, AUCJXF, AUCJZ, AUCPT, AUCUK',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'VOLKSWAGEN',
    model: 'GOLF',
    frame: 'AUDNU, AUDFG, AUCHH, AUCJXF, AUDJHF, AUDLB, AUCJZ, AUCPT, AUCUK',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'VOLKSWAGEN',
    model: 'GOLF',
    frame: 'CDDFY, CDDLA, CDDNFF, CDDNP, CDDTS, CDDTT',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'VOLKSWAGEN',
    model: 'GOLF',
    frame: 'CDDFYV, CDDLAV, CDDNFV, CDDTSV',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'VOLKSWAGEN',
    model: 'ID.3',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'VOLKSWAGEN',
    model: 'ID.4',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'VOLKSWAGEN',
    model: 'ID.5',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'VOLKSWAGEN',
    model: 'ID.6 CROZZ',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'VOLKSWAGEN',
    model: 'ID.6 X',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'VOLKSWAGEN',
    model: 'ID.BUZZ',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'VOLKSWAGEN',
    model: 'JETTA',
    frame: '162',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'VOLKSWAGEN',
    model: 'MULTIVAN',
    frame: 'STM,  STN',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'VOLKSWAGEN',
    model: 'PASSAT',
    frame: '3CCHH, 3CCZE, 3CCUK, 3CDFC',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'VOLKSWAGEN',
    model: 'PASSAT',
    frame: '3CCHH, 3CCZE, 3CCUK, 3CDFC, 3CDFCF',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'VOLKSWAGEN',
    model: 'PASSAT',
    frame: '3CDPC, 3CDFH, 3CDGE',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'VOLKSWAGEN',
    model: 'PASSAT',
    frame: '3G2',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'VOLKSWAGEN',
    model: 'PASSAT',
    frame: '3G5',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'VOLKSWAGEN',
    model: 'UP!',
    frame: '121',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'VOLKSWAGEN',
    model: 'XL1',
    frame: null,
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'VOLVO',
    model: 'C30',
    frame: 'MK20, MK38, MK43, MK67, MK75, MK76, MK_',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'VOLVO',
    model: 'C40',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'VOLVO',
    model: 'EX90',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'VOLVO',
    model: 'S60',
    frame: '',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'VOLVO',
    model: 'S60',
    frame: 'ZB420TM, ZB420P, ZB420P2, ZB420PT6, ZB420',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'VOLVO',
    model: 'S90',
    frame: '',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'VOLVO',
    model: 'V60',
    frame: '',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'VOLVO',
    model: 'V60',
    frame: 'ZB420TM, ZB420P, ZB420P2, ZB420PT6, ZB420',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'VOLVO',
    model: 'V90',
    frame: 'PB420P2A, PB420, PB420A, PB420PA, PD4204T',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'VOLVO',
    model: 'V90',
    frame: 'PB420TM, PB420P2A',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'VOLVO',
    model: 'XC40',
    frame: '',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'VOLVO',
    model: 'XC60',
    frame: 'UZ',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'VOLVO',
    model: 'XC90',
    frame: '',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'VOLVO',
    model: 'XC90',
    frame: 'L_A2, L_A4',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'VOLVO',
    model: 'XC90',
    frame: 'LD4204TXC, LB420TXCM, LB420XCP2, LB420XCP2A, LB420XCPA, LB420XC',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'VOLVO',
    model: 'XC90',
    frame: 'LD4204TXC, LB420XC, LB420XCP, LB420XCPA',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'VOYAH',
    model: 'FREE',
    frame: '',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'VOYAH',
    model: 'FREE',
    frame: '',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'VOYAH',
    model: 'FREE',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'WELTMEISTER',
    model: 'E5',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'WELTMEISTER',
    model: 'EX5-Z',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'WELTMEISTER',
    model: 'W6',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'XPENG',
    model: 'G3',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'XPENG',
    model: 'P7',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'ZEEKR',
    model: '001',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'ZEEKR',
    model: '009',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'АУРУС',
    model: 'АРСЕНАЛ',
    frame: null,
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'АУРУС',
    model: 'КОМЕНДАНТ',
    frame: null,
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'АУРУС',
    model: 'СЕНАТ',
    frame: '',
    reason: 'Гибридный автомобиль',
    reasonvalue: '#yes',
  },
  {
    company: 'ЛАДА',
    model: 'EL LADA',
    frame: null,
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
  {
    company: 'МОСКВИЧ',
    model: '3Е',
    frame: 'DA22',
    reason: 'Тип двигателя',
    reasonvalue: 'Электродвигатель',
  },
];

export function getHybridOrElectric(lot: AutoLotView | ManualLotData): object | null {
  return [...hybrids, ...electricCars, ...trucks].find(
    (x) =>
      x.company.toLowerCase() == lot.company.toLowerCase() &&
      x.model.toLowerCase() == lot.modelName.toLowerCase() &&
      checkFrame(x.frame, lot.frame),
  );
}

export const isHybridOrElectric = (lot: AutoLotView | ManualLotData): boolean =>
  !!getHybridOrElectric(lot);

function checkFrame(frame: string, lotFrame: string): boolean {
  if (lotFrame === '*') {
    return true;
  } else {
    return frame?.split(', ')?.includes(lotFrame);
  }
}
