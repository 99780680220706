import { Injectable } from '@angular/core';
import { Config, driver, DriveStep, State } from 'driver.js';
import { DriverStepDirective } from './directives/driver-step.directive';

@Injectable()
export class DriverService {
  private readonly driver = driver();
  private driverName: string | null = null;
  private steps = {};
  private isStartedGuide = false;

  public setDriverName(driverName: string): void {
    this.driverName = driverName;
  }

  public setConfig(config: Config): void {
    this.driver.setConfig(config);
  }

  public addStep(index: number, step: DriveStep): void {
    const key = step?.popover?.popoverClass;
    if (!this.steps.hasOwnProperty(key)) {
      this.steps[key] = [];
    }

    if (index !== undefined && index >= 0 && index <= this.steps[key].length) {
      this.steps[key].splice(index, 0, step);
    } else {
      this.steps[key].push(step);
    }
  }

  public sortSteps(): void {
    this.steps[this.driverName].sort((a: DriveStep, b: DriveStep) => {
      if (
        +(a?.element as HTMLElement)?.id.match(/\d+/)[0] <
        +(b?.element as HTMLElement)?.id.match(/\d+/)[0]
      ) {
        return -1;
      }
      if (
        +(a?.element as HTMLElement)?.id.match(/\d+/)[0] <
        +(b?.element as HTMLElement)?.id.match(/\d+/)[0]
      ) {
        return 1;
      }
      return 0;
    });
  }

  public checkStepByTitle(title: string): boolean {
    return this.steps[this.driverName]?.some((step: DriveStep) => step?.popover?.title === title);
  }

  public checkStepByDescr(descr: string): boolean {
    return this.steps[this.driverName]?.some(
      (step: DriveStep) => step?.popover?.description === descr,
    );
  }

  public removeStep(step: DriverStepDirective): void {
    this.steps[step?.popoverClass] = this.steps[step?.popoverClass]?.filter(
      (driveStep: DriveStep) => !driveStep?.popover?.description.includes(step?.stepDescription),
    );
  }

  public getStepsLenByDriverName(): number {
    return this.steps[this.driverName].length;
  }

  public deleteStepByTitle(key: string, title: string): void {
    this.steps[key] = this.steps[this.driverName]?.filter(
      (step: DriveStep) => !step?.popover?.title.includes(title),
    );
  }

  public deleteStepByDescr(key: string, title: string): void {
    this.steps[key] = this.steps[this.driverName]?.filter(
      (step: DriveStep) => !step?.popover?.description.includes(title),
    );
  }

  public start(stepIdx?: number): void {
    this.isStartedGuide = true;
    this.driver.setSteps(this.steps[this.driverName]);
    if (stepIdx) {
      this.driver.drive(stepIdx);
    } else {
      this.driver.drive();
    }
  }

  public prev(): void {
    this.driver.movePrevious();
  }

  public next(): void {
    this.driver.moveNext();
  }

  public moveTo(index: number): void {
    this.driver.moveTo(index);
  }

  public getState(): State {
    return this.driver.getState();
  }

  public getIsStartedGuide(): boolean {
    return this.isStartedGuide;
  }

  public reset(isDeepReset = false): void {
    this.driver.destroy();
    if (isDeepReset) {
      this.isStartedGuide = false;
    }
  }
}
