import { QueryHandlingService } from '@akebono/core';
import { Injectable, OnDestroy } from '@angular/core';
import { DateTime } from 'luxon';
import { Observable, Subject, Subscription } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';

import {
  ImportedBidsBiddingDataGQL,
  ImportedBidsBiddingDataQuery,
} from '../../graphql/service/graphql-auto-main-service';

@Injectable()
export class BiddingDataService implements OnDestroy {
  readonly loading$: Observable<boolean>;
  readonly data$: Observable<ImportedBidsBiddingDataQuery>;

  private readonly refetchSubject = new Subject<void>();
  private readonly subscription: Subscription;

  constructor(qhs: QueryHandlingService, biddingDataGQL: ImportedBidsBiddingDataGQL) {
    const queryRef$ = this.refetchSubject.pipe(
      map(() => DateTime.now().setZone('Asia/Tokyo').startOf('day').toISO()),
      map((todayDate) => qhs.fetch(biddingDataGQL, { todayDate }, 'network-only')),
      shareReplay(1),
    );

    this.loading$ = queryRef$.pipe(switchMap(({ loading }) => loading));
    this.data$ = queryRef$.pipe(
      switchMap(({ data }) => data),
      shareReplay(1),
    );

    this.subscription = this.data$.subscribe();
    this.refetchSubject.next();
  }

  refetch(): Observable<ImportedBidsBiddingDataQuery> {
    this.refetchSubject.next();
    return this.loading$.pipe(
      map((loading) => !loading),
      switchMap(() => this.data$),
    );
  }

  ngOnDestroy(): void {
    this.refetchSubject.complete();
    this.subscription.unsubscribe();
  }
}
