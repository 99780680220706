<nz-alert
  *ngIf="(minimumCommissionReached$ | async) === false && (maxBidLimitReached$ | async)"
  nzType="warning"
  nzMessage="{{
    'MAX_BID_COMMISSION.MINIMUM_LOT_BID'
      | translate
        : {
            firstBound: maxBidAmount - 5000 | currency: 'JPY':'symbol-narrow',
            secondBound: minBidCommission + maxBidAmount | currency: 'JPY':'symbol-narrow'
          }
  }}"
  nzShowIcon
></nz-alert>

<nz-alert
  *ngIf="(minimumCommissionReached$ | async) && (maxBidLimitReached$ | async)"
  nzType="warning"
  [nzMessage]="'WARNING' | translate"
  [nzDescription]="commissionAgreementAlert"
  nzShowIcon
>
  <ng-template #commissionAgreementAlert>
    <div>
      {{ 'MAX_BID_COMMISSION.YOU_BID_ON_LOT' | translate }}:
      {{ realAmount$ | async | currency: 'JPY':'symbol-narrow' }}
    </div>
    <div>
      {{ 'MAX_BID_COMMISSION.ADDITIONAL_DEALER_COMMISSION' | translate }}:
      {{ dealerCommission$ | async | currency: 'JPY':'symbol-narrow' }}
    </div>
    <div class="commission-agreement-label">
      <label nz-checkbox [(ngModel)]="agree" (ngModelChange)="agreeStateChange($event)">
        {{ 'MAX_BID_COMMISSION.AGREE_WITH_COMMISSION' | translate }}
      </label>
    </div>
  </ng-template>
</nz-alert>
