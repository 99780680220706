import { MutationHandlingServiceV2 } from '@akebono/core';
import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { iif, Observable, of } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

import {
  BidGroup,
  BidGroupCreateGQL,
  BidGroupCreateMutation,
} from '../../graphql/service/graphql-auto-main-service';
import { BidGroupInfo } from '../types/bid-group-info.type';
import { BiddingDataService } from './bidding-data.service';

@Injectable()
export class BidGroupService {
  private readonly userBidGroups$: Observable<BidGroup[]>;

  constructor(
    biddingDataService: BiddingDataService,
    private readonly mhs: MutationHandlingServiceV2,
    private readonly bidGroupCreateGQL: BidGroupCreateGQL,
  ) {
    this.userBidGroups$ = biddingDataService.data$.pipe(
      map((data) => data.currentUser?.bidGroups?.nodes || []),
    );
  }

  getOrCreate(
    bidGroupInfo: BidGroupInfo,
  ): Observable<BidGroupCreateMutation['bidGroupCreate']['group']> {
    return this.get(bidGroupInfo).pipe(
      switchMap((bidGroup) => iif(() => !!bidGroup, of(bidGroup), this.create(bidGroupInfo))),
    );
  }

  get(bidGroupInfo: BidGroupInfo): Observable<BidGroup | undefined> {
    return this.userBidGroups$.pipe(
      first(),
      map((groups) => this.findGroup(groups, bidGroupInfo)),
    );
  }

  create(
    bidGroupInfo: BidGroupInfo,
  ): Observable<BidGroupCreateMutation['bidGroupCreate']['group']> {
    return this.mhs
      .mutate(
        this.bidGroupCreateGQL,
        {
          input: {
            date: bidGroupInfo.auctionDate.toISO(),
            letter: bidGroupInfo.letter,
            quantity: bidGroupInfo.count,
          },
        },
        { renderSuccess: false },
      )
      .data.pipe(map((data) => data.bidGroupCreate?.group));
  }

  private findGroup(bidGroups: BidGroup[], bigGroupInfo: BidGroupInfo): BidGroup | undefined {
    const bidGroupInfoId = `${bigGroupInfo.letter}_${
      bigGroupInfo.count
    }_${bigGroupInfo.auctionDate.toFormat('yyyy-MM-dd')}`;

    return bidGroups.find((bidGroup) => {
      const bidGroupId = `${bidGroup.letter}_${bidGroup.quantity}_${DateTime.fromISO(
        bidGroup.date,
      ).toFormat('yyyy-MM-dd')}`;
      return bidGroupId === bidGroupInfoId;
    });
  }
}
