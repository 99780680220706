import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AutoLotView, mergeSavedLotToAutoLotView } from 'src/app/const';
import { BidConditionService } from 'src/app/services/bid-condition.service';
import { BiddingRulesService } from 'src/app/services/bidding-rules.service';
import { LimitsService } from 'src/app/services/limits.service';
import { SanctionsService } from 'src/app/services/sanctions.service';

import {
  BidCondition,
  BidGroup,
  ImportedBidsBiddingDataQuery,
  ImportedLotFragment,
} from '../../graphql/service/graphql-auto-main-service';
import { BidStatus } from '../classes/bid-status.calss';
import { ExcelBid } from '../types/excel-bid.type';
import { BidGroupService } from './bid-group.service';
import { BiddingDataService } from './bidding-data.service';

@Injectable()
export class BidStatusFactoryService {
  constructor(
    private readonly limitsService: LimitsService,
    private readonly bidGroupService: BidGroupService,
    private readonly sanctionsService: SanctionsService,
    private readonly biddingDataService: BiddingDataService,
    private readonly biddingRulesService: BiddingRulesService,
    private readonly bidConditionsService: BidConditionService,
  ) {}

  create(bid: Omit<ExcelBid, 'lot'>, lot: ImportedLotFragment): BidStatus {
    const autoLotView = mergeSavedLotToAutoLotView(null, lot);
    const biddingData$ = this.biddingDataService.data$;
    const limits = this.limitsService.getLimits(autoLotView, lot);
    const isSanctioned$ = this.sanctionsService.isLotSanctioned$(autoLotView, lot.subgroup);
    const canPlaceGroupBid$ = this.biddingRulesService.checkIfCanBidWithGroupsToAuction(
      autoLotView.auctionName,
    );
    const targetGroup$ = this.getTargetGroup(bid);
    const bidConditions$ = this.getBidConditions(autoLotView, lot, biddingData$);

    return new BidStatus(
      bid,
      lot,
      limits,
      isSanctioned$,
      targetGroup$,
      canPlaceGroupBid$,
      bidConditions$,
      biddingData$,
    );
  }

  private getBidConditions(
    autoLotView: AutoLotView,
    lot: ImportedLotFragment,
    biddingData$: Observable<ImportedBidsBiddingDataQuery>,
  ): Observable<BidCondition[]> {
    return biddingData$.pipe(
      map((data) => ({
        conditions: data.bidConditions,
        user: data.currentUser,
      })),
      switchMap(({ conditions, user }) =>
        this.bidConditionsService.filterForLot(conditions, autoLotView, lot.subgroup, user),
      ),
    );
  }

  private getTargetGroup(bid: Omit<ExcelBid, 'lot'>): Observable<BidGroup | null> {
    if (bid.group) {
      return this.bidGroupService.get(bid.group);
    } else {
      return of(null);
    }
  }
}
