<main>
  <section>
    <ng-container *ngTemplateOutlet="confirmTitle"></ng-container>
  </section>
  <section *ngIf="loading$ | async">
    <nz-spin nzSize="large" [nzSpinning]="loading$ | async">
     <div class="loading-placeholder"></div>
    </nz-spin>
  </section>
  <section *ngIf="renderAlert$ | async">
    <nz-alert
      nzType="warning"
      [nzMessage]="'SUBSCRIBE_TO_BEST_BID.CANT_BUY_ALERT' | translate"
      nzShowIcon
    ></nz-alert>
  </section>
  <section *ngIf="dataReceived$ | async">
    <span *ngIf="agreementTimer$ | async; else agreeCheckbox">
      {{ 'SUBSCRIBE_TO_BEST_BID.AGREEMENT_TIMER' | translate: { seconds: agreementTimer$ | async } }}
    </span>
    <ng-template #agreeCheckbox>
      <section>
        <label nz-checkbox [(ngModel)]="agreementAccepted">
          {{ 'SUBSCRIBE_TO_BEST_BID.AGREE' | translate }}
        </label>
      </section>
    </ng-template>
  </section>
</main>

<ng-template #confirmTitle>
  <div>
    <div>{{ 'SUBSCRIBE_TO_BEST_BID.CONFIRMATION_1' | translate }}</div>
    <div [innerHtml]="'SUBSCRIBE_TO_BEST_BID.CONFIRMATION_2' | translate"></div>
  </div>
</ng-template>

<footer *nzModalFooter>
  <nz-row nzJustify="space-between">
    <nz-col>
      <button nz-button (click)="cancel()">
        {{ 'CANCEL' | translate }}
      </button>
    </nz-col>
    <nz-col *ngIf="canSubscribe$ | async">
      <button
        nz-button
        nzType="primary"
        [disabled]="!agreementAccepted"
        [nzLoading]="subscribing$ | async"
        (click)="subscribeToViewBestBid(false)"
      >
        {{ 'SUBSCRIBE_TO_BEST_BID.OK_TEXT' | translate }}
      </button>
    </nz-col>
    <nz-col *ngIf="canSubscribeOnAdvanceUpdate$ | async">
      <button
        nz-button
        nzType="primary"
        [disabled]="!agreementAccepted"
        [nzLoading]="subscribing$ | async"
        (click)="subscribeToViewBestBid(true)"
      >
        {{ 'SUBSCRIBE_TO_BEST_BID.SUBSCRIBE_ON_ADVANCE_UPDATE' | translate }}
      </button>
    </nz-col>
    <nz-col *ngIf="canRequestSubscribeOnAdvanceUpdate$ | async">
      <button
        nz-button
        nzType="primary"
        [disabled]="!agreementAccepted"
        [nzLoading]="subscribing$ | async"
        (click)="requestSubscribeOnAdvanceUpdate()"
      >
        {{ 'SUBSCRIBE_TO_BEST_BID.REQUEST_SUBSCRIBE_ON_ADVANCE_UPDATE' | translate }}
      </button>
    </nz-col>
  </nz-row>
</footer>
