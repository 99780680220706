<section>
  <div *ngFor="let item of currentUserNotifyMessages">
    <nz-page-header
      class="site-page-header"
      [nzTitle]="'PROFILE.NOTIFICATION_BY' | translate: { name: item?.bot?.clientName }"
    >
      <nz-page-header-extra>
        <nz-space *ngIf="item?.channels?.length <= 3">
          <button *nzSpaceItem nz-button nzSize="small" (click)="addSubscribe(item?.bot)">
            <span nz-icon nzType="plus" nzTheme="outline"></span>
            {{ 'PROFILE.SUBSCRIBE' | translate }}
          </button>
        </nz-space>
      </nz-page-header-extra>
      <nz-page-header-content>
        <nz-collapse [nzBordered]="false" *ngIf="item?.messageTypes?.length > 0">
          <nz-collapse-panel [nzHeader]="'PROFILE.NOTIFICATION_TYPE' | translate" [nzActive]="true">
            <nz-list>
              <nz-list-header>
                <nz-list-item *ngIf="item?.channels?.length > 0">
                  <nz-list-item-meta [nzTitle]="" [nzDescription]=""> </nz-list-item-meta>
                  <ul nz-list-item-actions>
                    <nz-list-item-action *ngFor="let channelBot of item?.channels; let i = index">
                      <div *ngIf="channelBot?.username; else numberChat">
                        {{ channelBot?.username }}
                      </div>
                      <ng-template #numberChat> #{{ i + 1 }} </ng-template>
                      <a
                        nz-button
                        nzType="text"
                        nzSize="small"
                        nzDanger
                        *ngIf="item?.channels?.length > 1"
                        nz-popconfirm
                        [nzLoading]="loadingsDelete[channelBot?.id] | async"
                        [nzPopconfirmTitle]="'PROFILE.DELETE_NOTIFICATION_CHAT' | translate"
                        nzPopconfirmPlacement="bottom"
                        [nzCancelText]="'PROFILE.CANCEL_DELETE_NOTIFICATION_CHAT' | translate"
                        [nzOkText]="'PROFILE.CONFIRM_DELETE_NOTIFICATION_CHAT' | translate"
                        (nzOnConfirm)="deleteNotifiChat(channelBot)"
                      >
                        <i nz-icon nzType="delete" nzTheme="outline"></i>
                      </a>
                    </nz-list-item-action>
                  </ul>
                </nz-list-item>
              </nz-list-header>
              <nz-list-item *ngFor="let message of item?.messageTypes">
                <nz-list-item-meta
                  [nzTitle]="notificationTitle"
                  [nzDescription]="message?.description"
                >
                  <ng-template #notificationTitle>
                    <ng-container [ngSwitch]="language$ | async">
                      <ng-container *ngSwitchCase="'ru'">
                        {{ message.name || message.nameEng }}
                      </ng-container>
                      <ng-container *ngSwitchDefault>
                        {{ message.nameEng || message.name }}
                      </ng-container>
                    </ng-container>
                  </ng-template>
                </nz-list-item-meta>
                <ul nz-list-item-actions>
                  <nz-list-item-action *ngFor="let channel of message?.channels">
                    <nz-switch
                      [ngModel]="channel?.enabledMessage"
                      [nzDisabled]="!message.isDisabled"
                      [nzSize]="'small'"
                      [nzLoading]="loadings[channel?.id + '-' + message?.id] | async"
                      [nzCheckedChildren]="checkedTemplate"
                      [nzUnCheckedChildren]="unCheckedTemplate"
                      (ngModelChange)="toggleMessage(channel, message)"
                    ></nz-switch>
                    <ng-template #checkedTemplate><span nz-icon nzType="check"></span></ng-template>
                    <ng-template #unCheckedTemplate
                      ><span nz-icon nzType="close"></span
                    ></ng-template>
                  </nz-list-item-action>
                </ul>
              </nz-list-item>
            </nz-list>
          </nz-collapse-panel>
        </nz-collapse>
      </nz-page-header-content>
    </nz-page-header>
    <nz-divider nzType="horizontal"></nz-divider>
  </div>
</section>
