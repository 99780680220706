<form nz-form [formGroup]="form">
  <nz-form-item>
    <nz-alert [nzMessage]="'USER_REQUESTS.ALERT' | translate"></nz-alert>
  </nz-form-item>
  <nz-form-item *ngIf="isSaturday">
    <nz-alert [nzMessage]="'USER_REQUESTS.SATURDAY_TIME_LIMIT_ALERT' | translate"></nz-alert>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label nzSpan="6">
      {{ 'USER_REQUESTS.LANGUAGE' | translate }}
    </nz-form-label>
    <nz-form-control>
      <nz-radio-group formControlName="language" nzButtonStyle="solid">
        <label nz-radio-button [nzValue]="lang" *ngFor="let lang of translationLanguage">
          {{ lang | uppercase | translate }}
        </label>
      </nz-radio-group>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label nzSpan="6">
      {{ 'USER_REQUESTS.TRANSLATION_MEDIA_TYPE' | translate }}
    </nz-form-label>
    <nz-form-control>
      <nz-radio-group formControlName="translationMediaType" nzButtonStyle="solid">
        <label
          *ngFor="let type of translationMediaTypes"
          nz-radio-button
          [nzValue]="type"
          [nzDisabled]="isMediaTypeDisabled(type)"
        >
          {{ 'TRANSLATION_SETTINGS.TRANSLATION_MEDIA_TYPES.' + type | uppercase | translate }}
        </label>
      </nz-radio-group>
    </nz-form-control>
  </nz-form-item>
</form>

<footer *nzModalFooter>
  <button nz-button (click)="destroyModal()">
    {{ 'CANCEL' | translate }}
  </button>
  <button nz-button nzType="primary" [nzLoading]="creating$ | async" (click)="submit()">
    {{ 'USER_REQUESTS.CREATE' | translate }}
  </button>
</footer>
