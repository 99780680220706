import { HttpClient, HttpEventType, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FetchResult } from '@apollo/client/core';
import { NzUploadFile, NzUploadXHRArgs } from 'ng-zorro-antd/upload';
import { Observable } from 'rxjs';
import { filter, map, mapTo, switchMap, tap } from 'rxjs/operators';

import { PresignedPostCreateGQL, PresignedPostCreateMutation } from '../modules/graphql/service/graphql-auto-main-service';

type UploadData = {
  request: HttpRequest<any>;
  key: string;
  item: NzUploadXHRArgs | NzUploadFile;
};

type PPCResult = FetchResult<PresignedPostCreateMutation, Record<string, any>, Record<string, any>>;

@Injectable({
  providedIn: 'root',
})
export class S3FileUploaderService {
  constructor(private http: HttpClient, private preSignedPostCreate: PresignedPostCreateGQL) {}

  upload(item: NzUploadXHRArgs | NzUploadFile): Observable<string> {
    return this.preSignedPostCreate
      .mutate(
        {
          input: {
            filename: this.getFile(item).name,
            contentType: this.getFile(item).type,
          },
        },
        {
          fetchPolicy: 'no-cache',
        },
      )
      .pipe(
        tap((result) => console.log('PPC response', result)),
        map((result) => this.mapToRequest(result, item)),
        switchMap((requestData) => this.uploadToS3(requestData)),
      );
  }

  private mapToRequest(result: PPCResult, item: NzUploadXHRArgs | NzUploadFile): UploadData {
    const fields: object = JSON.parse(result.data.presignedPostCreate.fields);
    const formData = new FormData();
    const key = fields['key'];
    const headers = new HttpHeaders()
      .append('enctype', 'multipart/form-data')
      .append('accept', 'application/json');

    for (const fieldKey of Object.keys(fields)) {
      formData.append(fieldKey, fields[fieldKey]);
    }

    formData.append('file', this.getFile(item) as any);

    const req = new HttpRequest('POST', result.data.presignedPostCreate.url, formData, {
      headers,
      reportProgress: true,
      withCredentials: false,
      responseType: 'text',
    });

    return {
      request: req,
      key,
      item,
    };
  }

  private uploadToS3(uploadData: UploadData): Observable<string> {
    const req = uploadData.request;
    const item = uploadData.item;
    const key = uploadData.key;

    return this.http.request(req).pipe(
      tap((event) => console.log('Http event', event)),
      tap((event) => {
        if (event.type === HttpEventType.UploadProgress) {
          if (event.total > 0) {
            (event as any).percent = (event.loaded / event.total) * 100;
          }

          if (this.isXHRArgs(item)) {
            item.onProgress(event, item.file);
          }
        }
      }),
      filter((event) => event instanceof HttpResponse),
      tap((event: HttpResponse<any>) => {
        if (this.isXHRArgs(item)) {
          item.onSuccess(event.body, item.file, event);
        }
      }),
      mapTo(key),
    );
  }

  private isXHRArgs(item: NzUploadXHRArgs | NzUploadFile): boolean {
    return Boolean(item.onSuccess || item.onProgress || item.onError);
  }

  private getFile(item: NzUploadXHRArgs | NzUploadFile): NzUploadFile {
    return this.isXHRArgs(item) ? item.file : (item as NzUploadFile);
  }
}
