import { AkebonoCoreModule } from '@akebono/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LotListDataService } from './lot-list-data.service';

@NgModule({
  imports: [CommonModule, AkebonoCoreModule],
  providers: [LotListDataService],
})
export class LotListDataServiceModule {}
