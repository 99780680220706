import { Currency, CurrencyService } from '@akebono/core';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { DateTime } from 'luxon';
import { Observable, of } from 'rxjs';
import { Lot, LotTypeEnum } from 'src/app/modules/graphql/service/graphql-auto-main-service';
import {
  LotSource,
  MotoListLotsGQL,
} from 'src/app/modules/graphql/service/graphql-cars-default-service';
import { LotListDataService } from 'src/app/modules/shared-components/lot-list-data-service/lot-list-data.service';

import {
  DateRange,
  getDaysRange,
  getTypeSort,
  simpleNameSort,
  toLocalIsoString,
  toNumber,
} from '../../../../const';

@Component({
  selector: 'app-moto-lots',
  templateUrl: './moto-lots-list.component.html',
  styleUrls: ['./moto-lots-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MotoLotsComponent implements OnInit {
  data: any = {
    total: 0,
    nodes: [],
  };
  pageSize = 20;
  page = 1;
  loading = true;
  loadingResult = true;
  isYearFiltered = false;
  isEngineVolumeFiltered = false;
  isMileageFiltered = false;
  isActiveFilters = true;
  total = 0;
  companies: any;
  companiesList: any[] = [];
  auctions: any;
  currentCompany = null;
  form: FormGroup;
  inclusionFilterFields = {
    frame: 'modelTypeEn',
    score: 'scoresEn',
    auction: 'auctRef',
    color: 'colorEn',
    date: 'date',
    result: 'result',
  };
  showSorterUp = false;
  showSorterDown = false;
  lastDay: Date;
  dateRange: DateRange[] = [];
  selectedDays: string[] = [];
  currentCurrency: Currency;

  lotListData$: Observable<Map<string, Lot>> = of(new Map());

  constructor(
    private title: Title,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private lotListDataService: LotListDataService,
    private currencyService: CurrencyService,
    private motoListLotsGql: MotoListLotsGQL,
  ) {
    this.form = this.fb.group({
      auction: this.fb.control('all'),
      company: this.fb.control(''),
      model: this.fb.control(''),
      bid: this.fb.control(null),
      yearFrom: this.fb.control(null),
      yearTo: this.fb.control(null),
      mileageFrom: this.fb.control(null),
      mileageTo: this.fb.control(null),
      engineVolumeFrom: this.fb.control(null),
      engineVolumeTo: this.fb.control(null),
      inclusionScore: this.fb.control([]),
      inclusionColor: this.fb.control([]),
      datetime: this.fb.control(false),
    });
    this.currencyService.currentCurrency$.subscribe((item) => {
      this.currentCurrency = item;
    });
    this.route.queryParams.subscribe((params) => {
      this.loadingResult = true;
      this.page = toNumber(params.page) || 1;
      this.pageSize = toNumber(params.pageSize) || this.pageSize;
      const filter = this.getQueryFilter(params);
      this.form.patchValue(this.getFilterFormState(params));
      const sort = {};
      if (params.sort && params.sortType) {
        sort[params.sort] = {
          direction: params.sortType,
        };
      }
      this.initDate();
      this.motoListLotsGql
        .watch(
          {
            first: this.pageSize,
            offset: (this.page - 1) * this.pageSize,
            filter,
            sort,
          },
          { fetchPolicy: 'network-only' },
        )
        .valueChanges.subscribe(
          (result) => {
            this.data = result.data.lots;
            this.loading = result.loading;
            this.loadingResult = result.loading;
            this.total = result.data.motoLots.total;
            this.lotListData$ = this.lotListDataService.fetchData(
              result.data.lots.nodes,
              LotTypeEnum.Moto,
              LotSource.Aleado,
            );
            this.companies = result.data.motoCompanies.nodes.reduce((carry, company) => {
              carry[company.companyId] = company;
              return carry;
            }, {});
            this.companiesList = [...(result.data.motoCompanies.nodes ?? [])].sort(simpleNameSort);
            this.auctions = this.data.auctRefOptions;
          },
          (error) => {
            console.log(error);
          },
        );
    });
  }

  ngOnInit() {
    this.title.setTitle('ECar JP Lots');

    this.isActiveFilters = window.innerWidth >= 780;
  }

  filter(value) {
    this.router.navigate(['moto'], {
      queryParams: {
        r: Math.floor(Math.random() * 100),
        company: value.company,
        model: value.model,
        auction: value.auction ? (value.auction === 'all' ? undefined : value.auction) : undefined,
        bid: value.bid ? value.bid : undefined,
        yearFrom: value.yearFrom ? value.yearFrom : undefined,
        yearTo: value.yearTo ? value.yearTo : undefined,
        mileageFrom: value.mileageFrom ? value.mileageFrom : undefined,
        mileageTo: value.mileageTo ? value.mileageTo : undefined,
        engineVolumeFrom: value.engineVolumeFrom ? value.engineVolumeFrom : undefined,
        engineVolumeTo: value.engineVolumeTo ? value.engineVolumeTo : undefined,
        score: value.inclusionScore.length > 0 ? value.inclusionScore : undefined,
        color: value.inclusionColor.length > 0 ? value.inclusionColor : undefined,
        date: this.selectedDays.length > 0 ? this.selectedDays : undefined,
        datetime: value.datetime
          ? DateTime.local().setZone('Asia/Tokyo').plus({ minutes: 15 }).toISO()
          : undefined,
        page: null,
      },
      queryParamsHandling: 'merge',
    });
  }

  getQueryFilter(params) {
    const inclusionFilter = [];
    Object.keys(this.inclusionFilterFields).forEach((key) => {
      if (params[key] && params[key].length > 0) {
        if (params[key] !== 'any') {
          inclusionFilter.push({
            fieldName: this.inclusionFilterFields[key],
            eq: [].concat(params[key]),
          });
        }
      }
    });

    return {
      companyRef: {
        eq: params.company,
      },
      modelNameRef: {
        eq: params.model,
      },
      year: {
        gte: Number(params.yearFrom),
        lte: Number(params.yearTo),
      },
      mileage: {
        gte: Number(params.mileageFrom),
        lte: Number(params.mileageTo),
      },
      engineVolume: {
        gte: Number(params.engineVolumeFrom),
        lte: Number(params.engineVolumeTo),
      },
      bid: {
        eq: params.bid,
      },
      inclusion:
        inclusionFilter.length > 0
          ? {
              columns: inclusionFilter,
            }
          : undefined,
      datetime: {
        gt: params.datetime,
      },
    };
  }

  getFilterFormState(params) {
    this.isYearFiltered = (params.yearFrom || params.yearTo) as boolean;
    this.isMileageFiltered = (params.mileageFrom || params.mileageTo) as boolean;
    this.isEngineVolumeFiltered = (params.engineVolumeFrom || params.engineVolumeTo) as boolean;
    this.selectedDays = params.date ? [].concat(params.date) : [];
    return {
      auction: params.auction ? params.auction : 'all',
      company: params.company ? params.company : null,
      bid: params.bid ? params.bid : null,
      model: params.model ? toNumber(params.model) : null,
      yearFrom: params.yearFrom || null,
      yearTo: params.yearTo || null,
      mileageFrom: params.mileageFrom || null,
      mileageTo: params.mileageTo || null,
      engineVolumeFrom: params.engineVolumeFrom || null,
      engineVolumeTo: params.engineVolumeTo || null,
      inclusionScore: params.score ? [].concat(params.score) : [],
      inclusionColor: params.color ? [].concat(params.color) : [],
      datetime: params.datetime || false,
    };
  }

  resetFilter() {
    this.selectedDays = [];
    this.router.navigate(['moto']);
    return false;
  }

  loadData(page) {
    this.router.navigate(['moto'], {
      queryParams: {
        page,
      },
      queryParamsHandling: 'merge',
    });
  }

  changePageSize(pageSize) {
    this.router.navigate(['moto'], {
      queryParams: {
        pageSize,
      },
      queryParamsHandling: 'merge',
    });
  }
  searchCompany(event) {}

  sortEvent(sort: { key: string; value: string }): void {
    this.router.navigate(['moto'], {
      queryParams: {
        sort: sort.key,
        sortType: getTypeSort(sort.value),
      },
      queryParamsHandling: 'merge',
    });
  }

  removeFilter(filter) {
    if (filter === 'year') {
      this.form.get('yearFrom').setValue(null);
      this.form.get('yearTo').setValue(null);
    }
    if (filter === 'engineVolume') {
      this.form.get('engineVolumeFrom').setValue(null);
      this.form.get('engineVolumeTo').setValue(null);
    }
    if (filter === 'mileage') {
      this.form.get('mileageFrom').setValue(null);
      this.form.get('mileageTo').setValue(null);
    }
    this.filter(this.form.value);
  }

  scrollToTable() {
    document
      .querySelector('.table')
      .scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
  }

  formatterVolume(value) {
    if (value) {
      return `${value}cc`;
    }
  }

  search(event) {}

  change(event) {
    if (!this.loadingResult) {
      this.filter(this.form.value);
    }
  }

  selectDateAuction(event, date: DateRange) {
    const dateJapan = toLocalIsoString(
      this.getDate(date.date.toLocaleString('en-US', { timeZone: 'Asia/Tokyo' })),
    );
    date.checked = event;
    if (event) {
      this.selectedDays.push(dateJapan);
    } else {
      const day = this.selectedDays.indexOf(dateJapan);
      if (day > -1) {
        this.selectedDays.splice(day, 1);
      }
    }
    this.filter(this.form.value);
  }

  initDate() {
    this.lastDay = new Date();
    this.lastDay.setDate(this.lastDay.getDate() + 7);
    if (this.dateRange.length === 0) {
      const dates = getDaysRange(new Date(), new Date(this.lastDay));
      dates.map((item) => {
        this.dateRange.push({
          date: item,
          checked: this.selectedDays.indexOf(toLocalIsoString(this.getDate(item))) > -1,
        });
      });
    } else {
      this.dateRange.map((item) => {
        item.checked = this.selectedDays.indexOf(toLocalIsoString(this.getDate(item.date))) > -1;
      });
    }
  }

  getDate(string = null): Date {
    if (string) {
      return new Date(string);
    }
    return new Date();
  }

  getPrice(price: number) {
    return this.currencyService.getPrice(price, this.currentCurrency);
  }
}
