import { AkebonoCoreModule } from '@akebono/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';

import { LotEditAuctionSheetZonesButtonComponent } from './lot-edit-auction-sheet-zones-button.component';

@NgModule({
  declarations: [LotEditAuctionSheetZonesButtonComponent],
  imports: [CommonModule, TranslateModule, AkebonoCoreModule, NzButtonModule, NzIconModule],
  exports: [LotEditAuctionSheetZonesButtonComponent],
})
export class LotEditAuctionSheetZonesButtonModule {}
