import {
  HttpClient,
  HttpEventType,
  HttpHeaders,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { defer, Observable } from 'rxjs';

import { PresignedPostCreateGQL } from './../modules/graphql/service/graphql-auto-main-service';

export enum UploadState {
  initial,
  signing,
  uploading,
  compressing,
  success,
  error,
}

@Injectable()
export class UploadsService {
  uploads = {};

  constructor(private presignedPostCreateGQL: PresignedPostCreateGQL, private http: HttpClient) {}

  clearAll() {
    this.uploads = {};
  }

  async uploadFile(sourceFile: File, name?: string, info?: string): Promise<string> {
    const uploadId = new Date().getTime();
    this.uploads[uploadId] = {
      title: `${info} ${name}`,
      progress: 0,
      state: UploadState.initial,
      status: 'active',
    };

    this.uploads[uploadId].state = UploadState.signing;
    const result = await this.presignedPostCreateGQL
      .mutate(
        {
          input: {
            filename: name || sourceFile.name,
            contentType: sourceFile.type,
          },
        },
        { fetchPolicy: 'no-cache' },
      )
      .toPromise();

    const fields: object = JSON.parse(result.data.presignedPostCreate.fields);
    const formData = new FormData();
    const key = fields['key'];
    const headers = new HttpHeaders()
      .append('enctype', 'multipart/form-data')
      .append('accept', 'application/json');

    for (const fieldKey of Object.keys(fields)) {
      formData.append(fieldKey, fields[fieldKey]);
    }

    this.uploads[uploadId].state = UploadState.compressing;
    formData.append('file', sourceFile);

    const req = new HttpRequest('POST', result.data.presignedPostCreate.url, formData, {
      headers,
      reportProgress: true,
      withCredentials: false,
    });

    this.uploads[uploadId].state = UploadState.uploading;
    return await new Promise<string>((resolve, reject) => {
      this.http.request(req).subscribe(
        (event) => {
          if (event.type === HttpEventType.UploadProgress) {
            if (event.total > 0) {
              this.uploads[uploadId].progress = (event.loaded / event.total) * 100;
            }
          } else if (event instanceof HttpResponse) {
            resolve(key);
            this.uploads[uploadId].progress = 100;
            this.uploads[uploadId].state = UploadState.success;
            this.uploads[uploadId].status = 'success';
          }
        },
        (error) => {
          this.uploads[uploadId].progress = 100;
          this.uploads[uploadId].state = UploadState.error;
          this.uploads[uploadId].status = 'exception';
          reject(error);
        },
      );
    });
  }

  uploadFileReactive(sourceFile: File, name?: string, info?: string): Observable<string> {
    return defer(() => this.uploadFile(sourceFile, name, info));
  }
}
