import { MutationHandlingServiceV2, SingleMutationRef } from '@akebono/core';
import { Clipboard } from '@angular/cdk/clipboard';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import {
  UserLotMarksListUpdateGQL,
  UserLotMarksListUpdateMutation,
} from '../../../../graphql/service/graphql-auto-main-service';

@Component({
  selector: 'app-list-share-button',
  templateUrl: './list-share-button.component.html',
  styleUrls: ['./list-share-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListShareButtonComponent implements OnDestroy {
  @Input() id: string;
  @Input() shareLotLink = '';
  @Output() changeMark = new EventEmitter<void>();

  visible = false;
  private destroy$ = new Subject();
  refList$: SingleMutationRef<UserLotMarksListUpdateMutation>;

  constructor(
    private clipboard: Clipboard,
    private message: NzMessageService,
    private translate: TranslateService,
    private mhs: MutationHandlingServiceV2,
    private userLotMarksListUpdateGQL: UserLotMarksListUpdateGQL,
  ) {}

  userLotListUpdate(): void {
    if (!this.shareLotLink) {
      this.refList$ = this.mhs.mutate(
        this.userLotMarksListUpdateGQL,
        {
          input: {
            id: this.id,
            publishedAt: new Date().toISOString(),
          },
        },
        {
          refetch: true,
          failureTranslationKey: 'MUTATION_FAILED',
        },
      );

      this.refList$.loading.pipe(
        finalize(() => {
          this.changeMark.emit();
        }),
      );

      this.refList$.data.pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.shareLotLink = `https://${environment.domain}/list/${this.id}`;
        this.copyLinkClipboard();
      });
    } else {
      this.copyLinkClipboard();
    }
  }

  copyLinkClipboard(): void {
    this.clipboard.copy(this.shareLotLink);
    this.message.create('success', this.translate.instant('SHARED.COPY_LIST_SUCCESS'));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
