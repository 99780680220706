import { from, fromEvent, iif, Observable, of, Subject } from 'rxjs';
import { filter, map, mapTo, shareReplay, switchMap, tap, toArray } from 'rxjs/operators';
import {
  LotSource,
  LotTypeEnum,
  UserLotMark,
  UserLotMarkDataFragment,
  UserLotMarkEnum,
} from 'src/app/modules/graphql/service/graphql-auto-main-service';

import { INTERESTING_CHANGE } from '../../constants';
import { LotInterestingMutationService } from '../lot-interesting-mutation.service';
import { InterestingChange } from './interesting-change.type';
import { InterestingStoreEntry } from './interesting-store-entry.type';

export class LotInterestingStore {
  private readonly store: Observable<Map<string, InterestingStoreEntry>>;
  private readonly changes = new Subject<InterestingChange>();

  public get changes$(): Observable<InterestingChange> {
    return this.changes.asObservable();
  }

  constructor(
    source: Observable<UserLotMarkDataFragment[] | UserLotMarkDataFragment>,
    private mutations: LotInterestingMutationService,
  ) {
    this.store = source.pipe(
      map((marks) => marks ?? []),
      switchMap((marks) =>
        Array.isArray(marks) ? from(marks as UserLotMark[]) : of(marks as UserLotMark),
      ),
      map(
        (mark) =>
          [
            this.getSearchKey(mark.lot.id, mark.lot.subgroup),
            {
              lotId: mark.lot?.id,
              lotType: mark.lot?.subgroup,
              markId: mark.id,
              interesting: mark.mark,
            },
          ] as [string, InterestingStoreEntry],
      ),
      toArray(),
      map((mapInitializer) => new Map(mapInitializer)),
      shareReplay(1),
    );

    fromEvent<StorageEvent>(window, 'storage')
      .pipe(
        filter((event) => event.key === INTERESTING_CHANGE),
        map((event) => JSON.parse(event.newValue)),
        switchMap((change) => this.sync(change)),
      )
      .subscribe((change) => this.changes.next(change));
  }

  public get(lotId: string, lotType: LotTypeEnum): Observable<InterestingStoreEntry | null> {
    return this.store.pipe(map((store) => store.get(this.getSearchKey(lotId, lotType)) ?? null));
  }

  public getAll(): Observable<InterestingStoreEntry[]> {
    return this.store.pipe(map((store) => Array.from(store.values())));
  }

  public exists(lotId: string, lotType: LotTypeEnum): Observable<boolean> {
    return this.store.pipe(map((store) => store.has(this.getSearchKey(lotId, lotType))));
  }

  public create(
    lotId: string,
    lotType: LotTypeEnum,
    lotSource: LotSource,
    interesting: UserLotMarkEnum,
  ): Observable<InterestingStoreEntry> {
    return this.store.pipe(
      switchMap((store) =>
        iif(
          () => store.has(this.getSearchKey(lotId, lotType)),
          of(store.get(this.getSearchKey(lotId, lotType))),
          this.mutations
            .create({
              lotId,
              lotType,
              lotSource,
              mark: interesting,
            })
            .pipe(
              map(
                (mark) =>
                  ({
                    lotId: mark.lot?.id,
                    lotType: mark.lot?.subgroup,
                    markId: mark.id,
                    interesting: mark.mark,
                  } as InterestingStoreEntry),
              ),
            ),
        ).pipe(tap((entry) => store.set(this.getSearchKey(lotId, lotType), entry))),
      ),
      tap(() => this.broadcastChanges(lotId, lotType, interesting)),
    );
  }

  public update(
    lotId: string,
    lotType: LotTypeEnum,
    interesting: UserLotMarkEnum,
  ): Observable<InterestingStoreEntry> {
    return this.store.pipe(
      switchMap((store) => {
        const target = store.get(this.getSearchKey(lotId, lotType));
        return this.mutations.update({ id: target.markId, mark: interesting }).pipe(
          map(
            (mark) =>
              ({
                lotId: mark.lot?.id,
                lotType: mark.lot?.subgroup,
                markId: mark.id,
                interesting: mark.mark,
              } as InterestingStoreEntry),
          ),
          tap((entry) => store.set(this.getSearchKey(lotId, lotType), entry)),
          tap(() => this.broadcastChanges(lotId, lotType, interesting)),
        );
      }),
    );
  }

  public delete(lotId: string, lotType: LotTypeEnum): Observable<InterestingStoreEntry> {
    return this.store.pipe(
      switchMap((store) => {
        const target = store.get(this.getSearchKey(lotId, lotType));
        return this.mutations.delete({ id: target.markId }).pipe(
          map(
            (mark) =>
              ({
                lotId: mark.lot?.id,
                lotType: mark.lot?.subgroup,
                markId: mark.id,
                interesting: mark.mark,
              } as InterestingStoreEntry),
          ),
          tap(() => store.delete(this.getSearchKey(lotId, lotType))),
          tap(() => this.broadcastChanges(lotId, lotType, null)),
        );
      }),
    );
  }

  private broadcastChanges(
    lotId: string,
    lotType: LotTypeEnum,
    interesting: UserLotMarkEnum | null,
  ): void {
    const payload = { lotId, lotType, interesting };
    this.changes.next(payload);
    localStorage.setItem(INTERESTING_CHANGE, JSON.stringify(payload));
  }

  private sync(change: InterestingChange): Observable<InterestingChange> {
    const { lotId, lotType, interesting } = change;
    return this.store.pipe(
      tap((store) => {
        const key = this.getSearchKey(lotId, lotType);
        if (interesting) {
          const target = store.get(key);
          store.set(key, {
            ...target,
            interesting,
          });
        } else {
          store.delete(key);
        }
      }),
      mapTo(change),
    );
  }

  private getSearchKey(lotId: string, lotType: LotTypeEnum): string {
    return `${lotType}_${lotId}`;
  }
}
