import { MutationHandlingServiceV2 } from '@akebono/core';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { of } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Bid, BidUpdateGQL } from 'src/app/modules/graphql/service/graphql-auto-main-service';

@Component({
  selector: 'app-lot-participation-button',
  templateUrl: './lot-participation-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotParticipationButtonComponent {
  @Input() bid?: Bid;
  @Input() hasTranslations?: boolean;
  @Output() actionComplete = new EventEmitter<void>();

  submittingParticipationAcceptance = of(false);

  constructor(private mhs: MutationHandlingServiceV2, private bidUpdateGQL: BidUpdateGQL) {}

  setParticipation(isParticipationAccepted: boolean): void {
    this.submittingParticipationAcceptance = this.mhs
      .mutate(this.bidUpdateGQL, {
        input: {
          id: this.bid.id,
          isParticipationAccepted,
        },
      })
      .loading.pipe(finalize(() => this.actionComplete.emit()));
  }
}
