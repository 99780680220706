import {
  AgreementsRouterInterceptorService,
  AuthenticationService,
  FetchQueryRef,
  QueryHandlingService,
} from '@akebono/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Observable, timer } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { BidManualModalComponent } from 'src/app/components/bid-manual-modal/bid-manual-modal.component';
import {
  MufgCurrenciesGQL,
  MufgCurrenciesQuery,
} from 'src/app/modules/graphql/service/graphql-directory-service';
import { SettingsModalComponent } from 'src/app/modules/shared-components/settings-modal/components/settings-modal/settings-modal.component';
import { LayoutService } from 'src/app/services/layout.service';

@Component({
  selector: 'app-auctions-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutComponent implements OnInit {
  clock$: Observable<Date>;
  isMobile$: Observable<boolean>;
  isDesktop$: Observable<boolean>;

  mobileMenuOpened = false;

  translateFn = (key: string): string => {
    if (key) {
      return this.translate.instant(key);
    }
  };

  DateTime = DateTime;
  currenciesRef: FetchQueryRef<MufgCurrenciesQuery>;

  constructor(
    private router: Router,
    private location: Location,
    public layoutService: LayoutService,
    private translate: TranslateService,
    private modalService: NzModalService,
    breakpointObserver: BreakpointObserver,
    private authService: AuthenticationService,
    private agreementsRouterInterceptorService: AgreementsRouterInterceptorService,
    private mufgCurrenciesGQL: MufgCurrenciesGQL,
    private qhs: QueryHandlingService,
  ) {
    this.currenciesRef = this.qhs.fetch(this.mufgCurrenciesGQL, {}, 'network-only');
    this.isMobile$ = breakpointObserver.observe(['(max-width: 768px)']).pipe(
      map((state) => state.matches),
      shareReplay(1),
    );
    this.isDesktop$ = this.isMobile$.pipe(map((state) => !state));
  }

  ngOnInit(): void {
    this.clock$ = timer(0, 1000).pipe(map(() => new Date()));
    this.agreementsRouterInterceptorService.startIntercepting();
  }

  back(): void {
    const history = this.location.getState();
    const currentPath = this.location.path().split('/');

    if ((history === null || history['navigationId'] === 1) && currentPath.includes('lot')) {
      window.close();
    } else {
      this.location.back();
    }
  }

  singOut(): void {
    this.authService.signOut();
    this.router.navigateByUrl('/sign-in');
  }

  createLotManually(): void {
    this.modalService.create({
      nzTitle: this.translate.instant('LOT_MANUALLY'),
      nzContent: BidManualModalComponent,
      nzWidth: '550px',
    });
  }

  notificationSettings(): void {
    this.modalService.create({
      nzTitle: this.translate.instant('SETTINGS.TITLE'),
      nzContent: SettingsModalComponent,
    });
  }
}
