import { Currency, CurrencyService } from '@akebono/core';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { QueryRef } from 'apollo-angular';
import { DateTime } from 'luxon';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Subscription } from 'rxjs';
import {
  LotsGQL,
  LotSource,
  LotsQuery,
  LotsQueryVariables,
} from 'src/app/modules/graphql/service/graphql-auto-main-service';

import {
  DateRange,
  getDaysRange,
  getTypeSort,
  toLocalIsoString,
  toNumber,
} from '../../../../const';
import { AraiKenkiLotModalComponent } from '../arai-kenki-lot-modal/arai-kenki-lot-modal.component';

@Component({
  selector: 'app-arai-kenki-lots-list',
  templateUrl: './arai-kenki-lots-list.component.html',
  styleUrls: ['./arai-kenki-lots-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AraiKenkiLotsListComponent implements OnInit, OnDestroy {
  data: any = {
    total: 0,
    nodes: [],
  };
  pageSize = 20;
  page = 1;
  loadingResult = true;
  total = 0;

  form: FormGroup;
  lastDay: Date;
  dateRange: DateRange[] = [];
  selectedDays: string[] = [];
  currentCurrency: Currency;
  dumpVersion: string;
  lotsListQueryRef: QueryRef<LotsQuery, LotsQueryVariables>;
  sub = new Subscription();

  constructor(
    private title: Title,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private lotsGQL: LotsGQL,
    private currencyService: CurrencyService,
    private modalService: NzModalService,
    private translate: TranslateService,
  ) {
    this.form = this.fb.group({
      company: this.fb.control(''),
      model: this.fb.control(''),
      modelType: this.fb.control(''),
      auction: this.fb.control(''),
      auctionDate: this.fb.control(''),
      code: this.fb.control(''),
      color: this.fb.control(''),
      mileageFrom: this.fb.control(''),
      mileageTo: this.fb.control(''),
      yearFrom: this.fb.control(''),
      yearTo: this.fb.control(''),
      rating: this.fb.control(''),
    });

    this.route.queryParams.subscribe((params) => {
      this.loadingResult = true;
      this.page = toNumber(params.page) || 1;
      this.pageSize = toNumber(params.pageSize) || this.pageSize;

      this.form.patchValue(this.getFilterFormState(params));
      this.initDate();

      const sort = {};

      if (params.sort && params.sortType) {
        sort[params.sort] = {
          direction: params.sortType,
        };
      }

      const queryFilters: LotsQueryVariables = {
        first: this.pageSize,
        offset: (this.page - 1) * this.pageSize,
        filter: {
          lotCompanyEn: {
            eq: params.company,
          },
          lotModelEn: {
            eq: params.model,
          },
          lotYear: {
            gte: Number(params.yearFrom),
            lte: Number(params.yearTo),
          },
          lotMileageNum: {
            gte: Number(params.mileageFrom) ? Number(params.mileageFrom) : null,
            lte: Number(params.mileageTo) ? Number(params.mileageTo) : null,
          },
          lotSource: {
            eq: LotSource.Manual,
          },
          lotColorEn: {
            eq: params.color,
          },
          lotRatingEn: {
            eq: params.rating,
          },
          lotCode: {
            eq: params.code,
          },
          lotAuctionName: {
            eq: 'ARAI KENKI',
          },
          lotAuctionDate: {
            eq: params.auctionDate || this.getTuesday(),
          },
        },
        sort,
      };
      if (!this.lotsListQueryRef) {
        this.lotsListQueryRef = this.lotsGQL.watch(queryFilters, {
          fetchPolicy: 'network-only',
        });
        this.lotsListQueryRef.valueChanges.subscribe(
          (result) => {
            this.loadingResult = false;
            this.data = result.data.lots;
            this.total = result.data.lots.total;
          },
          (error) => {
            console.log(error);
            this.loadingResult = false;
          },
        );
      } else {
        this.lotsListQueryRef.refetch(queryFilters).finally(() => {
          this.loadingResult = false;
        });
      }
    });
  }

  ngOnInit() {
    this.title.setTitle('ECar JP Lots');

    this.currencyService.currentCurrency$.subscribe((item) => {
      this.currentCurrency = item;
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  filter() {
    this.router.navigate([], {
      queryParams: {
        page: 1,
        r: Math.floor(Math.random() * 100),
        company: this.form.value.company || null,
        model: this.form.value.model || null,
        modelType: this.form.value.modelType || null,
        auction: this.form.value.auction || this.getTuesday(),
        auctionDate: this.form.value.auctionDate || null,
        code: this.form.value.code || null,
        color: this.form.value.color || null,
        mileageFrom: this.form.value.mileageFrom || null,
        mileageTo: this.form.value.mileageTo || null,
        yearFrom: this.form.value.yearFrom || null,
        yearTo: this.form.value.yearTo || null,
        rating: this.form.value.rating || null,
      },
      queryParamsHandling: 'merge',
    });
  }

  createLotManually(): void {
    this.modalService.create({
      nzTitle: this.translate.instant('LOT_MANUALLY'),
      nzContent: AraiKenkiLotModalComponent,
      nzWidth: '550px',
    });
  }

  getTuesday() {
    const now = DateTime.now();
    return now.weekday < 4
      ? now.startOf('week').plus({ days: 1 }).toISODate()
      : now.plus({ days: 7 }).startOf('week').plus({ days: 1 }).toISODate();
  }

  getFilterFormState(params) {
    return {
      company: params.company,
      model: params.model,
      modelType: params.modelType,
      auction: params.auction || 'ARAI KENKI',
      auctionDate: params.auctionDate || this.getTuesday(),
      code: params.code,
      color: params.color,
      mileageFrom: params.mileageFrom,
      mileageTo: params.mileageTo,
      yearFrom: params.yearFrom,
      yearTo: params.yearTo,
      rating: params.rating,
    };
  }

  loadData(page) {
    this.router.navigate(['manual-lots'], {
      queryParams: {
        page,
      },
      queryParamsHandling: 'merge',
    });
  }

  changePageSize(pageSize) {
    this.loadingResult = true;

    this.router.navigate(['manual-lots'], {
      queryParams: {
        pageSize,
      },
      queryParamsHandling: 'merge',
    });
  }

  sortEvent(sort: { key: string; value: string }): void {
    this.loadingResult = true;

    this.router.navigate(['manual-lots'], {
      queryParams: {
        sort: sort.key,
        sortType: getTypeSort(sort.value),
      },
      queryParamsHandling: 'merge',
    });
  }

  removeFilter() {
    this.form.reset();
  }

  initDate() {
    this.lastDay = new Date();
    this.lastDay.setDate(this.lastDay.getDate() + 7);
    if (this.dateRange.length === 0) {
      const dates = getDaysRange(new Date(), new Date(this.lastDay));
      dates.map((item) => {
        this.dateRange.push({
          date: item,
          checked: this.selectedDays.indexOf(toLocalIsoString(this.getDate(item))) > -1,
        });
      });
    } else {
      this.dateRange.map((item) => {
        item.checked = this.selectedDays.indexOf(toLocalIsoString(this.getDate(item.date))) > -1;
      });
    }
  }

  getDate(string = null): Date {
    if (string) {
      return new Date(string);
    }
    return new Date();
  }

  getPrice(price: number) {
    return this.currencyService.getPrice(price, this.currentCurrency);
  }
}
