import {
  Directive,
  ElementRef,
  EventEmitter,
  Host,
  Input,
  OnChanges,
  OnDestroy,
  Output,
} from '@angular/core';
import { Alignment, AllowedButtons, DriverHook, Side } from 'driver.js';

import { DriverService } from '../driver.service';
import { DriverGuideDirective } from './driver-guide.directive';

export type DriverOnClickType = {
  driverService: DriverService;
  element: ElementRef<HTMLElement>;
  currentStep: DriverStepDirective;
  guide: DriverGuideDirective;
  originalState: Parameters<DriverHook>;
};

@Directive({
  selector: '[appDriverStep]',
  providers: [DriverGuideDirective],
})
export class DriverStepDirective implements OnChanges, OnDestroy {
  @Input() overrideStepIndex?: number;
  @Input() stepName?: string;
  @Input() stepTitle: string;
  @Input() stepDescription: string;
  @Input() popoverClass?: string;
  @Input() stepSide: Side;
  @Input() stepAlign: Alignment;
  @Input() showButtons: AllowedButtons[];
  @Input() allowMovePrev = true;
  @Input() allowMoveNext = true;
  @Input() styles?: string[];
  @Output() onPrevClick = new EventEmitter<DriverOnClickType>();
  @Output() onNextClick = new EventEmitter<DriverOnClickType>();

  constructor(
    private readonly element: ElementRef<HTMLElement>,
    @Host() private readonly driverGuideDirective: DriverGuideDirective,
    private readonly driverService: DriverService,
  ) {}

  ngOnChanges(): void {
    this.driverGuideDirective.registerStep(
      this,
      {
        element: this.element.nativeElement,
        popover: {
          popoverClass: this.popoverClass,
          title: this.stepTitle,
          description: this.stepDescription,
          align: this.stepAlign,
          side: this.stepSide,
          onPrevClick: (...originalArgs) => {
            this.onPrevClick.emit({
              element: this.element,
              driverService: this.driverService,
              currentStep: this,
              guide: this.driverGuideDirective,
              originalState: originalArgs,
            });
            this.allowMovePrev && this.driverService.prev();
          },
          onPopoverRender: (el, opts) => {
            if (this.styles) {
              this.driverService.getState()?.activeElement?.classList.add(this.styles.join(' '));
            }
          },
          onNextClick: (...originalArgs) => {
            this.onNextClick.emit({
              element: this.element,
              driverService: this.driverService,
              currentStep: this,
              guide: this.driverGuideDirective,
              originalState: originalArgs,
            });
            this.allowMoveNext && this.driverService.next();
          },
          showButtons: this.showButtons,
        },
        onDeselected: () => {
          if (this.styles) {
            this.driverService.getState()?.activeElement?.classList.remove(this.styles.join(' '));
          }
        },
      },
      this.overrideStepIndex,
    );
  }

  ngOnDestroy(): void {
    this.driverGuideDirective.removeStep(this);
  }
}
