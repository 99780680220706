<button
  #printButton
  ngxPrint
  styleSheetFile="assets/purchase-report-style.css"
  printTitle="Purchase report"
  [printSectionId]="printSectionId"
></button>

<section [id]="printSectionId">
  <h2>
    <b>VEHICLE INFORMATION</b>
  </h2>

  <div class="main">
    <div class="top-placeholder"></div>

    <div class="vehicle-info-container">
      <div class="auction-list">
        <img [src]="reportData.mainPhotoUrl" />
      </div>

      <div class="info">
        <table>
          <tr>
            <td colspan="2" class="label">Model</td>
            <td colspan="4">{{ reportData.model | default }}</td>
          </tr>
          <tr>
            <td colspan="2" class="label">Mod.</td>
            <td colspan="4">{{ reportData.mod | default }}</td>
          </tr>

          <tr>
            <td colspan="6" class="images-cell">
              <img *ngFor="let url of additionalImageUrls" [src]="url" />
            </td>
          </tr>

          <tr>
            <td class="label">Auction date</td>
            <td colspan="2">
              <ng-container *ngIf="reportData.auctionDate; else defaultValue">
                {{ reportData.auctionDate | date: 'yyyy-MM-dd' }}
              </ng-container>
            </td>
            <td class="label">Start, ¥</td>
            <td colspan="2">{{ reportData.start | defaultMoney }}</td>
          </tr>

          <tr>
            <td class="label">Auction</td>
            <td colspan="2">{{ reportData.auction | default }}</td>
            <td class="label">Final, ¥</td>
            <td colspan="2">{{ reportData.final | defaultMoney }}</td>
          </tr>

          <tr>
            <td class="label">Lot no.</td>
            <td colspan="2">{{ reportData.lotNo | default }}</td>
            <td class="label">Negotiations, ¥</td>
            <td colspan="2">{{ reportData.negotiations | defaultMoney }}</td>
          </tr>

          <tr>
            <td class="label">Grade</td>
            <td colspan="2">{{ reportData.grade | default }}</td>
            <td class="label">Status</td>
            <td colspan="2">{{ reportData.status | default }}</td>
          </tr>

          <tr>
            <td class="label">Registr. year</td>
            <td colspan="2">
              <ng-container *ngIf="reportData.registrYear; else defaultValue">
                {{ reportData.registrYear | date: registrYearFormat }}
              </ng-container>
            </td>
            <td class="label">Engine</td>
            <td colspan="2">{{ reportData.engine | default }}</td>
          </tr>

          <tr>
            <td class="label">Product. year</td>
            <td colspan="2">
              <ng-container *ngIf="reportData.productYear; else defaultValue">
                {{ reportData.productYear | date: productYearFormat }}
              </ng-container>
            </td>
            <td class="label">Odometer (km)</td>
            <td colspan="2">{{ reportData.odometer | defaultNumber }}</td>
          </tr>

          <tr>
            <td class="label">Chassis</td>
            <td colspan="2">{{ reportData.chassis | default }}</td>
            <td class="label">Color</td>
            <td colspan="2">{{ reportData.color | default }}</td>
          </tr>

          <tr>
            <td class="label">VIN</td>
            <td colspan="2">{{ reportData.vin | default }}</td>
            <td class="label">Transmission</td>
            <td colspan="2">{{ reportData.transmission | default }}</td>
          </tr>

          <tr>
            <td colspan="6">
              <table>
                <tr>
                  <td class="label">LENGTH</td>
                  <td>{{ reportData.length | default }}</td>
                  <td class="label">WIDTH</td>
                  <td>{{ reportData.width | default }}</td>
                  <td class="label">HEIGHT</td>
                  <td>{{ reportData.height | default }}</td>
                </tr>

                <tr>
                  <td class="label" colspan="2">RECYCLE FEE, ¥</td>
                  <td colspan="4">{{ reportData.recycleFee | defaultMoney }}</td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</section>

<ng-template #defaultValue>N/A</ng-template>
