import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { NzModalService } from 'ng-zorro-antd/modal';
import { of } from 'rxjs';

import {
  ServiceSubscription,
  UserViewBestBidSubscriptionDataFragment,
} from '../../../../graphql/service/graphql-auto-main-service';
import { SubscribeToBestBidModalComponent } from '../subscribe-to-best-bid-modal/subscribe-to-best-bid-modal.component';

@Component({
  selector: 'app-subscribe-to-best-bid-button',
  templateUrl: './subscribe-to-best-bid-button.component.html',
  styleUrls: ['./subscribe-to-best-bid-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class SubscribeToBestBidButtonComponent {
  @Input() renderCounter = false;
  @Input() buttonTextType: 'full' | 'short' = 'full';
  @Output() subscriptionCreated = new EventEmitter<void>();

  @HostBinding('style.display') display: 'none' | 'inline-block' = 'none';

  loading$ = of(false);
  subscriptionActive = false;
  hasBestBidPermission = false;
  confirmVisible = false;
  tooltipVisible = false;
  subDaysLeft = 0;

  @Input()
  set subscriptionData(subscriptionData: UserViewBestBidSubscriptionDataFragment) {
    this.subscriptionActive = this.isSubscriptionActive(
      subscriptionData.bestBidViewServiceSubscription,
    );
    this.hasBestBidPermission =
      subscriptionData.canSeeBestBid || subscriptionData.canSeeBestBidAdmin;

    if (this.subscriptionActive) {
      this.subDaysLeft = Math.round(
        DateTime.fromISO(subscriptionData.bestBidViewServiceSubscription.expiresAt).diff(
          DateTime.now(),
          ['days'],
        ).days,
      );
    }

    this.display =
      (this.subscriptionActive || this.hasBestBidPermission) && !this.renderCounter
        ? 'none'
        : 'inline-block';
  }

  constructor(private modalService: NzModalService, private translate: TranslateService) {}

  public openModal(): void {
    this.tooltipVisible = false;
    this.modalService.create({
      nzTitle: this.translate.instant('SUBSCRIBE_TO_BEST_BID.MODAL_TITLE'),
      nzContent: SubscribeToBestBidModalComponent,
      nzComponentParams: {
        subscriptionCreated: this.subscriptionCreated,
      },
    });
  }

  private isSubscriptionActive(sub?: ServiceSubscription): boolean {
    if (!Boolean(sub)) {
      return false;
    }

    return DateTime.now() < DateTime.fromISO(sub.expiresAt);
  }
}
