<div class="content">
  <h3 nz-title>
    {{ 'BOUNDARY_LOTS' | translate }}
    <i *ngIf="queryRef?.loading | async" nz-icon [nzType]="'loading'"></i>
    <span *ngIf="(queryRef?.loading | async) === false">
      ({{ (queryRef?.data | async)?.boundaryLotsCount?.total }})
    </span>
  </h3>

  <section>
    <app-boundary-lots-filter [initFilter]="filter"></app-boundary-lots-filter>
  </section>

  <section>
    <app-lots-universal-table
      [customTitle]="getTitle((queryRef?.data | async)?.boundaryLotsCount?.total, (queryRef?.data | async)?.boundaryLots?.total)"
      [lots]="(queryRef?.data | async)?.boundaryLots?.nodes"
      [total]="(queryRef?.data | async)?.boundaryLots?.total"
      [loading]="queryRef?.loading | async"
      [page]="page"
      [pageSize]="pageSize"
    ></app-lots-universal-table>
  </section>
</div>
