import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NzAlertModule } from 'ng-zorro-antd/alert';

import { AlertService } from './alert.service';
import { AlertsListAutoComponent } from './components/alerts-list-auto/alerts-list-auto.component';
import { AlertsListManualComponent } from './components/alerts-list-manual/alerts-list-manual.component';

@NgModule({
  declarations: [AlertsListManualComponent, AlertsListAutoComponent],
  imports: [CommonModule, TranslateModule, NzAlertModule],
  providers: [AlertService],
  exports: [AlertsListManualComponent, AlertsListAutoComponent],
})
export class AlertsModule {}
