<div class="auc_list_shame" id="auc_list_shame_8">
  <img src="/assets/images/auctions/{{ lang }}/IAA_OSAKA.png" class="shame_img" alt="IAA_OSAKA">
  <div
    class="shame_blocks shame_block_1"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.LOT_NUM' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_2"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.FIRST_REG_DATE' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_3"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.CAR_OPTIONS' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_4"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.USE_TYPE' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_5"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.CHASSIS_NUM' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_6"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.ENGINE_CAPACITY' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_7"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.FUEL_TYPE.TITLE' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_8"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.GRADE' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_9"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.TECH_INSP' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_10"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.MILEAGE' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_11"
    nz-tooltip
    [nzTooltipTitle]="shameblock11"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  >
    <ng-template #shameblock11>
      <div class="header">
        {{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.IAA_OSAKA.ODOMETER_PROBLEMS.TITLE' | translate }}
      </div>
      <p>{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.IAA_OSAKA.ODOMETER_PROBLEMS.DESCR' | translate }}</p>
    </ng-template>
  </div>
  <div
    class="shame_blocks shame_block_12"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.VEHICLE_COLOR' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_13"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.COLOR_CODE' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_14"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.INTERIOR_COLOR' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_15"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.PLATE_NUM' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_16"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.SEATS' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_17"
    nz-tooltip
    [nzTooltipTitle]="shameblock17"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  >
    <ng-template #shameblock17>
      <div class="header">
        {{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.IAA_OSAKA.NOX.TITLE' | translate }}
      </div>
      <p>{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.IAA_OSAKA.NOX.DESCR' | translate }}</p>
    </ng-template>
  </div>
  <div
    class="shame_blocks shame_block_18"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.CARRYING_CAPACITY' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_19"
    nz-tooltip
    [nzTooltipTitle]="shameblock19"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  >
    <ng-template #shameblock19>
      <div class="header">
        {{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.IAA_OSAKA.SELLER_TYPE.TITLE' | translate }}
      </div>
      <p>{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.IAA_OSAKA.SELLER_TYPE.DESCR' | translate }}</p>
    </ng-template>
  </div>
  <div
    class="shame_blocks shame_block_20"
    nz-tooltip
    [nzTooltipTitle]="shameblock20"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  >
    <ng-template #shameblock20>
      <div class="header">
        {{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.STEERING_WHEEL' | translate }}
      </div>
      <p>{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.LEFT_RIGHT' | translate }}</p>
    </ng-template>
  </div>
  <div
    class="shame_blocks shame_block_21"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.MODEL_YEAR' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_22"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.RECYCLING_FEE' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_23"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.NEW_CAR_INSURANCE' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_24"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.JP_DOCS' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>

  <div
    class="shame_blocks shame_block_25"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.SHIFTER_LOCATION' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_26"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.TRANSMISSION' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_27"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.CONDITIONER' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_28"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.SELLER_NOTES' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div class="shame_blocks shame_block_29"></div>
  <div class="shame_blocks shame_block_30"></div>
  <div class="shame_blocks shame_block_31"></div>
  <div class="shame_blocks shame_block_32"></div>
  <div
    class="shame_blocks shame_block_33"
    nz-tooltip
    [nzTooltipTitle]="shameblock33"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  >
    <ng-template #shameblock33>
      <div class="header">
        {{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.PURCHASED_FROM_OWNER.TITLE' | translate }}
      </div>
      <p>{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.PURCHASED_FROM_OWNER.DESCR' | translate }}</p>
    </ng-template>
  </div>
  <div
    class="shame_blocks shame_block_34"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.EXTERIOR_GRADE' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_35"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.INTERIOR_GRADE' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_36"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.POWER_STEERING' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_37"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.POWER_WINDOWS' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_38"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.ALLOY_WHEELS' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_39"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.SUNROOF' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_40"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.LEATHER_INTERIOR' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_41"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.AIRBAG' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_42"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.TV' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_43"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.TV_ORIGINAL' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_44"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.ABS' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_45"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.TUNER' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div class="shame_blocks shame_block_46"></div>
  <div
    class="shame_blocks shame_block_47"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.SELLER_NOTES' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_48"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.INSPECTOR_NOTES' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_49"
    nz-tooltip
    [nzTooltipTitle]="shameblock49"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  >
    <ng-template #shameblock49>
      <div class="header">
        {{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.FW' | translate }}
      </div>
      <p>{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.WINDSHIELD' | translate }}</p>
    </ng-template>
  </div>
  <div
    class="shame_blocks shame_block_50"
    nz-tooltip
    [nzTooltipTitle]="shameblock50"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  >
    <ng-template #shameblock50>
      <div class="header">
        {{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.A_SCRATCHES' | translate }}
      </div>
      <p>{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.IAA_OSAKA.X_OPTIONS' | translate }}</p>
    </ng-template>
  </div>
  <div
    class="shame_blocks shame_block_51"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.INTERIOR_SEATS' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_52"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.IAA_OSAKA.CAR_DISADVANTAGES' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_53"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.STEERING_WHEEL_CONDITION' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_54"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.SEATS_CONDITION' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_55"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.AUDIO' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_56"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.WHEEL_COVERS' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_57"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.BODY_KIT' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_58"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.SIDE_MIRRORS' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_59"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.IAA_OSAKA.SCRATCHES_ABRASIONS' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_60"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.IAA_OSAKA.SCRATCHES_ABRASIONS' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_61"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.NOT_WORK_ABSENT' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_62"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.X_CRACKS_BROKEN' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_63"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.A_SCRATCHES_BROKEN' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_64"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.A_SCRATCHES_BROKEN' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_65"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.NOTES' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div class="shame_blocks shame_block_66"></div>
  <div
    class="shame_blocks shame_block_67"
    nz-tooltip
    [nzTooltipTitle]="shameblock67"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  >
    <ng-template #shameblock67>
      <div class="header">
        {{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.DAMAGE_SCHEME.TITLE' | translate }}
      </div>
      <p>
        {{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.DAMAGE_SCHEME.DESCR' | translate }}
        <a href="https://ecarjp.com/article/sheet" target="_blank">
          {{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.DAMAGE_SCHEME.LINK' | translate }}
        </a>
      </p>
    </ng-template>
  </div>
  <div
    class="shame_blocks shame_block_68"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.SPARE_WHEEL' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
</div>
