import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzIconModule } from 'ng-zorro-antd/icon';

import { DriverModule } from '../driver/driver.module';
import { LotAddPhotoButtonModule } from '../lot-add-photo-button/lot-add-photo-button.module';
import { LotBidsModule } from '../lot-bids/lot-bids.module';
import { LotCreateTranslationRequestButtonModule } from '../lot-create-translation-request-button/lot-create-translation-request-button.module';
import { LotEditAuctionSheetZonesButtonModule } from '../lot-edit-auction-sheet-zones-button/lot-edit-auction-sheet-zones-button.module';
import { LotEventsModalModule } from '../lot-events-modal/lot-events-modal.module';
import { LotGetPhotoRequestButtonModule } from '../lot-get-photo-request-button/lot-get-photo-request-button.module';
import { LotNotationsButtonModule } from '../lot-notations-button/lot-notations-button.module';
import { LotPlaceBidButtonModule } from '../lot-place-bid-button/lot-place-bid-button.module';
import { LotResetButtonModule } from '../lot-reset-button/lot-reset-button.module';
import { LotTranslateButtonModule } from '../lot-translate-button/lot-translate-button.module';
import { LotWatchRequestButtonModule } from '../lot-watch-request-button/lot-watch-request-button.module';
import { PurchaseReportFormModalModule } from '../purchase-report-form-modal/purchase-report-form-modal.module';
import { ShareButtonsModule } from '../share-buttons/share-buttons.module';
import { LotActionsComponent } from './lot-actions.component';

@NgModule({
  declarations: [LotActionsComponent],
  imports: [
    CommonModule,
    TranslateModule,
    DriverModule,
    LotPlaceBidButtonModule,
    ShareButtonsModule,
    LotTranslateButtonModule,
    LotAddPhotoButtonModule,
    LotBidsModule,
    LotEventsModalModule,
    LotResetButtonModule,
    PurchaseReportFormModalModule,
    LotCreateTranslationRequestButtonModule,
    LotNotationsButtonModule,
    LotGetPhotoRequestButtonModule,
    LotWatchRequestButtonModule,
    LotEditAuctionSheetZonesButtonModule,
    NzDrawerModule,
    NzButtonModule,
    NzIconModule,
  ],
  exports: [LotActionsComponent],
})
export class LotActionsModule {}
