<main>
  <section *ngIf="style === 'alert'">
    <nz-alert
      nzShowIcon
      [nzType]="redColorStatuses.includes(status) ? 'error' : 'info'"
      [nzMessage]="alertBody"
    >
      <ng-template #alertBody>
        <span *ngIf="status === BoundaryStatus.Early; else alertOtherStatuses">
          {{ 'BOUNDARY_INFO.' + status | translate: { date: earlyDate | date: 'yyyy-MM-dd' } }}
        </span>
      </ng-template>
      <ng-template #alertOtherStatuses>
        {{ 'BOUNDARY_INFO.' + status | translate: { date: overdueDate | date: 'yyyy-MM-dd' } }}
      </ng-template>
    </nz-alert>
  </section>
  <section *ngIf="style === 'text'">
    <span
      *ngIf="status === BoundaryStatus.Early; else otherStatuses"
      [ngClass]="{ red: redColorStatuses.includes(status) }"
    >
      {{ 'BOUNDARY_INFO.' + status | translate: { date: earlyDate | date: 'yyyy-MM-dd' } }}
    </span>
    <ng-template #otherStatuses>
      <span [ngClass]="{ red: redColorStatuses.includes(status) }">
        {{ 'BOUNDARY_INFO.' + status | translate: { date: overdueDate | date: 'yyyy-MM-dd' } }}
      </span>
    </ng-template>
  </section>
</main>
