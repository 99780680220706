<nz-collapse>
  <nz-collapse-panel [nzHeader]="'BODY_DEFECTS.TITLE' | translate" [nzActive]="active">
    <p translate>BODY_DEFECTS.A1</p>
    <p translate>BODY_DEFECTS.A2</p>
    <p translate>BODY_DEFECTS.A3</p>
    <p translate>BODY_DEFECTS.A4</p>
    <p translate>BODY_DEFECTS.A5</p>
    <p translate>BODY_DEFECTS.B</p>
    <p translate>BODY_DEFECTS.E1</p>
    <p translate>BODY_DEFECTS.E2</p>
    <p translate>BODY_DEFECTS.E3</p>
    <p translate>BODY_DEFECTS.U1</p>
    <p translate>BODY_DEFECTS.U2</p>
    <p translate>BODY_DEFECTS.U3</p>
    <p translate>BODY_DEFECTS.W1</p>
    <p translate>BODY_DEFECTS.W2</p>
    <p translate>BODY_DEFECTS.W3</p>
    <p translate>BODY_DEFECTS.S1</p>
    <p translate>BODY_DEFECTS.S2</p>
    <p translate>BODY_DEFECTS.C1</p>
    <p translate>BODY_DEFECTS.C2</p>
    <p translate>BODY_DEFECTS.P</p>
    <p translate>BODY_DEFECTS.H</p>
    <p translate>BODY_DEFECTS.X</p>
    <p translate>BODY_DEFECTS.XX</p>
    <p translate>BODY_DEFECTS.B1</p>
    <p translate>BODY_DEFECTS.B2</p>
    <p translate>BODY_DEFECTS.Y1</p>
    <p translate>BODY_DEFECTS.Y2</p>
    <p translate>BODY_DEFECTS.Y3</p>
    <p translate>BODY_DEFECTS.X1</p>
    <p translate>BODY_DEFECTS.R</p>
    <p translate>BODY_DEFECTS.RX</p>
    <p translate>BODY_DEFECTS.X_CORRECTION</p>
    <p translate>BODY_DEFECTS.G</p>
  </nz-collapse-panel>
</nz-collapse>
