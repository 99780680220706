import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  ViewChild,
} from '@angular/core';
import { map } from 'rxjs/operators';
import { AutoLotView } from 'src/app/const';
import { AuctionService } from 'src/app/services/auction.service';

import {
  AuctionSheetZoneFragment,
  AuctionSheetZoneTypeEnum,
  LotsQuery,
} from '../../graphql/service/graphql-auto-main-service';

@Component({
  selector: 'app-lot-vin-image',
  templateUrl: './lot-vin-image.component.html',
  styleUrls: ['./lot-vin-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotVinImageComponent implements AfterViewInit {
  @Input() lot: LotsQuery['lots']['nodes'][number] | AutoLotView;
  @Input() width: number;
  @Input() height: number;

  @ViewChild('canvas')
  private canvas: ElementRef<HTMLCanvasElement>;

  @HostBinding('style.display')
  display: 'inline-block' | 'none' = 'none';

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly auctionService: AuctionService,
  ) {}

  async ngAfterViewInit(): Promise<void> {
    this.display = 'none';

    if (!this.hasAuctionSheet) {
      return;
    }

    const canvas = this.canvas.nativeElement;
    const context = canvas.getContext('2d');
    const auctionSheetImage = await this.loadAuctionSheet();
    const vinZone = await this.getAuctionSheetVinZone();

    if (!vinZone) {
      return;
    }

    canvas.width = this.width;
    canvas.height = this.height;

    const { x, y, w, h } = vinZone;
    context.drawImage(auctionSheetImage, x, y, w, h, 0, 0, this.width, this.height);

    this.display = 'inline-block';
    this.cdr.markForCheck();
  }

  private get hasAuctionSheet(): boolean {
    return this.lot.images.length > 1;
  }

  private get auctionSheetUrl(): string {
    return this.lot.images[0];
  }

  private loadAuctionSheet(): Promise<HTMLImageElement> {
    return new Promise((resolve) => {
      const auctionSheetImage = new Image();
      auctionSheetImage.src = this.auctionSheetUrl;
      auctionSheetImage.onload = () => resolve(auctionSheetImage);
    });
  }

  private getAuctionSheetVinZone(): Promise<AuctionSheetZoneFragment | undefined> {
    return this.auctionService
      .getAuctionSheetZones(this.lot.auctionName)
      .pipe(map((zones) => zones.find((zone) => zone.zoneType === AuctionSheetZoneTypeEnum.Vin)))
      .toPromise();
  }
}
