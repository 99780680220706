import { QueryHandlingService } from '@akebono/core';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { from, Observable, of, Subject } from 'rxjs';
import { filter, first, map, switchMap, takeUntil, toArray } from 'rxjs/operators';
import { CurrentUserIsEcarjpBotConnectedGQL } from 'src/app/modules/graphql/service/graphql-shared-familiar-service';

import {
  NotificationSettingChannel,
  NotificationSettingName,
  UserNotificationSettingsFragment,
} from '../../../../../modules/graphql/service/graphql-auto-main-service';
import { ConnectTelegramService } from '../../../connect-telegram/connect-telegram.service';
import { NotificationSetting } from '../../types/notification-setting.type';
import { SettingsChanges } from '../../types/settings-changes.type';

@Component({
  selector: 'app-notification-settings',
  templateUrl: './notification-settings.component.html',
  styleUrls: ['./notification-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationSettingsComponent implements OnInit, OnChanges {
  @Input() loading = false;
  @Input() isEcarBotConnected = false;
  @Input() notificationSettings: UserNotificationSettingsFragment;
  @Output() settingsChange = new EventEmitter<SettingsChanges['notification']>();

  notificationSettings$: Observable<NotificationSetting[]> = of([]);
  notificationChannels: NotificationSettingChannel[] = [
    NotificationSettingChannel.Email,
    NotificationSettingChannel.Telegram,
  ];
  changes$ = new Subject<any>();

  private disabledNotifications = [{ channel: 'sms', name: 'AkebonoBidTranslationCompleted' }];

  NotificationSettingChannel = NotificationSettingChannel;

  private readonly destroy$ = new Subject<void>();

  constructor(
    private qhs: QueryHandlingService,
    private connectTelegramService: ConnectTelegramService,
    private currentUserIsEcarJpBotConnectedGQL: CurrentUserIsEcarjpBotConnectedGQL,
  ) {}

  ngOnInit(): void {
    this.changes$
      .pipe(
        switchMap((settings: NotificationSetting[]) =>
          from(settings).pipe(
            filter((setting) => setting.values.some((v) => v.value !== v.default)),
            map((setting) =>
              setting.values
                .filter((v) => v.value !== v.default)
                .map((v) => ({
                  name: setting.name,
                  value: v.value,
                  channel: v.channel,
                })),
            ),
            toArray(),
          ),
        ),
        map((inputs) => inputs.flat()),
        takeUntil(this.destroy$),
      )
      .subscribe((inputs) => this.settingsChange.emit(inputs));
  }

  openConnectTelegramModal(): void {
    this.connectTelegramService
      .openModal()
      .afterClose.pipe(first(), takeUntil(this.destroy$))
      .subscribe(() => this.qhs.refetchAll(this.currentUserIsEcarJpBotConnectedGQL.client));
  }

  ngOnChanges(): void {
    this.notificationSettings$ = of(this.notificationSettings).pipe(
      filter((data) => data?.notificationSettings),
      map((data) => data.notificationSettings),
      map((settings) =>
        Object.values(NotificationSettingName).map((settingName) => ({
          name: settingName,
          values: this.notificationChannels.sort().map((settingChannel) => ({
            channel: settingChannel,
            value: this.isDisabled(settingChannel, settingName)
              ? false
              : settings[settingChannel]?.[settingName] ?? true,
            default: settings[settingChannel]?.[settingName] ?? true,
            disabled: this.isDisabled(settingChannel, settingName),
          })),
        })),
      ),
    );
  }

  getTooltipTrigger(settingValue: NotificationSetting['values'][number]): 'hover' | null {
    if (settingValue.channel === NotificationSettingChannel.Telegram && settingValue.disabled) {
      return 'hover';
    } else {
      return null;
    }
  }

  private isDisabled(channel: NotificationSettingChannel, name: string): boolean {
    const disabled = !!this.disabledNotifications.find(
      (n) => n.channel === channel && n.name === name,
    );
    const telegramForceDisabled =
      channel === NotificationSettingChannel.Telegram ? !this.isEcarBotConnected : false;

    return disabled || telegramForceDisabled;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
