<div data-auction="LAA" class="auc_list_shame" id="auc_list_shame_5">
  <img src="/assets/images/auctions/{{ lang }}/LAA.png" class="shame_img" alt="LAA">
  <div
    class="shame_blocks shame_block_1"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.LOT_NUM' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_2"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.FIRST_REG_DATE' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_3"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.CAR_NAME' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_4"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.DOORS' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_5"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.CHASSIS_TYPE' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_6"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.MODEL_VERSION' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_7"
    nz-tooltip
    [nzTooltipTitle]="shameblock7"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  >
    <ng-template #shameblock7>
      <div class="header">
        {{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.USE_TYPE' | translate }}
      </div>
      <p>{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.PERSONAL_RENT' | translate }}</p>
    </ng-template>
  </div>
  <div
    class="shame_blocks shame_block_8"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.CHASSIS' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_9"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.ENGINE_CAPACITY' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_10"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.SEATS' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_11"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.GRADE' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_12"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.TECH_INSP' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_13"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.MILEAGE' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_14"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.VEHICLE_COLOR' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_15"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.SHIFTER_LOCATION' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_16"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.TRANSMISSION' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_17"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.CONDITIONER' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_18"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.FUEL_TYPE.TITLE' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_19"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.INTERIOR_COLOR' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_20"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.COLOR_CODE' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_21"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.MODEL_YEAR' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_22"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.IMPORT_TYPE' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_23"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.STEERING_WHEEL' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_24"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.LAA.DEALER_INSURANCE' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_25"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.LAA.OWNERS_MANUAL' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_26"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.RECYCLING_FEE' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_27"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.SELLER_NOTES' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_28"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.INSPECTOR_NOTES' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_29"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.WINDSHIELD' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_30"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.SCRATCHES_NEED_REPLACE' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div class="shame_blocks shame_block_31"></div>
  <div
    class="shame_blocks shame_block_32"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.INTERIOR_SEATS' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_33"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.LAA.INTERIOR_OPTIONS' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_34"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.NOTES' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_35"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.LENGTH' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_36"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.WIDTH' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_37"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.HEIGHT' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_38"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.CARRYING_CAPACITY' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_39"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.SELLER_NOTES' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_40"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.EXTERIOR_GRADE' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_41"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.INTERIOR_GRADE' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_42"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.POWER_STEERING' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_43"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.POWER_WINDOWS' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_44"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.ALLOY_WHEELS' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_45"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.SUNROOF' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_46"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.LEATHER_INTERIOR' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_47"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.AIRBAG' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_48"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.TV' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_49"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.GPS' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_50"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.MARKED_AVAILABLE' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_51"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.RE_REG_DEADLINE.TITLE' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_52"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.MONTH_DAY' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_53"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.CHASSIS_NUM' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_54"
    nz-tooltip
    [nzTooltipTitle]="shameblock54"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  >
    <ng-template #shameblock54>
      <div class="header">
        {{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.DAMAGE_SCHEME.TITLE' | translate }}
      </div>
      <p>
        {{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.DAMAGE_SCHEME.DESCR' | translate }}
        <a href="https://ecarjp.com/article/sheet" target="_blank">
          {{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.DAMAGE_SCHEME.LINK' | translate }}
        </a>
      </p>
    </ng-template>
  </div>
  <div
    class="shame_blocks shame_block_55"
    nz-tooltip
    [nzTooltipTitle]="shameblock55"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  >
    <ng-template #shameblock55>
      <div class="header">
        {{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.LAA.KEYS_OFFICE.TITLE' | translate }}
      </div>
      <p>{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.LAA.KEYS_OFFICE.DESCR' | translate }}</p>
    </ng-template>
  </div>
</div>
