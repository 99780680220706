import { TypeEnum } from '@akebono/calculator';
import { AuthenticationService, ConfigService, MutationHandlingServiceV2 } from '@akebono/core';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, combineLatest, iif, Observable, of, Subject } from 'rxjs';
import { map, startWith, switchMap, tap } from 'rxjs/operators';
import { getCalcData, mergeSavedLotToAutoLotView, ReactiveSharedLotData } from 'src/app/const';
import { LotTypeEnum } from 'src/app/modules/graphql/service/graphql-auto-main-service';
import {
  UserLotMarkGQL,
  UserLotMarkUpdateGQL,
} from 'src/app/modules/graphql/service/graphql-auto-shared-service';

import { BoundaryStatus } from "src/app/const";

@Component({
  selector: 'app-shared-lot',
  templateUrl: './shared-lot.component.html',
  styleUrls: ['./shared-lot.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedLotComponent implements OnInit {
  data$: ReactiveSharedLotData = of({});
  loading$ = new BehaviorSubject<boolean>(true);
  refetch$ = new Subject<void>();
  activeSlide: number = 1;
  BoundaryStatus = BoundaryStatus;
  updateMark$: Observable<boolean>;

  constructor(
    private title: Title,
    config: ConfigService,
    private route: ActivatedRoute,
    private authService: AuthenticationService,
    private mhs: MutationHandlingServiceV2,
    private userLotMarkGQL: UserLotMarkGQL,
    private userLotMarkUpdateGQL: UserLotMarkUpdateGQL,
  ) {
    if (!config.isLanguageSelected) {
      const language = config.defaultAppLanguageByBrowser;
      config.setCurrentLanguage(language);
    }
  }

  ngOnInit(): void {
    this.updateMark$ = this.mhs.mutate(
      this.userLotMarkUpdateGQL,
      {
        input: {
          id: this.route.snapshot.params?.id,
        },
      },
      {
        renderSuccess: false,
      },
    ).loading;

    this.data$ = combineLatest([
      this.route.params,
      this.refetch$.asObservable().pipe(startWith(true)),
    ]).pipe(
      tap(() => this.loading$.next(true)),
      map(([params]) => ({
        lotId: params.id,
        lotType: LotTypeEnum.Auto,
        calcTypes: [TypeEnum.Automobile, TypeEnum.ElectroAuto, TypeEnum.Parts, TypeEnum.Hybrid],
      })),
      switchMap((initData) =>
        combineLatest([
          this.userLotMarkGQL.fetch({ id: initData.lotId || 0 }, { fetchPolicy: 'network-only' }),
          this.authService.checkPermissions(['cars.lots']),
        ]).pipe(
          map(([lotResult, hasLotsPermission]) => ({
            initData,
            lotResult,
            hasLotsPermission,
          })),
        ),
      ),
      switchMap((data) =>
        iif(
          () => Boolean(data.lotResult.data.userLotMark),
          of(data).pipe(
            map((data) => ({
              ...data,
              lot: mergeSavedLotToAutoLotView(null, data.lotResult.data.userLotMark.lot as any),
            })),
            map((data) => ({
              ...data.initData,
              hasLotsPermission: data.hasLotsPermission,
              fullLotLink: data.lotResult?.data?.userLotMark?.lot?.link,
              lot: data.lot,
              icons: data.lotResult.data.userLotMark.lot.icons ?? [],
              translations: data.lotResult.data.userLotMark.lot.translations,
              fullVin: Boolean(data.lotResult.data?.userLotMark.lot?.vin),
              calcData: getCalcData(data.lot, null),
            })),
            tap((data) => this.title.setTitle(`ECar JP Lot #${data.lot.bid}`)),
          ),
          of(data.initData),
        ),
      ),
      tap(() => this.loading$.next(false)),
    );
  }

  goToFirstSlide(isUsingSavedImages: boolean): void {
    if (!isUsingSavedImages) {
      this.activeSlide = 1;
    }
  }
}
