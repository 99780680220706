<ng-container *ngIf="(isGuide$ | async) === false">
  <ng-container *ngIf="isManager">
    <nz-spin [nzSpinning]="loading$ | async">
      <section nz-row [nzGutter]="[5, 5]">
        <ng-container *ngFor="let group of icons.controls; index as i">
          <div
            nz-col
            [nzXXl]="4"
            [nzXl]="6"
            [nzLg]="6"
            [nzMd]="6"
            [nzSm]="6"
            [nzXs]="12"
          >
            <app-lot-icon
              [icon]="group.value.icon"
              [clickable]="true"
              [enabled]="group.value.enabled"
              (enabledChange)="group.controls.enabled.setValue($event)"
            ></app-lot-icon>
          </div>
        </ng-container>
      </section>
    </nz-spin>
  </ng-container>

  <section nz-row *ngIf="!isManager" [nzGutter]="[5, 5]">
    <ng-container *ngFor="let icon of lotIcons">
      <div
        nz-col
        [nzXXl]="4"
        [nzXl]="6"
        [nzLg]="6"
        [nzMd]="6"
        [nzSm]="6"
        [nzXs]="12"
        *ngIf="showIconByRole(icon)"
      >
        <app-lot-icon [icon]="icon" [enabled]="true" [clickable]="false"></app-lot-icon>
      </div>
    </ng-container>
  </section>
</ng-container>

<section nz-row class="lot-icons-guide" *ngIf="isGuide$ | async" [nzGutter]="[5, 5]">
  <ng-container *ngFor="let icon of guideIcons">
    <div nz-col [nzXXl]="4" [nzXl]="6" [nzLg]="6" [nzMd]="6" [nzSm]="6" [nzXs]="12">
      <app-lot-icon nz-col [icon]="icon" [enabled]="true" [clickable]="false"></app-lot-icon>
    </div>
  </ng-container>
</section>
