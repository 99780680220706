import { AkebonoCoreModule } from '@akebono/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';

import { LotTranslationsComponent } from './lot-translations.component';
import { AudioTranslationSpeedService } from './services/audio-translation-speed.service';

@NgModule({
  declarations: [LotTranslationsComponent],
  imports: [
    CommonModule,
    BrowserModule,
    TranslateModule,
    BrowserAnimationsModule,
    NzCollapseModule,
    NzAlertModule,
    AkebonoCoreModule,
  ],
  providers: [AudioTranslationSpeedService],
  exports: [LotTranslationsComponent],
})
export class LotTranslationsModule {}
