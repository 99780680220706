import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-filter-arai-kenki-lots',
  templateUrl: './filter-arai-kenki-lots.component.html',
  styleUrls: ['./filter-arai-kenki-lots.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterAraiKenkiLotsComponent {
  items = [
    {
      img: '/assets/img/kenki/icon_shovel.png',
      label: 'EXCAVATOR',
      value: 'Excavator',
      models: [
        {
          label: 'MINI_EXCAVATOR',
          value: 'Mini excavator',
        },
      ],
    },
    {
      img: '/assets/img/kenki/icon_wheel.png',
      label: 'WHEEL_LOADER',
      value: 'Wheel loader',
      models: [
        {
          label: 'MINI_WHEEL_LOADER',
          value: 'Mini wheel loader',
        },
      ],
    },
    {
      img: '/assets/img/kenki/icon_skid.png',
      label: 'SKID_STEER_LOADER',
      value: 'Skid steer loader',
      models: [],
    },
    {
      img: '/assets/img/kenki/icon_grader.png',
      label: 'GRADER',
      value: 'Grader',
      models: [],
    },
    {
      img: '/assets/img/kenki/icon_finisher.png',
      label: 'ASPHALT_FINISHER',
      value: 'Asphalt finisher',
      models: [],
    },
    {
      img: '/assets/img/kenki/icon_crane.png',
      label: 'CRANE',
      value: 'Crane',
      models: [
        {
          label: 'ROUGH_TERRAIN_CRANE',
          value: 'Rough terrain crane',
        },
        {
          label: 'TRUCK_CRANE',
          value: 'Truck crane',
        },
        {
          label: 'ALL_TERRAIN_CRANE',
          value: 'All terrain crane',
        },
        {
          label: 'AERIAL_WORK_PLATFORM',
          value: 'Aerial work platform',
        },
        {
          label: 'CRAWLER_CRANE',
          value: 'Crawler crane',
        },
        {
          label: 'MINI_CRAWLER_CRANE',
          value: 'Mini crawler crane',
        },
      ],
    },
    {
      img: '/assets/img/kenki/icon_compressor.png',
      label: 'COMPRESSOR',
      value: 'Compressor',
      models: [],
    },
    {
      img: '/assets/img/kenki/icon_crasher.png',
      label: 'MOBILE_CRUSHER',
      value: 'Mobile crusher',
      models: [],
    },
    {
      img: '/assets/img/kenki/icon_attachment.png',
      label: 'ATTACHMENTS',
      value: 'Attachments',
      models: [
        {
          label: 'ATTACHMENTS_ETC',
          value: 'Attachments etc',
        },
        {
          label: 'BLADE',
          value: 'Blade',
        },
        {
          label: 'BUCKET',
          value: 'Bucket',
        },
        {
          label: 'BREAKER',
          value: 'Breaker',
        },
        {
          label: 'CUTTER',
          value: 'Cutter',
        },
        {
          label: 'CRUSHER',
          value: 'Crusher',
        },
        {
          label: 'CONCRETE_CUTTER',
          value: 'Concrete cutter',
        },
        {
          label: 'CONSTRUCTION_MISCELLANEOUS',
          value: 'Construction miscellaneous',
        },
        {
          label: 'VIBRO',
          value: 'Vibro',
        },
        {
          label: 'VIBRO_HAMMER',
          value: 'Vibro hammer',
        },
        {
          label: 'FORK',
          value: 'Fork',
        },
        {
          label: 'FORKLIFT_NAIL',
          value: 'Forklift nail',
        },
        {
          label: 'FLOODLIGHT',
          value: 'Floodlight',
        },
        {
          label: 'MAGNET',
          value: 'Magnet',
        },
        {
          label: 'PLATE',
          value: 'Plate',
        },
        {
          label: 'RAMMER',
          value: 'Rammer',
        },
      ],
    },
    {
      img: '/assets/img/kenki/icon_bulldozer.png',
      label: 'BULLDOZER',
      value: 'Bulldozer',
      models: [],
    },
    {
      img: '/assets/img/kenki/icon_shovelrorder.png',
      label: 'SHOVEL_LOADER',
      value: 'Shovel loader',
      models: [],
    },
    {
      img: '/assets/img/kenki/icon_forklist.png',
      label: 'FORKLIFT',
      value: 'Forklift',
      models: [
        {
          label: 'BATTERY_FORKLIFT',
          value: 'Battery forklift',
        },
        {
          label: 'REACH_FORKLIFT',
          value: 'Reach forklift',
        },
        {
          label: 'RICE_TRANSPLANTER',
          value: 'Rice transplanter',
        },
      ],
    },
    {
      img: '/assets/img/kenki/icon_roller.png',
      label: 'ROLLER',
      value: 'Roller',
      models: [
        {
          label: 'COMBINE_ROLLER',
          value: 'Combined roller',
        },
        {
          label: 'SOIL_ROLLER',
          value: 'Soil roller',
        },
        {
          label: 'TIRE_ROLLER',
          value: 'Tire roller',
        },
        {
          label: 'TANDEM_ROLLER',
          value: 'Tandem roller',
        },
        {
          label: 'HAND_GUIDED_ROLLER',
          value: 'Hand guided roller',
        },
        {
          label: 'MACADAM_ROLLER',
          value: 'Macadam roller',
        },
      ],
    },
    {
      img: '/assets/img/kenki/icon_crawler.png',
      label: 'CRAWLER_DUMP',
      value: 'Crawler dump',
      models: [
        {
          label: 'CRAWLER_CARRIER',
          value: 'Crawler carrier',
        },
        {
          label: 'CRAWLER_TRUCK',
          value: 'Crawler truck',
        },
        {
          label: 'WHEEL_CARRIER',
          value: 'Wheel carrier',
        },
      ],
    },
    {
      img: '/assets/img/kenki/icon_high.png',
      label: 'AERIAL_WORK_PLATFORM',
      value: 'Aerial work platform',
      models: [],
    },
    {
      img: '/assets/img/kenki/icon_generator.png',
      label: 'GENERATOR',
      value: 'Generator',
      models: [
        {
          label: 'WELDER_GENERATOR',
          value: 'Welder generator',
        },
      ],
    },
    {
      img: '/assets/img/kenki/icon_other.png',
      label: 'AGRICULTURAL_MACHINE_ETC',
      value: 'Agricultural machine etc',
      models: [
        {
          label: 'CONSTRUCTION_MACHINE_ETC',
          value: 'Construction machine etc',
        },
        {
          label: 'OFFROAD_DUMP_TRUCK',
          value: 'Offroad dump truck',
        },
        {
          label: 'SNOW_REMOVING_MACHINE',
          value: 'Snow removing machine',
        },
        {
          label: 'STABILIZER',
          value: 'Stabilizer',
        },
        {
          label: 'SPECIAL_PURPOSE_VEHICLE',
          value: 'Special purpose vehicle',
        },
        {
          label: 'ROAD_CUTTER',
          value: 'Road cutter',
        },
        {
          label: 'TURRET_TRUCK',
          value: 'Turret truck',
        },
        {
          label: 'LOGGING_TRACTOR',
          value: 'Logging tractor',
        },
        {
          label: 'TOWING_TRACTOR',
          value: 'Towing tractor',
        },
      ],
    },
    {
      img: '/assets/img/kenki/icon_peripheral.png',
      label: 'SMALL_MACHINES',
      value: 'Small machines',
      models: [
        {
          label: 'CONSTRUCTION_MISCELLANEOUS',
          value: 'Construction miscellaneous',
        },
        {
          label: 'CONCRETE_CUTTER',
          value: 'Concrete cutter',
        },
        {
          label: 'FLOODLIGHT',
          value: 'Floodlight',
        },
        {
          label: 'PLATE',
          value: 'Plate',
        },
        {
          label: 'RAMMER',
          value: 'Rammer',
        },
      ],
    },
    {
      img: '/assets/img/kenki/noki_icon_tractor.png',
      label: 'TRACTOR',
      value: 'Tractor',
      models: [],
    },
    {
      img: '/assets/img/kenki/noki_icon_combine.png',
      label: 'COMBINE',
      value: 'Combine',
      models: [],
    },
    {
      img: '/assets/img/kenki/noki_icon_transplanter.png',
      label: 'RICE_TRANSPLANTER',
      value: 'Rice transplanter',
      models: [],
    },
    {
      img: '/assets/img/kenki/noki_icon_cultivator.png',
      label: 'AGRICULTURAL_MACHINE_ETC',
      value: 'Agricultural machine etc',
      models: [],
    },
    {
      img: '/assets/img/kenki/noki_icon_worker.png',
      label: 'AGRICULTURAL_MACHINE_ATTACHMENT',
      value: 'Agricultural machine attachment',
      models: [],
    },
  ];

  constructor(private router: Router) {}

  addModel(model: string) {
    this.router.navigate(['manual-lots'], {
      queryParams: {
        r: Math.floor(Math.random() * 100),
        model: model.charAt(0).toUpperCase() + model.slice(1).toLowerCase(),
      },
      queryParamsHandling: 'merge',
    });
  }
}
