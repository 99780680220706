<nz-content class="wrap">
  <div class="content">
    <nz-divider></nz-divider>
    <h3 nz-title>{{ (queryRef.data | async)?.lotMarksList?.title }}</h3>
    <nz-divider></nz-divider>

    <nz-table
      #marks
      nzSize="small"
      nzShowPagination
      nzShowSizeChanger
      [nzBordered]="true"
      nzPaginationPosition="both"
      [nzLoading]="queryRef.loading | async"
      [nzPageSizeOptions]="[10, 20, 30, 40, 50]"
      [nzData]="(queryRef.data | async)?.lotMarksList?.lotMarks"
    >
      <thead>
        <tr>
          <th nzAlign="center" rowspan="2">{{ 'PHOTO' | translate }}</th>
          <th nzAlign="center" rowspan="2">{{ 'LOT' | translate }}</th>
          <th nzAlign="center">{{ 'LOT_INFO.AUCTION' | translate }}</th>
          <th nzAlign="center">{{ 'LOT_INFO.ENGINE_CC' | translate }}</th>
          <th nzAlign="center">{{ 'LOT_INFO.START_PRICE' | translate }}</th>
          <th nzAlign="center">{{ 'LOT_INFO.YEAR' | translate }}</th>
          <th nzAlign="center">{{ 'LOT_INFO.MILEAGE' | translate }}</th>
          <th nzAlign="center" rowspan="2">{{ 'STATUS' | translate }}</th>
        </tr>
        <tr>
          <th nzAlign="center">{{ 'LOT_INFO.AUCTION_DATE' | translate }}</th>
          <th nzAlign="center">{{ 'LOT_INFO.COLOR' | translate }}</th>
          <th nzAlign="center">{{ 'LOT_INFO.SOLD_FOR' | translate }}</th>
          <th nzAlign="center">{{ 'LOT_INFO.CHASSIS_ID' | translate }}</th>
          <th nzAlign="center">{{ 'LOT_INFO.SCORE' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let mark of marks.data">
          <td  nzAlign="center" >
            <app-lot-mini-gallery [lot]="mark.lot"></app-lot-mini-gallery>
          </td>
          <td nzAlign="center" >
            <a
              nzSize="small"
              target="_blank"
              class="lot-button"
              nz-button
              [routerLink]="['/shared', mark.id]"
              nzType="primary"
            >
              {{ mark.lot.code }}
            </a>
          </td>
          <td nzAlign="center" >
            {{ mark.lot.auctionName }} <br />
            {{ mark.lot.auctionDate | date: 'yyyy-MM-dd H:mm:ss zz':'+0900' }}
          </td>
          <td nzAlign="center" >
            <span nz-text nzType="danger">{{ mark.lot.transmissionEn }}</span>
            {{ mark.lot.engineVolumeNum }} cc<br />
            <span nz-text nzType="secondary">{{ mark.lot.colorEn }}</span>
          </td>
          <td nzAlign="center" >
            {{ mark.lot.startPriceNum | currency: 'JPY':'symbol-narrow' }}
            <br />
            {{ mark.lot.endPriceNum | currency: 'JPY':'symbol-narrow' }}
          </td>
          <td nzAlign="center" >
            <span style="color: #6db3eb">{{ mark.lot.companyEn }} {{ mark.lot.modelEn }}</span>
            <br />
            <span nz-text nzType="danger">{{ mark.lot.year }}</span> {{ mark.lot.modelTypeEn }}
            <br />
            <span nz-text nzType="secondary">{{ mark.lot.modelGradeEn }}</span>
          </td>
          <td nzAlign="center" >
            {{ mark.lot.mileageNum }}000km <br />
            <span nz-text nzType="warning">{{ mark.lot.ratingEn }}</span>
          </td>
          <td nzAlign="center" >
            <span nz-text nzType="warning">
              {{ 'LOT_STATUSES.' + mark.lot.status | uppercase | translate }}
            </span>
          </td>
        </tr>
      </tbody>
    </nz-table>

    <nz-divider></nz-divider>
  </div>
</nz-content>
