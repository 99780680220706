<nz-collapse [nzBordered]="false">
  <nz-collapse-panel
    [nzHeader]="'FILTERS' | translate"
    [(nzActive)]="isActiveFilters"
    class="mobile-scrollable"
    [nzExtra]="startGuideButtonTemplate"
    appDriverGuide
  >
    <ng-template #startGuideButtonTemplate>
      <button
        *ngIf="!loadingFilters"
        nz-button
        nzType="primary"
        nzShape="circle"
        (click)="startGuide($event)"
      >
        <i nz-icon nzType="question" nzTheme="outline"></i>
      </button>
    </ng-template>
    <div *ngIf="outdated" style="color: red">{{ 'FILTER_DATA_OUTDATED' | translate }}</div>
    <nz-skeleton *ngIf="loadingFilters" [nzActive]="true"></nz-skeleton>
    <div
      nz-row
      *ngIf="!loadingFilters"
      nzJustify="center"
      nzAlign="middle"
      style="padding-bottom: 10px"
    >
      <app-user-filter-presets
        appDriverStep
        [overrideStepIndex]="14"
        [stepTitle]="'AUTO_FILTER_GUIDE.14.TITLE' | translate"
        [stepDescription]="'AUTO_FILTER_GUIDE.14.TEXT' | translate"
        [popoverClass]="'auto-lots-filter'"
        [presetContent]="presetContent"
        (presetSelected)="applyFilterPreset($event)"
      ></app-user-filter-presets>
    </div>
    <div
      *ngIf="!loadingFilters"
      valign="top"
      class="aj_tglob"
      style="padding: 0 12px; max-height: 80%"
    >
      <form
        id="poisk"
        class="poisk"
        style="margin: 0"
        method="post"
        [formGroup]="form"
        nz-row
        nzJustify="center"
        nzAlign="center"
      >
        <table border="0" cellpadding="0" cellspacing="0" class="sd_srh_tbl">
          <tbody>
            <tr>
              <td valign="top" style="width: 40px; padding: 0 7px 0 0" align="right">
                <table
                  border="0"
                  cellpadding="0"
                  cellspacing="0"
                  appDriverStep
                  [overrideStepIndex]="0"
                  [stepTitle]="'AUTO_FILTER_GUIDE.0.TITLE' | translate"
                  [stepDescription]="'AUTO_FILTER_GUIDE.0.TEXT' | translate"
                  [popoverClass]="'auto-lots-filter'"
                  [showButtons]="['next']"
                >
                  <tbody>
                    <tr>
                      <td height="14px"></td>
                      <td></td>
                    </tr>
                    <tr *ngFor="let date of dateRange" style="margin-bottom: 5px">
                      <td
                        valign="bottom"
                        align="right"
                        style="
                          color: #666;
                          font-size: 10px;
                          font-family: Tahoma;
                          padding: 0 3px 1px 0;
                          height: 18px;
                        "
                      >
                        {{ date.date | date: 'EEE' | translate }}
                      </td>
                      <td valign="bottom">
                        <input
                          class="ch"
                          style="margin: -1px"
                          type="checkbox"
                          [alt]="date.date"
                          [ngModel]="date.checked"
                          [ngModelOptions]="{ standalone: true }"
                          (ngModelChange)="selectAuctionDate($event, date)"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td valign="top">
                <table
                  border="0"
                  class="bg_tbl"
                  height="151px"
                  style="border-top: solid 1px #cccccd; background-color: #e8e8e8"
                  cellpadding="0"
                  cellspacing="0"
                >
                  <colgroup>
                    <col width="147px" />
                    <col width="194px" />
                    <col width="109px" />
                    <col width="124px" />
                    <col width="68px" />
                    <col width="145px" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <td
                        class="bg_sch"
                        valign="top"
                        style="
                          min-width: 147px;
                          max-width: 147px;
                          border-left: solid 1px #cccccd;
                          padding-top: 1px;
                          padding-left: 4px;
                        "
                        appDriverStep
                        [overrideStepIndex]="1"
                        [stepTitle]="'AUTO_FILTER_GUIDE.1.TITLE' | translate"
                        [stepDescription]="'AUTO_FILTER_GUIDE.1.TEXT' | translate"
                        [popoverClass]="'auto-lots-filter'"
                      >
                        <span style="font-size: 2px">&nbsp;</span>{{ 'Производитель' | translate
                        }}<br />
                        <div class="sl">
                          <select
                            class="sel"
                            size="8"
                            style="height: 138px; font-size: 14px; font-family: Arial; width: 148px"
                            formControlName="company"
                          >
                            <option
                              value="any"
                              style="
                                color: rgb(102, 102, 102);
                                background-color: rgb(238, 238, 238);
                              "
                            >
                              {{ 'ANY_M' | translate }}
                            </option>
                            <option *ngFor="let item of mainCompaniesList" [value]="item.companyId">
                              {{ item.name }}
                            </option>
                            <option
                              *ngFor="let item of otherCompaniesList"
                              [value]="item.companyId"
                            >
                              {{ item.name }}
                            </option>
                          </select>
                        </div>
                      </td>
                      <td
                        class="bg_sch"
                        valign="top"
                        style="
                          min-width: 194px;
                          max-width: 194px;
                          padding-top: 1px;
                          padding-left: 4px;
                        "
                        appDriverStep
                        [overrideStepIndex]="2"
                        [stepTitle]="'AUTO_FILTER_GUIDE.2.TITLE' | translate"
                        [stepDescription]="'AUTO_FILTER_GUIDE.2.TEXT' | translate"
                        [popoverClass]="'auto-lots-filter'"
                      >
                        <span style="font-size: 2px">&nbsp;</span>{{ 'Модель' | translate }}<br />
                        <div class="sl">
                          <select
                            class="sel"
                            size="8"
                            style="height: 138px; font-size: 14px; font-family: Arial; width: 195px"
                            formControlName="model"
                          >
                            <option
                              value="any"
                              style="
                                color: rgb(102, 102, 102);
                                background-color: rgb(238, 238, 238);
                              "
                            >
                              {{ 'ANY' | translate }}
                            </option>
                            <ng-container *ngFor="let model of modelsList">
                              <option
                                *ngIf="
                                  !form.controls.company.value ||
                                  form.controls.company.value === 'any' ||
                                  model.companyRef == form.controls.company.value
                                "
                                [value]="model.modelId"
                              >
                                {{ model.name }} ({{ model.autoLotsTotal }})
                              </option>
                            </ng-container>
                          </select>
                        </div>
                      </td>
                      <td
                        class="bg_sch"
                        valign="top"
                        style="
                          min-width: 109px;
                          max-width: 109px;
                          padding-top: 1px;
                          padding-left: 4px;
                        "
                        appDriverStep
                        [overrideStepIndex]="3"
                        [stepTitle]="'AUTO_FILTER_GUIDE.3.TITLE' | translate"
                        [stepDescription]="'AUTO_FILTER_GUIDE.3.TEXT' | translate"
                        [popoverClass]="'auto-lots-filter'"
                      >
                        <span
                          ><span style="font-size: 2px">&nbsp;</span>{{ 'Кузов' | translate }}</span
                        ><br />
                        <nz-spin [nzSpinning]="loadingFrames">
                          <div class="sl" id="div_kuzov2">
                            <select
                              class="sel"
                              size="8"
                              id="kuzov"
                              formControlName="inclusionFrame"
                              type="text"
                              multiple="multiple"
                              style="font-size: 14px; font-family: Arial; width: 110px"
                            >
                              <option
                                value="any"
                                style="
                                  color: rgb(102, 102, 102);
                                  background-color: rgb(238, 238, 238);
                                "
                              >
                                {{ 'ANY_M' | translate }}
                              </option>
                              <option
                                *ngFor="let option of filtersData.modelTypeEnOptions"
                                [value]="option.value"
                                innerHTML="{{ option.value }} ({{ option.count }})"
                              ></option>
                            </select>
                          </div>
                        </nz-spin>
                      </td>
                      <td
                        class="bg_sch"
                        valign="top"
                        style="
                          min-width: 124px;
                          max-width: 124px;
                          padding-top: 1px;
                          padding-left: 4px;
                        "
                        appDriverStep
                        [overrideStepIndex]="4"
                        [stepTitle]="'AUTO_FILTER_GUIDE.4.TITLE' | translate"
                        [stepDescription]="'AUTO_FILTER_GUIDE.4.TEXT' | translate"
                        [popoverClass]="'auto-lots-filter'"
                      >
                        <span>
                          <span style="font-size: 2px">&nbsp;</span
                          >{{ 'Аукцион' | translate }}&nbsp;<span
                            style="color: #999; font-size: 11px"
                            alt="удерживайте CTRL для выбора нескольких аукционов"
                            >[?]</span
                          > </span
                        ><br />
                        <nz-spin [nzSpinning]="loadingFrames">
                          <div class="sl" id="div_auct_name">
                            <select
                              class="sel"
                              size="8"
                              formControlName="inclusionAuction"
                              type="text"
                              multiple="multiple"
                              style="font-size: 14px; font-family: Arial; width: 125px"
                            >
                              <option style="background: #eee; color: #666" value="any">
                                {{ 'ANY_M' | translate }}
                              </option>
                              <option
                                *ngFor="let option of auctRefOptions"
                                [value]="option.id"
                                [innerHTML]="option.name"
                              ></option>
                            </select>
                          </div>
                        </nz-spin>
                      </td>
                      <td
                        class="bg_sch"
                        valign="top"
                        style="
                          min-width: 68px;
                          max-width: 68px;
                          padding-top: 1px;
                          padding-left: 4px;
                        "
                        appDriverStep
                        [overrideStepIndex]="5"
                        [stepTitle]="'AUTO_FILTER_GUIDE.5.TITLE' | translate"
                        [stepDescription]="'AUTO_FILTER_GUIDE.5.TEXT' | translate"
                        [popoverClass]="'auto-lots-filter'"
                      >
                        <span translate>Оценка</span><br />
                        <nz-spin [nzSpinning]="loadingFrames">
                          <div class="sl" id="div_rate">
                            <select
                              class="sel"
                              size="8"
                              formControlName="inclusionScore"
                              type="text"
                              multiple="multiple"
                              style="font-size: 14px; font-family: Arial; width: 68px"
                            >
                              <option style="background: #eee; color: #666" value="any" selected="">
                                {{ 'ANY' | translate }}
                              </option>
                              <option
                                *ngFor="let option of filtersData.scoresEnOptions.slice().reverse()"
                                [value]="option.scoresEn"
                              >
                                {{ option.scoresEn }}
                              </option>
                            </select>
                          </div>
                        </nz-spin>
                      </td>
                      <div
                        appDriverStep
                        [overrideStepIndex]="6"
                        [stepTitle]="'AUTO_FILTER_GUIDE.6.TITLE' | translate"
                        [stepDescription]="'AUTO_FILTER_GUIDE.6.TEXT' | translate"
                        [popoverClass]="'auto-lots-filter'"
                      >
                        <td
                          class="bg_sch"
                          valign="top"
                          style="
                            min-width: 145px;
                            max-width: 145px;
                            padding-top: 1px;
                            padding-left: 4px;
                          "
                        >
                          <span translate>Цвет</span><br />
                          <nz-spin [nzSpinning]="loadingFrames">
                            <div class="sl">
                              <select
                                class="sel"
                                size="8"
                                formControlName="inclusionColor"
                                type="text"
                                multiple="multiple"
                                style="font-size: 14px; font-family: Arial; width: 145px"
                              >
                                <option
                                  style="background: #eee; color: #666"
                                  value="any"
                                  selected=""
                                >
                                  {{ 'ANY_M' | translate }}
                                </option>
                                <option
                                  *ngFor="let option of filtersData.colorEnOptions"
                                  [value]="option.colorEn"
                                >
                                  {{ option.colorEn | uppercase }}
                                </option>
                              </select>
                            </div>
                          </nz-spin>
                        </td>
                        <td
                          class="bg_sch"
                          valign="top"
                          style="
                            min-width: 68px;
                            max-width: 68px;
                            padding-top: 1px;
                            padding-left: 4px;
                          "
                        >
                          <span translate>КПП</span><br />
                          <nz-spin [nzSpinning]="loadingFrames">
                            <div class="sl">
                              <select
                                class="sel"
                                size="8"
                                formControlName="inclusionTransmission"
                                type="text"
                                multiple="multiple"
                                style="font-size: 14px; font-family: Arial; width: 68px"
                              >
                                <option
                                  style="background: #eee; color: #666"
                                  value="any"
                                  selected=""
                                >
                                  {{ 'ANY' | translate }}
                                </option>
                                <option
                                  *ngFor="let option of filtersData.transmissionEnOptions"
                                  [value]="option.transmissionEn"
                                  [innerHTML]="option.transmissionEn"
                                ></option>
                              </select>
                            </div>
                          </nz-spin>
                        </td>
                      </div>
                      <td
                        width="130px"
                        class="bg_sch"
                        style="
                          min-width: 145px;
                          max-width: 145px;
                          padding: 1px 2px 0 4px;
                          border-right: solid 1px #cccccd;
                        "
                        valign="top"
                      >
                        <!-- SCH TABLE -->
                        <table
                          border="0"
                          height="146px"
                          class="aj_sm"
                          style="margin: 0"
                          cellpadding="0"
                          cellspacing="0"
                        >
                          <tbody>
                            <div
                              appDriverStep
                              [overrideStepIndex]="7"
                              [stepTitle]="'AUTO_FILTER_GUIDE.7.TITLE' | translate"
                              [stepDescription]="'AUTO_FILTER_GUIDE.7.TEXT' | translate"
                              [popoverClass]="'auto-lots-filter'"
                            >
                              <tr>
                                <td
                                  colspan="2"
                                  valign="top"
                                  class="mz_fix"
                                  height="1px"
                                  style="padding: 1px 0 0 1px"
                                  translate
                                >
                                  год от, до
                                </td>
                              </tr>
                              <tr>
                                <td valign="top" style="padding: 0 0 0 1px">
                                  <div class="op_fix">
                                    <div class="sl">
                                      <select
                                        name="year"
                                        type="text"
                                        class="sl_sch"
                                        formControlName="yearFrom"
                                      >
                                        <option value="" selected="">&nbsp;</option>
                                        <option *ngFor="let year of years" [value]="year">
                                          {{ year }}
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                </td>
                                <td valign="top" style="padding: 0 0 0 2px">
                                  <div class="op_fix">
                                    <div class="sl">
                                      <select
                                        name="year2"
                                        type="text"
                                        class="sl_sch"
                                        formControlName="yearTo"
                                      >
                                        <option value="" selected="">&nbsp;</option>
                                        <option *ngFor="let year of years" [value]="year">
                                          {{ year }}
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="2" height="17px" style="padding: 4px 0 1px 1px">
                                  <span translate>Пробег от, до (тыс.км)</span>
                                </td>
                              </tr>
                              <tr>
                                <td class="aj_sm">
                                  <input
                                    formControlName="mileageFrom"
                                    value=""
                                    type="text"
                                    class="inp_sch"
                                    (focus)="clearValue('mileageFrom')"
                                  />
                                </td>
                                <td class="aj_sm">
                                  <input
                                    formControlName="mileageTo"
                                    style="margin-left: 1px"
                                    name="probeg2"
                                    value=""
                                    type="text"
                                    class="inp_sch"
                                    (focus)="clearValue('mileageTo')"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td
                                  colspan="2"
                                  height="17px"
                                  style="padding: 3px 0 1px 1px"
                                  translate
                                >
                                  Объем двигат. от, до
                                </td>
                              </tr>
                              <tr>
                                <td class="aj_sm">
                                  <input
                                    formControlName="engineVolumeFrom"
                                    value=""
                                    type="text"
                                    class="inp_sch"
                                    (focus)="clearValue('engineVolumeFrom')"
                                  />
                                </td>
                                <td class="aj_sm">
                                  <input
                                    style="margin-left: 1px"
                                    formControlName="engineVolumeTo"
                                    value=""
                                    type="text"
                                    class="inp_sch"
                                    (focus)="clearValue('engineVolumeTo')"
                                  />
                                </td>
                              </tr>
                            </div>
                            <tr
                              appDriverStep
                              [overrideStepIndex]="8"
                              [stepTitle]="'AUTO_FILTER_GUIDE.8.TITLE' | translate"
                              [stepDescription]="'AUTO_FILTER_GUIDE.8.TEXT' | translate"
                              [popoverClass]="'auto-lots-filter'"
                            >
                              <td colspan="2" style="padding: 2px 0 0 1px">
                                <input
                                  formControlName="bid"
                                  type="text"
                                  class="inp_sch"
                                  (focus)="clearValue('bid')"
                                  style="width: 122px"
                                />
                              </td>
                            </tr>
                            <tr></tr>
                          </tbody>
                        </table>
                        <!-- /SCH TABLE -->
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td valign="top" align="center" style="padding: 46px 20px 0 12px; line-height: 0.5em">
                <div
                  appDriverStep
                  [overrideStepIndex]="15"
                  [stepTitle]="'AUTO_FILTER_GUIDE.15.TITLE' | translate"
                  [stepDescription]="'AUTO_FILTER_GUIDE.15.TEXT' | translate"
                  [popoverClass]="'auto-lots-filter'"
                >
                  <button
                    nz-button
                    class="i_but"
                    type="button"
                    style="margin: 0 0 10px; width: 70px; border-color: rgb(139, 144, 154)"
                    (click)="resetFilter()"
                  >
                    {{ 'Сброс' | translate }}
                  </button>
                  <br />
                  <button
                    nz-button
                    nzType="primary"
                    class="i_but"
                    style="margin: 0 0 10px 0; width: 70px"
                    (click)="filter()"
                  >
                    {{ 'Поиск' | translate }}
                  </button>
                </div>
                <a
                  style="line-height: 0.7em; font-weight: 400; text-decoration: none"
                  href="javascript:void(0)"
                  (click)="extendedSearchVisible = !extendedSearchVisible"
                  appDriverStep
                  [overrideStepIndex]="16"
                  [stepTitle]="'AUTO_FILTER_GUIDE.16.TITLE' | translate"
                  [stepDescription]="'AUTO_FILTER_GUIDE.16.TEXT' | translate"
                  [popoverClass]="'auto-lots-filter'"
                >
                  <span style="font-size: 11px; font-family: Tahoma" translate>
                    Расширенный поиск
                  </span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
      <form
        [formGroup]="form"
        nz-row
        nzAlign="end"
        style="align-items: center"
        nzJustify="center"
        nzGutter="20"
      >
        <div nz-col nzXs="6" nzSm="4" nzFlex="350px" style="display: none">
          {{ 'LOT_INFO.TRUCK' | translate }}<br />
          <nz-select
            formControlName="truck"
            nzMode="multiple"
            nzSize="small"
            nzPlaceHolder="{{ 'PLEASE_SELECT' | translate }}"
            style="width: 200px"
          >
            <nz-option
              *ngFor="let option of trucks"
              [nzLabel]="option.name"
              [nzValue]="option.id"
            ></nz-option>
          </nz-select>
        </div>
        <div nz-col nzXs="24" nzSm="6" nzFlex="500px">
          <nz-row nzGutter="5">
            <nz-col
              nzSpan="8"
              appDriverStep
              [overrideStepIndex]="9"
              [stepTitle]="'AUTO_FILTER_GUIDE.9.TITLE' | translate"
              [stepDescription]="'AUTO_FILTER_GUIDE.9.TEXT' | translate"
              [popoverClass]="'auto-lots-filter'"
            >
              {{ 'LOT_INFO.TRUCK' | translate }}<br />
              <nz-select
                *ngIf="trucks"
                formControlName="truck"
                nzMode="multiple"
                nzSize="small"
                style="width: 100%"
              >
                <nz-option
                  *ngFor="let option of trucks"
                  [nzLabel]="option.name"
                  [nzValue]="option.id"
                ></nz-option>
              </nz-select>
            </nz-col>
            <nz-col
              nzSpan="8"
              appDriverStep
              [overrideStepIndex]="10"
              [stepTitle]="'AUTO_FILTER_GUIDE.10.TITLE' | translate"
              [stepDescription]="'AUTO_FILTER_GUIDE.10.TEXT' | translate"
              [popoverClass]="'auto-lots-filter'"
            >
              {{ 'MODEL_GRADE' | translate }}<br />
              <input nzSize="small" nz-input type="text" formControlName="modelGrade" />
            </nz-col>
            <nz-col
              nzSpan="8"
              appDriverStep
              [overrideStepIndex]="11"
              [stepTitle]="'AUTO_FILTER_GUIDE.11.TITLE' | translate"
              [stepDescription]="'AUTO_FILTER_GUIDE.11.TEXT' | translate"
              [popoverClass]="'auto-lots-filter'"
            >
              {{ 'LOT_INFO.DRIVE' | translate }}<br />
              <nz-select *ngIf="trucks" formControlName="drive" nzSize="small" style="width: 100%">
                <nz-option [nzLabel]="'ALL' | translate" [nzValue]="null"></nz-option>
                <nz-option [nzLabel]="'LEFT_DRIVE' | translate" nzValue="left"></nz-option>
                <nz-option [nzLabel]="'RIGHT_DRIVE' | translate" nzValue="right"></nz-option>
              </nz-select>
            </nz-col>
          </nz-row>
        </div>
        <div
          nz-col
          nzXs="24"
          nzSm="6"
          nzFlex="350px"
          appDriverStep
          [overrideStepIndex]="12"
          [stepTitle]="'AUTO_FILTER_GUIDE.12.TITLE' | translate"
          [stepDescription]="'AUTO_FILTER_GUIDE.12.TEXT' | translate"
          [popoverClass]="'auto-lots-filter'"
        >
          {{ 'PRODUCTION_DATE' | translate }}<br />
          <nz-input-group nzCompact nzSize="small">
            <nz-range-picker
              style="display: flex; text-align: center"
              nzSeparator="-"
              [nzPlaceHolder]="['FROM' | translate, 'TO' | translate]"
              nzSize="small"
              formControlName="productionDate"
            ></nz-range-picker>
          </nz-input-group>
        </div>
        <div
          nz-col
          nzXs="24"
          nzSm="8"
          nzFlex="250px"
          appDriverStep
          [overrideStepIndex]="13"
          [stepTitle]="'AUTO_FILTER_GUIDE.13.TITLE' | translate"
          [stepDescription]="'AUTO_FILTER_GUIDE.13.TEXT' | translate"
          [popoverClass]="'auto-lots-filter'"
        >
          <label nz-checkbox formControlName="datetime">
            {{ 'AUCTION_NOT_STARTED' | translate }}
          </label>
        </div>
      </form>
      <div *ngIf="extendedSearchVisible" class="extended-search">
        <h3 class="extended-search-header" translate>Японские автоаукционы</h3>
        <div nz-row nzAlign="middle" nzJustify="center">
          <div nz-col *ngFor="let day of [1, 2, 3, 4, 5, 6]">
            <h4 style="text-align: center; text-decoration: underline; color: rgb(57, 100, 179)">
              {{ weekdayTranslationKeys[day - 1] | translate | uppercase }}
            </h4>
            <ng-container *ngFor="let auction of auctions">
              <p>
                <label
                  nz-checkbox
                  *ngIf="auction.dayOfWeek === day"
                  [ngModel]="valueIncludes(form.controls['inclusionAuction'], auction.id)"
                  (ngModelChange)="
                    $event
                      ? addValueToControl(form.controls['inclusionAuction'], auction.id)
                      : removeValueFromControl(form.controls['inclusionAuction'], auction.id)
                  "
                >
                  {{ auction.name }} ({{ auction.numLots }})
                </label>
              </p>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </nz-collapse-panel>
</nz-collapse>
