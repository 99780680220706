import { MutationHandlingServiceV2, QueryHandlingService } from '@akebono/core';
import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { of, timer } from 'rxjs';
import { map, mapTo, pluck, shareReplay, take, tap } from 'rxjs/operators';
import {
  PlugViewBestBidSubscriptionOnAdvanceUpdateRequestCreateGQL,
  UserViewBestBidSubscribeDataGQL,
  UserViewBestBidSubscribeGQL,
} from 'src/app/modules/graphql/service/graphql-auto-main-service';

@Component({
  selector: 'app-subscribe-to-best-bid-modal',
  templateUrl: './subscribe-to-best-bid-modal.component.html',
  styleUrls: ['./subscribe-to-best-bid-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscribeToBestBidModalComponent implements OnInit {
  @Output() subscriptionCreated = new EventEmitter();

  loading$ = of(false);
  subscribing$ = of(false);

  private readingTimeSeconds = 5;
  agreementAccepted = false;

  agreementTimer$ = of(this.readingTimeSeconds);
  canSubscribe$ = of(false);
  renderAlert$ = of(false);
  dataReceived$ = of(false);
  canSubscribeOnAdvanceUpdate$ = of(false);
  canRequestSubscribeOnAdvanceUpdate$ = of(false);

  constructor(
    private modal: NzModalRef,
    private qhs: QueryHandlingService,
    private mhs: MutationHandlingServiceV2,
    private userViewBestBidSubscribeDataGQL: UserViewBestBidSubscribeDataGQL,
    private userViewBestBidSubscribeGQL: UserViewBestBidSubscribeGQL,
    private plugViewBestBidSubscriptionOnAdvanceUpdateRequestCreateGQL: PlugViewBestBidSubscriptionOnAdvanceUpdateRequestCreateGQL,
  ) {}

  ngOnInit(): void {
    this.agreementAccepted = false;
    this.agreementTimer$ = timer(0, 1000).pipe(
      map((i) => this.readingTimeSeconds - i),
      take(this.readingTimeSeconds + 1),
      shareReplay(1),
    );

    const queryRef = this.qhs.fetch(this.userViewBestBidSubscribeDataGQL);
    this.loading$ = queryRef.loading;

    const currentUser$ = queryRef.data.pipe(pluck('currentUser'), shareReplay(1));
    this.dataReceived$ = queryRef.data.pipe(mapTo(true));
    this.canSubscribe$ = currentUser$.pipe(pluck('isEnoughFundsToSubscribeToViewTheBestBid'));
    this.renderAlert$ = this.canSubscribe$.pipe(map((value) => !value));
    this.canSubscribeOnAdvanceUpdate$ = currentUser$.pipe(
      map(
        (currentUser) =>
          !currentUser.isEnoughFundsToSubscribeToViewTheBestBid && currentUser.isTrusted,
      ),
    );
    this.canRequestSubscribeOnAdvanceUpdate$ = currentUser$.pipe(
      map(
        (currentUser) =>
          !currentUser.isEnoughFundsToSubscribeToViewTheBestBid && !currentUser.isTrusted,
      ),
    );
  }

  subscribeToViewBestBid(subscribeWhenAdvanceUpdate: boolean): void {
    this.subscribing$ = this.mhs
      .mutate(
        this.userViewBestBidSubscribeGQL,
        {
          input: {
            plugWhenAdvanceUpdate: subscribeWhenAdvanceUpdate,
          },
        },
        {
          successTranslationKey: subscribeWhenAdvanceUpdate
            ? 'SUBSCRIBE_TO_BEST_BID.SUCCESS_WHEN_ADVANCE_UPDATE'
            : 'SUBSCRIBE_TO_BEST_BID.SUCCESS',
          failureTranslationKey: 'SUBSCRIBE_TO_BEST_BID.FAIL',
          refetch: true,
        },
      )
      .loading.pipe(
        tap({
          complete: () => {
            this.subscriptionCreated.emit();
            this.cancel();
          },
        }),
      );
  }

  requestSubscribeOnAdvanceUpdate(): void {
    this.subscribing$ = this.mhs
      .mutate(
        this.plugViewBestBidSubscriptionOnAdvanceUpdateRequestCreateGQL,
        {
          input: {
            message: null,
          },
        },
        {
          refetch: true,
          successTranslationKey: 'SUBSCRIBE_TO_BEST_BID.SUCCESS_REQUEST',
        },
      )
      .loading.pipe(
        tap({
          complete: () => {
            this.subscriptionCreated.emit();
            this.cancel();
          },
        }),
      );
  }

  cancel(): void {
    this.modal.destroy();
  }
}
