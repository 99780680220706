<div class="auc_list_shame" id="auc_list_shame_10">
  <img src="/assets/images/auctions/{{ lang }}/MIRIVE.png" class="shame_img" alt="MIRIVE">
  <div
    class="shame_blocks shame_block_1"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.LOT_NUM' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_2"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.FIRST_REG_DATE' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_3"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.CAR_NAME' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_4"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.DOORS' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_5"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.MODEL_VERSION' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_6"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.DRIVETRAIN_TYPE' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_7"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.GRADE' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_8"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.MIRIVE.INNER_AUCTION_NAME' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_9"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.MIRIVE.REGISTRATION' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_10"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.MODEL_NAME' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_11"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.CHASSIS' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_12"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.ENGINE_CAPACITY' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_13"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.INSURANCE' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_14"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.SEATS' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_15"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.MILEAGE' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_16"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.TECH_INSP' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_17"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.VEHICLE_COLOR' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_18"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.FUEL_TYPE.TITLE' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_19"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.INTERIOR_GRADE' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_20"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.EXTERIOR_GRADE' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_21"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.TRANSMISSION' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_22"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.CONDITIONER' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_23"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.RECYCLE_TICKET' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_24"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.ORIGINAL_EQUIPMENT' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_25"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.SELLER_NOTES' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_26"
    nz-tooltip
    [nzTooltipTitle]="shameblock26"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  >
    <ng-template #shameblock26>
      <div class="header">{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.MIRIVE.RE_REG_PERIOD' | translate }}</div>
      <div class="header">{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.MIRIVE.BUY_CAR' | translate }}</div>
      <div class="header">{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.MIRIVE.DEALER_MILEAGE' | translate }}</div>
      <div class="header">
        {{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.MIRIVE.LEFT_RIGHT_STEERING_WHEEL' | translate }}
      </div>
    </ng-template>
  </div>
  <div
    class="shame_blocks shame_block_27"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.SELLER_NOTES' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_28"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.INSPECTOR_NOTES' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_29"
    nz-tooltip
    [nzTooltipTitle]="shameblock29"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  >
    <ng-template #shameblock29>
      <div class="header">
        {{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.DAMAGE_SCHEME.TITLE' | translate }}
      </div>
      <p>
        {{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.DAMAGE_SCHEME.DESCR' | translate }}
        <a href="https://ecarjp.com/article/sheet" target="_blank">
          {{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.DAMAGE_SCHEME.LINK' | translate }}
        </a>
      </p>
    </ng-template>
  </div>
  <div
    class="shame_blocks shame_block_30"
    nz-tooltip
    [nzTooltipTitle]="shameblock30"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  >
    <ng-template #shameblock30>
      <div class="header">
        {{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.MIRIVE.WHEELS_SCRATCHES_BROKEN' | translate }}
      </div>
      <div class="header">{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.MIRIVE.REAR_VIEW_MIRRORS' | translate }}</div>
    </ng-template>
  </div>
  <div
    class="shame_blocks shame_block_31"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.MIRIVE.ADDITIONALLY' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_32"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.PLATE_NUM' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_33"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.CHASSIS_NUM' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
</div>
