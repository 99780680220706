import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-priority-pass-info',
  templateUrl: './priority-pass-info.component.html',
  styleUrls: ['./priority-pass-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriorityPassInfoComponent {
  constructor(private modal: NzModalRef) {}

  close(): void {
    this.modal.destroy();
  }
}
