import { AkebonoCoreModule } from '@akebono/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

import { SubscribeToBestBidButtonComponent } from './components/subscribe-to-best-bid-button/subscribe-to-best-bid-button.component';
import { SubscribeToBestBidModalComponent } from './components/subscribe-to-best-bid-modal/subscribe-to-best-bid-modal.component';

@NgModule({
  declarations: [SubscribeToBestBidButtonComponent, SubscribeToBestBidModalComponent],
  imports: [
    CommonModule,
    BrowserModule,
    AkebonoCoreModule,
    FormsModule,
    TranslateModule,
    BrowserAnimationsModule,
    NzButtonModule,
    NzModalModule,
    NzToolTipModule,
    NzGridModule,
    NzSpinModule,
    NzAlertModule,
    NzCheckboxModule,
  ],
  exports: [SubscribeToBestBidButtonComponent],
})
export class SubscribeToBestBidButtonModule {}
