import { AkebonoCoreModule } from '@akebono/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LinkOutline } from '@ant-design/icons-angular/icons';
import { TranslateModule } from '@ngx-translate/core';
import { NzIconModule } from 'ng-zorro-antd/icon';

import { LotSharedIconComponent } from './lot-shared-icon.component';

@NgModule({
  declarations: [LotSharedIconComponent],
  exports: [LotSharedIconComponent],
  imports: [CommonModule, AkebonoCoreModule, TranslateModule, NzIconModule.forRoot([LinkOutline])],
})
export class LotSharedIconModule {}
