import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserLotMarkDataFragment } from 'src/app/modules/graphql/service/graphql-auto-main-service';

import { LotInterestingMutationService } from '../lot-interesting-mutation.service';
import { LotInterestingStore } from './lot-intresting-store';

@Injectable()
export class LotInterestingStorageFactoryService {
  constructor(private lotInterestingMutations: LotInterestingMutationService) {}

  public create(
    source: Observable<UserLotMarkDataFragment | UserLotMarkDataFragment[]>,
  ): LotInterestingStore {
    return new LotInterestingStore(source, this.lotInterestingMutations);
  }
}
