import { Injectable } from '@angular/core';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import * as XLSX from 'xlsx';

import { ExcelBidFactory } from '../classes/excel-bid-factory.class';
import { ExcelBidParsingResult } from '../types/excel-bid-parse-result.type';

@Injectable()
export class ExcelBidsParserService {
  async parse(file: NzUploadFile): Promise<ExcelBidParsingResult> {
    const fileData = await this.loadFile(file);
    const rawRows = this.getRawRows(fileData);
    const bids = rawRows.map((row) => ExcelBidFactory.create(row));
    const result: ExcelBidParsingResult = {
      filename: file.name,
      bids,
    };

    return Object.freeze(result);
  }

  private getRawRows(fileData: string | ArrayBuffer): string[][] {
    const workbook = XLSX.read(fileData, { type: 'array' });
    const sheets = Object.values(workbook.Sheets);
    const sheetCsvs = sheets.map((sheet) => this.sheetToCsv(sheet));
    const csvRows = sheetCsvs
      .map((csv) => csv.split('\n'))
      .flat()
      .slice(1);
    const rawRows = csvRows.map((csvRow) => csvRow.split(';').map((col) => col.trim()));

    return rawRows;
  }

  private sheetToCsv(sheet: XLSX.WorkSheet): string {
    return XLSX.utils.sheet_to_csv(sheet, {
      FS: ';',
      RS: '\n',
      blankrows: false,
      strip: true,
    });
  }

  private loadFile(file: NzUploadFile): Promise<string | ArrayBuffer> {
    return new Promise<string | ArrayBuffer>((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event: ProgressEvent<FileReader>) => {
        resolve(event.target.result);
      };

      reader.onerror = (event: ProgressEvent<FileReader>) => {
        reject(event.target.error);
      };

      reader.readAsArrayBuffer(file as unknown as Blob);
    });
  }
}
