<nz-collapse [nzBordered]="false">
  <nz-collapse-panel [nzHeader]="'FILTERS' | translate" nzActive>
    <form nz-form [formGroup]="form" [nzLayout]="'vertical'">
      <nz-row [nzGutter]="40">
        <nz-col [nzXs]="24" [nzSm]="24" [nzMd]="6" [nzLg]="6" [nzXl]="6" [nzXXl]="6">
          <nz-form-item>
            <nz-form-label>
              <strong>{{ 'LOT_INFO.AUCTION_DATE' | translate }}</strong>
            </nz-form-label>
            <nz-form-control>
              <nz-row>
                <nz-input-group nzCompact nzSize="small">
                  <nz-calendar
                    [nzFullscreen]="false"
                    formControlName="lotAuctionDate"
                  ></nz-calendar>
                </nz-input-group>
              </nz-row>
            </nz-form-control>
          </nz-form-item>
        </nz-col>
      </nz-row>
      <div nz-row>
        <div nz-col [nzSpan]="24" class="search-area">
          <button nz-button nzType="primary" (click)="filter()">
            {{ 'SEARCH' | translate }}
          </button>
          <button nz-button (click)="resetFilter()">
            {{ 'CLEAR' | translate }}
          </button>
        </div>
      </div>
    </form>
  </nz-collapse-panel>
</nz-collapse>
