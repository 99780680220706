import { GearboxType } from './const';

const gearboxTypes: Array<{ name: string; type: GearboxType }> = [
  {
    name: '&#08544;5',
    type: 'manual',
  },
  {
    name: '2',
    type: 'automatic',
  },
  {
    name: '3AT',
    type: 'automatic',
  },
  {
    name: '3C',
    type: 'automatic',
  },
  {
    name: '3DAT',
    type: 'automatic',
  },
  {
    name: '3F',
    type: 'manual',
  },
  {
    name: '3FAT',
    type: 'automatic',
  },
  {
    name: '4&#65407;&#65400;',
    type: 'manual',
  },
  {
    name: '4AT',
    type: 'automatic',
  },
  {
    name: '4C',
    type: 'automatic',
  },
  {
    name: '4CAT',
    type: 'automatic',
  },
  {
    name: '4DAT',
    type: 'automatic',
  },
  {
    name: '4F',
    type: 'manual',
  },
  {
    name: '4FAT',
    type: 'automatic',
  },
  {
    name: '4MT',
    type: 'manual',
  },
  {
    name: '4X2',
    type: 'manual',
  },
  {
    name: '5',
    type: 'manual',
  },
  {
    name: '5AG',
    type: 'automatic',
  },
  {
    name: '5AT',
    type: 'automatic',
  },
  {
    name: '5C',
    type: 'automatic',
  },
  {
    name: '5CAT',
    type: 'automatic',
  },
  {
    name: '5D',
    type: 'automatic',
  },
  {
    name: '5DAT',
    type: 'automatic',
  },
  {
    name: '5DMT',
    type: 'manual',
  },
  {
    name: '5F',
    type: 'manual',
  },
  {
    name: '5FAT',
    type: 'automatic',
  },
  {
    name: '5FMT',
    type: 'manual',
  },
  {
    name: '5I',
    type: 'automatic',
  },
  {
    name: '5MT',
    type: 'manual',
  },
  {
    name: '5X2',
    type: 'manual',
  },
  {
    name: '&#65396;&#65405;&#65',
    type: 'manual',
  },
  {
    name: '&#65407;&#65417;&#65',
    type: 'automatic',
  },
  {
    name: '&#65412;&#65400;S',
    type: 'manual',
  },
  {
    name: '6AT',
    type: 'automatic',
  },
  {
    name: '6DAT',
    type: 'automatic',
  },
  {
    name: '6F',
    type: 'manual',
  },
  {
    name: '6FAT',
    type: 'automatic',
  },
  {
    name: '6FMT',
    type: 'manual',
  },
  {
    name: '6MT',
    type: 'manual',
  },
  {
    name: '7DAT',
    type: 'automatic',
  },
  {
    name: '7F',
    type: 'manual',
  },
  {
    name: '7FAT',
    type: 'automatic',
  },
  {
    name: '7MT',
    type: 'manual',
  },
  {
    name: '8DAT',
    type: 'automatic',
  },
  {
    name: 'AT',
    type: 'automatic',
  },
  {
    name: 'AT4',
    type: 'automatic',
  },
  {
    name: 'C',
    type: 'manual',
  },
  {
    name: 'C3',
    type: 'manual',
  },
  {
    name: 'C4',
    type: 'manual',
  },
  {
    name: 'C5',
    type: 'manual',
  },
  {
    name: 'C5MT',
    type: 'manual',
  },
  {
    name: 'C6',
    type: 'manual',
  },
  {
    name: 'C7',
    type: 'manual',
  },
  {
    name: 'CA',
    type: 'automatic',
  },
  {
    name: 'CAT',
    type: 'automatic',
  },
  {
    name: 'CCVT',
    type: 'automatic',
  },
  {
    name: 'CM',
    type: 'manual',
  },
  {
    name: 'CMT',
    type: 'manual',
  },
  {
    name: 'CVT',
    type: 'automatic',
  },
  {
    name: 'D5',
    type: 'manual',
  },
  {
    name: 'D5MT',
    type: 'manual',
  },
  {
    name: 'D6',
    type: 'manual',
  },
  {
    name: 'DA',
    type: 'automatic',
  },
  {
    name: 'DAT',
    type: 'automatic',
  },
  {
    name: 'DCV',
    type: 'automatic',
  },
  {
    name: 'DCVT',
    type: 'automatic',
  },
  {
    name: 'DMT',
    type: 'manual',
  },
  {
    name: 'F',
    type: 'manual',
  },
  {
    name: 'F2',
    type: 'manual',
  },
  {
    name: 'F3',
    type: 'manual',
  },
  {
    name: 'F4',
    type: 'manual',
  },
  {
    name: 'F5',
    type: 'manual',
  },
  {
    name: 'F5MT',
    type: 'manual',
  },
  {
    name: 'F6',
    type: 'manual',
  },
  {
    name: 'F7',
    type: 'manual',
  },
  {
    name: 'F8',
    type: 'manual',
  },
  {
    name: 'F9',
    type: 'manual',
  },
  {
    name: '',
    type: 'manual',
  },
  {
    name: 'FA',
    type: 'automatic',
  },
  {
    name: 'FAT',
    type: 'automatic',
  },
  {
    name: 'FCVT',
    type: 'automatic',
  },
  {
    name: 'I5',
    type: 'manual',
  },
  {
    name: 'IAT',
    type: 'automatic',
  },
  {
    name: 'ICV',
    type: 'automatic',
  },
  {
    name: 'ICVT',
    type: 'automatic',
  },
  {
    name: 'IMT',
    type: 'manual',
  },
  {
    name: 'M4',
    type: 'manual',
  },
  {
    name: 'M5',
    type: 'manual',
  },
  {
    name: 'M6',
    type: 'manual',
  },
  {
    name: 'MT',
    type: 'manual',
  },
  {
    name: 'MT5',
    type: 'manual',
  },
  {
    name: 'PAT',
    type: 'automatic',
  },
  {
    name: '.S',
    type: 'manual',
  },
  {
    name: '..S',
    type: 'manual',
  },
  {
    name: 'SAT',
    type: 'automatic',
  },
  {
    name: 'SMT',
    type: 'manual',
  },
  {
    name: 'X',
    type: 'manual',
  },
];

const mapInitializer = gearboxTypes.map((gt) => <[string, GearboxType]>[gt.name.trim(), gt.type]);
export const gearboxTypeMap = new Map<string, GearboxType>(mapInitializer);
