<main>
  <nz-tabset [nzSelectedIndex]="selectedIndex">
    <nz-tab [nzTitle]="'SETTINGS.NOTIFICATIONS' | translate">
      <app-notification-settings
        [notificationSettings]="currentUser$ | async"
        [loading]="loading$ | async"
        [isEcarBotConnected]="isEcarBotConnected$ | async"
        (settingsChange)="onSettingsChange('notification', $event)"
      ></app-notification-settings>
    </nz-tab>
    <nz-tab [nzTitle]="'SETTINGS.BIDS' | translate">
      <app-bid-settings
        [bidSettings]="currentUser$ | async"
        [loading]="loading$ | async"
        (settingsChange)="onSettingsChange('bid', $event)"
      ></app-bid-settings>
    </nz-tab>
    <nz-tab [nzTitle]="'SETTINGS.TRANSLATIONS' | translate">
      <app-translation-settings
        [bidSettings]="currentUser$ | async"
        [loading]="loading$ | async"
        (settingsChange)="onSettingsChange('translation', $event)"
      ></app-translation-settings>
    </nz-tab>
  </nz-tabset>
</main>

<footer *nzModalFooter>
  <button nz-button (click)="modal.close()">
    {{ 'CANCEL' | translate }}
  </button>
  <button nz-button nzType="primary" [nzLoading]="saving$ | async" (click)="save()">
    {{ 'SAVE' | translate }}
  </button>
</footer>
