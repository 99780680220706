<button
  nz-button
  nzType="primary"
  nzSize="small"
  style="margin-right: 20px"
  [nzLoading]="queryRef.loading | async"
  (click)="openCreateUserFilterPresetModal()"
>
  {{ 'SAVE_PRESET' | translate }}
</button>

<ng-container *ngIf="(queryRef.data | async)?.currentUser?.userFilterPresets as filterPresets">
  <nz-button-group *ngFor="let preset of filterPresets" style="margin-right: 10px">
    <button nz-button nzSize="small" (click)="presetSelected.emit(preset)" translate>
      {{ preset.title }}
    </button>
    <button
      nz-button
      nz-tooltip
      nzSize="small"
      [nzType]="preset.compilationEmailEnabled ? 'primary' : 'default'"
      [nzLoading]="updating[preset.id] | async"
      [nzTooltipTitle]="(preset.compilationEmailEnabled ? 'DISABLE_NEW_LOTS_NOTIFICATION' : 'ENABLE_NEW_LOTS_NOTIFICATION' ) | translate"
      (click)="toggleEmailCompilations(preset)"
    >
      <i nz-icon nzType="mail" nzTheme="outline"></i>
    </button>
    <button
      nz-button
      nz-tooltip
      nz-popconfirm
      nzSize="small"
      [nzLoading]="deleting[preset.id] | async"
      (nzOnConfirm)="deletePreset(preset)"
      [nzTooltipTitle]="'DELETE' | translate"
      [nzPopconfirmTitle]="'ARE_YOU_SURE' | translate"
    >
      X
    </button>
  </nz-button-group>
</ng-container>
