import { AkebonoCoreModule } from '@akebono/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NzButtonModule } from 'ng-zorro-antd/button';

import { LotFetchAraiKenkiPhotoButtonComponent } from './lot-fetch-arai-kenki-photo-button.component';

@NgModule({
  declarations: [LotFetchAraiKenkiPhotoButtonComponent],
  imports: [CommonModule, AkebonoCoreModule, TranslateModule, NzButtonModule],
  exports: [LotFetchAraiKenkiPhotoButtonComponent],
})
export class LotFetchAraiKenkiPhotoButtonModule {}
