import { MutationHandlingServiceV2 } from '@akebono/core';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { distinctUntilChanged, finalize, map, startWith } from 'rxjs/operators';
import {
  BidUpdateGQL,
  UserLotQuery,
} from 'src/app/modules/graphql/service/graphql-auto-main-service';

@Component({
  selector: 'app-lot-negotiations-button',
  templateUrl: './lot-negotiations-button.component.html',
  styleUrls: ['./lot-negotiations-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotNegotiationsButtonComponent implements OnInit {
  @Input() currentUserLastBid?: UserLotQuery['lot']['currentUserLastBid'];
  @Input() isOtherCountriesUser: boolean;
  @Input() negotiationsPriceStr?: string;
  @Output() actionComplete = new EventEmitter<void>();

  readonly OVERSTEP = 20_000;
  readonly MAX_AMOUNT = 5_900_000;

  @ViewChild('content2') content2: TemplateRef<HTMLElement>;

  form: FormGroup;
  negotiationsPrice: number;

  constructor(
    private fb: FormBuilder,
    private ms: NzModalService,
    private translate: TranslateService,
    private mhs2: MutationHandlingServiceV2,
    private bidUpdateGQL: BidUpdateGQL,
  ) {}

  ngOnInit(): void {
    this.negotiationsPrice = Math.max(
      this.currentUserLastBid.amount,
      Number(this.negotiationsPriceStr) + this.OVERSTEP,
    );

    this.form = this.fb.group({
      amount: this.fb.control(null, [Validators.required, Validators.min(this.negotiationsPrice)]),
    });

    if (!this.isOtherCountriesUser) {
      this.form.controls.amount.addValidators(Validators.max(this.MAX_AMOUNT));
    }
  }

  async confirmNegotiations1() {
    this.ms.confirm({
      nzContent: await this.translate
        .get('NEGOTIATIONS_CONFIRM_1', {
          link: `<a href="/article/negotiations" target="_blank">${this.translate.instant(
            'NEGOTIATIONS_WORK',
          )}</a>`,
        })
        .toPromise(),
      nzOnOk: () => this.confirmNegotiations2(),
    });
  }

  async confirmNegotiations2() {
    const ref: NzModalRef = this.ms.confirm(
      {
        nzContent: this.content2,
        nzOnOk: async () =>
          this.mhs2
            .mutate(this.bidUpdateGQL, {
              input: {
                id: this.currentUserLastBid.id,
                isNegotiationsAccepted: true,
                newAmount: this.form.value.amount,
              },
            })
            .loading.pipe(finalize(() => this.actionComplete.emit()))
            .toPromise()
            .then(() => {
              ref.close();
              this.form.reset();
            }),
      },
      'warning',
    );

    this.form.valueChanges
      .pipe(
        map(() => this.form.invalid),
        startWith(true),
        distinctUntilChanged(),
      )
      .subscribe((formInvalid) => {
        ref.updateConfig({ nzOkDisabled: formInvalid });
      });
  }
}
