import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { NewsEntryComponent } from './components/news-entry/news-entry.component';
import { NewsLayoutComponent } from './components/news-layout/news-layout.component';
import { NewsListComponent } from './components/news-list/news-list.component';

const routes: Routes = [
  {
    path: 'news',
    component: NewsLayoutComponent,
    children: [
      { path: '', pathMatch: 'full', component: NewsListComponent },
      { path: ':id', component: NewsEntryComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class NewsRoutingModule {}
