<div class="content">
  <div class="content__title-wrapper">
    <h3
      nz-title
      appDriverStep
      [overrideStepIndex]="0"
      [stepTitle]="'LOT_INFO.GUIDE.MY_BIDS.TITLE' | translate"
      [stepDescription]="'LOT_INFO.GUIDE.MY_BIDS.DESCR' | translate"
      [stepSide]="'left'"
      [stepAlign]="'start'"
      [popoverClass]="'bids'"
    >
      {{ 'MY_BIDS' | translate }}
    </h3>
    <button
      class="guide-btn"
      nz-tooltip
      [nzTooltipTitle]="'HINT' | translate"
      nzTooltipPlacement="left"
      nz-button
      nzType="default"
      nzShape="circle"
      (click)="startDriver()"
    >
      <span nz-icon nzType="question" nzTheme="outline"></span>
    </button>
  </div>

  <div class="import-bids-wrapper">
    <a nz-button nzType="primary" routerLink="/bids/import">
      {{ 'IMPORT_BIDS.TITLE' | translate }}
    </a>
  </div>

  <app-bids-filter
    [counters]="counters"
    [conditions]="conditions"
    (apply)="scrollToTable()"
  ></app-bids-filter>

  <nz-table
    #table
    class="table mt-10"
    nzSize="small"
    [nzData]="bids"
    nzShowPagination
    nzShowSizeChanger
    [nzBordered]="true"
    [nzTotal]="total"
    [nzLoading]="loading"
    [nzPageSize]="pageSize"
    [nzPageIndex]="pageIndex"
    [nzFrontPagination]="false"
    nzPaginationPosition="both"
    [nzPageSizeOptions]="[10, 20, 30, 40, 50]"
    (nzPageSizeChange)="changePageSize($event)"
    (nzPageIndexChange)="changePageIndex($event)"
    nzTitle="{{ 'TOTAL' | translate }}: {{ total }}"
    appDriverStep
    [overrideStepIndex]="3"
    [stepTitle]="'LOT_INFO.GUIDE.BIDS_LIST.TITLE' | translate"
    [stepDescription]="'LOT_INFO.GUIDE.BIDS_LIST.DESCR' | translate"
    [stepSide]="'left'"
    [stepAlign]="'start'"
    [popoverClass]="'bids'"
  >
    <thead>
      <tr>
        <th
          *ngIf="isAwaitingParticipationMode"
          [nzChecked]="checked"
          [nzIndeterminate]="indeterminate"
          (nzCheckedChange)="onAllChecked($event)"
          rowspan="2"
        >
          <div class="group-actions" nz-col>
            <button
              nz-button
              nzType="primary"
              nzSize="small"
              [disabled]="setOfCheckedId.size === 0"
              [nzLoading]="approvingParticipations | async"
              (click)="approveParticipations()"
            >
              {{ 'LOT_INFO.APPROVE_PARTICIPATION' | translate }}
            </button>
          </div>
        </th>
        <th nzAlign="center" rowspan="2">{{ 'PHOTO' | translate }}</th>
        <th nzAlign="center">
          {{ 'LOT' | translate }}
        </th>
        <th nzAlign="center">
          {{ 'AMOUNT' | translate }}
        </th>
        <th nzAlign="center" class="hide-column-small" rowspan="2">
          <app-sort-component
            (nzSortChange)="sortEvent($event)"
            sortKey="bidGroup"
            [title]="'GROUP' | translate"
          >
          </app-sort-component>
        </th>
        <th nzAlign="center" [ngClass]="'hide-column-small hide-column-medium'" rowspan="2">
          {{ 'LOT_INFO.NAME' | translate }}
        </th>
        <th nzAlign="center" class="hide-column-small" rowspan="2">
          {{ 'LOT_DATA' | translate }}
        </th>
        <th nzAlign="center" class="hide-column-small" rowspan="2">
          {{ 'STATUS' | translate }}
        </th>
        <th nzAlign="center" class="hide-column-small" rowspan="2">
          <app-sort-component
            (nzSortChange)="sortEvent($event)"
            sortKey="bidCondition"
            [title]="'CONDITION' | translate"
          >
          </app-sort-component>
        </th>
        <th nzAlign="center" class="hide-column-small" rowspan="2">
          {{ 'BIDS_DATE' | translate }}
        </th>
        <th nzWidth="1px" rowspan="2"></th>
      </tr>
      <tr>
        <th nzAlign="center">
          <app-sort-component
            (nzSortChange)="sortEvent($event)"
            sortKey="bidLotAuctionDatetime"
            [title]="'DATE' | translate"
            [sortOrder]="'descend'"
          ></app-sort-component>
        </th>
        <th nzAlign="center">
          {{ 'LOT_INFO.SOLD_FOR' | translate }}
        </th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let item of table.data">
        <tr>
          <td
            *ngIf="isAwaitingParticipationMode"
            [nzChecked]="setOfCheckedId.has(item.id)"
            (nzCheckedChange)="onItemChecked(item.id, $event)"
          ></td>
          <td nzAlign="center">
            <app-lot-mini-gallery [lot]="item?.lot"></app-lot-mini-gallery>
          </td>
          <td nzAlign="center">
            {{ item.lot?.auctionName }}<br />
            {{ item.lot?.auctionDatetime | date: 'yyyy-MM-dd HH:mm:ss zz':'+0900' }}<br />
            <a
              nzSize="small"
              class="lot-button"
              nz-button
              target="_blank"
              [href]="item?.lot?.link"
              nzType="primary"
              >{{ item?.lot?.code }}</a
            >
          </td>
          <td nzAlign="center">
            <app-lot-bid-amount [bid]="item"></app-lot-bid-amount>
            <div *ngIf="item.wasOverbid" style="color: red">
              {{ 'LOT_INFO.WAS_OVERBID' | translate }}
            </div>
            <div *ngIf="item?.lot?.soldPrice">
              {{
                item?.lot?.soldPrice | currency: (currentCurrency$ | async)?.name:'symbol-narrow'
              }}
            </div>
          </td>
          <td nzAlign="center" [ngClass]="'hide-column-small hide-column-medium'">
            <ng-container *ngIf="item?.bidGroup; else noGroup">
              <div>{{ item?.bidGroup?.letter }}-{{ item?.bidGroup?.quantity }}</div>
              <div>{{ item?.bidGroup?.date | date: 'dd.MM' }}</div>
            </ng-container>
            <ng-template #noGroup>
              {{ 'LOT_BIDDING_MODAL.NO_GROUP' | translate }}
            </ng-template>
          </td>
          <td nzAlign="left" class="hide-column-small">
            {{ item?.lot?.title }}
          </td>
          <td nzAlign="left" class="hide-column-small">
            <div class="lot-data">
              <p *ngIf="item?.lot?.modelTypeEn">
                <strong>{{ 'LOT_INFO.CHASSIS_ID' | translate }}:</strong>
                {{ item?.lot?.modelTypeEn }}
              </p>
              <p *ngIf="item?.lot?.engineVolumeNum">
                <strong>{{ 'LOT_INFO.ENGINE_CC' | translate }}:</strong>
                {{ item?.lot?.engineVolumeNum }}
              </p>
              <p *ngIf="item?.lot?.mileageNum">
                <strong>{{ 'LOT_INFO.MILEAGE' | translate }}:</strong> {{ item?.lot?.mileageNum }}
              </p>
              <p *ngIf="item?.lot?.colorEn">
                <strong>{{ 'LOT_INFO.COLOR' | translate }}:</strong> {{ item?.lot?.colorEn }}
              </p>
              <p *ngIf="item?.lot?.status">
                <strong>{{ 'LOT_STATUSES.STATUS' | translate }}:</strong>
                {{ 'LOT_STATUSES.' + getLotStatus(item) | uppercase | translate }}
              </p>
              <div *ngIf="item?.lot?.translations?.length">
                <strong>{{ 'TRANSLATION' | translate }}:</strong>
                <div
                  *ngFor="let tr of getTranslationsByLanguage(item, currentLanguage$ | async)"
                  style="white-space: pre-wrap"
                >
                  {{ tr.message }}
                  <audio *ngIf="tr.file" [src]="tr.file.fullPath"></audio>
                </div>
              </div>
            </div>
          </td>
          <td nzAlign="center" class="hide-column-small">
            <nz-tag>
              {{ item?.status | uppercase | translate }}
            </nz-tag>
          </td>
          <td nzAlign="center" class="hide-column-small" style="color: #378de5">
            {{ item?.bidCondition?.title | translate }}
            <app-bid-priority-pass-status [bid]="item"></app-bid-priority-pass-status>
          </td>
          <td nzAlign="center" class="hide-column-small">
            {{ item?.createdAt | date: 'yyyy-MM-dd HH:mm:ss zz':'+0900' }}
          </td>
          <td nzAlign="center">
            <nz-row [nzGutter]="[5, 5]">
              <nz-col>
                <button
                  *ngIf="isCurrentUserOfficeWorldwide"
                  class="bid-place-comment-btn"
                  nz-button
                  nz-tooltip
                  [nzTooltipTitle]="'USER_REQUESTS.BID_PLACE_COMMENT' | translate"
                  [nzType]="expandSet.has(item.id) ? 'default' : 'primary'"
                  (click)="toggleComments(item.id)"
                  appDriverStep
                  [overrideStepIndex]="4"
                  [stepTitle]="'LOT_INFO.GUIDE.COMMENT.TITLE' | translate"
                  [stepDescription]="'LOT_INFO.GUIDE.COMMENT.DESCR' | translate"
                  [stepSide]="'left'"
                  [stepAlign]="'start'"
                  [styles]="['pulse-comment-btn']"
                  [popoverClass]="'bids'"
                >
                  <i nz-icon nzType="comment" nzTheme="outline"></i>
                </button>
              </nz-col>
              <nz-col>
                <app-lot-participation-button
                  [bid]="item"
                  [hasTranslations]="!!item.lot?.translations?.length"
                ></app-lot-participation-button>
                <hr />
                <button
                  *ngIf="['initial', 'confirmed'].includes(item.status)"
                  class="bid-place-delete-btn"
                  nz-button
                  nzType="primary"
                  nzDanger
                  nz-tooltip
                  [nzTooltipTitle]="'USER_REQUESTS.BID_DELETE_REQUEST' | translate"
                  (click)="openDeleteBidModal(item)"
                  appDriverStep
                  [overrideStepIndex]="5"
                  [stepTitle]="'LOT_INFO.GUIDE.DELETE_BID.TITLE' | translate"
                  [stepDescription]="'LOT_INFO.GUIDE.DELETE_BID.DESCR' | translate"
                  [stepSide]="'left'"
                  [stepAlign]="'start'"
                  [styles]="['pulse-delete-btn']"
                  [popoverClass]="'bids'"
                >
                  <i nz-icon nzType="delete" nzTheme="outline"></i>
                </button>
              </nz-col>
            </nz-row>
          </td>
        </tr>
        <tr [nzExpand]="expandSet.has(item.id)">
          <app-bid-comments
            [bidId]="item.id"
            [currentUserId]="currentUserId"
            [bid]="item"
          ></app-bid-comments>
        </tr>
      </ng-container>
    </tbody>
  </nz-table>
</div>
