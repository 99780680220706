import { QueryHandlingService, WatchQueryRef } from '@akebono/core';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  CurrentLotMarksListsGQL,
  CurrentLotMarksListsQuery,
  CurrentLotMarksListsQueryVariables,
} from '../../graphql/service/graphql-auto-main-service';

@Component({
  selector: 'app-lot-shared-includes',
  templateUrl: './lot-shared-includes.component.html',
  styleUrls: ['./lot-shared-includes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotSharedIncludesComponent implements OnInit {
  @Input() lotId: number;

  queryRef$: WatchQueryRef<CurrentLotMarksListsQuery, CurrentLotMarksListsQueryVariables>;
  data$: Observable<string[]>;

  constructor(
    private qhs: QueryHandlingService,
    private currentLotMarksListsGQL: CurrentLotMarksListsGQL,
  ) {}

  ngOnInit(): void {
    if (this.queryRef$) {
      this.queryRef$.refetch();
    } else {
      this.queryRef$ = this.qhs.watch(this.currentLotMarksListsGQL, {});

      this.data$ = this.queryRef$.data.pipe(
        map((res) =>
          res.currentUser.lotMarksLists.map(
            (markList) =>
              markList.lotMarks.find((mark) => mark.lot.id === this.lotId)?.userLotMarksListTitle,
          ),
        ),
      );
    }
  }
}
