import { AkebonoCoreModule } from '@akebono/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LinkOutline } from '@ant-design/icons-angular/icons';
import { TranslateModule } from '@ngx-translate/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSpinModule } from 'ng-zorro-antd/spin';

import { LotSharedAddListButtonComponent } from './lot-shared-add-list-button.component';

@NgModule({
  declarations: [LotSharedAddListButtonComponent],
  exports: [LotSharedAddListButtonComponent],
  imports: [
    CommonModule,
    NzLayoutModule,
    AkebonoCoreModule,
    TranslateModule,
    NzButtonModule,
    NzModalModule,
    NzSelectModule,
    NzFormModule,
    FormsModule,
    ReactiveFormsModule,
    NzInputModule,
    NzPopoverModule,
    NzSpinModule,
    NzIconModule.forRoot([LinkOutline]),
  ],
})
export class LotSharedAddListButtonModule {}
