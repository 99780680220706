import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { UniversalLotTableFragment } from '../../graphql/service/graphql-auto-main-service';

@Component({
  selector: 'app-lots-universal-table',
  templateUrl: './lots-universal-table.component.html',
  styleUrls: ['./lots-universal-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotsUniversalTableComponent implements OnInit {
  @Input() lots: UniversalLotTableFragment[] = [];
  @Input() customTitle?: string;
  @Input() loading: boolean = false;
  @Input() total: number = 0;
  @Input() page: number = 1;
  @Input() pageSize: number = 20;

  constructor(private router: Router, private translate: TranslateService) {}

  ngOnInit(): void {}

  getTitle(): string {
    if (this.customTitle) {
      return this.customTitle;
    } else {
      return `${this.translate.instant('TOTAL')}: ${this.total}`;
    }
  }

  changePage(pageIndex: number): void {
    this.router.navigate([], {
      queryParams: {
        pageIndex,
        pageSize: this.pageSize,
      },
      queryParamsHandling: 'merge',
    });
  }

  changePageSize(pageSize: number): void {
    this.router.navigate([], {
      queryParams: {
        pageSize,
        pageIndex: 1,
      },
      queryParamsHandling: 'merge',
    });
  }
}
