import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { AutoLotView } from 'src/app/const';
import { gearboxTypeMap } from 'src/app/gearbox-types';
import { en } from 'src/locale/en';

import { LotStatusEnum, LotTypeEnum } from '../../graphql/service/graphql-auto-main-service';
import { PurchaseReportTemplateComponent } from './components/purchase-report-template/purchase-report-template.component';

@Component({
  selector: 'app-purchase-report-form-modal',
  templateUrl: './purchase-report-form-modal.component.html',
  styleUrls: ['./purchase-report-form-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PurchaseReportFormModalComponent implements OnInit {
  @Input() lot: AutoLotView;
  @Input() lotType: LotTypeEnum;

  form: FormGroup;

  labelSpan = 7;
  controlSpan = 24 - this.labelSpan;

  maxYear = new Date().getFullYear();
  minYear = this.maxYear - 60;

  photoUrls: string[] = [];
  lotStatuses = Object.values(LotStatusEnum).map((status) => en.LOT_STATUSES[status.toUpperCase()]);

  @ViewChild('reportTemplate')
  private reportTemplateComponent: PurchaseReportTemplateComponent;

  constructor(fb: FormBuilder, private readonly modal: NzModalRef) {
    this.form = fb.group({
      model: fb.control(null),
      mod: fb.control(null),
      auctionDate: fb.control(null),
      auction: fb.control(null),
      lotNo: fb.control(null),
      grade: fb.control(null),
      registrYear: fb.control(null),
      registrYearType: fb.control('year'),
      productYear: fb.control(null),
      productYearType: fb.control('date'),
      chassis: fb.control(null),
      vin: fb.control(null),
      start: fb.control(null),
      final: fb.control(null),
      negotiations: fb.control(null),
      status: fb.control(null),
      engine: fb.control(null),
      color: fb.control(null),
      odometer: fb.control(null),
      transmission: fb.control(null),
      length: fb.control(null),
      width: fb.control(null),
      height: fb.control(null),
      recycleFee: fb.control(null),
      mainPhotoUrl: fb.control(null),
      firstAdditionalPhotoUrl: fb.control(null),
      secondAdditionalPhotoUrl: fb.control(null),
    });
  }

  ngOnInit(): void {
    this.photoUrls = this.lot.images || [];
    this.form.patchValue({
      model: `${this.lot.company} ${this.lot.modelName}`.trim(),
      mod: this.decodeString(this.lot.modelGrade),
      auctionDate: this.parseDate(this.lot.date),
      auction: this.lot.auctionName,
      lotNo: this.lot.bid,
      grade: this.lot.rate,
      registrYear: this.lot.year ? new Date(`${this.lot.year}-01-01`) : null,
      productYear: this.parseDate(this.lot.productionDate) || null,
      chassis: this.lot.frame,
      vin: this.lot.vin || this.lot.frame,
      start: Number(this.lot.startPrice) || null,
      final: Number(this.lot.finishPrice) || null,
      negotiations: Number(this.lot.negotiationsPrice) || null,
      status: this.lot.status ? en.LOT_STATUSES[this.lot.status.toUpperCase()] : null,
      engine: `${this.lot.engineVolume}cc`,
      color: this.lot.color,
      odometer: this.parseMilage(this.lot.mileage),
      transmission: [this.lot.transmission, this.getGearboxType(this.lot.transmission)].join(', '),
      mainPhotoUrl: this.lot.images[0] || null,
      firstAdditionalPhotoUrl: this.lot.images[1] || null,
      secondAdditionalPhotoUrl: this.lot.images[2] || null,
    });
  }

  print(): void {
    this.reportTemplateComponent.print();
  }

  close(): void {
    this.modal.destroy();
  }

  private getGearboxType(transmission: string | null | undefined): string | null {
    const gearboxType = gearboxTypeMap.get(transmission?.trim());
    return gearboxType ?? null;
  }

  private parseDate(dateString: string | null | undefined): Date | null {
    if (dateString) {
      return new Date(dateString);
    } else {
      return null;
    }
  }

  private parseMilage(mileage: string | null | undefined): number | null {
    if (!mileage) {
      return null;
    }

    if (this.lotType !== LotTypeEnum.Moto) {
      mileage = `${mileage}000`;
    }

    return Number(mileage) || null;
  }

  private decodeString(value: string | null | undefined): string | null {
    const domParser = new DOMParser();

    if (value) {
      return domParser.parseFromString(value, 'text/html').body.innerText;
    } else {
      return null;
    }
  }
}
