import { Injectable } from '@angular/core';
import { fromEvent, merge, Observable, Subject } from 'rxjs';
import { filter, map, share, startWith } from 'rxjs/operators';

type LocalStorageChange = {
  key: string | null;
  newValue: string | null;
  oldValue: string | null;
};

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
  readonly changes$: Observable<LocalStorageChange>;

  private readonly _length$: Observable<number>;
  private readonly currentTabChanges$ = new Subject<LocalStorageChange>();

  constructor() {
    const otherTabChanges$ = fromEvent<StorageEvent>(window, 'storage').pipe(
      map(
        (event) =>
          <LocalStorageChange>{
            key: event.key,
            newValue: event.newValue,
            oldValue: event.oldValue,
          },
      ),
    );

    this.changes$ = merge(this.currentTabChanges$, otherTabChanges$).pipe(share());
    this._length$ = this.changes$.pipe(map(() => this.length));
  }

  set(key: string, value: unknown): void {
    const oldValue = localStorage.getItem(key);
    const newValue = JSON.stringify(value);

    localStorage.setItem(key, newValue);
    this.currentTabChanges$.next({ key, oldValue, newValue });
  }

  get<T>(key: string): T | null;
  get<T>(key: string, defaultValue: T, initItem?: boolean): T;
  get<T>(key: string, defaultValue?: T, initItem = true): T | null {
    const valueJson = localStorage.getItem(key);
    if (valueJson === null) {
      if (defaultValue && initItem) {
        this.set(key, defaultValue);
      }

      return defaultValue ?? null;
    } else {
      return JSON.parse(valueJson);
    }
  }

  get$<T>(key: string): Observable<T | null> {
    return this.changes$.pipe(
      filter((change) => (change.key !== null ? change.key === key : true)),
      map(({ newValue }) => newValue),
      map((value) => (value === null ? null : JSON.parse(value))),
    );
  }

  remove(key: string): void {
    if (this.has(key)) {
      const oldValue = localStorage.getItem(key);

      localStorage.removeItem(key);

      this.currentTabChanges$.next({ key, oldValue, newValue: null });
    }
  }

  has(key: string): boolean {
    return localStorage.getItem(key) !== null;
  }

  clear(): void {
    if (this.length > 0) {
      localStorage.clear();
      this.currentTabChanges$.next({ key: null, oldValue: null, newValue: null });
    }
  }

  key(index: number): string | null {
    return localStorage.key(index);
  }

  get length(): number {
    return localStorage.length;
  }

  get length$(): Observable<number> {
    return this._length$.pipe(startWith(this.length));
  }
}
