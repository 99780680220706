<nz-collapse
  appDriverStep
  [overrideStepIndex]="1"
  [stepTitle]="'LOT_INFO.GUIDE.FILTER.TITLE' | translate"
  [stepDescription]="'LOT_INFO.GUIDE.FILTER.DESCR' | translate"
  [stepSide]="'left'"
  [stepAlign]="'start'"
  [popoverClass]="'bids'"
  [nzBordered]="false"
  class="mb-10"
>
  <nz-collapse-panel [nzHeader]="'FILTERS' | translate" nzActive>
    <form nz-form [formGroup]="form" [nzLayout]="'vertical'">
      <div nz-row [nzGutter]="24">
        <nz-col>
          <nz-form-item>
            <nz-form-label>
              <strong>{{ 'LOT_INFO.AUCTION_DATE' | translate }}</strong>
            </nz-form-label>
            <nz-form-control>
              <nz-row>
                <nz-input-group nzCompact nzSize="small">
                  <nz-date-picker
                    nzFormat="yyyy-MM-dd"
                    formControlName="bidLotAuctionDateFrom"
                    nzPlaceHolder="Start date"
                  ></nz-date-picker>
                  <nz-date-picker
                    nzFormat="yyyy-MM-dd"
                    formControlName="bidLotAuctionDateTo"
                    nzPlaceHolder="End date"
                  ></nz-date-picker>
                  <button nz-button type="button" (click)="setDateToday()">
                    {{ 'TODAY' | translate }}
                  </button>
                </nz-input-group>
              </nz-row>
            </nz-form-control>
          </nz-form-item>
        </nz-col>
        <nz-col>
          <nz-form-item>
            <nz-form-label>
              <strong>{{ 'Аукцион' | translate }}</strong>
            </nz-form-label>
            <nz-form-control>
              <input nz-input formControlName="auctionName" />
            </nz-form-control>
          </nz-form-item>
        </nz-col>
        <nz-col>
          <nz-form-item>
            <nz-form-label>
              <strong>{{ 'Производитель' | translate }}</strong>
            </nz-form-label>
            <nz-form-control>
              <input nz-input formControlName="company" />
            </nz-form-control>
          </nz-form-item>
        </nz-col>
        <nz-col>
          <nz-form-item>
            <nz-form-label>
              <strong>{{ 'Модель' | translate }}</strong>
            </nz-form-label>
            <nz-form-control>
              <input nz-input formControlName="model" />
            </nz-form-control>
          </nz-form-item>
        </nz-col>
        <nz-col>
          <nz-form-item>
            <nz-form-label>
              <strong>{{ 'Кузов' | translate }}</strong>
            </nz-form-label>
            <nz-form-control>
              <input nz-input formControlName="modelType" />
            </nz-form-control>
          </nz-form-item>
        </nz-col>
        <nz-col>
          <nz-form-item>
            <nz-form-label>
              <strong>{{ 'CONDITION' | translate }}</strong>
            </nz-form-label>
            <nz-form-control>
              <nz-select
                [nzDropdownMatchSelectWidth]="false"
                nzAllowClear
                formControlName="condition"
                nzPlaceHolder="{{ 'LOT_BIDDING_MODAL.SELECT_CONDITIONS' | translate }}"
              >
                <nz-option
                  *ngFor="let condition of conditions"
                  [nzLabel]="condition.title | translate"
                  [nzValue]="condition.id"
                >
                </nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </nz-col>
        <nz-col>
          <nz-form-item>
            <nz-form-label>
              <strong>{{ 'GROUP' | translate }}</strong>
            </nz-form-label>
            <nz-form-control>
              <input nz-input formControlName="groupLetter" />
            </nz-form-control>
          </nz-form-item>
        </nz-col>
        <nz-col>
          <nz-form-item>
            <nz-form-label>
              <strong>{{ 'Номер лота' | translate }}</strong>
            </nz-form-label>
            <nz-form-control>
              <input nz-input formControlName="code" />
            </nz-form-control>
          </nz-form-item>
        </nz-col>
      </div>
      <div nz-row>
        <div nz-col [nzSpan]="24" class="search-area">
          <nz-space>
            <button *nzSpaceItem nz-button nzType="primary" (click)="filter()">
              {{ 'SEARCH' | translate }}
            </button>
            <button *nzSpaceItem nz-button (click)="resetFilter()">
              {{ 'CLEAR' | translate }}
            </button>
          </nz-space>
        </div>
      </div>
    </form>
  </nz-collapse-panel>
</nz-collapse>

<section
  appDriverStep
  [overrideStepIndex]="2"
  [stepTitle]="'LOT_INFO.GUIDE.STATUS.TITLE' | translate"
  [stepDescription]="'LOT_INFO.GUIDE.STATUS.DESCR' | translate"
  [stepSide]="'left'"
  [stepAlign]="'start'"
  [popoverClass]="'bids'"
  nz-row
  nzJustify="start"
  [nzGutter]="[10, 10]"
  class="status-buttons"
>
  <div nz-col *ngFor="let status of tabs">
    <button
      nz-button
      nzSize="large"
      [nzType]="tab === status ? 'primary' : 'default'"
      (click)="changeTab(status)"
    >
      {{ status | uppercase | translate }}
      <ng-container *ngIf="counters[status]"> ({{ counters[status] }})</ng-container>
    </button>
  </div>
</section>
