<nz-spin nzSize="large" [nzSpinning]="loading$ | async">
  <header>
    <app-lot-last-visit-date [lotId]="(data$ | async)?.lot?.id"></app-lot-last-visit-date>
    <nz-result
      *ngIf="(loading$ | async) === false && !(data$ | async)?.lot"
      nzStatus="404"
      [nzTitle]="'LOT_NOT_FOUND' | translate"
    ></nz-result>
    <app-lot-title-full *ngIf="(data$ | async)?.lot as lot" [lot]="lot"></app-lot-title-full>
  </header>

  <main *ngIf="data$ | async as data" nz-row nzGutter="16">
    <aside nz-col nzXs="24" nzLg="10">
      <section>
        <app-lot-carousel
          [lot]="data.lot"
          [lotType]="data.lotType"
          [lotSource]="data.lotSource"
          [isUsingSavedImages]="data.isUsingSavedImages"
          (imgDelete)="refetch$.next()"
        ></app-lot-carousel>
      </section>

      <section>
        <app-alerts-list-auto
          [target]="'lot_page'"
          [auction]="data.lot.auctionName"
          [lotCode]="data.lot.bid"
        ></app-alerts-list-auto>
      </section>
    </aside>

    <aside nz-col nzXs="24" nzLg="14">
      <section>
        <app-lot-icons
          [lot]="data.lot"
          [lotType]="data.lotType"
          [lotSource]="data.lotSource"
          [lotIcons]="data.icons"
          [currentUser]="data.currentUser"
        ></app-lot-icons>
      </section>

      <section>
        <app-lot-actions
          [lot]="data.lot"
          [limits]="data.limits"
          [lotType]="data.lotType"
          [lotSource]="data.lotSource"
          [savedLot]="data.savedLot"
          [bids]="data.bids"
          [isLotSanctioned]="false"
          [hasTranslations]="data.hasTranslations"
          [currentUser]="data.currentUser"
          [currentUserLastBid]="data.currentUserLastBid"
          [userLotMarks]="data.currentUserLotMarks"
          [currentUserLimitsOverrideAvailable]="data.currentUserLimitsOverrideAvailable"
          (actionCompleted)="refetch$.next()"
          (scrollToTranslations)="scrollToTranslations()"
        ></app-lot-actions>
      </section>

      <section>
        <app-lot-shared-add-list-button
          [lot]="data.lot"
          [lotType]="data.lotType"
          [lotSource]="data.lotSource"
          [userLotMarks]="data.currentUserLotMarks"
        ></app-lot-shared-add-list-button>
      </section>

      <section>
        <app-lot-shared-includes [lotId]="data.lot?.id"></app-lot-shared-includes>
      </section>

      <section>
        <app-lot-translations
          id="lotTranslations"
          [translations]="data.translations"
        ></app-lot-translations>
      </section>

      <section>
        <app-lot-boundary-info
          [lot]="data.lot"
          [style]="'alert'"
          [excludeStatuses]="[BoundaryStatus.NotBoundary, BoundaryStatus.Early]"
        ></app-lot-boundary-info>
      </section>

      <section>
        <app-lot-main-info
          [lot]="data.lot"
          [lotType]="data.lotType"
          [lotSource]="data.lotSource"
          [bestBid]="data.bestBid"
          [hasBestBid]="data.hasBestBid"
          [currentUser]="data.currentUser"
          [hasTranslations]="data.hasTranslations"
          [currentUserLastBid]="data.currentUserLastBid"
          (actionComplete)="refetch$.next()"
        ></app-lot-main-info>
      </section>

      <section>
        <lib-akebono-calculator [lot]="data.calcData"></lib-akebono-calculator>
      </section>
    </aside>
  </main>
</nz-spin>
