import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-taa-auction',
  templateUrl: './taa-auction.component.html',
  styleUrls: ['./taa-auction.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaaAuctionComponent {
  @Input() lang = 'ru';
}
