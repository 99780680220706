import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SharedChannelComponent } from './components/shared-channel/shared-channel.component';
import { SharedLotComponent } from './components/shared-lot/shared-lot.component';
import { SharedSelectionsComponent } from './components/shared-selections/shared-selections.component';

const routes: Routes = [
  {
    path: 'shared',
    children: [{ path: ':id', component: SharedLotComponent }],
  },
  {
    path: 'list',
    children: [{ path: ':id', component: SharedSelectionsComponent }],
  },
  {
    path: 'channel',
    children: [{ path: ':path', component: SharedChannelComponent }],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class SharedPagesRoutingModule {}
