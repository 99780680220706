import { FetchQueryRef, QueryHandlingService } from '@akebono/core';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { LotMarksListGQL, LotMarksListQuery } from 'src/app/modules/graphql/service/graphql-auto-shared-service';

@Component({
  selector: 'app-shared-selections',
  templateUrl: './shared-selections.component.html',
  styleUrls: ['./shared-selections.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedSelectionsComponent implements OnInit {
  listId: string;
  queryRef?: FetchQueryRef<LotMarksListQuery>;

  constructor(
    private title: Title,
    private route: ActivatedRoute,
    private qhs: QueryHandlingService,
    private lotMarksListGQL: LotMarksListGQL,
  ) {
    this.listId = this.route.snapshot.params?.id;
  }

  ngOnInit(): void {
    this.queryRef = this.qhs.fetch(this.lotMarksListGQL, { id: this.listId }, 'network-only');

    this.queryRef.data.subscribe((e) => {
      console.log(e, '123');
    });
  }
}
