import { QueryHandlingService } from '@akebono/core';
import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { interval, merge, Observable, Subject } from 'rxjs';
import { map, shareReplay, startWith, switchMap } from 'rxjs/operators';

import {
  UserPurchaseStatisticsGQL,
  UserPurchaseStatisticsQueryVariables,
} from '../../../graphql/service/graphql-auto-main-service';
import { UserPurchaseStatisticsDates } from '../types/user-purchase-statistics-dates.type';
import { UserPurchaseStatistics } from '../types/user-purchase-statistics.type';

@Injectable()
export class UserPurchaseStatisticsService {
  readonly loading$: Observable<boolean>;
  readonly statistics$: Observable<UserPurchaseStatistics>;

  private forceUpdateSubject = new Subject<void>();
  private readonly AUTO_UPDATE_AFTER_MINUTES = 10;

  constructor(qhs: QueryHandlingService, userPurchaseStatisticsGQL: UserPurchaseStatisticsGQL) {
    const queryRef$ = merge(
      this.forceUpdateSubject,
      interval(this.AUTO_UPDATE_AFTER_MINUTES * 1000 * 60),
    ).pipe(
      startWith({}),
      map(() => this.statisticsDates),
      map((dates) => {
        return <UserPurchaseStatisticsQueryVariables>{
          todayDate: dates.today.toISO(),
          yesterdayDate: dates.yesterday.toISO(),
          weekStartDate: dates.weekStart.toISO(),
        };
      }),
      map((variables) => qhs.fetch(userPurchaseStatisticsGQL, variables, 'network-only')),
      shareReplay(1),
    );

    this.loading$ = queryRef$.pipe(switchMap((ref) => ref.loading));
    this.statistics$ = queryRef$.pipe(
      switchMap((ref) => ref.data),
      map((data) => data.currentUser),
      map(
        ({ todayPurchases, yesterdayPurchases, weekPurchases }) =>
          <UserPurchaseStatistics>{
            today: todayPurchases?.total ?? 0,
            yesterday: yesterdayPurchases?.total ?? 0,
            week: weekPurchases?.total ?? 0,
          },
      ),
      startWith({ today: 0, yesterday: 0, week: 0 }),
      shareReplay(1),
    );
  }

  get statisticsDates(): UserPurchaseStatisticsDates {
    const today = DateTime.now().setZone('UTC', { keepLocalTime: true }).startOf('day');
    return {
      today: today,
      yesterday: today.minus({ day: 1 }),
      weekStart: today.minus({ week: 1 }),
    };
  }

  update(): void {
    this.forceUpdateSubject.next();
  }
}
