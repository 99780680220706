<main>
  <section *ngIf="type === UserRequestTypeEnum.BidDeleteRequest">
    <nz-alert
      nzType="warning"
      [nzMessage]="'WARNING' | translate"
      [nzDescription]="descriptionTemplate"
      nzShowIcon
    ></nz-alert>
    <ng-template #descriptionTemplate>
      <div style="font-size: 14px;">{{'USER_REQUESTS.BID_DELETE_WARNING' | translate}}
        <p><b>{{'USER_REQUESTS.BID_DELETE_WARNING2' | translate}}</b>
          {{'USER_REQUESTS.BID_DELETE_WARNING3' | translate}}
        </p>
      </div>
    </ng-template>
  </section>
  <section></section>
  <form nz-form>
    <nz-form-item>
      <nz-form-label>
        {{ 'USER_REQUESTS.MESSAGE' | translate }}
      </nz-form-label>
      <nz-form-control>
        <textarea
          rows="4"
          nz-input
          [(ngModel)]="message"
          [ngModelOptions]="{ standalone: true }"
        ></textarea>
      </nz-form-control>
    </nz-form-item>
  </form>
</main>

<footer *nzModalFooter>
  <button nz-button (click)="modal.destroy()">
    {{ 'CANCEL' | translate }}
  </button>
  <button nz-button nzType="primary" [nzLoading]="creating$ | async" (click)="create()">
    {{ 'USER_REQUESTS.CREATE' | translate }}
  </button>
</footer>
