import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';

import { LotBodyDefectsInfoComponent } from './lot-body-defects-info.component';

@NgModule({
  declarations: [LotBodyDefectsInfoComponent],
  imports: [CommonModule, TranslateModule, NzCollapseModule],
  exports: [LotBodyDefectsInfoComponent],
})
export class LotBodyDefectsInfoModule {}
