import { AkebonoCoreModule } from '@akebono/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { LetModule } from '@rx-angular/template';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzStatisticModule } from 'ng-zorro-antd/statistic';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzTypographyModule } from 'ng-zorro-antd/typography';

import { BidPriorityPassStatusModule } from '../bid-priority-pass-status/bid-priority-pass-status.module';
import { LotAuctionFeedbackModule } from '../lot-auction-feedback/lot-auction-feedback.module';
import { LotAuctionNameModule } from '../lot-auction-name/lot-auction-name.module';
import { LotBidAmountModule } from '../lot-bid-amount/lot-bid-amount.module';
import { LotBoundaryInfoModule } from '../lot-boundary-info/lot-boundary-info.module';
import { LotCountersModule } from '../lot-counters/lot-counters.module';
import { SubscribeToBestBidButtonModule } from '../subscribe-to-best-bid-button/subscribe-to-best-bid-button.module';
import { LotEditAuctionDatetimeComponent } from './components/lot-edit-auction-datetime/lot-edit-auction-datetime.component';
import { LotEditEngineVolumeComponent } from './components/lot-edit-engine-volume/lot-edit-engine-volume.component';
import { LotEditModelBrandComponent } from './components/lot-edit-model-brand/lot-edit-model-brand.component';
import { LotEditRatingComponent } from './components/lot-edit-rating/lot-edit-rating.component';
import { LotEditStartPriceComponent } from './components/lot-edit-start-price/lot-edit-start-price.component';
import { LotEditYearComponent } from './components/lot-edit-year/lot-edit-year.component';
import { LotMainInfoComponent } from './components/lot-main-info/lot-main-info.component';
import { LotNegotiationsButtonComponent } from './components/lot-negotiations-button/lot-negotiations-button.component';
import { LotParticipationButtonComponent } from './components/lot-participation-button/lot-participation-button.component';
import { DriverModule } from '../driver/driver.module';
import { LotCheckProductionDateModule } from '../lot-check-production-date/lot-check-production-date.module';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';

@NgModule({
  declarations: [
    LotMainInfoComponent,
    LotEditStartPriceComponent,
    LotEditAuctionDatetimeComponent,
    LotEditModelBrandComponent,
    LotEditRatingComponent,
    LotEditEngineVolumeComponent,
    LotEditYearComponent,
    LotNegotiationsButtonComponent,
    LotParticipationButtonComponent,
    LotMainInfoComponent,
  ],
  imports: [
    CommonModule,
    AkebonoCoreModule,
    LotBoundaryInfoModule,
    SubscribeToBestBidButtonModule,
    NzDescriptionsModule,
    TranslateModule,
    FormsModule,
    NzInputModule,
    NzInputNumberModule,
    NzButtonModule,
    NzStatisticModule,
    NzTypographyModule,
    NzTagModule,
    NzIconModule,
    NzGridModule,
    NzPopoverModule,
    NzDatePickerModule,
    NzFormModule,
    LotCountersModule,
    LotAuctionNameModule,
    LotBidAmountModule,
    LetModule,
    BidPriorityPassStatusModule,
    LotAuctionFeedbackModule,
    DriverModule,
    LotCheckProductionDateModule,
    NzCollapseModule,
  ],
  exports: [LotParticipationButtonComponent, LotNegotiationsButtonComponent, LotMainInfoComponent],
})
export class LotMainInfoModule {}
