import { NotificationService } from '@akebono/core';
import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client';
import { Apollo, Mutation } from 'apollo-angular';
import { FetchResult } from 'apollo-link';
import { BehaviorSubject, combineLatest, defer, from, Observable, of } from 'rxjs';
import { catchError, mapTo, share, startWith, switchMap } from 'rxjs/operators';

export interface MutationTranslationsKeys {
  successTranslationKey?: string;
  failureTranslationKey?: string;
}

export interface MutationHandlerOptions extends MutationTranslationsKeys {
  refetch?: boolean;
}

type NotificationType = 'success' | 'fail' | 'warning';

@Injectable({
  providedIn: 'root',
})
export class MutationHandlingService {
  constructor(private notificationService: NotificationService, private apollo: Apollo) {}

  processingOf<T, R>(
    mutation: Mutation<T, R>,
    input: R,
    options: MutationHandlerOptions = {
      refetch: false,
    },
  ): Observable<boolean> {
    const loading = new BehaviorSubject(true);
    mutation.mutate(input).subscribe(
      (result: FetchResult) => {
        console.log('Response', result);
        if (!result?.data) {
          this.notify('fail', options, 'Empty response');
          loading.next(false);
          return;
        }

        const payloadKey = Object.keys(result.data)[0];
        if (result.data[payloadKey].error) {
          this.notify('fail', options, result.data[payloadKey].error.message);
          loading.next(false);
        } else {
          this.notify('success', options);

          if (options.refetch) {
            this.apollo
              .use(mutation.client)
              .client.reFetchObservableQueries()
              .finally(() => loading.next(false));
          } else {
            loading.next(false);
          }
        }
      },
      (error) => {
        console.log(error);
        this.notify('fail', options, error);
        loading.next(false);
      },
    );
    return loading.asObservable();
  }

  processingFrom<T, R>(
    mutation: Mutation<T, R>,
    inputs: R[],
    options: MutationHandlerOptions = {
      refetch: false,
    },
  ): Observable<boolean> {
    const mutationObservables = inputs.map((input) => mutation.mutate(input));
    return combineLatest(mutationObservables).pipe(
      switchMap((results: FetchResult[]) => {
        const failedResults = results.filter((result) => {
          const key = Object.keys(result.data)[0];
          const error = Boolean(result.data[key].error);
          return !result?.data || error;
        });

        const successResults = results.filter((result) => {
          const key = Object.keys(result.data)[0];
          const error = Boolean(result.data[key].error);
          return result?.data && !error;
        });

        console.log('Responses', results);

        if (failedResults.length === results.length) {
          const key = Object.keys(failedResults[0].data)[0];
          const message = failedResults[0]?.data[key]?.error?.message;
          this.notify('fail', options, message || 'Empty response');
          return of(false);
        }

        if (successResults.length === results.length) {
          this.notify('success', options);

          if (options.refetch) {
            return this.refetch(mutation.client, false);
          }
        }

        if (successResults.length !== results.length && failedResults.length !== results.length) {
          this.notify('warning', options);

          if (options.refetch) {
            return this.refetch(mutation.client, false);
          }
        }

        return of(false);
      }),

      catchError((error, caught: Observable<boolean>) => {
        console.log(error);
        this.notify('fail', options, error);
        return of(false);
      }),

      startWith(true),

      share(),
    );
  }

  handle<T, R>(
    mutation: Mutation<T, R>,
    input: R,
    options: MutationTranslationsKeys = {},
  ): Observable<FetchResult<T>> {
    return from(
      mutation
        .mutate(input)
        .toPromise()
        .then(
          (result) => {
            const payloadKey = Object.keys(result.data)[0];
            if (result.data[payloadKey].error) {
              this.notify('fail', options, result.data[payloadKey].error.message);
              throw new Error(result.data[payloadKey].error.message);
            } else {
              this.notify('success', options);
              return result;
            }
          },
          (error) => {
            this.notify('fail', options, error.message);
            throw new Error(error.message);
          },
        ),
    );
  }

  handleAll<T, R>(
    mutation: Mutation<T, R>,
    inputs: R[],
    options: MutationTranslationsKeys = {},
  ): Observable<FetchResult[]> {
    return new Observable((subscribe) => {
      const mutationObservables = inputs.map((input) => mutation.mutate(input));
      const mutationSubs = combineLatest(mutationObservables).subscribe(
        (results) => {
          const failedResults = results.filter((result) => {
            const key = Object.keys(result.data)[0];
            const error = Boolean(result.data[key].error);
            return !result?.data || error;
          });

          const successResults = results.filter((result) => {
            const key = Object.keys(result.data)[0];
            const error = Boolean(result.data[key].error);
            return result?.data && !error;
          });

          console.log('Responses', results);

          if (failedResults.length === results.length) {
            const key = Object.keys(failedResults[0].data)[0];
            const message = failedResults[0]?.data[key]?.error?.message;
            this.notify('fail', options, message || 'Empty response');
            subscribe.error(message || 'Empty response');
          }

          if (successResults.length === results.length) {
            this.notify('success', options);
            subscribe.next(results);
          }

          if (successResults.length !== results.length && failedResults.length !== results.length) {
            this.notify('warning', options);
            subscribe.error(results);
          }

          subscribe.complete();
          mutationSubs.unsubscribe();
        },
        (error) => {
          subscribe.error(error);
          mutationSubs.unsubscribe();
        },
      );
    });
  }

  refetch<T>(client: string, response?: T): Observable<T | ApolloQueryResult<any>[]> {
    if (response) {
      return defer(() => {
        return from(this.apollo.use(client).client.reFetchObservableQueries()).pipe(
          mapTo(response),
        );
      });
    } else {
      return defer(() => {
        return from(this.apollo.use(client).client.reFetchObservableQueries());
      });
    }
  }

  private notify(type: NotificationType, options: MutationHandlerOptions, message?: string) {
    switch (type) {
      case 'fail':
        this.notificationService.renderError(
          options.failureTranslationKey || 'MUTATION_FAILED',
          message,
        );
        break;

      case 'success':
        this.notificationService.renderSuccess(
          options.successTranslationKey || 'MUTATION_SUCCEEDED',
          message,
        );
        break;

      case 'warning':
        this.notificationService.renderWarning(
          options.successTranslationKey || 'MUTATION_PARTIALLY',
          message,
        );
        break;
    }
  }
}
