import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CountryConfigComponent } from './country-config.component';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';

@NgModule({
  declarations: [CountryConfigComponent],
  exports: [CountryConfigComponent],
  imports: [
    CommonModule,
    NzModalModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    NzSelectModule,
    NzFormModule,
    NzInputModule,
  ],
})
export class CountryConfigModule {}
