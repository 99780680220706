import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzStatisticModule } from 'ng-zorro-antd/statistic';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzUploadModule } from 'ng-zorro-antd/upload';

import { LotMiniGalleryModule } from '../shared-components/lot-mini-gallery/lot-mini-gallery.module';
import { BidStatusComponent } from './components/bid-status/bid-status.component';
import { ImportBidsDashboardNotFoundLotsComponent } from './components/import-bids-dashboard-not-found-lots/import-bids-dashboard-not-found-lots.component';
import { ImportBidsDashboardTitleComponent } from './components/import-bids-dashboard-title/import-bids-dashboard-title.component';
import { ImportBidsDashboardComponent } from './components/import-bids-dashboard/import-bids-dashboard.component';
import { ImportBidsPlaceModalComponent } from './components/import-bids-place-modal/import-bids-place-modal.component';
import { ImportFileUploadComponent } from './components/import-file-upload/import-file-upload.component';
import { ImportBidsComponent } from './import-bids.component';
import { BidGroupService } from './services/bid-group.service';
import { BidStatusFactoryService } from './services/bid-status-factory.service';
import { BidService } from './services/bid.service';
import { BiddingDataService } from './services/bidding-data.service';
import { ExcelBidsParserService } from './services/excel-bids-parser.service';
import { LotFetcherService } from './services/lot-fetcher.service';

@NgModule({
  declarations: [
    ImportBidsComponent,
    ImportFileUploadComponent,
    ImportBidsDashboardComponent,
    ImportBidsDashboardTitleComponent,
    ImportBidsDashboardNotFoundLotsComponent,
    BidStatusComponent,
    ImportBidsPlaceModalComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    LotMiniGalleryModule,
    NzTagModule,
    NzGridModule,
    NzIconModule,
    NzSpaceModule,
    NzAlertModule,
    NzUploadModule,
    NzTableModule,
    NzButtonModule,
    NzModalModule,
    NzProgressModule,
    NzStatisticModule,
    NzTypographyModule,
  ],
  providers: [
    ExcelBidsParserService,
    LotFetcherService,
    BiddingDataService,
    BidGroupService,
    BidService,
    BidStatusFactoryService,
  ],
  exports: [ImportBidsComponent],
})
export class ImportBidsModule {}
