import { ConfigService, QueryHandlingService } from '@akebono/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { map, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';
import {
  NewsCardDataFragment,
  NewsListGQL,
  NewsListQueryVariables,
} from 'src/app/modules/graphql/service/graphql-content-service';
import { environment } from 'src/environments/environment';

import { CurrentUserNewsDataService } from '../../services/current-user-news-data.service';

@Component({
  selector: 'app-news-list',
  templateUrl: './news-list.component.html',
  styleUrls: ['./news-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewsListComponent {
  loading$: Observable<boolean>;

  language$: Observable<'ru' | 'en'>;
  news$: Observable<NewsCardDataFragment[]>;
  isNoNews$: Observable<boolean>;

  page = 1;
  pageSize = 20;
  total$: Observable<number>;

  constructor(
    route: ActivatedRoute,
    qhs: QueryHandlingService,
    configService: ConfigService,
    private readonly router: Router,
    currentUserNewsDataService: CurrentUserNewsDataService,
    newsListGQL: NewsListGQL,
  ) {
    const queryRef$ = combineLatest([
      route.queryParams,
      currentUserNewsDataService.userNewsCountry$,
    ]).pipe(
      tap(([params]) => {
        this.page = Number(params.page) || 1;
        this.pageSize = Number(params.pageSize) || 20;
      }),
      map(
        ([, country]) =>
          <NewsListQueryVariables>{
            offset: (this.page - 1) * this.pageSize,
            first: this.pageSize,
            filter: {
              site: {
                domain: {
                  eq: environment.newsDomain,
                },
              },
              country: {
                in: ['all', country],
              },
            },
          },
      ),
      map((variables) => qhs.fetch(newsListGQL, variables, 'network-only')),
      shareReplay(1),
    );

    this.loading$ = combineLatest([
      queryRef$.pipe(
        switchMap((ref) => ref.loading),
        startWith(true),
      ),
      currentUserNewsDataService.loading$,
    ]).pipe(map((loadings) => loadings.some((v) => v)));
    this.news$ = queryRef$.pipe(
      switchMap((ref) => ref.data),
      map((data) => data.news?.items ?? []),
    );
    this.total$ = queryRef$.pipe(
      switchMap((ref) => ref.data),
      map((data) => data.news?.totalCount ?? 0),
    );
    this.language$ = configService
      .getCurrentLanguage$()
      .pipe(map((lang) => lang.localeId as 'ru' | 'en'));
    this.isNoNews$ = this.total$.pipe(map((total) => total === 0));
  }

  changePage(page: number): void {
    this.router.navigate([], {
      queryParams: {
        page,
      },
      queryParamsHandling: 'merge',
    });
  }

  changePageSize(pageSize: number): void {
    this.router.navigate([], {
      queryParams: {
        page: null,
        pageSize,
      },
      queryParamsHandling: 'merge',
    });
  }
}
