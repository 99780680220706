import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

import { LotCountersModule } from '../lot-counters/lot-counters.module';
import { LotInterestingModule } from '../lot-interesting/lot-interesting.module';
import { LotSharedIconModule } from '../lot-shared-icon/lot-shared-icon.module';
import { LotsListLotLinkComponent } from './lots-list-lot-link.component';

@NgModule({
  declarations: [LotsListLotLinkComponent],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    LotCountersModule,
    LotSharedIconModule,
    LotInterestingModule,
    NzGridModule,
    NzIconModule,
    NzButtonModule,
    NzToolTipModule,
  ],
  exports: [LotsListLotLinkComponent],
})
export class LotsListLotLinkModule {}
