import { QueryHandlingService } from '@akebono/core';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { first, map, shareReplay, takeUntil } from 'rxjs/operators';

import { UserCountryIsoGQL } from '../../graphql/service/graphql-auto-main-service';

@Injectable()
export class CurrentUserNewsDataService implements OnDestroy {
  readonly loading$: Observable<boolean>;
  readonly userNewsCountry$: Observable<'ru' | 'another'>;

  private readonly destroy$ = new Subject<void>();

  constructor(qhs: QueryHandlingService, userCountryIsoGQL: UserCountryIsoGQL) {
    const queryRef = qhs.fetch(userCountryIsoGQL);

    this.loading$ = queryRef.loading;
    this.userNewsCountry$ = queryRef.data.pipe(
      map((data) => data.currentUser?.countryIso),
      map((countryIso) => this.countryIsoToNewsCountry(countryIso)),
      shareReplay(1),
    );
  }

  public preload(): void {
    this.userNewsCountry$.pipe(first(), takeUntil(this.destroy$)).subscribe();
  }

  private countryIsoToNewsCountry(countryIso: string | null | undefined): 'ru' | 'another' {
    if (countryIso?.toLocaleLowerCase() === 'ru') {
      return 'ru';
    } else {
      return 'another';
    }
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
