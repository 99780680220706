import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserBidSettingsFragment } from 'src/app/modules/graphql/service/graphql-auto-main-service';

import { SettingsChanges } from '../../types/settings-changes.type';

@Component({
  selector: 'app-bid-settings',
  templateUrl: './bid-settings.component.html',
  styleUrls: ['./bid-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BidSettingsComponent implements OnInit, OnDestroy {
  @Input() loading = false;
  @Input() settings?: UserBidSettingsFragment;
  @Output() settingsChange = new EventEmitter<SettingsChanges['bid']>();

  form: FormGroup;

  private destroy$ = new Subject<void>();

  @Input()
  set bidSettings(settings: UserBidSettingsFragment) {
    this.settings = settings;

    this.form.patchValue({
      bidTranslationByDefault: settings?.bidTranslationByDefault,
      ignoreSmallBidWarning: settings?.ignoreSmallBidWarning,
    });
  }

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      bidTranslationByDefault: this.fb.control(null),
      ignoreSmallBidWarning: this.fb.control(null),
    });
  }

  ngOnInit(): void {
    this.form.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => this.settingsChange.emit(value));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
