<div class="auc_list_shame" id="auc_list_shame_1">
  <img src="/assets/images/auctions/{{ lang }}/USS.png" class="shame_img" alt="USS">
  <div
    class="shame_blocks shame_block_1"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.LOT_NUM' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_2"
    nz-tooltip
    [nzTooltipTitle]="shameblock2"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  >
    <ng-template #shameblock2>
      <div class="header">{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.USS.CAR_HISTORY.TITLE' | translate }}</div>
      <p>{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.USS.CAR_HISTORY.DESCR' | translate }}</p>
    </ng-template>
  </div>
  <div
    class="shame_blocks shame_block_3"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.ENGINE_CAPACITY' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_4"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.CHASSIS_MODEL' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_5"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.USS.FIRST_REG_JP_CHRONO' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_6"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.MODEL_NAME' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_7"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.CHASSIS' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_8"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.MODEL_VERSION' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_9"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.DRIVETRAIN_TYPE' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_10"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.GRADE' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_12"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.INTERIOR_GRADE' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_14"
    nz-tooltip
    [nzTooltipTitle]="shameblock14"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  >
    <ng-template #shameblock14>
      <div class="header">
        {{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.USS.TECH_INSPECTION_VALIDITY_PERIOD.TITLE' | translate }}
      </div>
      <p>{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.USS.TECH_INSPECTION_VALIDITY_PERIOD.DESCR' | translate }}</p>
    </ng-template>
  </div>
  <div
    class="shame_blocks shame_block_15"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.MILEAGE' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_16"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.VEHICLE_COLOR' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_17"
    nz-tooltip
    [nzTooltipTitle]="shameblock17"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  >
    <ng-template #shameblock17>
      <p>{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.COLOR_DESCR_1' | translate }}</p>
      <p>{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.COLOR_DESCR_2' | translate }}</p>
    </ng-template>
  </div>
  <div
    class="shame_blocks shame_block_18"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.COLOR_CODE' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_19"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.FUEL_TYPE.TITLE' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_20"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.FUEL_TYPE.DESCR' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_21"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.INTERIOR_COLOR' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_22"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.IMPORT_HISTORY' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_23"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.MODEL_YEAR' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_24"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.IMPORT_CLASS' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_25"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.DEALER_SELF_IMPORT' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_26"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.STEERING_WHEEL' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_27"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.LEFT_RIGHT' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_28"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.TRANSMISSION' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_29"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.USS.CONDITIONER.TITLE' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_30"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.USS.CONDITIONER.DESCR' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_31"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.SERVICE_BOOK.TITLE' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_32"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.SERVICE_BOOK.DESCR' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div class="shame_blocks shame_block_33"></div>
  <div
    class="shame_blocks shame_block_34"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.USS.DOCS_VALIDITY_PERIOD' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_35"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.ORIGINAL_EQUIPMENT' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_36"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.SUNROOF' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_37"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.LEATHER_SEATS' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_38"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.ALLOY_WHEELS' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_39"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.TV' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_40"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.POWER_STEERING' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_41"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.GPS' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_42"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.POWER_WINDOWS' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_43"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.AIRBAG' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_44"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.SELLER_NOTES' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_45"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.PLATE_NUM' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div class="shame_blocks shame_block_46"></div>
  <div class="shame_blocks shame_block_47"></div>
  <div class="shame_blocks shame_block_48"></div>
  <div class="shame_blocks shame_block_49"></div>
  <div class="shame_blocks shame_block_50"></div>
  <div
    class="shame_blocks shame_block_51"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.SERIAL_NUM' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div class="shame_blocks shame_block_52"></div>
  <div
    class="shame_blocks shame_block_53"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.SELLER_NOTES' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_54"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.INSPECTOR_NOTES' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_55"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.LENGTH' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_56"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.WIDTH' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_57"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.HEIGHT' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_58"
    nz-tooltip
    [nzTooltipTitle]="shameblock58"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  >
    <ng-template #shameblock58>
      <div class="header">
        {{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.DAMAGE_SCHEME.TITLE' | translate }}
      </div>
      <p>
        {{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.DAMAGE_SCHEME.DESCR' | translate }}
        <a href="https://ecarjp.com/article/sheet" target="_blank">
          {{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.DAMAGE_SCHEME.LINK' | translate }}
        </a>
      </p>
    </ng-template>
  </div>
  <div
    class="shame_blocks shame_block_59"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.SPARE_WHEEL' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_60"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.RECYCLING_FEE' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_61"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.SEATING_CAPACITY' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_62"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.CARRYING_CAPACITY' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_63"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.CHASSIS_NUM' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
  <div
    class="shame_blocks shame_block_64"
    nz-tooltip
    nzTooltipTitle="{{ 'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TIPS.GENERAL.MODEL_VERSION' | translate }}"
    nzTooltipPlacement="top"
    [nzTooltipOverlayClassName]="'auction-tip-block-tooltip'"
  ></div>
</div>
