import { Injectable } from '@angular/core';

import { ColorPool } from './color-pool';
import { ColorPoolConfiguration } from './color-pool-configuration.type';

@Injectable()
export class ColorPoolFactoryService {
  public readonly DEFAULT_COLORS: ReadonlyArray<string> = [
    '#FFBF00',
    '#088F8F',
    '#80461B',
    '#800080',
    '#E1C16E',
    '#FFFF00',
    '#FF00FF',
    '#9F2B68',
    '#89CFF0',
    '#FFDEAD',
    '#FFEA00',
    '#CD7F32',
    '#FF69B4',
    '#FA8072',
    '#E9DCC9',
    '#0096FF',
    '#808000',
    '#AAFF00',
    '#96DED1',
    '#A52A2A',
    '#0000FF',
    '#008000',
    '#00FFFF',
    '#93C572',
    '#0BDA51',
    '#FF7F50',
    '#191970',
    '#6F4E37',
    '#DE3163',
    '#FF0000',
    '#5D3FD3',
    '#FF4433',
    '#6d0161',
    '#2e4e80',
    '#d766da',
    '#513b9e',
    '#6a9ca2',
    '#23a7ff',
    '#99407c',
    '#58f37d',
  ];

  public create(config?: Partial<ColorPoolConfiguration>): ColorPool {
    return new ColorPool({
      ...config,
      colors: config?.colors ?? this.DEFAULT_COLORS,
    });
  }
}
