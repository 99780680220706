<nz-spin [nzSpinning]="loading$ | async" nzSize="large">
  <form [formGroup]="form" nz-form>
    <nz-form-item>
      <nz-form-control>
        <nz-select formControlName="lotType" nzPlaceHolder="{{ 'SELECT_LOT_TYPE' | translate }}">
          <nz-option
            *ngFor="let lotType of lotTypes"
            [nzLabel]="lotType.toUpperCase() | translate"
            [nzValue]="lotType"
          >
          </nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
    <nz-divider></nz-divider>
    <nz-form-item>
      <nz-form-label [nzSm]="5" [nzXs]="24" class="align-start">
        {{ 'DATE' | translate }}
      </nz-form-label>
      <nz-form-control [nzSm]="24" [nzXs]="24">
        <nz-date-picker
          formControlName="date"
          nzPlaceHolder="{{ 'DATE' | translate }}"
          nzShowTime
          style="width: 100%"
        ></nz-date-picker>
      </nz-form-control>
    </nz-form-item>
    <nz-divider></nz-divider>
    <nz-form-item>
      <nz-form-label [nzSm]="5" [nzXs]="24" class="align-start">
        {{ 'LOT_INFO.AUCTION' | translate }} / {{ 'LOT_INFO.TITLE' | translate }}
      </nz-form-label>
      <nz-form-control [nzSm]="24" [nzXs]="24" [nzExtra]="'NO_TARGET_AUCTION_TOOLTIP' | translate">
        <nz-input-group nzCompact>
          <nz-select
            formControlName="auctionName"
            nzShowSearch
            [nzDropdownMatchSelectWidth]="false"
            [nzPlaceHolder]="'LOT_INFO.AUCTION' | translate"
            style="width: 50%"
          >
            <nz-option
              *ngFor="let auction of auctions$ | async"
              [nzLabel]="auction"
              [nzValue]="auction"
            ></nz-option>
          </nz-select>
          <input
            formControlName="code"
            nz-input
            placeholder="{{ 'BID_NUMBER' | translate }}"
            style="width: 50%"
          />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
    <nz-divider></nz-divider>
    <nz-form-item>
      <nz-form-label [nzSm]="5" [nzXs]="24" class="align-start">
        {{ 'AUTO' | translate }}
      </nz-form-label>
      <nz-form-control [nzSm]="24" [nzXs]="24">
        <nz-input-group nzCompact>
          <input
            formControlName="company"
            nz-input
            placeholder="{{ 'COMPANY' | translate }}"
            style="width: 50%"
            type="text"
          />
          <input
            formControlName="modelName"
            nz-input
            placeholder="{{ 'MODEL' | translate }}"
            style="width: 50%"
            type="text"
          />
          <input
            formControlName="frame"
            nz-input
            placeholder="{{ 'FRAME_MODEL' | translate }}"
            style="width: 50%"
            type="text"
          />
          <nz-input-number
            [nzMin]="0"
            formControlName="year"
            nzPlaceHolder="{{ 'LOT_INFO.YEAR' | translate }}"
            style="width: 50%"
          ></nz-input-number>
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
    <nz-divider></nz-divider>
    <nz-form-item>
      <nz-form-label [nzSm]="5" [nzXs]="24" class="align-start">
        {{ 'AMOUNT' | translate }}
      </nz-form-label>
      <nz-form-control [nzSm]="24" [nzXs]="24">
        <nz-input-group nzCompact nzSuffix="￥">
          <nz-input-number
            [nzMin]="0"
            [nzStep]="1000"
            formControlName="startPrice"
            nzPlaceHolder="{{ 'LOT_INFO.START_PRICE' | translate }}"
            style="width: 50%"
          ></nz-input-number>
          <nz-input-number
            [nzMin]="0"
            [nzStep]="1000"
            formControlName="amount"
            nzPlaceHolder="{{ 'LOT_BIDDING_MODAL.YOUR_BID' | translate }}"
            style="width: 50%"
          ></nz-input-number>
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
    <nz-divider></nz-divider>
    <nz-form-item>
      <nz-form-control>
        <nz-select
          formControlName="conditionId"
          nzPlaceHolder="{{ 'LOT_BIDDING_MODAL.SELECT_CONDITIONS' | translate }}"
        >
          <nz-option
            *ngFor="let condition of conditions$ | async"
            [nzLabel]="condition.title | translate"
            [nzValue]="condition.id"
          >
          </nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
    <nz-divider></nz-divider>
    <nz-form-item>
      <nz-form-control>
        <nz-input-group>
          <textarea
            formControlName="comment"
            nz-input
            placeholder="{{ 'LOT_BIDDING_MODAL.YOUR_COMMENT' | translate }}"
            rows="2"
          ></textarea>
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
    <nz-divider></nz-divider>
    <nz-form-item>
      <nz-form-control>
        <label formControlName="createTranslation" nz-checkbox>
          {{ 'LOT_BIDDING_MODAL.TRANSLATE_REQUEST' | translate }}
        </label>
      </nz-form-control>
      <nz-form-control>
        <label formControlName="notify" nz-checkbox>
          {{ 'LOT_BIDDING_MODAL.NOTIFY_REQUEST' | translate }}
        </label>
      </nz-form-control>
    </nz-form-item>
  </form>
</nz-spin>

<footer *nzModalFooter>
  <button (click)="modal.close()" [disabled]="isPlacingBid$ | async" nz-button nzType="default">
    {{ 'CANCEL' | translate }}
  </button>
  <button
    (click)="placeBid()"
    [disabled]="loading$ | async"
    [nzLoading]="isPlacingBid$ | async"
    nz-button
    nzType="primary"
  >
    {{ 'LOT_BIDDING_MODAL.PLACE_YOUR_BID' | translate }}
  </button>
</footer>
