import { MutationHandlingServiceV2 } from '@akebono/core';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { IAlbum, Lightbox } from 'ngx-lightbox';
import { Observable, of } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import SwiperCore, { Keyboard, Navigation, Pagination, SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';

import { AutoLotView } from '../../../const';
import {
  LotSource,
  LotTypeEnum,
  LotUpdateGQL,
  UserLotQuery,
} from '../../graphql/service/graphql-auto-main-service';
import { AuctionsTipModalComponent } from './components/auctions-tip-modal/auctions-tip-modal.component';

SwiperCore.use([Pagination, Navigation, Keyboard]);

@Component({
  selector: 'app-lot-carousel',
  templateUrl: './lot-carousel.component.html',
  styleUrls: ['./lot-carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotCarouselComponent implements OnInit, OnChanges {
  @Input() lot: AutoLotView;
  @Input() lotType: LotTypeEnum;
  @Input() lotSource: LotSource;
  @Input() activeSlide: number = 0;
  @Input() isUsingSavedImages: boolean;
  @Input() currentUser?: UserLotQuery['currentUser'];
  @Output() activeSlideChange = new EventEmitter<number>();
  @Output() imgDelete = new EventEmitter<void>();
  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;

  configSwiper: SwiperOptions = {
    loop: true,
    keyboard: true,
    slidesPerView: 1,
    spaceBetween: 0,
    navigation: true,
  };

  albums: IAlbum[] = [];

  isLoadingPhotos: Observable<boolean> = of(false);

  isMovingPhotos = false;

  constructor(
    private lightbox: Lightbox,
    private mhs: MutationHandlingServiceV2,
    private lotUpdateGQL: LotUpdateGQL,
    private modalService: NzModalService,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.createAlbums();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.lot?.currentValue?.images?.length !== changes.lot?.previousValue?.images?.length) {
      this.createAlbums();
    }

    const ref = this.swiper?.swiperRef;

    if (ref && ref?.activeIndex !== this.activeSlide) {
      ref.slideTo(this.activeSlide);
    }

    if (
      this.currentUser?.isAuctioneer ||
      this.currentUser?.isTranslator ||
      this.currentUser?.hasClearPhotos
    ) {
      this.lot.images = this.lot.images.map((src) => src.replace('/pp', ''));
    }
  }

  createAlbums(): void {
    this.albums = [];
    this.lot.images.map((image) => {
      this.albums.push({
        src: image,
        caption: this.lot.company + ' ' + this.lot.modelName,
        thumb: image,
      });
    });
  }

  setActiveSlide(index: number): void {
    this.activeSlide = index;
    this.activeSlideChange.emit(this.activeSlide);
  }

  onClickThumb(index: number): void {
    this.swiper.swiperRef.slideTo(index);
  }

  openLightbox(index: number): void {
    this.lightbox.open(this.albums, index, {
      fadeDuration: 0.01,
      resizeDuration: 0.01,
    });
  }

  updateLotPhotos(photos: string[]): void {
    this.isLoadingPhotos = this.mhs
      .mutate(
        this.lotUpdateGQL,
        {
          input: {
            lotId: this.lot.id,
            lotType: this.lotType,
            lotSource: this.lotSource,
            photos: photos.map((x) =>
              x.replace(`${environment.domain}/uss/`, `${environment.domain}/pp/uss/`),
            ),
          },
        },
        {
          successTranslationKey: 'MUTATION_SUCCEEDED',
          failureTranslationKey: 'MUTATION_FAILED',
        },
      )
      .loading.pipe(
        finalize(() => {
          this.imgDelete.emit();
          this.isMovingPhotos = false;
        }),
      );
  }

  deletePhoto(): void {
    this.updateLotPhotos(this.lot.images.filter((value, index) => index !== this.activeSlide - 1));
  }

  get activeSlideUrl(): string {
    return this.lot.images[this.activeSlide - 1];
  }

  movePhoto(to: number): void {
    if (to >= 0 && to < this.lot.images.length) {
      if (!this.isMovingPhotos) {
        this.isMovingPhotos = true;
      }
      const images = [...this.lot.images];
      [images[this.activeSlide - 1], images[to]] = [images[to], images[this.activeSlide - 1]];

      this.lot.images = images;
      this.swiper.swiperRef.slideTo(to + 1);
    }
  }

  openAuctionListTipModal(): void {
    this.modalService.create({
      nzTitle: this.translate.instant('LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.TITLE'),
      nzContent: AuctionsTipModalComponent,
      nzWidth: '900px',
      nzFooter: null,
      nzStyle: {
        top: '10px',
      },
      nzComponentParams: {
        lot: this.lot,
      },
      nzClassName: 'auction-tip-modal',
    });
  }
}
