import { FetchQueryRef, QueryHandlingService } from '@akebono/core';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { getImgPrefix } from 'src/app/const';

import { UserChannelGQL, UserChannelQuery } from './../../../../graphql/service/graphql-auto-shared-service';

@Component({
  selector: 'app-shared-channel',
  templateUrl: './shared-channel.component.html',
  styleUrls: ['./shared-channel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedChannelComponent implements OnInit {
  path: string;
  queryRef?: FetchQueryRef<UserChannelQuery>;
  imgPrefix = getImgPrefix();

  constructor(
    private title: Title,
    private route: ActivatedRoute,
    private qhs: QueryHandlingService,
    private userChannelGQL: UserChannelGQL,
  ) {
    this.path = this.route.snapshot.params?.path;
  }

  ngOnInit(): void {
    this.queryRef = this.qhs.fetch(this.userChannelGQL, { path: this.path }, 'network-only');
  }
}
