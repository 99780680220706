import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { LotCounters } from '../../types/lot-counters.type';

@Component({
  selector: 'app-lot-views-and-favorites-counter',
  templateUrl: './lot-views-and-favorites-counter.component.html',
  styleUrls: ['./lot-views-and-favorites-counter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotViewsAndFavoritesCounterComponent {
  @HostBinding('style.display') display: 'none' | 'block' = 'none';

  views = 0;
  inFavorites = 0;

  @Input()
  set counter(counters: LotCounters | null) {
    if (counters?.viewsCount || counters?.inFavoritesCount) {
      this.views = counters.viewsCount;
      this.inFavorites = counters.inFavoritesCount;
      this.display = 'block';
    } else {
      this.display = 'none';
    }
  }
}
