import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-uss-auction',
  templateUrl: './uss-auction.component.html',
  styleUrls: ['./uss-auction.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UssAuctionComponent {
  @Input() lang = 'ru';
}
