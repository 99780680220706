import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { Observable, ReplaySubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AutoLotView } from 'src/app/const';
import { Lot } from 'src/app/modules/graphql/service/graphql-auto-main-service';

import { ProductionDatePayload } from '../types/production-date-payload.type';

@Injectable()
export class CheckProductionDatePayloadService {
  private lot$ = new ReplaySubject<Lot | AutoLotView | null>(1);

  public get payload$(): Observable<ProductionDatePayload> {
    return this.lot$.pipe(
      filter((lot) => Boolean(lot)),
      map((lot) => this.compilePayloadPairs(lot)),
    );
  }

  public emit(lot: Lot | AutoLotView): void {
    this.lot$.next(lot);
  }

  private compilePayloadPairs(lot: Lot | AutoLotView | null): ProductionDatePayload {
    const excludePayloadFields = ['year', 'month', 'day', 'vin'];
    const payload = lot?.productionDatePayload?.epc_export ?? {};
    let newPayload = {
      date: lot?.productionDate
        ? DateTime.fromISO(lot.productionDate).toFormat('yyyy-MM-dd')
        : undefined,
      ...payload,
    } as { [key: string]: string };

    if (lot.vin && lot.productionDate) {
      newPayload = {
        vincode: lot.vin,
        ...newPayload,
      };
    }

    return Object.entries(newPayload)
      .map((pair) => ({ key: pair[0], value: pair[1] }))
      .filter((pair) => !excludePayloadFields.includes(pair.key))
      .filter((pair) => Boolean(pair.value));
  }
}
