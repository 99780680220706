import { PermissionsGuard } from '@akebono/core';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProtectedGuard } from 'ngx-auth';
import { PERMISSION_LOTS } from 'src/app/const';

import { LayoutComponent } from '../../components/layout/layout.component';
import { LotComponent } from './components/auto-lot/auto-lot.component';
import { ListComponent } from './components/auto-lots-list/auto-lots-list.component';

const routes: Routes = [
  {
    path: 'stats-auto',
    component: LayoutComponent,
    children: [
      {
        path: '',
        component: ListComponent,
        canActivate: [PermissionsGuard, ProtectedGuard],
        data: {
          permissions: [PERMISSION_LOTS],
          breadcrumb: 'Lots',
        },
      },
      {
        path: 'lot/:lot',
        component: LotComponent,
        canActivate: [PermissionsGuard, ProtectedGuard],
        data: {
          permissions: [PERMISSION_LOTS],
          breadcrumb: 'Lot',
        },
      },
      {
        path: 'lot/:source/:lot',
        component: LotComponent,
        canActivate: [PermissionsGuard, ProtectedGuard],
        data: {
          permissions: [PERMISSION_LOTS],
          breadcrumb: 'Lot',
        },
      },
    ],
    canActivate: [PermissionsGuard, ProtectedGuard],
    data: {
      permissions: [PERMISSION_LOTS],
      breadcrumb: 'Statistics - Auto',
    },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AuctionsAutoStatsRoutingModule {}
