import { ConfigService } from '@akebono/core';
import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { combineLatest, from, Observable, of } from 'rxjs';
import { filter, map, pluck, switchMap, tap, toArray } from 'rxjs/operators';
import { AlertTargetEnum } from 'src/app/modules/graphql/service/graphql-auto-main-service';
import { AlertService } from 'src/app/modules/shared-components/alerts-list/alert.service';

import { AlertData } from '../../types/alert-data.type';

@Component({
  selector: 'app-alerts-list-auto',
  templateUrl: './alerts-list-auto.component.html',
  styleUrls: ['./alerts-list-auto.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertsListAutoComponent implements OnInit {
  @Input() target?: AlertTargetEnum;
  @Input() auction?: string;
  @Input() lotCode?: string;

  @HostBinding('style.display') display: 'none' | 'block' = 'none';

  alerts$: Observable<AlertData[]> = of([]);

  constructor(private config: ConfigService, private alerts: AlertService) {}

  ngOnInit(): void {
    this.alerts$ = combineLatest([
      this.config.getCurrentLanguage$().pipe(pluck('localeId')),
      this.alerts.getAlerts(this.auction, this.target, this.lotCode),
    ]).pipe(
      switchMap(([lang, alerts]) =>
        from(alerts).pipe(
          filter((alert) => (lang === 'ru' ? !!alert.contentRu : !!alert.contentEn)),
          map((alert) => ({
            style: alert.style,
            content: lang === 'ru' ? alert.contentRu : alert.contentEn,
          })),
          toArray(),
        ),
      ),
      tap((alerts) => (this.display = alerts.length ? 'block' : 'none')),
    );
  }
}
