import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';

import { AutoLotView, BoundaryStatus } from '../const';

export type LotBoundaryInfo = {
  earlyDate: Date;
  overdueDate: Date;
  status: BoundaryStatus;
};
@Injectable()
export class LotBoundaryInfoService {
  getLotBoundaryInfo(lot: AutoLotView): LotBoundaryInfo | null {
    if (lot.productionDate) {
      const productionDate = DateTime.fromISO(lot.productionDate);
      const earlyDate = productionDate.plus({ years: 3 });
      const overdueDate = productionDate.plus({ years: 5 });
      const status = productionDate ? this.boundaryStatus(overdueDate, earlyDate) : null;
      return {
        earlyDate: earlyDate.toJSDate(),
        overdueDate: overdueDate.toJSDate(),
        status,
      };
    }
    return null;
  }
  private boundaryStatus(overdueDate: DateTime, earlyDate: DateTime): BoundaryStatus {
    if (DateTime.now() >= overdueDate) {
      return BoundaryStatus.Overdue;
    } else {
      if (DateTime.now() < earlyDate) {
        return BoundaryStatus.Early;
      } else if (DateTime.now() < overdueDate.minus({ months: 2 })) {
        return BoundaryStatus.NotBoundary;
      } else {
        return BoundaryStatus.Boundary;
      }
    }
  }
}
