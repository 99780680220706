import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Observable, Subject } from 'rxjs';
import {
  delay,
  filter,
  first,
  map,
  scan,
  share,
  shareReplay,
  startWith,
  switchMap,
  takeUntil,
  toArray,
} from 'rxjs/operators';

import { PlaceBidStatus } from '../../enums/place-bid-status.enum';
import { BidService } from '../../services/bid.service';
import { TargetBid } from '../../types/target-bid.type';

@Component({
  selector: 'app-import-bids-place-modal',
  templateUrl: './import-bids-place-modal.component.html',
  styleUrls: ['./import-bids-place-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImportBidsPlaceModalComponent implements OnInit, OnDestroy {
  @Input() bids: TargetBid[] = [];

  progressPercent$: Observable<number>;
  successPlacedCount$: Observable<number>;
  failPlacedCount$: Observable<number>;

  private readonly destroy$ = new Subject<void>();

  constructor(private readonly modal: NzModalRef, private readonly bidService: BidService) {}

  ngOnInit(): void {
    const placedBids$ = this.bidService.placeAll(this.bids).pipe(share());
    const placedBidsCount$ = placedBids$.pipe(
      scan((acc, _) => acc + 1, 0),
      startWith(0),
      shareReplay(1),
    );

    this.progressPercent$ = placedBidsCount$.pipe(
      map((placedCount) => (placedCount / this.totalBidsCount) * 100),
    );
    this.successPlacedCount$ = placedBids$.pipe(
      switchMap((bid) => bid.status.status$.pipe(first())),
      filter((status) => status === PlaceBidStatus.Placed),
      scan((acc, _) => acc + 1, 0),
      startWith(0),
      shareReplay(1),
    );
    this.failPlacedCount$ = placedBids$.pipe(
      switchMap((bid) => bid.status.status$.pipe(first())),
      filter((status) => status !== PlaceBidStatus.Placed),
      scan((acc, _) => acc + 1, 0),
      startWith(0),
      shareReplay(1),
    );

    placedBids$.pipe(toArray(), delay(2000), takeUntil(this.destroy$)).subscribe({
      complete: () => this.modal.destroy(),
    });
  }

  get totalBidsCount(): number {
    return this.bids.length;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
