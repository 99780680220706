import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-navis-rules-modal',
  templateUrl: './navis-rules-modal.component.html',
  styleUrls: ['./navis-rules-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavisRulesModalComponent {
  constructor(public modal: NzModalRef) {}
}
