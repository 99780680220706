import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DateTime } from 'luxon';

import { BidStatusEnum } from '../../modules/graphql/service/graphql-auto-main-service';

@Component({
  selector: 'app-bids-filter',
  templateUrl: './bids-filter.component.html',
  styleUrls: ['./bids-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BidsFilterComponent {
  @Input() conditions = [];
  @Input() counters = {};
  @Output() apply = new EventEmitter<void>();

  form: FormGroup;

  tabs = [
    'all',
    BidStatusEnum.Initial,
    'translated',
    BidStatusEnum.Confirmed,
    BidStatusEnum.Won,
    BidStatusEnum.Canceled,
  ];
  tab: string;

  constructor(private fb: FormBuilder, private router: Router, private route: ActivatedRoute) {
    this.tab = this.route.snapshot.queryParams.tab ?? 'all';
    this.route.queryParams.subscribe((params) => {
      this.form = this.fb.group({
        bidLotAuctionDateFrom: params.bidLotAuctionDateFrom
          ? DateTime.fromISO(params.bidLotAuctionDateFrom).toJSDate()
          : null,
        bidLotAuctionDateTo: params.bidLotAuctionDateTo
          ? DateTime.fromISO(params.bidLotAuctionDateTo).toJSDate()
          : null,
        auctionName: params.auctionName,
        company: params.company,
        model: params.model,
        modelType: params.modelType,
        groupLetter: params.groupLetter,
        condition: params.condition,
        code: params.code,
      });
    });
  }

  filter(): void {
    this.apply.emit();

    this.router.navigate([], {
      queryParams: {
        company: this.form.value.company?.trim(),
        model: this.form.value.model?.trim(),
        modelType: this.form.value.modelType?.trim(),
        auctionName: this.form.value.auctionName?.trim(),
        groupLetter: this.form.value.groupLetter?.trim(),
        code: this.form.value.code?.trim(),
        condition: this.form.value.condition,
        bidLotAuctionDateFrom: this.form.value.bidLotAuctionDateFrom
          ? DateTime.fromJSDate(this.form.value.bidLotAuctionDateFrom).toFormat('yyyy-MM-dd')
          : undefined,
        bidLotAuctionDateTo: this.form.value.bidLotAuctionDateTo
          ? DateTime.fromJSDate(this.form.value.bidLotAuctionDateTo).toFormat('yyyy-MM-dd')
          : undefined,
      },
      queryParamsHandling: 'merge',
    });
  }

  setDateToday() {
    this.form.patchValue({ bidLotAuctionDateFrom: new Date(), bidLotAuctionDateTo: new Date() });
    this.router.navigate([], {
      queryParams: {
        bidLotAuctionDateFrom: DateTime.utc().toFormat('yyyy-MM-dd'),
        bidLotAuctionDateTo: DateTime.utc().toFormat('yyyy-MM-dd'),
        page: this.route.snapshot.queryParams.page,
        pageSize: this.route.snapshot.queryParams.pageSize,
      },
      queryParamsHandling: 'merge',
    });
  }

  changeTab(tab: string) {
    this.tab = tab;
    this.router.navigate([], {
      queryParams: {
        tab,
      },
      queryParamsHandling: 'merge',
    });
  }

  resetFilter() {
    this.router.navigate([], {
      queryParams: {
        bidLotAuctionDateFrom: DateTime.utc().toFormat('yyyy-MM-dd'),
        page: this.route.snapshot.queryParams.page,
        pageSize: this.route.snapshot.queryParams.pageSize,
      },
    });
  }
}
