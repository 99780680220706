import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { AutoLotView } from 'src/app/const';

import { CheckProductionDatePayloadService } from '../../services/check-production-date-payload.service';
import { ProductionDatePayload } from '../../types/production-date-payload.type';

@Component({
  selector: 'app-lot-check-production-date-result',
  templateUrl: './lot-check-production-date-result.component.html',
  styleUrls: ['./lot-check-production-date-result.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotCheckProductionDateResultComponent {
  @Input()
  set lot(lot: AutoLotView) {
    this.checkProductionDatePayloadService.emit(lot);
  }

  payload$: Observable<ProductionDatePayload>;

  constructor(private checkProductionDatePayloadService: CheckProductionDatePayloadService) {
    this.payload$ = this.checkProductionDatePayloadService.payload$;
  }
}
