import { FetchQueryRef, QueryHandlingService } from '@akebono/core';
import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DateTime } from 'luxon';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { AutoLotView, mergeSavedLotToAutoLotView, mileageToInterval } from '../../../const';
import {
  StatsFiltersGQL,
  StatsFiltersQuery,
} from '../../graphql/service/graphql-cars-default-service';
import { AveragePrice, LotsGQL } from '../../graphql/service/graphql-stats-auto-service';

type StatisticsData = {
  stats: AutoLotView[];
  pricesPerRating: object;
  pricesPerYear: AveragePrice[];
};

@Component({
  selector: 'app-lot-statistics',
  templateUrl: './lot-statistics.component.html',
  styleUrls: ['./lot-statistics.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotStatisticsComponent implements OnInit, OnChanges {
  @Input() lot: AutoLotView;
  @Input() statisticsOpen = false;

  filtersData$: FetchQueryRef<StatsFiltersQuery> | null = null;
  statsData$: Observable<StatisticsData> | null = null;
  statsLoading$ = of(false);
  dateToday = DateTime.local().setZone('Asia/Tokyo').toISODate();

  form: FormGroup;

  mileageIntervals = {
    1: '0-25',
    2: '25-50',
    3: '50-75',
    4: '75-100',
    5: '100-125',
    6: '125-150',
    7: '>150',
    totalAvg: 'TOTAL',
  };

  constructor(
    private fb: FormBuilder,
    private qhs: QueryHandlingService,
    private lotsGQL: LotsGQL,
    private statsFiltersGQL: StatsFiltersGQL,
  ) {
    this.form = this.fb.group({
      year: [null],
      mileage: [null],
      score: [null],
      frame: [null],
    });
  }

  ngOnInit(): void {
    this.form.patchValue({
      year: Number(this.lot.year),
      mileage: mileageToInterval(this.lot.mileage),
      score: this.lot.rate,
    });
  }

  initStatistics(): void {
    if (this.filtersData$ === null && this.statsData$ === null) {
      this.initFilters();
      this.loadStats();
    }
  }

  initFilters(): void {
    this.filtersData$ = this.qhs.fetch(this.statsFiltersGQL, {
      filter: {
        companyRef: {
          eq: String(this.lot.companyId),
        },
        modelNameRef: {
          eq: String(this.lot.modelId),
        },
      },
    });
  }

  selectYear(year: string) {
    this.form.patchValue({
      year,
    });
  }

  selectInterval(score: string, interval: string): void {
    this.form.patchValue({
      score,
      mileage: interval,
    });

    this.loadStats();
  }

  loadStats(): void {
    const value = this.form.value;

    let subgroupByRating = 'year-company-model-rating-mileage';
    let subgroupByYear = 'year-company-model';

    if (value.frame) {
      subgroupByRating = 'year-company-model-frame-rating-mileage';
      subgroupByYear = 'year-company-model-frame';
    }

    const queryRef = this.qhs.fetch(this.lotsGQL, {
      subgroupByRating,
      subgroupByYear,
      companyEn: this.lot.company,
      modelEn: this.lot.modelName,
      modelTypeEn: value.frame,
      filter: {
        companyEn: {
          eq: this.lot.company,
        },
        modelNameEn: {
          eq: this.lot.modelName,
        },
        modelYearEn: {
          eq: Number(value.year),
        },
        scoresEn: {
          eq: value.score,
        },
        ...this.intervalToFilter(value.mileage),
      },
    });

    this.statsLoading$ = queryRef.loading;
    this.statsData$ = queryRef.data.pipe(
      map((data) => ({
        pricesPerRating: this.fillAveragePerYearRating(
          this.fillScoreMatrix(data.averagePriceByRating),
        ),
        pricesPerYear: data.averagePriceByYear,
        years: data.years,
        scores: data.scores,
        frames: data.frames,
        stats: data.lotsPaginatedList.items.map((lot) =>
          mergeSavedLotToAutoLotView(null, {
            ...lot,
            __typename: 'Lot',
            id: lot.lotId,
            code: lot.bid,
            images: lot.pictures.split('#').filter((v) => !!v),
            auctionDate: lot.date,
            mileageNum: String(lot.mileageNum),
            startPriceNum: String(lot.startPriceNum),
            endPriceNum: String(lot.endPriceNum),
            engineVolumeNum: Number(lot.displacementNum),
            modelYearEn: String(lot.modelYearEn),
          }),
        ),
      })),
    );
  }

  fillScoreMatrix(averagePriceByRating: any[]): void {
    return averagePriceByRating.reduce((c, data) => {
      if (!c[data.year]) {
        c[data.year] = {};
      }
      if (!c[data.year][data.score]) {
        c[data.year][data.score] = {};
      }
      c[data.year][data.score][data.mileageInterval] = { avg: data.avg, count: data.count };
      return c;
    }, {});
  }

  fillAveragePerYearRating(scores: any): any {
    Object.keys(scores).forEach((year) => {
      Object.keys(scores[year]).forEach((score) => {
        scores[year][score].totalAvg = Object.values(scores[year][score]).reduce(
          (a: number, v: number, i) => (a * i + v) / (i + 1),
          0,
        );
      });
    });
    return scores;
  }

  intervalToFilter(interval: string) {
    switch (interval) {
      case '1':
        return {
          mileageNum: {
            gte: 0,
            lte: 25,
          },
        };
      case '2':
        return {
          mileageNum: {
            gte: 25,
            lte: 50,
          },
        };
      case '3':
        return {
          mileageNum: {
            gte: 50,
            lte: 75,
          },
        };
      case '4':
        return {
          mileageNum: {
            gte: 75,
            lte: 100,
          },
        };
      case '5':
        return {
          mileageNum: {
            gte: 100,
            lte: 125,
          },
        };
      case '6':
        return {
          mileageNum: {
            gte: 125,
            lte: 150,
          },
        };
      case '7':
        return {
          mileageNum: {
            gte: 150,
          },
        };
      default:
        return {};
    }
  }

  ngOnChanges(): void {
    if (this.statisticsOpen) {
      this.initStatistics();
    }
  }
}
