import { QueryHandlingService } from '@akebono/core';
import { Injectable } from '@angular/core';
import { combineLatest, from, Observable } from 'rxjs';
import { map, pluck, shareReplay, switchMap, toArray } from 'rxjs/operators';

import { LotsCountersGQL, LotTypeEnum } from '../../graphql/service/graphql-auto-main-service';
import { AutoLot, LotSource } from '../../graphql/service/graphql-cars-default-service';
import { LotCounters } from './types/lot-counters.type';
import { LotId } from './types/lot-id.type';

@Injectable({
  providedIn: 'root',
})
export class LotCountersService {
  constructor(private qhs: QueryHandlingService, private lotsCountersGQL: LotsCountersGQL) {}

  public getCountersForLots(lotIds: (string | LotId)[], lotType: LotTypeEnum, lotSource: LotSource): Observable<Map<string, LotCounters>> {
    if (!lotIds?.length) return;

    let ids: string[] = [];
    if (typeof lotIds[0] === 'string') {
      ids = lotIds as string[];
    } else {
      ids = lotIds.map((lot: AutoLot) => lot.id);
    }

    const data$ = this.qhs.fetch(this.lotsCountersGQL, { lotIds: ids, subgroup: lotType, lotSource }).data;
    const lotCountersMap$ = data$.pipe(
      pluck('lots', 'nodes'),
      switchMap((lots) =>
        from(lots).pipe(
          map((lot) => [lot.id, lot] as [string, LotCounters]),
          toArray(),
        ),
      ),
      map((initializer) => new Map(initializer)),
    );

    return combineLatest([
      data$.pipe(pluck('lotsViewsCounts')),
      lotCountersMap$,
    ]).pipe(
      switchMap(([viewsCounts, lotCountersMap]) => from(viewsCounts).pipe(
        map((viewsCount) => lotCountersMap.has(viewsCount.originalId) ?
          [viewsCount.originalId, lotCountersMap.get(viewsCount.originalId)] as [string, LotCounters] :
          [viewsCount.originalId, {
            bidsCount: 0,
            biddersCount: 0,
            viewsCount: viewsCount.viewsCount,
            inFavoritesCount: 0,
          }] as [string, LotCounters]
        ),
        toArray(),
      )),
      map((initializer) => new Map(initializer)),
      shareReplay(1),
    );
  }
}
