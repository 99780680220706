<nz-table
  #table
  [nzTitle]="getTitle()"
  [nzTotal]="total"
  [nzData]="lots"
  [nzPageIndex]="page"
  [nzPageSize]="pageSize"
  [nzLoading]="loading"
  [nzFrontPagination]="false"
  [nzBordered]="true"
  nzPaginationPosition="both"
  nzShowSizeChanger
  class="table"
  nzSize="small"
  (nzPageIndexChange)="changePage($event)"
  (nzPageSizeChange)="changePageSize($event)"
  nzOuterBordered
>
  <thead>
    <tr>
      <th rowspan="2" nzAlign="center">{{ 'PHOTO' | translate }}</th>
      <th rowspan="2" nzAlign="center">{{ 'LOT' | translate }}</th>
      <th rowspan="1" nzAlign="center">{{ 'LOT_INFO.AUCTION' | translate }}</th>
      <th rowspan="1" nzAlign="center">{{ 'LOT_INFO.YEAR' | translate }}</th>
      <th rowspan="1" nzAlign="center" [ngClass]="'hide-column-small hide-column-medium'">
        {{ 'LOT_INFO.ENGINE_CC' | translate }}
      </th>
      <th rowspan="1" nzAlign="center">{{ 'LOT_INFO.MILEAGE' | translate }}</th>
      <th rowspan="1" nzAlign="center">{{ 'LOT_INFO.START_PRICE' | translate }}</th>
    </tr>
    <tr>
      <th nzAlign="center">{{ 'DATE' | translate }}</th>
      <th nzAlign="center">{{ 'FRAME_MODEL' | translate }}</th>
      <th nzAlign="center">{{ 'LOT_INFO.COLOR' | translate }}</th>
      <th nzAlign="center">{{ 'LOT_INFO.SCORE' | translate }}</th>
      <th nzAlign="center">{{ 'LOT_INFO.SOLD_FOR' | translate }}</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let lot of table.data">
      <td nzAlign="center">
        <app-lot-mini-gallery [lot]="lot"></app-lot-mini-gallery>
      </td>
      <td nzAlign="center">
        <div class="lot-buttons">
          <a
            nzSize="small"
            target="_blank"
            class="lot-button"
            nz-button
            [href]="lot.link"
            nzType="primary"
          >
            {{ lot.code }}
          </a>
        </div>
      </td>
      <td nzAlign="center">
        {{ lot.auctionName }} <br />
        {{ lot.auctionDatetime | date: 'yyyy-MM-dd H:mm:ss zz':'+0900' }}
      </td>
      <td nzAlign="left" [ngClass]="'hide-column-small hide-column-medium'">
        <span style="color: #6db3eb">{{ lot.companyEn }} {{ lot.modelEn }}</span>
        <br />
        <span nz-text nzType="danger">{{ lot.year }}</span> {{ lot.modelTypeEn }}
        <br />
        <pre nz-text nzType="secondary" [innerHTML]="lot.modelGradeEn"></pre>
      </td>
      <td nzAlign="left">
        <span nz-text nzType="danger">{{ lot.transmissionEn }}</span>
        {{ lot.engineVolumeNum }} cc<br />
        <span nz-text nzType="secondary">{{ lot.colorEn }}</span>
      </td>
      <td nzAlign="right">
        {{ lot.mileageNum }}{{ lot.mileageNum > 0 ? '000' : '' }}km
        <span nz-text nzType="warning">{{ lot.rate }}</span>
      </td>
      <td nzAlign="right">
        {{ lot.startPriceNum | currency: 'JPY':'symbol-narrow' }}
        <br />
        {{ lot.endPriceNum | currency: 'JPY':'symbol-narrow' }}
      </td>
    </tr>
  </tbody>
</nz-table>
