<form
  nz-form
  [formGroup]="form"
  (ngSubmit)="submit()"
  nzLayout="horizontal"
  *ngIf="allowed"
  appDriverStep
  [overrideStepIndex]="6"
  [stepTitle]="'AUTO_LOT.GUIDE.DATE_OF_MANUFACTURE.FIRST.TITLE' | translate"
  [stepDescription]="'AUTO_LOT.GUIDE.DATE_OF_MANUFACTURE.FIRST.DESCR' | translate"
  [stepSide]="'left'"
  [stepAlign]="'start'"
  [popoverClass]="'auto-lot'"
>
  <nz-input-group
    *ngIf="canCheckProductionDate$ | async"
    appDriverStep
    [overrideStepIndex]="7"
    [stepTitle]="'AUTO_LOT.GUIDE.DATE_OF_MANUFACTURE.SECOND.TITLE' | translate"
    [stepDescription]="
      ('AUTO_LOT.GUIDE.DATE_OF_MANUFACTURE.SECOND.DESCR' | translate) +
      '<a href=&quot;https://ecarjp.com/article/faq&quot; target=&quot;_blank&quot;>' +
      ('AUTO_LOT.GUIDE.DATE_OF_MANUFACTURE.SECOND.LINK' | translate) +
      '</a>'
    "
    [stepSide]="'bottom'"
    [stepAlign]="'start'"
    [popoverClass]="'auto-lot'"
  >
    <input
      nz-input
      type="text"
      formControlName="vinManufacture"
      placeholder="{{ 'FRAME_NUMBER' | translate }}"
      (focus)="inputFocus.emit()"
    />
    <button
      *ngIf="!this.checkFail || this.currentUser.isAuctioneer"
      nz-button
      nzType="primary"
      [nzLoading]="loading$ | async"
    >
      {{ 'CHECK_DATE_OF_MANUFACTURE' | translate }}
    </button>
    <button
      *ngIf="this.checkFail && !this.currentUser.isAuctioneer"
      nz-button
      nzType="primary"
      [disabled]="cantRequestCheckProductionDate$ | async"
      [nzLoading]="loading$ | async"
      nz-tooltip
      [nzTooltipTitle]="'PRODUCTION_DATE_CHECK_REQUEST_LIMIT_REACHED' | translate"
      [nzTooltipTrigger]="(cantRequestCheckProductionDate$ | async) ? 'hover' : null"
    >
      {{ 'CREATE_PRODUCTION_DATE_CHECK_REQUEST' | translate }}
    </button>
  </nz-input-group>
</form>
