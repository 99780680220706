import { AkebonoCoreModule } from '@akebono/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzModalModule } from 'ng-zorro-antd/modal';

import { CreateUserRequestModalComponent } from './create-user-request-modal.component';

@NgModule({
  declarations: [CreateUserRequestModalComponent],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    TranslateModule,
    AkebonoCoreModule,
    BrowserAnimationsModule,
    NzButtonModule,
    NzModalModule,
    NzFormModule,
    NzInputModule,
    NzAlertModule,
  ],
  exports: [CreateUserRequestModalComponent],
})
export class CreateUserRequestModalModule {}
