import { AkebonoCoreModule } from '@akebono/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';

import { LotAddPhotoButtonComponent } from './lot-add-photo-button.component';

@NgModule({
  declarations: [LotAddPhotoButtonComponent],
  imports: [
    CommonModule,
    BrowserModule,
    AkebonoCoreModule,
    TranslateModule,
    BrowserAnimationsModule,
    NzButtonModule,
    NzIconModule,
  ],
  exports: [LotAddPhotoButtonComponent],
})
export class LotAddPhotoButtonModule {}
