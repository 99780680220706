import { QueryHandlingService } from '@akebono/core';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { map, pluck, shareReplay, startWith, switchMap } from 'rxjs/operators';

import { AuctionFeedbacksGQL } from '../../../../graphql/service/graphql-shared-familiar-service';

@Component({
  selector: 'app-auction-reviews',
  templateUrl: './auction-reviews.component.html',
  styleUrls: ['./auction-reviews.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuctionReviewsComponent implements OnInit {
  @Input() auctionId: number;

  loading$ = of(false);
  reviews$ = of([]);
  isEmpty$ = of(true);

  avatarStyles = {
    newbie: null,
    silver: {
      'background-color': '#e6f7ff',
      color: '#096dd9',
      border: '1px solid #91d5ff',
    },
    gold: {
      'background-color': '#fffbe6',
      color: '#d48806',
      border: '1px solid #ffe58f',
    },
  };

  page$ = new BehaviorSubject<number>(1);
  pageSize$ = new BehaviorSubject<number>(10);
  total$ = of(null);

  constructor(
    private qhs: QueryHandlingService,
    private auctionFeedbacksGQL: AuctionFeedbacksGQL,
  ) {}

  ngOnInit(): void {
    const queryRef$ = combineLatest([of(this.auctionId), this.page$, this.pageSize$]).pipe(
      map(([auctionId, page, pageSize]) => ({
        auctionId,
        first: pageSize,
        offset: (page - 1) * pageSize,
      })),
      map((variables) => this.qhs.fetch(this.auctionFeedbacksGQL, variables, 'network-only')),
      shareReplay(1),
    );

    const data$ = queryRef$.pipe(
      switchMap((queryRef) => queryRef.data),
      pluck('auctionFeedbacks'),
    );
    this.loading$ = queryRef$.pipe(switchMap((queryRef) => queryRef.loading));
    this.reviews$ = data$.pipe(pluck('nodes'));
    this.total$ = data$.pipe(pluck('total'));
    this.isEmpty$ = this.total$.pipe(
      map((total) => !total),
      startWith(true),
    );
  }
}
