import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable } from 'rxjs';

import { NewsCounterService } from '../../services/news-counter.service';

@Component({
  selector: 'app-news-counter',
  templateUrl: './news-counter.component.html',
  styleUrls: ['./news-counter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewsCounterComponent {
  @Input() offset: [number, number] = [0, 0];

  hasUnreadNews$: Observable<boolean>;

  constructor(newsCounterService: NewsCounterService) {
    this.hasUnreadNews$ = newsCounterService.hasUnreadNews$;
  }
}
