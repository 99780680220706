import { AkebonoCoreModule } from '@akebono/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LinkOutline } from '@ant-design/icons-angular/icons';
import { TranslateModule } from '@ngx-translate/core';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzTagModule } from 'ng-zorro-antd/tag';

import { LotSharedIncludesComponent } from './lot-shared-includes.component';

@NgModule({
  declarations: [LotSharedIncludesComponent],
  exports: [LotSharedIncludesComponent],
  imports: [
    CommonModule,
    AkebonoCoreModule,
    TranslateModule,
    NzSpinModule,
    NzTagModule,
    NzDividerModule,
    NzIconModule.forRoot([LinkOutline]),
  ],
})
export class LotSharedIncludesModule {}
