<nz-spin nzSize="large" [nzSpinning]="(loading$ | async) && (updateMark$ | async)">
  <header>
    <nz-row *ngIf="data$ | async as data" nzAlign="middle" nzJustify="space-between">
      <nz-col>
        <app-lot-last-visit-date [lotId]="data.lot?.id"></app-lot-last-visit-date>
      </nz-col>
      <nz-col>
        <nz-row>
          <nz-col>
            <nz-row nzGutter="10">
              <nz-col>
                <app-language-selector></app-language-selector>
              </nz-col>
              <nz-col>
                <a *ngIf="data.hasLotsPermission" nz-button [href]="data.fullLotLink">
                  {{ 'TO_FULL_LOT' | translate }}
                </a>
              </nz-col>
            </nz-row>
          </nz-col>
        </nz-row>
      </nz-col>
    </nz-row>

    <nz-result
      *ngIf="(loading$ | async) === false && !(data$ | async)?.lot"
      nzStatus="404"
      [nzTitle]="'LOT_NOT_FOUND' | translate"
    ></nz-result>

    <app-lot-title-full *ngIf="(data$ | async)?.lot as lot" [lot]="lot"></app-lot-title-full>
  </header>

  <ng-container *ngIf="data$ | async as data">
    <main *ngIf="data?.lot" nz-row nzGutter="16">
      <aside nz-col nzXs="24" nzLg="10">
        <app-lot-carousel [lot]="data.lot" [(activeSlide)]="activeSlide"></app-lot-carousel>
      </aside>
      <aside nz-col nzXs="24" nzLg="14">
        <section>
          <app-lot-translations
            id="lotTranslations"
            [translations]="data.translations"
          ></app-lot-translations>
        </section>

        <section>
          <app-lot-main-info [forShared]="true" [lot]="data.lot"></app-lot-main-info>
        </section>

        <section>
          <app-lot-extra-info [lot]="data.lot" [active]="true"></app-lot-extra-info>
          <app-lot-body-defects-info></app-lot-body-defects-info>
        </section>

        <!-- <section>
          <lib-akebono-calculator
            [lot]="data.calcData"
            [types]="data.calcTypes"
          ></lib-akebono-calculator>
        </section> -->
      </aside>
    </main>
  </ng-container>
</nz-spin>
