import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzModalModule } from 'ng-zorro-antd/modal';

import { MainAlertsComponent } from './main-alerts.component';
import { SafePipe } from './safe.pipe';

@NgModule({
  declarations: [MainAlertsComponent, SafePipe],
  imports: [CommonModule, TranslateModule, NzModalModule, NzButtonModule],
  exports: [MainAlertsComponent],
})
export class MainAlertsModule {}
