<nz-content>
  <div nz-row nzJustify="center">
    <nz-row [nzGutter]="[15, 15]" nzJustify="center" class="categories">
      <div nz-col nzXl="4">
        <a routerLink="/auto">
          <nz-card class="main-card">
            <img src="/assets/images/main-categories/auto.png" class="card-img" alt="auto" loading="lazy" />
            <p class="card-header">
              <span>{{ 'AUTO' | translate }}</span>
            </p>
          </nz-card>
        </a>
      </div>
      <div nz-col nzXl="4">
        <a routerLink="/moto">
          <nz-card class="main-card">
            <img src="/assets/images/main-categories/moto.png" class="card-img" alt="moto" loading="lazy" />
            <p class="card-header" translate>
              <span>{{ 'MOTO' | translate }}</span>
            </p>
          </nz-card>
        </a>
      </div>
      <div nz-col nzXl="4">
        <a routerLink="/oneprice">
          <nz-card class="main-card">
            <img src="/assets/images/main-categories/oneprice.png" class="card-img" alt="oneprice" loading="lazy"  />
            <p class="card-header" translate>
              <span>{{ 'ONEPRICE' | translate }}</span>
            </p>
          </nz-card>
        </a>
      </div>
      <div nz-col nzXl="4">
        <a routerLink="/manual-lots">
          <nz-card class="main-card">
            <img src="/assets/images/main-categories/spec-equip.png" class="card-img" alt="arai kenki" loading="lazy"  />
            <p class="card-header">
              <span>ARAI KENKI</span>
            </p>
          </nz-card>
        </a>
      </div>
      <div nz-col nzXl="4">
        <a routerLink="/stats-auto">
          <nz-card class="main-card">
            <img src="/assets/images/main-categories/stats.png" class="card-img" alt="statistics" loading="lazy" />
            <p class="card-header" translate>
              <span>Statistics</span>
            </p>
          </nz-card>
        </a>
      </div>
    </nz-row>
  </div>

  <nz-row *ngIf="loading" nzJustify="center" nzAlign="middle" style="height: 90vh">
    <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
  </nz-row>

  <div nz-row nzJustify="center" class="purchase-statistics">
    <app-user-purchase-statistics></app-user-purchase-statistics>
  </div>

  <div nz-row nzJustify="center" class="companies mt-20">
    <a
      nzFlex="140px"
      nz-col
      nz-button
      nzType="primary"
      nzSize="small"
      class="top"
      *ngFor="let company of onlyTopCompanies"
      [routerLink]="['/auto']"
      [queryParams]="{ company: company.companyId }"
    >
      {{ company.name }}
    </a>
  </div>

  <nz-divider></nz-divider>

  <div class="content mt-20">
    <div nz-row nzJustify="space-between">
      <div nz-col [nzXs]="24" [nzSm]="12" [nzMd]="8" [nzLg]="3" [nzXl]="4.8" *ngFor="let column of columns">
        <ng-container *ngFor="let company of column.items">
          <h4>{{ company.key }}</h4>
          <div class="companies-col">
            <ng-container *ngFor="let companyItem of company.items">
              <a
                [routerLink]="['/auto']"
                [queryParams]="{ company: companyItem.companyId }"
                class="link-item"
              >
                {{ companyItem.name }}
              </a>
            </ng-container>
          </div>
          <br />
        </ng-container>
      </div>
    </div>
  </div>
</nz-content>

<app-main-alerts [alerts]="alerts$ | async"></app-main-alerts>
<app-country-config></app-country-config>
