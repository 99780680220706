import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AutoLotView } from 'src/app/const';
import { AuctionService } from 'src/app/services/auction.service';

@Component({
  selector: 'app-lot-ticket-rules',
  templateUrl: './lot-ticket-rules.component.html',
  styleUrls: ['./lot-ticket-rules.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotTicketRulesComponent implements OnInit {
  @Input() lot: AutoLotView;

  auctionTicketRules$: Observable<string>;

  constructor(private as: AuctionService) {}

  ngOnInit(): void {
    this.auctionTicketRules$ = this.as.getByName(this.lot.auctionName).pipe(
      map((auction) => {
        return auction?.ticketRules;
      }),
      catchError((err) => {
        console.log(err);
        return of(null);
      }),
    );
  }
}
