import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NgxPrintModule } from 'ngx-print';

import { PhotoSelectorComponent } from './components/photo-selector/photo-selector.component';
import { PurchaseReportButtonComponent } from './components/purchase-report-button/purchase-report-button.component';
import { PurchaseReportTemplateComponent } from './components/purchase-report-template/purchase-report-template.component';
import { DefaultMoneyPipe } from './pipes/default-money.pipe';
import { DefaultNumberPipe } from './pipes/default-number.pipe';
import { DefaultPipe } from './pipes/default.pipe';
import { PurchaseReportFormModalComponent } from './purchase-report-form-modal.component';
import { PurchaseReportFormModalService } from './services/purchase-report-form-modal.service';

@NgModule({
  declarations: [
    PurchaseReportFormModalComponent,
    PurchaseReportTemplateComponent,
    PhotoSelectorComponent,
    DefaultPipe,
    DefaultNumberPipe,
    DefaultMoneyPipe,
    PurchaseReportButtonComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    NgxPrintModule,
    ReactiveFormsModule,
    NzIconModule,
    NzButtonModule,
    NzModalModule,
    NzFormModule,
    NzInputModule,
    NzSelectModule,
    NzRadioModule,
    NzDatePickerModule,
    NzInputNumberModule,
  ],
  providers: [PurchaseReportFormModalService],
  exports: [PurchaseReportButtonComponent],
})
export class PurchaseReportFormModalModule {}
