import {
  MutationHandlingServiceV2,
  NotificationService,
  QueryHandlingService,
} from '@akebono/core';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateTime } from 'luxon';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { filter, finalize, first, map, shareReplay, takeUntil } from 'rxjs/operators';
import { ManualLotData, validateAllFormFields } from 'src/app/const';
import {
  BidCondition,
  BidCreateManualGQL,
  BidManualDataGQL,
  LotTypeEnum,
} from 'src/app/modules/graphql/service/graphql-auto-main-service';

import { BidConditionService } from '../../services/bid-condition.service';

@Component({
  selector: 'app-bid-manual-modal',
  templateUrl: './bid-manual-modal.component.html',
  styleUrls: ['./bid-manual-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BidManualModalComponent implements OnInit, OnDestroy {
  conditions$: Observable<BidCondition[]>;
  loading$ = of(true);
  isPlacingBid$ = of(false);
  auctions$: Observable<string[]> = of([]);

  lotTypes = Object.values(LotTypeEnum);

  form: FormGroup;

  private readonly destroy$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    public modal: NzModalRef,
    private qhs: QueryHandlingService,
    private mhs: MutationHandlingServiceV2,
    private notification: NotificationService,
    private bidConditionService: BidConditionService,
    private bidManualDataGQL: BidManualDataGQL,
    private bidCreateManualGQL: BidCreateManualGQL,
  ) {
    this.form = this.fb.group({
      lotType: [null, [Validators.required]],
      date: [null, [Validators.required]],
      auctionName: [null, [Validators.required]],
      code: [null, [Validators.required]],
      company: [null, [Validators.required]],
      modelName: [null, [Validators.required]],
      frame: [null, [Validators.required]],
      year: [null, [Validators.required]],
      amount: [null, [Validators.required]],
      startPrice: [null, [Validators.required]],
      conditionId: [{ value: null, disabled: true }, [Validators.required]],
      comment: [null, [Validators.required]],
      createTranslation: [false],
      notify: [false],
    });

    fb.control({});
  }

  ngOnInit(): void {
    const queryRef = this.qhs.fetch(this.bidManualDataGQL);

    this.loading$ = queryRef.loading;
    const data$ = queryRef.data.pipe(shareReplay(1));
    const currentUser$ = data$.pipe(map((data) => data.currentUser));

    const lotDataPartial$ = this.form.valueChanges.pipe(
      map(
        (formValue) =>
          <ManualLotData>{
            company: formValue.company,
            modelName: formValue.modelName,
            frame: formValue.frame,
            year: formValue.year,
            lotType: formValue.lotType,
          },
      ),
      shareReplay(1),
    );
    const lotDataRequired$ = lotDataPartial$.pipe(
      filter((lotData) => Object.values(lotData).every((value) => !!value)),
    );

    this.conditions$ = combineLatest([
      lotDataRequired$,
      currentUser$,
      data$.pipe(
        map((data) => data.bidConditions),
        map((conditions) => this.bidConditionService.sort(conditions)),
      ),
    ]).pipe(
      map(([lotData, currentUser, bidConditions]) =>
        this.bidConditionService.filterForManualLot(bidConditions, lotData, currentUser),
      ),
    );
    this.auctions$ = data$.pipe(
      map((data) => data.auctions ?? []),
      map((auctions) => {
        const allAuctions = auctions
          .map((auction) => [auction.title, ...auction.auctionAliases.map((alias) => alias.title)])
          .flat();
        return [...new Set(allAuctions)];
      }),
    );

    lotDataPartial$
      .pipe(
        map((lotData) => Object.values(lotData).every((value) => !!value)),
        takeUntil(this.destroy$),
      )
      .subscribe((bidConditionEnabled) => {
        const conditionIfControl = this.form.controls.conditionId;
        if (bidConditionEnabled && conditionIfControl.disabled) {
          conditionIfControl.enable();
          conditionIfControl.setValue(null);
        } else if (!bidConditionEnabled && conditionIfControl.enabled) {
          conditionIfControl.disable();
          conditionIfControl.setValue(null);
        }
      });
  }

  async placeBid(): Promise<void> {
    validateAllFormFields(this.form);

    if (this.form.valid) {
      if (Number(this.form.value.startPrice) % 5000 !== 0) {
        this.notification.renderError('LOT_BIDDING_MODAL.BID_MUST_BE_ROUND');
        return;
      }
      if (this.form.value.startPrice > this.form.value.amount) {
        this.notification.renderError('RATE_MUST_BE_HIGHER');
        return;
      }
      if (this.form.value.year.toString().length !== 4) {
        this.notification.renderError('WRONG_YEAR');
        return;
      }

      const conditions = await this.conditions$.pipe(first()).toPromise();
      const conditionIdControl = this.form.controls.conditionId;
      if (!conditions.find((condition) => condition.id === conditionIdControl.value)) {
        this.notification.renderError('WRONG_BID_CONDITION');
        conditionIdControl.setValue(null);
        return;
      }

      this.isPlacingBid$ = this.mhs
        .mutate(
          this.bidCreateManualGQL,
          {
            input: {
              amount: this.form.value.amount,
              lotType: this.form.value.lotType,
              conditionId: this.form.value.conditionId,
              comment: this.form.value.comment,
              createTranslation: this.form.value.createTranslation,
              notify: this.form.value.notify,
              code: this.form.value.code.toString(),
              auctionName: this.form.value.auctionName.trim(),
              auctionDatetime: DateTime.fromISO(this.form.value.date.toISOString())
                .setZone('Asia/Tokyo', { keepLocalTime: true })
                .toISO(),
              company: this.form.value.company,
              model: this.form.value.modelName,
              modelType: this.form.value.frame,
              year: this.form.value.year,
              startPrice: this.form.value.startPrice,
            },
          },
          {
            successTranslationKey: 'LOT_BIDDING_MODAL.SUCCESS_CREATE_BID',
            failureTranslationKey: 'LOT_BIDDING_MODAL.FAIL_CREATE_BID',
          },
        )
        .loading.pipe(finalize(() => this.modal.close()));
    } else {
      this.notification.renderError('LOT_BIDDING_MODAL.FILL_ALL_FIELDS');
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
