<app-import-bids-dashboard-not-found-lots
  *ngIf="notFoundLotInfos$ | async as notFoundLots"
  [notFoundLots]="notFoundLots"
></app-import-bids-dashboard-not-found-lots>

<nz-table
  #table
  nzBordered
  nzSize="small"
  [nzTitle]="tableTitle"
  [nzData]="bids$ | async"
  [nzLoading]="loading$ | async"
  [nzShowPagination]="false"
  [nzFrontPagination]="false"
>
  <ng-template #tableTitle>
    <app-import-bids-dashboard-title
      [parsingResult]="parsingResultSubject | async"
      [availableBidsCount]="biddableBidsCount$ | async"
      [selectedBidsCount]="selectedLotIds.size"
      (reset)="reset()"
      (placeSelectedBids)="placeSelectedBids()"
      (placeAvailableBids)="placeAvailableBids()"
    ></app-import-bids-dashboard-title>
  </ng-template>

  <thead>
    <tr>
      <th nzWidth="1px" rowspan="2"></th>
      <th rowspan="2" nzAlign="center" nzWidth="230px">{{ 'PHOTO' | translate }}</th>
      <th rowspan="2" nzAlign="center">
        {{ 'LOT' | translate }}
      </th>
      <th rowspan="1" nzAlign="center">
        {{ 'LOT_INFO.AUCTION' | translate }}
      </th>
      <th rowspan="1" nzAlign="center">
        {{ 'LOT_INFO.YEAR' | translate }}
      </th>
      <th rowspan="1" nzAlign="center" [ngClass]="'hide-column-small hide-column-medium'">
        {{ 'LOT_INFO.ENGINE_CC' | translate }}
      </th>
      <th rowspan="1" nzAlign="center">
        {{ 'LOT_INFO.MILEAGE' | translate }}
      </th>
      <th rowspan="1" nzAlign="center">
        {{ 'LOT_INFO.START_PRICE' | translate }}
      </th>
      <th rowspan="1" nzAlign="center">
        {{ 'IMPORT_BIDS.BID' | translate }}
      </th>
      <th rowspan="2" nzAlign="center">
        {{ 'IMPORT_BIDS.STATUS' | translate }}
      </th>
    </tr>
    <tr>
      <th nzAlign="center">
        {{ 'DATE' | translate }}
      </th>
      <th nzAlign="center">
        {{ 'FRAME_MODEL' | translate }}
      </th>
      <th nzAlign="center">
        {{ 'LOT_INFO.COLOR' | translate }}
      </th>
      <th nzAlign="center">
        {{ 'LOT_INFO.SCORE' | translate }}
      </th>
      <th nzAlign="center">
        {{ 'LOT_INFO.SOLD_FOR' | translate }}
      </th>
      <th nzAlign="center">
        {{ 'IMPORT_BIDS.GROUP' | translate }}
      </th>
    </tr>
  </thead>

  <tbody>
    <tr *ngFor="let bid of table.data; trackBy: bidIdentity">
      <td
        [nzChecked]="selectedLotIds.has(bid.lot.id)"
        [nzDisabled]="(bid.status.isBiddable$ | async) === false"
        (nzCheckedChange)="selectBid(bid, $event)"
      ></td>
      <td nzAlign="center">
        <app-lot-mini-gallery [lot]="bid.lot" [height]="50"></app-lot-mini-gallery>
      </td>
      <td>
        <a nz-button nzBlock nzSize="small" nzType="primary" [href]="bid.lot.link" target="_blank">
          {{ bid.lot.code }}
        </a>
      </td>
      <td nzAlign="center">
        <div>{{ bid.lot.auctionName }}</div>
        <div>{{ bid.lot.auctionDatetime | date: 'yyyy-MM-dd H:mm:ss zz':'+0900' }}</div>
      </td>
      <td>
        <div class="blue">
          {{ bid.lot.companyEn }}
          {{ bid.lot.modelEn }}
        </div>
        <div>
          <span class="red">{{ bid.lot.year }}</span>
          {{ bid.lot.modelTypeEn }}
        </div>
        <div *ngIf="bid.lot.modelGradeEn" class="gray" [innerHtml]="bid.lot.modelGradeEn"></div>
      </td>
      <td>
        <div>
          <span class="red">{{ bid.lot.transmissionEn }}</span>
          {{ bid.lot.engineVolumeNum }} cc
        </div>
        <div *ngIf="bid.lot.colorEn" class="gray">
          {{ bid.lot.colorEn }}
        </div>
      </td>
      <td nzAlign="right">
        <div>{{ bid.lot.mileageNum }}000 km</div>
        <div *ngIf="bid.lot.ratingEn" class="orange">
          {{ bid.lot.ratingEn }}
        </div>
      </td>
      <td nzAlign="right">
        <div>{{ bid.lot.startPriceNum || 0 | number }}¥</div>
        <div>{{ bid.lot.soldPrice || 0 | number }}¥</div>
      </td>
      <td nzAlign="right">
        <div>{{ bid.amount || 0 | number }}¥</div>
        <div>
          <ng-container *ngIf="bid.group as group">
            {{ group.letter }} - {{ group.count }}
          </ng-container>
          <ng-container *ngIf="!bid.group"> — </ng-container>
        </div>
      </td>
      <td>
        <app-bid-status [bid]="bid"></app-bid-status>
      </td>
    </tr>
  </tbody>
</nz-table>
