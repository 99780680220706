import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PlaceBidError } from '../../enums/place-bid-error.enum';
import { PlaceBidStatus } from '../../enums/place-bid-status.enum';
import { TargetBid } from '../../types/target-bid.type';

@Component({
  selector: 'app-bid-status',
  templateUrl: './bid-status.component.html',
  styleUrls: ['./bid-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BidStatusComponent {
  @Input() bid: TargetBid;

  get isBiddable$(): Observable<boolean> {
    return this.bid.status.isBiddable$;
  }

  get errors$(): Observable<PlaceBidError[]> {
    return this.bid.status.errors$;
  }

  get mutationError$(): Observable<string> {
    return this.bid.status.mutationError$;
  }

  get isPlaced$(): Observable<boolean> {
    return this.bid.status.status$.pipe(map((status) => status === PlaceBidStatus.Placed));
  }
}
