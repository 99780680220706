import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';

import { validateAllFormFields } from '../../const';
import {
  BidComment,
  BidCommentCreateGQL,
  BidCommentsFragment,
} from '../../modules/graphql/service/graphql-auto-main-service';
import { MutationHandlingService } from '../../services/mutation-handling.service';

@Component({
  selector: 'app-bid-comments',
  templateUrl: './bid-comments.component.html',
  styleUrls: ['./bid-comments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BidCommentsComponent {
  @Input() bidId: string;
  @Input() currentUserId: string;
  @Input() bid: BidCommentsFragment;

  form: FormGroup;

  saving = of(false);

  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private mutationHandlingService: MutationHandlingService,
    private bidCommentCreateGQL: BidCommentCreateGQL,
  ) {
    this.form = this.fb.group({
      content: [null, [Validators.required]],
    });
  }

  getCommentAuthor(comment: BidComment): string {
    if (comment.isManager) {
      const managerLabel = this.translate.instant('BID_COMMENTS.MANAGER');
      return `(${managerLabel}) ${comment.user.fullName}`;
    } else {
      return comment.user.fullName;
    }
  }

  save(): void {
    if (this.form.valid) {
      this.saving = this.mutationHandlingService.processingOf(
        this.bidCommentCreateGQL,
        {
          input: {
            bidId: this.bidId,
            content: this.form.value.content,
          },
        },
        {
          refetch: true,
          successTranslationKey: 'BID_COMMENTS.SUCCESS',
          failureTranslationKey: 'BID_COMMENTS.FAIL',
        },
      );
    } else {
      validateAllFormFields(this.form);
    }
  }
}
