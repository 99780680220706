<div>
  <span
    *ngIf="views"
    [title]="'LOT_COUNTERS.VIEWS_COUNT' | translate:{ count: views }"
  >
    <i nz-icon nzType="eye" nzTheme="fill"></i>
    {{ views }}<span *ngIf="inFavorites">, </span>
  </span>

  <span
    *ngIf="inFavorites"
    nz-tooltip
    [title]="'LOT_COUNTERS.IN_FAVORITES_COUNT' | translate:{ count: inFavorites }"
  >
    <i nz-icon nzType="star" nzTheme="fill"></i>
    {{ inFavorites }}
  </span>
</div>
