<nz-row [nzGutter]="[60, 20]" nzJustify="center">
  <nz-col [nzSpan]="24">
    <nz-progress [nzPercent]="progressPercent$ | async" nzType="circle"></nz-progress>
  </nz-col>
  <nz-col>
    <nz-statistic
      [nzTitle]="'IMPORT_BIDS.SUCCESS' | translate"
      [nzValue]="successPlacedCount$ | async"
      [nzValueStyle]="{ color: '#3F8600' }"
    ></nz-statistic>
  </nz-col>
  <nz-col>
    <nz-statistic
      [nzTitle]="'IMPORT_BIDS.FAIL' | translate"
      [nzValue]="failPlacedCount$ | async"
      [nzValueStyle]="{ color: '#CF1322' }"
    ></nz-statistic>
  </nz-col>
</nz-row>
