import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LotVinImageComponent } from './lot-vin-image.component';

@NgModule({
  declarations: [LotVinImageComponent],
  imports: [CommonModule],
  exports: [LotVinImageComponent],
})
export class LotVinImageModule {}
