import { ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-photo-selector',
  templateUrl: './photo-selector.component.html',
  styleUrls: ['./photo-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhotoSelectorComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhotoSelectorComponent implements ControlValueAccessor {
  @Input() photoUrls: string[] = [];

  selectedPhotoUrl: string | null = null;

  onChange = (url: string | null) => {};
  onTouch = () => {};

  writeValue(photoUrl: string | null = null): void {
    this.selectedPhotoUrl = photoUrl;
  }

  registerOnChange(fb: (url: string | null) => void): void {
    this.onChange = fb;
  }

  registerOnTouched(fb: () => void): void {
    this.onTouch = fb;
  }

  selectPhoto(url: string): void {
    if (url === this.selectedPhotoUrl) {
      this.selectedPhotoUrl = null;
    } else {
      this.selectedPhotoUrl = url;
    }

    this.onChange(url);
  }
}
