import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzImageModule } from 'ng-zorro-antd/image';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';

import { LotWatchRequestFormModalComponent } from './components/lot-watch-request-form-modal/lot-watch-request-form-modal.component';
import { LotWatchRequestButtonComponent } from './lot-watch-request-button.component';
import { NzDividerModule } from 'ng-zorro-antd/divider';

@NgModule({
  declarations: [LotWatchRequestButtonComponent, LotWatchRequestFormModalComponent],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    NzButtonModule,
    NzModalModule,
    NzAlertModule,
    NzInputModule,
    NzImageModule,
    NzSkeletonModule,
    NzDividerModule,
  ],
  exports: [LotWatchRequestButtonComponent],
})
export class LotWatchRequestButtonModule {}
