export enum PlaceBidError {
  BidAmountIsNotMultiple5k = 'bid-amount-is-not-multiple-5k',
  BidAmountLessThenStartPrice = 'bid-amount-less-then-start-price',
  ImpossiblePlaceGroupBid = 'impossible-place-group-bid',
  GroupLimitsReached = 'group-limits-reached',
  NoAvailableBidCondition = 'no-available-bid-condition',
  LotSanctioned = 'lot-sanctioned',
  MotoOverprice = 'moto-overprice',
  TimeOver = 'time-over',
  LotsLimit = 'lots-limit',
  BidsLimit = 'bids-limit',
}
