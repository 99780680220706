import { combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import { filter, map, shareReplay, startWith, switchMap } from 'rxjs/operators';
import {
  BID_CONDITION_INTACT,
  BID_CONDITION_MOTO,
  BID_CONDITION_OTHER_COUNTRIES,
  BID_CONDITION_OTHER_COUNTRIES_MOTO,
} from 'src/app/const';
import { Limits } from 'src/app/services/limits.service';

import {
  BidCondition,
  BidGroup,
  ImportedBidsBiddingDataQuery,
  ImportedLotFragment,
  LotTypeEnum,
} from '../../graphql/service/graphql-auto-main-service';
import { PlaceBidError } from '../enums/place-bid-error.enum';
import { PlaceBidStatus } from '../enums/place-bid-status.enum';
import { ExcelBid } from '../types/excel-bid.type';

type BidOnly = Omit<ExcelBid, 'lot'>;

export class BidStatus {
  readonly isBiddable$: Observable<boolean>;
  readonly status$: Observable<PlaceBidStatus>;
  readonly errors$: Observable<PlaceBidError[]>;

  private readonly placeResultSubject = new ReplaySubject<{ success: boolean; error?: string }>();

  constructor(
    bid: BidOnly,
    lot: ImportedLotFragment,
    limits: Limits,
    isSanctioned$: Observable<boolean>,
    targetGroup$: Observable<BidGroup | null>,
    canPlaceGroupBid$: Observable<boolean>,
    bidConditions$: Observable<BidCondition[]>,
    biddingData$: Observable<ImportedBidsBiddingDataQuery>,
  ) {
    this.errors$ = this.buildErrors(
      bid,
      lot,
      limits,
      isSanctioned$,
      canPlaceGroupBid$,
      targetGroup$,
      bidConditions$,
      biddingData$,
    );
    this.status$ = this.errors$.pipe(
      map((errors) => (errors.length ? PlaceBidStatus.NotBiddable : PlaceBidStatus.Biddable)),
      switchMap((status) =>
        this.placeResultSubject.pipe(
          map((result) => (result.success ? PlaceBidStatus.Placed : PlaceBidStatus.PlaceFailed)),
          startWith(status),
        ),
      ),
      shareReplay(1),
    );
    this.isBiddable$ = this.status$.pipe(map((status) => status === PlaceBidStatus.Biddable));
  }

  placeFailed(error: string): void {
    this.placeResultSubject.next({
      success: false,
      error,
    });
  }

  placeSuccess(): void {
    this.placeResultSubject.next({
      success: true,
    });
  }

  get mutationError$(): Observable<string> {
    return this.placeResultSubject.asObservable().pipe(map((result) => result.error));
  }

  private buildErrors(
    bid: BidOnly,
    lot: ImportedLotFragment,
    limits: Limits,
    isSanctioned$: Observable<boolean>,
    canPlaceGroupBid$: Observable<boolean>,
    targetGroup$: Observable<BidGroup | null>,
    bidConditions$: Observable<BidCondition[]>,
    biddingData$: Observable<ImportedBidsBiddingDataQuery>,
  ): Observable<PlaceBidError[]> {
    return combineLatest([
      this.checkBidAmountIsNotMultiple5k(bid),
      this.checkBidAmountLessThenStartPrice(bid, lot),
      this.checkImpossiblePlaceGroupBid(bid, canPlaceGroupBid$),
      this.checkGroupLimitsReached(bid, targetGroup$),
      this.checkNoAvailableBidCondition(lot, bidConditions$, biddingData$),
      this.checkLotSanctioned(isSanctioned$),
      this.checkMotoOverprice(lot),
      this.checkTimeOver(limits),
      this.checkBidsLimit(limits),
      this.checkLotsLimit(limits),
    ]).pipe(map((errors) => errors.filter((error) => !!error)));
  }

  private checkBidAmountIsNotMultiple5k(
    bid: BidOnly,
  ): Observable<PlaceBidError.BidAmountIsNotMultiple5k | null> {
    const amount = bid.amount;
    return of(amount % 5000 ? PlaceBidError.BidAmountIsNotMultiple5k : null);
  }

  private checkBidAmountLessThenStartPrice(
    bid: BidOnly,
    lot: ImportedLotFragment,
  ): Observable<PlaceBidError.BidAmountLessThenStartPrice | null> {
    return of(
      bid.amount < Number(lot.startPriceNum) ? PlaceBidError.BidAmountLessThenStartPrice : null,
    );
  }

  private checkImpossiblePlaceGroupBid(
    bid: BidOnly,
    canPlaceGroupBid$: Observable<boolean>,
  ): Observable<PlaceBidError.ImpossiblePlaceGroupBid | null> {
    if (bid.group) {
      return canPlaceGroupBid$.pipe(
        map((canPlaceGroupBid) =>
          canPlaceGroupBid ? null : PlaceBidError.ImpossiblePlaceGroupBid,
        ),
      );
    } else {
      return of(null);
    }
  }

  private checkGroupLimitsReached(
    bid: BidOnly,
    targetGroup$: Observable<BidGroup | null>,
  ): Observable<PlaceBidError.GroupLimitsReached | null> {
    if (bid.group) {
      return targetGroup$.pipe(
        map((group) => group?.bidsLimitReached || group?.lotsLimitReached),
        map((someLimitReached) => (someLimitReached ? PlaceBidError.GroupLimitsReached : null)),
      );
    } else {
      return of(null);
    }
  }

  private checkNoAvailableBidCondition(
    lot: ImportedLotFragment,
    bidConditions$: Observable<BidCondition[]>,
    biddingData$: Observable<ImportedBidsBiddingDataQuery>,
  ): Observable<PlaceBidError.NoAvailableBidCondition | null> {
    return combineLatest([
      of(lot.subgroup),
      biddingData$.pipe(map((data) => data.currentUser?.isOtherCountries)),
    ]).pipe(
      map(([lotType, isOtherCountries]) => {
        if (lotType === LotTypeEnum.Moto) {
          return isOtherCountries ? BID_CONDITION_OTHER_COUNTRIES_MOTO : BID_CONDITION_MOTO;
        } else {
          return isOtherCountries ? BID_CONDITION_OTHER_COUNTRIES : BID_CONDITION_INTACT;
        }
      }),
      switchMap((targetBidConditionId) =>
        bidConditions$.pipe(
          map((conditions) =>
            conditions.find((condition) => Number(condition.id) === targetBidConditionId),
          ),
        ),
      ),
      map((hasTargetBidCondition) =>
        hasTargetBidCondition ? null : PlaceBidError.NoAvailableBidCondition,
      ),
    );
  }

  private checkLotSanctioned(
    isLotSanctioned$: Observable<boolean>,
  ): Observable<PlaceBidError.LotSanctioned | null> {
    return isLotSanctioned$.pipe(
      map((isLotSanctioned) => (isLotSanctioned ? PlaceBidError.LotSanctioned : null)),
    );
  }

  private checkMotoOverprice(
    lot: ImportedLotFragment,
  ): Observable<PlaceBidError.MotoOverprice | null> {
    const isOverprice = lot.subgroup === LotTypeEnum.Moto && Number(lot.startPriceNum) >= 9999000;
    return of(isOverprice ? PlaceBidError.MotoOverprice : null);
  }

  private checkTimeOver(limits: Limits): Observable<PlaceBidError.TimeOver | null> {
    return limits.loading$.pipe(
      filter((loading) => !loading),
      switchMap(() => limits.timeLimitReached$),
      map((reached) => (reached ? PlaceBidError.TimeOver : null)),
    );
  }

  private checkBidsLimit(limits: Limits): Observable<PlaceBidError.BidsLimit | null> {
    return limits.loading$.pipe(
      filter((loading) => !loading),
      switchMap(() => limits.lotBidsLimitReached$),
      map((reached) => (reached ? PlaceBidError.BidsLimit : null)),
    );
  }

  private checkLotsLimit(limits: Limits): Observable<PlaceBidError.LotsLimit | null> {
    return limits.loading$.pipe(
      filter((loading) => !loading),
      switchMap(() => limits.lotsLimitReached$),
      map((reached) => (reached ? PlaceBidError.LotsLimit : null)),
    );
  }
}
