import { AuthenticationService } from '@akebono/core';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthenticationDetails, CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import { Angulartics2 } from 'angulartics2';
import { Apollo } from 'apollo-angular';
import { NzMessageService } from 'ng-zorro-antd/message';
import { CookieService } from 'ngx-cookie-service';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { SpecialAlertsService } from '../../../../services/special-alerts.service';

@Component({
  selector: 'app-auth-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent {
  form: FormGroup;
  formAfterSendMfa: FormGroup;
  isCheckUserLoading = false;
  isSensSms = false;
  cogUser = null;
  username = '';
  password = '';
  deadline: number;
  prefixTimer: string;
  disabledTimer: boolean;
  smsWaitTime = 3000 * 60;
  disabledNewSms: boolean;
  queryParams: Params;
  referrer: string = null;

  constructor(
    private formBuilder: FormBuilder,
    private message: NzMessageService,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private authService: AuthenticationService,
    private router: Router,
    private apollo: Apollo,
    private specialAlerts: SpecialAlertsService,
    private angulartics2: Angulartics2,
  ) {
    this.route.queryParams.subscribe((queryParams: Params) => {
      this.queryParams = queryParams;
      if (queryParams.referrer) {
        this.referrer = queryParams.referrer;
      }
    });

    this.form = formBuilder.group({
      username: this.formBuilder.control('', [Validators.required]),
      password: this.formBuilder.control('', [Validators.required]),
    });

    this.formAfterSendMfa = formBuilder.group({
      code: this.formBuilder.control('', [Validators.required]),
    });

    this.disabledTimer = false;
    this.disabledNewSms = true;
  }

  validateAllFormFields(form: FormGroup) {
    // tslint:disable-next-line:forin
    for (const i in form.controls) {
      form.controls[i].markAsDirty();
      form.controls[i].updateValueAndValidity();
    }
  }

  submitForm() {
    this.isCheckUserLoading = true;
    this.validateAllFormFields(this.form);
    if (this.form.valid) {
      let username = this.form.controls.username.value;
      if (username.includes('@')) {
        username = username.toLowerCase();
      }
      this.auth(username?.trim(), this.form.controls.password.value);
    } else {
      this.isCheckUserLoading = false;
    }
  }

  auth(username: string, password: string) {
    const cogUserPool = new CognitoUserPool(environment.cognitoPool);
    const cogAuthData = new AuthenticationDetails({
      Password: password,
      Username: username,
    });

    this.cogUser = new CognitoUser({
      Username: username,
      Pool: cogUserPool,
    });

    this.cogUser.authenticateUser(cogAuthData, {
      onSuccess: (session, userConfirmationNecessary) => {
        this.angulartics2.eventTrack.next({
          action: 'AuthSuccess',
          properties: {
            step: 'CalbackStart',
            username: username,
          },
        });
        this.setCookie('token', session.getAccessToken().getJwtToken());
        this.setCookie('refreshToken', session.getRefreshToken().getToken());
        this.angulartics2.eventTrack.next({
          action: 'AuthSuccess',
          properties: {
            step: 'CookieSet',
            username: username,
          },
        });
        this.authService.setIsAuthorized(true);
        this.angulartics2.eventTrack.next({
          action: 'AuthSuccess',
          properties: {
            step: 'SetAuthorized',
            username: username,
          },
        });

        // if (username) {
        //   this.angulartics2.setUsername.next(username);
        // }

        this.angulartics2.eventTrack.next({
          action: 'AuthSuccess',
          properties: {
            step: 'SetUsername',
            username: username,
          },
        });

        this.onSuccess();
      },
      onFailure: (err) => {
        this.isCheckUserLoading = false;
        if (err.code === 'PasswordResetRequiredException') {
          this.message.warning('Required change password');
        } else {
          this.message.error(err.code + ' ' + err.message);
        }
      },
      mfaRequired: (challengeName, challengeParameters) => {
        this.isCheckUserLoading = false;
        this.isSensSms = true;
        this.username = username;
        this.password = password;
        if (challengeName === 'SMS_MFA') {
          this.message.success(
            'Send sms to phone ' + challengeParameters.CODE_DELIVERY_DESTINATION,
          );
          this.startCountDown();
        }
      },
    });
  }

  submitCode() {
    this.isCheckUserLoading = true;
    this.validateAllFormFields(this.formAfterSendMfa);
    if (this.formAfterSendMfa.valid) {
      this.cogUser.sendMFACode(this.formAfterSendMfa.controls.code.value, {
        onSuccess: (session) => {
          this.setCookie('token', session.getAccessToken().getJwtToken());
          this.setCookie('refreshToken', session.getRefreshToken().getToken());
          this.authService.setIsAuthorized(true);
          this.onSuccess();
        },
        onFailure: (err) => {
          this.isCheckUserLoading = false;
          if (err.code === 'PasswordResetRequiredException') {
            this.message.warning('Required change password');
            setTimeout(() => {
              this.router.navigateByUrl('/reset-password');
            }, 1000);
          } else {
            this.message.error(err.code + ' ' + err.message);
          }
        },
      });
    }
  }

  async onSuccess() {
    this.angulartics2.eventTrack.next({
      action: 'AuthSuccess',
      properties: {
        step: 'ResettingClients',
      },
    });
    this.angulartics2.eventTrack.next({
      action: 'AuthSuccess',
      properties: {
        step: 'Navigating',
      },
    });

    await this.specialAlerts
      .checkAgentDebts()
      .pipe(
        tap(() => {
          // location.reload(); // need to be reloaded to recreate permissions
        }),
      )
      .toPromise();

    await Promise.all([
      this.apollo.use('authentication').client.resetStore(),
      this.apollo.use('cars-default').client.resetStore(),
      this.apollo.use('auto-main').client.resetStore(),
      this.apollo.use('calculator').client.resetStore(),
    ]).then(() => {
      this.angulartics2.eventTrack.next({
        action: 'AuthSuccess',
        properties: {
          step: 'Navigating',
        },
      });
      this.router.navigateByUrl('/');
    });
  }

  startCountDown() {
    this.disabledTimer = true;
    this.deadline = Date.now() + this.smsWaitTime;
    this.disabledNewSms = true;

    setTimeout(() => {
      this.showSendNewSms();
    }, this.smsWaitTime);
  }

  showSendNewSms() {
    this.disabledTimer = false;
    this.disabledNewSms = true;
  }

  sendNewSms() {
    if (this.username && this.password) {
      this.auth(this.username, this.password);
    }
  }

  setCookie(key, value) {
    this.cookieService.set(key, value, 31, '/', environment.domain, environment.production, 'Lax');
  }
}
