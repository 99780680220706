import { Injectable } from '@angular/core';
import { JpcenterAuctLotsFull3FilterInput } from 'src/app/modules/graphql/service/graphql-stats-auto-service';

@Injectable()
export class QueryFilterService {
  public getQueryFilter(params, inclusionFilterFields): JpcenterAuctLotsFull3FilterInput {
    const inclusionFilter = {};
    Object.keys(inclusionFilterFields).forEach((key) => {
      if (params[key] && params[key].length > 0) {
        inclusionFilter[inclusionFilterFields[key]] = {
          in: [].concat(params[key]).map((v) => (key === 'auction' ? Number(v) : v)),
        };
      }
    });

    const filter: JpcenterAuctLotsFull3FilterInput = { ...inclusionFilter };
    if (params.company && params.company != 'any') {
      filter.companyRef = {
        eq: Number(params.company),
      };
    }
    if (params.model && params.model != 'any') {
      filter.modelNameRef = {
        eq: Number(params.model),
      };
    }
    if (params.mileageFrom) {
      filter.mileageNum = {
        gte: Number(params.mileageFrom),
      };
    }
    if (params.mileageTo) {
      if (!filter.mileageNum) {
        filter.mileageNum = {};
      }
      filter.mileageNum.lte = Number(params.mileageTo);
    }
    if (params.engineVolumeFrom) {
      filter.displacementNum = {
        gte: Number(params.engineVolumeFrom),
      };
    }
    if (params.engineVolumeTo) {
      if (!filter.displacementNum) {
        filter.displacementNum = {};
      }
      filter.displacementNum.lte = Number(params.engineVolumeTo);
    }
    if (params.yearFrom) {
      filter.modelYearEn = {
        gte: Number(params.yearFrom),
      };
    }
    if (params.yearTo) {
      if (!filter.modelYearEn) {
        filter.modelYearEn = {};
      }
      filter.modelYearEn.lte = Number(params.yearTo);
    }
    if (params.bid) {
      filter.bid = {
        eq: params.bid,
      };
    }
    if (params.datetime) {
      filter.datetime = {
        eq: params.datetime,
      };
    }

    return Object.keys(filter).length > 0 ? filter : null;
  }
}
