<nz-tabset
  nzType="editable-card"
  nzHideAdd
  style="overflow: visible;"
  (nzSelectChange)="changeTab()"
  [nzSelectedIndex]="activeTabIndex"
>
  <nz-tab nzTitle="USS">
    <app-uss-auction [lang]="currentLang"></app-uss-auction>
  </nz-tab>
  <nz-tab nzTitle="TAA">
    <app-taa-auction [lang]="currentLang"></app-taa-auction>
  </nz-tab>
  <nz-tab nzTitle="СAA">
    <app-caa-auction [lang]="currentLang"></app-caa-auction>
  </nz-tab>
  <nz-tab nzTitle="JU">
    <app-ju-auction [lang]="currentLang"></app-ju-auction>
  </nz-tab>
  <nz-tab nzTitle="LAA">
    <app-laa-auction [lang]="currentLang"></app-laa-auction>
  </nz-tab>
  <nz-tab nzTitle="ARAI">
    <app-arai-auction [lang]="currentLang"></app-arai-auction>
  </nz-tab>
  <nz-tab nzTitle="HERO">
    <app-hero-auction [lang]="currentLang"></app-hero-auction>
  </nz-tab>
  <nz-tab nzTitle="IAA OSAKA">
    <app-iaa-osaka-auction [lang]="currentLang"></app-iaa-osaka-auction>
  </nz-tab>
  <nz-tab nzTitle="BAYAUC">
    <app-bayauc-auction [lang]="currentLang"></app-bayauc-auction>
  </nz-tab>
  <nz-tab nzTitle="MIRIVE">
    <app-mirive-auction [lang]="currentLang"></app-mirive-auction>
  </nz-tab>
</nz-tabset>
