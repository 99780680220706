<nz-skeleton [nzLoading]="loading$ | async" [nzActive]="true" [nzParagraph]="{ rows: 10 }">
  <section class="header">
    <div class="meta">
      {{ publishedAt$ | async | date: 'dd.MM.yyyy' }}
    </div>
    <h3>{{ title$ | async }}</h3>
  </section>

  <section class="content" [innerHtml]="content$ | async"></section>
</nz-skeleton>
