import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { LotCounters } from '../../types/lot-counters.type';

@Component({
  selector: 'app-lot-bid-and-bidders-counter',
  templateUrl: './lot-bid-and-bidders-counter.component.html',
  styleUrls: ['./lot-bid-and-bidders-counter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotBidAndBiddersCounterComponent {
  @Input() tooltipPosition?: string | string[] = null;

  @HostBinding('style.display') display: 'none' | 'inline-block' = 'none';

  bids = 0;
  bidders = 0;

  @Input()
  set counter(counters: LotCounters | null) {
    if (counters?.bidsCount && counters?.biddersCount) {
      this.bids = counters.bidsCount;
      this.bidders = counters.biddersCount;
      this.display = 'inline-block';
    } else {
      this.display = 'none';
    }
  }

  getCorrectWordEnding(num: number, endings: string[]) {
    const n = Math.abs(num) % 100;
    const n1 = n % 10;
    if (n > 10 && n < 20) return endings[2];
    if (n1 > 1 && n1 < 5) return endings[1];
    if (n1 == 1) return endings[0];
    return endings[2];
  }
}
