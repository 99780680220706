import { AkebonoCoreModule } from '@akebono/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { LotTicketRulesComponent } from './lot-ticket-rules.component';

@NgModule({
  declarations: [LotTicketRulesComponent],
  imports: [
    CommonModule,
    BrowserModule,
    AkebonoCoreModule,
    TranslateModule,
    BrowserAnimationsModule,
    NzButtonModule,
    NzAlertModule,
    NzCollapseModule,
  ],
  exports: [LotTicketRulesComponent],
})
export class LotTicketRulesModule {}
