<h1 class="lot-header">
  <span>
    {{ lot?.company }}
    {{ lot?.modelName }}
    {{ lot?.frame }}
  </span>
  <span class="counter">
    <app-lot-views-and-favorites-counter [counter]="lot"></app-lot-views-and-favorites-counter>
  </span>
</h1>
<h3 class="lot-subheader">
  {{ lot?.mileage | mileage }} - {{ lot?.auctionName }} - {{ lot?.bid }} -
  {{ lot?.date?.slice(0, 10) }}
</h3>
