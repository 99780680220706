<ng-container *rxLet="interesting$ as interesting">
  <i
    *ngIf="interesting === UserLotMarkEnum.Interesting"
    nz-icon
    nzType="star"
    nzTheme="fill"
    [style.color]="'#fadb14'"
  ></i>
  <i
    *ngIf="interesting === UserLotMarkEnum.Uninteresting"
    nz-icon
    nzType="dislike"
    nzTheme="fill"
    [style.color]="'#ff4d4f'"
  ></i>
</ng-container>
