import { AkebonoCoreModule } from '@akebono/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTypographyModule } from 'ng-zorro-antd/typography';

import { LotMiniGalleryModule } from '../lot-mini-gallery/lot-mini-gallery.module';
import { LotsUniversalTableComponent } from './lots-universal-table.component';

@NgModule({
  declarations: [LotsUniversalTableComponent],
  imports: [
    CommonModule,
    TranslateModule,
    AkebonoCoreModule,
    LotMiniGalleryModule,
    NzButtonModule,
    NzTypographyModule,
    NzTableModule,
  ],
  exports: [LotsUniversalTableComponent],
})
export class LotsUniversalTableModule {}
