import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, defer, iif, Observable, of } from 'rxjs';
import { pluck, switchMap } from 'rxjs/operators';
import { AuctionService } from 'src/app/services/auction.service';

import { AuctionRemotenessEnum } from '../../graphql/service/graphql-directory-service';

@Component({
  selector: 'app-lot-auction-name',
  templateUrl: './lot-auction-name.component.html',
  styleUrls: ['./lot-auction-name.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotAuctionNameComponent implements OnInit {
  @Input()
  set auctionName(auctionName: string | null) {
    this.auctionName$.next(auctionName);
  }

  auctionName$ = new BehaviorSubject<string | null>(null);
  remoteness$: Observable<AuctionRemotenessEnum | null> = of(null);

  constructor(private auctionService: AuctionService) {}

  ngOnInit(): void {
    this.remoteness$ = this.auctionName$.pipe(
      switchMap((auctionName) =>
        iif(
          () => Boolean(auctionName),
          defer(() => this.auctionService.getByName(auctionName)),
          of(null),
        ),
      ),
      pluck('auctionData', 'remoteness'),
    );
  }
}
