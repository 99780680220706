import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-lot-last-visit-date',
  templateUrl: './lot-last-visit-date.component.html',
  styleUrls: ['./lot-last-visit-date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotLastVisitDateComponent implements OnInit, OnChanges {
  @Input() lotId?: number | string;

  lastVisitDate: Date = null;

  constructor() {}

  ngOnInit(): void {
    if (this.lotId) {
      this.setLastVisitDate();
    }
  }

  setLastVisitDate(): void {
    try {
      const date = localStorage.getItem(`${this.lotId}-lastVisit`);
      if (date) {
        this.lastVisitDate = new Date(date);
      }
      localStorage.setItem(`${this.lotId}-lastVisit`, new Date().toISOString());
    } catch (e) {
      console.log(e);
    }
  }

  ngOnChanges(): void {
    if (this.lotId) {
      this.setLastVisitDate();
    }
  }
}
