import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocalStorageService } from 'src/app/services/local-storage.service';

@Injectable()
export class AudioTranslationSpeedService {
  private readonly audioTranslationSpeedLocalStorageKey = 'defaultAudioTranslationSpeed';
  private readonly defaultSpeed = 1.0;

  constructor(private readonly localStorageService: LocalStorageService) {}

  set speed(value: number) {
    this.localStorageService.set(this.audioTranslationSpeedLocalStorageKey, value);
  }

  get speed(): number {
    return this.localStorageService.get<number>(
      this.audioTranslationSpeedLocalStorageKey,
      this.defaultSpeed,
    );
  }

  get changes$(): Observable<number> {
    return this.localStorageService
      .get$<number>(this.audioTranslationSpeedLocalStorageKey)
      .pipe(map((speed) => speed ?? this.defaultSpeed));
  }
}
