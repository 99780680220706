import { Injectable } from '@angular/core';
import {
  LotIconEnum,
  LotUpdateGQL,
  LotUpdateInput,
} from '../../../graphql/service/graphql-auto-main-service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { MutationHandlingServiceV2 } from '@akebono/core';

@Injectable()
export class LotIconsService {
  constructor(private mhs: MutationHandlingServiceV2, private lotUpdateGQL: LotUpdateGQL) {}

  updateLotIcons(input: LotUpdateInput): Observable<LotIconEnum[]> {
    return this.mhs
      .mutate(this.lotUpdateGQL, {
        input,
      })
      .data.pipe(
        map((result) => Array.from(result.lotUpdate?.lot?.icons ?? [])),
        catchError(() => of([])),
      );
  }
}
