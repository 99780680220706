import { ConfigService, MutationHandlingServiceV2 } from '@akebono/core';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { ReactiveLoadings } from 'src/app/const';
import {
  CurrentUserNotifyMessage,
  CurrentUserNotifyMessagesGQL,
  DeleteUserNotifyMessageGQL,
  NotifyBotInfo,
  NotifyMessage,
  ToggleUserNotifyMessageGQL,
  User,
  UserChannel,
} from 'src/app/modules/graphql/service/graphql-shared-familiar-service';

import { SubscribeTelegramModalComponent } from '../subscribe-telegram-modal/subscribe-telegram-modal.component';

@Component({
  selector: 'lib-notification-tab',
  templateUrl: './notification-tab.component.html',
  styleUrls: ['./notification-tab.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NotificationTabComponent implements OnInit {
  @Input() currentUser: User = null;

  language$: Observable<string>;

  loadings: ReactiveLoadings = {};
  loadingsDelete: ReactiveLoadings = {};
  currentUserNotifyMessages: CurrentUserNotifyMessage[] = [];
  form: FormGroup = null;

  changing = false;

  constructor(
    config: ConfigService,
    private modal: NzModalService,
    private translate: TranslateService,
    private currentUserNotifyMessagesGQL: CurrentUserNotifyMessagesGQL,
    private toggleUserNotifyMessageGQL: ToggleUserNotifyMessageGQL,
    private deleteUserNotifyMessageGQL: DeleteUserNotifyMessageGQL,
    private mhs: MutationHandlingServiceV2,
  ) {
    this.language$ = config.getCurrentLanguage$().pipe(
      map((language) => language.localeId),
      shareReplay(1),
    );
  }

  ngOnInit(): void {
    this.currentUserNotifyMessagesGQL
      .watch(null, {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
      })
      .valueChanges.subscribe((result) => {
        console.log('currentUserNotifyMessages', result.data?.currentUserNotifyMessages);
        this.currentUserNotifyMessages = result.data?.currentUserNotifyMessages;
      });
  }

  addSubscribe(bot: NotifyBotInfo): void {
    this.modal.create({
      nzTitle: this.translate.instant('PROFILE.SUBSCRIBE_TO', {
        name: bot?.clientName,
      }),
      nzContent: SubscribeTelegramModalComponent,
      nzFooter: null,
      nzComponentParams: {
        bot,
        currentUser: this.currentUser,
      },
    });
  }
  deleteNotifiChat(channel: UserChannel): void {
    this.loadingsDelete[channel?.id] = this.mhs.mutate(
      this.deleteUserNotifyMessageGQL,
      {
        input: {
          channelId: channel?.id,
        },
      },
      {
        refetch: true,
      },
    ).loading;
  }

  toggleMessage(channel: UserChannel, message: NotifyMessage): void {
    this.loadings[`${channel?.id}-${message?.id}`] = this.mhs.mutate(
      this.toggleUserNotifyMessageGQL,
      {
        input: {
          channelId: channel?.id,
          messageTypeId: message?.id,
        },
      },
      {
        refetch: true,
      },
    ).loading;
  }
}
