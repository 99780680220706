<form [formGroup]="form" nz-form>
  <nz-form-item>
    <nz-form-control>
      <nz-select formControlName="lotType" nzPlaceHolder="{{ 'SELECT_LOT_TYPE' | translate }}">
        <nz-option
          *ngFor="let lotType of lotTypes"
          [nzLabel]="lotType.toUpperCase() | translate"
          [nzValue]="lotType"
        >
        </nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
  <nz-divider></nz-divider>
  <nz-form-item>
    <nz-form-label [nzSm]="5" [nzXs]="24" class="align-start">
      {{ 'DATE' | translate }}
    </nz-form-label>
    <nz-form-control [nzSm]="24" [nzXs]="24">
      <nz-date-picker
        formControlName="date"
        nzPlaceHolder="{{ 'DATE' | translate }}"
        nzShowTime
        style="width: 100%"
      ></nz-date-picker>
    </nz-form-control>
  </nz-form-item>
  <nz-divider></nz-divider>
  <nz-form-item>
    <nz-form-label [nzSm]="5" [nzXs]="24" class="align-start">
      {{ 'LOT_INFO.AUCTION' | translate }} / {{ 'LOT_INFO.TITLE' | translate }}
    </nz-form-label>
    <nz-form-control [nzSm]="24" [nzXs]="24">
      <nz-input-group nzCompact>
        <input
          formControlName="auctionName"
          nz-input
          placeholder="{{ 'LOT_INFO.AUCTION' | translate }}"
          style="width: 50%"
        />
        <input
          formControlName="code"
          nz-input
          placeholder="{{ 'BID_NUMBER' | translate }}"
          style="width: 50%"
        />
      </nz-input-group>
    </nz-form-control>
  </nz-form-item>
  <nz-divider></nz-divider>
  <nz-form-item>
    <nz-form-label [nzSm]="5" [nzXs]="24" class="align-start">
      {{ 'AUTO' | translate }}
    </nz-form-label>
    <nz-form-control [nzSm]="24" [nzXs]="24">
      <nz-input-group nzCompact>
        <input
          formControlName="company"
          nz-input
          placeholder="{{ 'COMPANY' | translate }}"
          style="width: 50%"
          type="text"
        />
        <input
          formControlName="modelName"
          nz-input
          placeholder="{{ 'MODEL' | translate }}"
          style="width: 50%"
          type="text"
        />
        <input
          formControlName="frame"
          nz-input
          placeholder="{{ 'FRAME_MODEL' | translate }}"
          style="width: 50%"
          type="text"
        />
        <nz-input-number
          [nzMin]="0"
          formControlName="year"
          nzPlaceHolder="{{ 'LOT_INFO.YEAR' | translate }}"
          style="width: 50%"
        ></nz-input-number>
        <input
          formControlName="engineVolume"
          nz-input
          placeholder="{{ 'LOT_INFO.ENGINE_CC' | translate }}"
          style="width: 50%"
          type="text"
        />
        <input
          formControlName="color"
          nz-input
          placeholder="{{ 'LOT_INFO.COLOR' | translate }}"
          style="width: 50%"
          type="text"
        />
        <input
          formControlName="mileage"
          nz-input
          placeholder="{{ 'LOT_INFO.MILEAGE' | translate }}"
          style="width: 50%"
          type="text"
        />
        <input
          formControlName="score"
          nz-input
          placeholder="{{ 'LOT_INFO.SCORE' | translate }}"
          style="width: 50%"
          type="text"
        />
      </nz-input-group>
      <nz-input-number
        [nzMin]="0"
        [nzStep]="1000"
        formControlName="startPrice"
        nzPlaceHolder="{{ 'LOT_INFO.START_PRICE' | translate }}"
        style="width: 50%"
      ></nz-input-number>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-upload
      [nzMultiple]="true"
      [nzDisabled]="fileList?.length !== s3Keys?.length"
      [(nzFileList)]="fileList"
      [nzRemove]="removeFiles"
      [nzCustomRequest]="uploadFiles"
    >
      <button nz-button>
        <i nz-icon nzType="upload"></i>
        {{ 'MANUAL_LOTS.UPLOAD' | translate }}
      </button>
    </nz-upload>
  </nz-form-item>
</form>

<footer *nzModalFooter>
  <button (click)="modal.close()" [disabled]="loading$ | async" nz-button nzType="default">
    {{ 'CANCEL' | translate }}
  </button>
  <button
    (click)="lotCreateManual()"
    [disabled]="(loading$ | async) || fileList?.length !== s3Keys?.length"
    [nzLoading]="loading$ | async"
    nz-button
    nzType="primary"
  >
    {{ 'MANUAL_LOTS.CREATE' | translate }}
  </button>
</footer>
