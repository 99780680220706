import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { first, map, shareReplay, takeUntil } from 'rxjs/operators';
import { AuctionService } from 'src/app/services/auction.service';

import { environment } from '../../../../environments/environment';
import { AutoLotView } from '../../../const';
import { UserLotQuery } from '../../graphql/service/graphql-auto-main-service';

@Component({
  selector: 'app-lot-edit-auction-sheet-zones',
  templateUrl: './lot-edit-auction-sheet-zones-button.component.html',
  styleUrls: ['./lot-edit-auction-sheet-zones-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotEditAuctionSheetZonesButtonComponent implements OnInit, OnDestroy {
  @Input() lot: AutoLotView;
  @Input() currentUser: UserLotQuery['currentUser'];
  @Input() fullSize = false;

  auctionId$: Observable<number | null>;

  @HostBinding('style.display')
  display: 'none' | 'inline-block' = 'inline-block';

  private readonly destroy$ = new Subject<void>();

  constructor(private readonly auctionService: AuctionService) {}

  ngOnInit(): void {
    if (!this.currentUser.isAuctioneer) {
      this.display = 'none';
      return;
    }

    this.auctionId$ = this.auctionService.getByName(this.lot.auctionName).pipe(
      first(),
      map((auction) => auction?.id),
      shareReplay(1),
      takeUntil(this.destroy$),
    );

    this.auctionId$.subscribe(
      (auctionId) => (this.display = !!auctionId ? 'inline-block' : 'none'),
    );
  }

  async open(): Promise<void> {
    const windowWidth = 950;
    const windowHeight = 800;

    const auctionId = await this.auctionId$.toPromise();
    const url = `${environment.managerDomain}/akebono-auctioneer/auction-sheet-zones/${auctionId}?layout=clean`;
    const features = `Toolbar=0,Location=0,Directories=0,Status=0,Menubar=0,Scrollbars=0,Width=${windowWidth},Height=${windowHeight}`;

    window.open(url, null, features);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
