<button nz-button nzSize="large" [nzBlock]="fullSize" (click)="modalVisible = true">
  <i nz-icon nzType="ordered-list" nzTheme="outline"></i>
  <span *ngIf="fullSize">
    {{ 'BIDS_LIST' | translate }}
  </span>
</button>

<nz-modal
  [(nzVisible)]="modalVisible"
  [nzTitle]="'BIDS' | translate"
  [nzFooter]="null"
  [nzWidth]="'980px'"
  (nzOnCancel)="modalVisible = false"
>
  <section *nzModalContent>
    <nz-table
      #table
      nzBordered
      nzSize="small"
      [nzFrontPagination]="false"
      [nzShowPagination]="false"
      [nzData]="bids"
      [nzTitle]="
        'LOT_BIDS_TABLE_TITLE' | translate: { bidsTotal: bids.length, usersTotal: usersTotal }
      "
    >
      <thead>
      <tr>
        <th [nzSortFn]="bidUserFullNameSort" [nzSortPriority]="true">{{ 'USER' | translate }}</th>
        <th
          *akbCheck="'cars.lot-user-bids-amount'"
          [nzSortPriority]="true"
          [nzSortFn]="bidFullAmountSort"
          nzWidth="100px"
        >
          {{ 'AMOUNT' | translate }}
        </th>
        <th nzWidth="100px">{{ 'GROUP' | translate }}</th>
        <th nzWidth="1px">{{ 'STATUS' | translate }}</th>
        <th nzWidth="1px">{{ 'CONDITION' | translate }}</th>
        <th
          nzWidth="198px"
          [nzSortPriority]="true"
          [nzSortOrder]="'descend'"
          [nzSortFn]="bidCreatedAtSort"
        >
          {{ 'BIDS_DATE' | translate }}
        </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let bid of table.data">
        <td>
          <nz-row nzJustify="space-between" nzAlign="middle">
            <nz-col>
              <span class="user-color" [style.background-color]="userColorsMap[bid.user.id]"></span>
              <a class="user-link" [href]="getUserUrl(bid.user)" target="_blank">
                {{ bid.user.fullName }}
              </a>
              <i
                *ngIf="bid.user.hasActiveBestBidViewServiceSubscription"
                nz-icon
                nz-tooltip
                nzType="eye"
                nzTheme="outline"
                [nzTooltipTitle]="'SUBSCRIBE_TO_BEST_BID.HAS_ACTIVE_SUBSCRIPTION' | translate"
              ></i>
            </nz-col>
            <nz-col *ngIf="isManager" class="options">
              <nz-tag
                *ngIf="bid?.isGreenCorner"
                class="options-tag"
                [nzColor]="'#58F37D'"
                nz-tooltip
                [nzTooltipTitle]="'GREEN_CORNER' | translate"
              >
                GC
              </nz-tag>
              <nz-tag
                class="options-tag"
                *ngIf="bid?.notify"
                [nzColor]="'#FFA500'"
                nz-tooltip
                [nzTooltipTitle]="'NOTIFY' | translate"
              >
                NF
              </nz-tag>
              <nz-tag
                *ngIf="bid?.createTranslation"
                [nzColor]="'#378de5'"
                class="options-tag"
                nz-tooltip
                [nzTooltipTitle]="'TRANSLATE' | translate"
              >
                TR
              </nz-tag>
            </nz-col>
          </nz-row>
        </td>
        <td *akbCheck="'cars.lot-user-bids-amount'">
          <app-lot-bid-amount
            [bid]="bid"
            [renderColon]="false"
            commissionPrefix="+"
          ></app-lot-bid-amount>
        </td>
        <td>
            <span *ngIf="bid.bidGroup"
            >{{ bid.bidGroup.letter }} - {{ bid.bidGroup.quantity }}</span
            >
          <span *ngIf="!bid.bidGroup">
              {{ 'LOT_BIDDING_MODAL.NO_GROUP' | translate }}
            </span>
        </td>
        <td>
          <nz-tag [nzColor]="tagColorMap[bid.status]">
            {{ bid.status | uppercase | translate }}
          </nz-tag>
        </td>
        <td>
            <span *ngIf="bid.needPriorityPass" title="Priority pass">
              <i nz-icon nzType="crown" nzTheme="fill" style="color: #d48806"></i>
            </span>
          {{ bid.bidCondition.title }}
        </td>
        <td>
          {{ bid.createdAt | date: 'yyyy-MM-dd HH:mm:ss zz':'+0900' }}
        </td>
      </tr>
      </tbody>
    </nz-table>
  </section>
</nz-modal>
