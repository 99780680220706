import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { LetModule } from '@rx-angular/template';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

import { LotMiniGalleryModule } from '../lot-mini-gallery/lot-mini-gallery.module';
import { LotVinImageModule } from '../lot-vin-image/lot-vin-image.module';
import { LotStatisticsComponent } from './lot-statistics.component';

@NgModule({
  declarations: [LotStatisticsComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    NzButtonModule,
    NzFormModule,
    NzInputModule,
    NzSelectModule,
    NzTableModule,
    NzGridModule,
    NzCollapseModule,
    NzToolTipModule,
    NzSpinModule,
    NzAlertModule,
    LetModule,
    LotMiniGalleryModule,
    LotVinImageModule,
  ],
  exports: [LotStatisticsComponent],
})
export class LotStatisticsModule {}
