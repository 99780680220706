import { AkebonoCoreModule } from '@akebono/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NzButtonModule } from 'ng-zorro-antd/button';

import { LotGetPhotoRequestButtonComponent } from './lot-get-photo-request-button.component';

@NgModule({
  declarations: [LotGetPhotoRequestButtonComponent],
  imports: [CommonModule, AkebonoCoreModule, TranslateModule, NzButtonModule],
  exports: [LotGetPhotoRequestButtonComponent],
})
export class LotGetPhotoRequestButtonModule {}
