import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { LetModule, PushModule } from '@rx-angular/template';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

import { LotInterestingControlsComponent } from './components/lot-interesting-controls/lot-interesting-controls.component';
import { LotInterestingStatusComponent } from './components/lot-interesting-status/lot-interesting-status.component';
import { INTERESTING_LIMIT, INTERESTING_LIMIT_DEFAULT } from './constants';
import { LotInterestingManagerFactory } from './services/lot-interesting-manager/lot-interesting-manager-factory.service';
import { LotInterestingMutationService } from './services/lot-interesting-mutation.service';
import { LotInterestingStorageFactoryService } from './services/lot-interesting-store/lot-interesting-store-factory.service';

@NgModule({
  declarations: [LotInterestingControlsComponent, LotInterestingStatusComponent],
  imports: [
    CommonModule,
    LetModule,
    PushModule,
    NzIconModule,
    TranslateModule,
    NzButtonModule,
    NzToolTipModule,
  ],
  providers: [
    LotInterestingManagerFactory,
    LotInterestingMutationService,
    LotInterestingStorageFactoryService,
    {
      provide: INTERESTING_LIMIT,
      useValue: INTERESTING_LIMIT_DEFAULT,
    },
  ],
  exports: [LotInterestingControlsComponent, LotInterestingStatusComponent],
})
export class LotInterestingModule {}
