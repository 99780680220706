import { MutationHandlingServiceV2 } from '@akebono/core';
import { Injectable } from '@angular/core';
import { BehaviorSubject, defer, from, iif, Observable, of } from 'rxjs';
import { catchError, concatMap, finalize, first, map, switchMap } from 'rxjs/operators';
import {
  BID_CONDITION_INTACT,
  BID_CONDITION_MOTO,
  BID_CONDITION_OTHER_COUNTRIES,
  BID_CONDITION_OTHER_COUNTRIES_MOTO,
} from 'src/app/const';

import {
  BidCreateGQL,
  ImportedLotFragment,
  LotSource,
  LotTypeEnum,
} from '../../graphql/service/graphql-auto-main-service';
import { LotIdBuilder } from '../classes/lot-id-builder.class';
import { ExcelBid } from '../types/excel-bid.type';
import { TargetBid } from '../types/target-bid.type';
import { BidGroupService } from './bid-group.service';
import { BidStatusFactoryService } from './bid-status-factory.service';
import { BiddingDataService } from './bidding-data.service';

@Injectable()
export class BidService {
  readonly loading$ = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly biddingDataService: BiddingDataService,
    private readonly bidGroupService: BidGroupService,
    private readonly mhs: MutationHandlingServiceV2,
    private readonly bidStatusFactoryService: BidStatusFactoryService,
    private readonly bidCreateGQL: BidCreateGQL,
  ) {}

  createAll(excelBids: ExcelBid[], lots: ImportedLotFragment[]): TargetBid[] {
    return this.joinLotsAndBids(excelBids, lots).map(({ bid, lot }) => this.create(bid, lot));
  }

  create(excelBid: ExcelBid, lot: ImportedLotFragment): TargetBid {
    return {
      ...excelBid,
      lot,
      status: this.bidStatusFactoryService.create(excelBid, lot),
    };
  }

  private joinLotsAndBids(
    excelBids: ExcelBid[],
    lots: ImportedLotFragment[],
  ): Array<{ bid: ExcelBid; lot: ImportedLotFragment }> {
    if (excelBids.length >= lots.length) {
      return lots.map((lot) => {
        const lotId = LotIdBuilder.build(lot);
        const excelBid = excelBids.find((bid) => LotIdBuilder.build(bid.lot) === lotId);

        if (!excelBid) {
          throw new Error('Bid for lot not found');
        }

        return {
          bid: excelBid,
          lot,
        };
      });
    } else {
      return excelBids.map((excelBid) => {
        const lotInfoId = LotIdBuilder.build(excelBid.lot);
        const lot = lots.find((lot) => LotIdBuilder.build(lot) === lotInfoId);

        if (!lot) {
          throw new Error('Lot for bid not found');
        }

        return {
          bid: excelBid,
          lot,
        };
      });
    }
  }

  placeAll(bids: TargetBid[]): Observable<TargetBid> {
    this.loading$.next(true);
    return this.biddingDataService.refetch().pipe(
      first(),
      switchMap(() => from(bids)),
      concatMap((bid) =>
        bid.status.isBiddable$.pipe(
          first(),
          switchMap((isBiddable) =>
            iif(
              () => isBiddable,
              defer(() => this.place(bid)),
              of(bid),
            ),
          ),
          switchMap((bid) => this.biddingDataService.refetch().pipe(map(() => bid))),
        ),
      ),
      finalize(() => this.loading$.next(false)),
    );
  }

  private place(bid: TargetBid): Observable<TargetBid> {
    return of(bid).pipe(
      switchMap((bid) => this.getBidGroupId(bid).pipe(map((groupId) => ({ bid, groupId })))),
      switchMap((bidWithGroup) =>
        this.getBidConditionId(bidWithGroup.bid.lot).pipe(
          map((conditionId) => ({ ...bidWithGroup, conditionId })),
        ),
      ),
      switchMap(({ bid, groupId, conditionId }) => this.createBid(bid, groupId, conditionId)),
    );
  }

  private createBid(
    bid: TargetBid,
    groupId: string | null,
    conditionId: string,
  ): Observable<TargetBid> {
    return this.mhs
      .mutate(
        this.bidCreateGQL,
        {
          input: {
            amount: bid.amount,
            conditionId,
            groupId,
            createTranslation: false,
            lotId: bid.lot.id,
            lotSource: LotSource.Aleado,
            lotType: bid.lot.subgroup,
            comment: '',
            notify: false,
          },
        },
        {
          renderSuccess: false,
          renderError: false,
        },
      )
      .data.pipe(
        map(() => {
          bid.status.placeSuccess();
          return bid;
        }),
        catchError((error) => {
          bid.status.placeFailed(error.message);
          return of(bid);
        }),
      );
  }

  private getBidGroupId(bid: TargetBid): Observable<string | null> {
    if (bid.group) {
      return defer(() => this.bidGroupService.getOrCreate(bid.group)).pipe(
        map((group) => group.id),
      );
    } else {
      return of(null);
    }
  }

  private getBidConditionId(lot: ImportedLotFragment): Observable<string> {
    return this.biddingDataService.data$.pipe(
      first(),
      map((data) => data.currentUser.isOtherCountries),
      map((isOtherCountries) => {
        if (lot.subgroup === LotTypeEnum.Moto) {
          return isOtherCountries ? BID_CONDITION_OTHER_COUNTRIES_MOTO : BID_CONDITION_MOTO;
        } else {
          return isOtherCountries ? BID_CONDITION_OTHER_COUNTRIES : BID_CONDITION_INTACT;
        }
      }),
      map((conditionId) => conditionId.toString()),
    );
  }
}
