import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { LotIdBuilder } from '../../classes/lot-id-builder.class';
import { ExcelLot } from '../../types/excel-lot.type';

@Component({
  selector: 'app-import-bids-dashboard-not-found-lots',
  templateUrl: './import-bids-dashboard-not-found-lots.component.html',
  styleUrls: ['./import-bids-dashboard-not-found-lots.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImportBidsDashboardNotFoundLotsComponent {
  @Input() notFoundLots: ExcelLot[] = [];

  lotInfoIdentity(_: number, lot: ExcelLot): string {
    return LotIdBuilder.build(lot);
  }
}
