import { AkebonoCoreModule } from '@akebono/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

import { AutoStatsLotsFilterComponent } from './components/auto-lots-filter/auto-lots-filter.component';
import { QueryFilterService } from './services/query-filter.service';

@NgModule({
  declarations: [AutoStatsLotsFilterComponent],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    TranslateModule,
    AkebonoCoreModule,
    BrowserAnimationsModule,
    NzButtonModule,
    NzModalModule,
    NzFormModule,
    NzInputModule,
    NzAlertModule,
    NzCollapseModule,
    NzSkeletonModule,
    NzSpinModule,
    NzCheckboxModule,
    NzDatePickerModule,
    NzSelectModule,
    NzPopconfirmModule,
    NzToolTipModule,
    NzIconModule,
  ],
  exports: [AutoStatsLotsFilterComponent],
  providers: [QueryFilterService],
})
export class AutoStatsLotsFilterModule {}
