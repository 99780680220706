import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Bid } from '../../graphql/service/graphql-auto-main-service';

@Component({
  selector: 'app-bid-priority-pass-status',
  templateUrl: './bid-priority-pass-status.component.html',
  styleUrls: ['./bid-priority-pass-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BidPriorityPassStatusComponent {
  @Input() bid: Bid;
}
