<button
  *akbCheck="['world.akebono.auto.translator']"
  nz-button
  [nzBlock]="fullSize"
  nzSize="large"
  (click)="open()"
>
  <i nz-icon nzType="zhihu-circle" nzTheme="fill"></i>
  <span *ngIf="fullSize">
    {{ 'TRANSLATE_LOT' | translate }}
  </span>
</button>
