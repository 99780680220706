import { MutationHandlingServiceV2 } from '@akebono/core';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzUploadFile, NzUploadXHRArgs } from 'ng-zorro-antd/upload';
import { Observable, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { getBase64, getImgPrefix } from 'src/app/const';
import {
  UserChannel,
  UserChannelCreateGQL,
  UserChannelUpdateGQL,
} from 'src/app/modules/graphql/service/graphql-auto-main-service';
import { S3FileUploaderService } from 'src/app/services/s3-file-uploader.service';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-user-channel-modal',
  templateUrl: './user-channel-modal.component.html',
  styleUrls: ['./user-channel-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserChannelModalComponent implements OnInit {
  @Input() userChannel: UserChannel;

  fileList: NzUploadFile[] = [];
  s3Keys: string[] = [];
  avatarUrl?: string;
  avatarUrlPlaceholder?: string;
  form: FormGroup;
  loading$: Observable<boolean>;
  imgPrefix = getImgPrefix();

  constructor(
    @Optional() private modal: NzModalRef,
    private ref: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private mhs: MutationHandlingServiceV2,
    private userChannelCreateGQL: UserChannelCreateGQL,
    private userChannelUpdateGQL: UserChannelUpdateGQL,
    private s3FileUploaderService: S3FileUploaderService,
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      channelTitle: [this.userChannel?.path || uuidv4(), Validators.required],
      companyName: [this.userChannel?.title || '', Validators.required],
      description: [this.userChannel?.description || ''],
    });

    if (this.userChannel?.images?.[0]) {
      this.avatarUrlPlaceholder = this.userChannel?.images?.[0];
    }
  }

  closeModal(): void {
    this.modal.destroy();
  }

  userChannelCreate(): void {
    this.loading$ = this.mhs.mutate(
      this.userChannelCreateGQL,
      {
        input: {
          images: this.s3Keys,
          path: this.form.value.channelTitle,
          title: this.form.value.companyName,
          publishedAt: new Date().toISOString(),
          description: this.form.value.description,
        },
      },
      {
        refetch: true,
      },
    ).loading;

    this.loading$ = this.loading$.pipe(finalize(() => this.closeModal()));
  }

  userChannelUpdate(): void {
    this.loading$ = this.mhs.mutate(
      this.userChannelUpdateGQL,
      {
        input: {
          images: this.avatarUrlPlaceholder ? [this.avatarUrlPlaceholder] : this.s3Keys,
          path: this.form.value.channelTitle,
          title: this.form.value.companyName,
          publishedAt: new Date().toISOString(),
          description: this.form.value.description,
        },
      },
      {
        refetch: true,
      },
    ).loading;

    this.loading$ = this.loading$.pipe(finalize(() => this.closeModal()));
  }

  handler(): void {
    if (this.userChannel) {
      this.userChannelUpdate();
    } else {
      this.userChannelCreate();
    }
  }

  uploadFiles = (item: NzUploadXHRArgs): Subscription => {
    this.s3FileUploaderService.upload(item).subscribe(
      (key) => {
        this.s3Keys.push(key);
        this.fileList.find((f) => (item.file = f)).key = key;

        getBase64(item.file!.originFileObj!, (img: string) => {
          this.avatarUrl = img;
          this.ref.detectChanges();
        });
      },
      (error) => {
        console.error(error);
        prompt('Ошибка при загрузке файла');
      },
    );

    return Subscription.EMPTY;
  };

  removeFiles = (): boolean => {
    this.s3Keys = [];
    this.fileList = [];
    this.avatarUrl = '';
    this.avatarUrlPlaceholder = '';
    return true;
  };
}
