<div class="content">
  <app-auto-lots-filter></app-auto-lots-filter>
  <nz-divider></nz-divider>

  <div nz-row nzJustify="center" class="purchase-statistics">
    <app-user-purchase-statistics></app-user-purchase-statistics>
  </div>

  <nz-table
    #table
    nzTitle="{{ 'TOTAL' | translate }}: {{ data.total }}"
    [nzTotal]="data.total"
    [nzData]="data.nodes"
    [nzPageIndex]="page"
    [nzPageSize]="pageSize"
    [nzLoading]="loadingResult"
    [nzFrontPagination]="false"
    [nzBordered]="true"
    nzPaginationPosition="both"
    nzShowSizeChanger
    class="table"
    nzSize="small"
    (nzPageIndexChange)="loadData($event)"
    (nzPageSizeChange)="changePageSize($event)"
    nzOuterBordered
  >
    <thead>
    <tr>
      <th rowspan="2" nzAlign="center">{{ 'PHOTO' | translate }}</th>
      <th rowspan="2" nzAlign="center">
        <app-sort-component
          (nzSortChange)="sortEvent($event)"
          [sortKey]="'bid'"
          [title]="'LOT' | translate"
        >
        </app-sort-component>
      </th>
      <th rowspan="1" nzAlign="center">
        <app-sort-component
          (nzSortChange)="sortEvent($event)"
          [sortKey]="'auctRef'"
          [title]="'LOT_INFO.AUCTION' | translate"
        ></app-sort-component>
      </th>
      <th rowspan="1" nzAlign="center">
        <app-sort-component
          (nzSortChange)="sortEvent($event)"
          [sortKey]="'modelYearEn'"
          [title]="'LOT_INFO.YEAR' | translate"
        >
        </app-sort-component>
      </th>
      <th rowspan="1" nzAlign="center" [ngClass]="'hide-column-small hide-column-medium'">
        <app-sort-component
          (nzSortChange)="sortEvent($event)"
          [sortKey]="'displacementNum'"
          [title]="'LOT_INFO.ENGINE_CC' | translate"
        ></app-sort-component>
      </th>
      <th rowspan="1" nzAlign="center">
        <app-sort-component
          (nzSortChange)="sortEvent($event)"
          [sortKey]="'mileageNum'"
          [title]="'LOT_INFO.MILEAGE' | translate"
        ></app-sort-component>
      </th>
      <th rowspan="1" nzAlign="center">
        <app-sort-component
          (nzSortChange)="sortEvent($event)"
          [sortKey]="'startPriceNum'"
          [title]="'LOT_INFO.START_PRICE' | translate"
        ></app-sort-component>
      </th>
    </tr>
    <tr>
      <th nzAlign="center">
        <app-sort-component
          (nzSortChange)="sortEvent($event)"
          [sortKey]="'date'"
          [title]="'DATE' | translate"
        >
        </app-sort-component>
      </th>
      <th nzAlign="center">
        <app-sort-component
          (nzSortChange)="sortEvent($event)"
          [sortKey]="'modelTypeEn'"
          [title]="'FRAME_MODEL' | translate"
        >
        </app-sort-component>
      </th>
      <th nzAlign="center">
        <app-sort-component
          (nzSortChange)="sortEvent($event)"
          [sortKey]="'colorEn'"
          [title]="'LOT_INFO.COLOR' | translate"
        >
        </app-sort-component>
      </th>
      <th nzAlign="center">
        <app-sort-component
          (nzSortChange)="sortEvent($event)"
          [sortKey]="'scoresEn'"
          [title]="'LOT_INFO.SCORE' | translate"
        >
        </app-sort-component>
      </th>
      <th nzAlign="center">
        <app-sort-component
          (nzSortChange)="sortEvent($event)"
          [sortKey]="'endPriceNum'"
          [title]="'LOT_INFO.SOLD_FOR' | translate"
        >
        </app-sort-component>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let item of table.data">
      <td nzAlign="center">
        <app-lot-mini-gallery [lot]="item"></app-lot-mini-gallery>
      </td>
      <td nzAlign="center">
        <app-lots-list-lot-link
          [lot]="item"
          [lotType]="'auto'"
          link="/auto/lot/{{ item.id }}"
          [lotData]="(lotListData$ | async)?.get(item.id)"
        ></app-lots-list-lot-link>
      </td>
      <td nzAlign="center">
        <app-lot-auction-name [auctionName]="item.autoAuction?.name"></app-lot-auction-name>
        <br />
        {{ item.time | date: 'yyyy-MM-dd H:mm:ss zz':'+0900' }}
      </td>
      <td nzAlign="left" [ngClass]="'hide-column-small hide-column-medium'">
        <span style="color: #6db3eb">{{ item.companyEn }} {{ item.modelNameEn }}</span>
        <br />
        <span nz-text nzType="danger">{{ item.year }}</span>
        <span [innerHTML]="item.frame"></span>
        <br />
        <span nz-text [innerHTML]="item.modelGrade" nzType="secondary"></span>
      </td>
      <td nzAlign="left">
        <span nz-text nzType="danger">{{ item.transmissionEn }}</span>
        {{ item.engineVolume }} cc<br />
        <span nz-text nzType="secondary">{{ item.colorEn }}</span>
        <!-- {{item.equip}} -->
      </td>
      <td nzAlign="right">
        {{ item.mileage }}{{ item.mileage > 0 ? '000' : '' }} km
        <span nz-text nzType="warning" style="display: inline-block; min-width: 2em">{{
            item.rate
          }}</span>
      </td>
      <td>
        <nz-row nzJustify="end">
          <div>
            {{ item.startPrice | currency: 'JPY':'symbol-narrow' }}
            <br />
            {{ item.finishPrice | currency: 'JPY':'symbol-narrow' }}
            <nz-tag *ngIf="item.result" style="margin: 0">
              {{ item.result | translate }}
            </nz-tag>
          </div>
          <app-lot-place-bid-button-dumb
            [lot]="item"
            [lotType]="'auto'"
            [lotSource]="'aleado'"
          ></app-lot-place-bid-button-dumb>
        </nz-row>
      </td>
    </tr>
    </tbody>
  </nz-table>
</div>
