<form nz-form [formGroup]="form">
  <nz-form-item>
    <nz-form-label [nzRequired]="true" [nzSpan]="8">
      {{ 'CHANNEL.CHANNEL_TITLE' | translate }}
    </nz-form-label>
    <nz-form-control>
      <input nz-input formControlName="channelTitle" />
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzRequired]="true" [nzSpan]="8">
      {{ 'CHANNEL.COMPANY_NAME' | translate }}
    </nz-form-label>
    <nz-form-control>
      <input nz-input formControlName="companyName" />
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="8">
      {{ 'CHANNEL.DESCRIPTION' | translate }}
    </nz-form-label>
    <nz-form-control>
      <textarea
        nz-input
        [nzAutosize]="{ minRows: 3, maxRows: 5 }"
        formControlName="description"
      ></textarea>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-upload
      [nzDisabled]="avatarUrl || fileList?.length !== s3Keys?.length || avatarUrlPlaceholder"
      [(nzFileList)]="fileList"
      [nzRemove]="removeFiles"
      [nzCustomRequest]="uploadFiles"
    >
      <button nz-button>
        <i nz-icon nzType="upload"></i>
        {{ 'CHANNEL.UPLOAD_LOGO' | translate }}
      </button>
    </nz-upload>
  </nz-form-item>
  <nz-form-item>
    <div class="img-wrapper" *ngIf="avatarUrl || avatarUrlPlaceholder">
      <img *ngIf="avatarUrl" [src]="avatarUrl" />
      <img *ngIf="avatarUrlPlaceholder" [src]="imgPrefix + avatarUrlPlaceholder" />
      <div class="img-placeholder">
        <button nz-button nzType="text" (click)="removeFiles()">
          <i nz-icon nzType="close" nzTheme="outline"></i>
        </button>
      </div>
    </div>
  </nz-form-item>
</form>

<footer *nzModalFooter>
  <button (click)="closeModal()" nz-button nzType="default">
    {{ 'CANCEL' | translate }}
  </button>
  <button
    nz-button
    nzType="primary"
    (click)="handler()"
    [nzLoading]="loading$ | async"
    [disabled]="(loading$ | async) || fileList?.length !== s3Keys?.length"
  >
    <span *ngIf="!userChannel">{{ 'CREATE' | translate }}</span>
    <span *ngIf="userChannel">{{ 'UPDATE' | translate }}</span>
  </button>
</footer>
