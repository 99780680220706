<nz-collapse *ngIf="info$ | async as info">
  <nz-collapse-panel
    [nzActive]="active"
    nzHeader="{{ 'EXTRA_DESCRIPTION' | translate }}"
    appDriverStep
    [overrideStepIndex]="13"
    [stepTitle]="'AUTO_LOT.GUIDE.ADDITIONAL_DESCR.TITLE' | translate"
    [stepDescription]="'AUTO_LOT.GUIDE.ADDITIONAL_DESCR.DESCR' | translate"
    [stepSide]="'left'"
    [stepAlign]="'start'"
    [popoverClass]="'auto-lot'"
  >
    <section class="switch-container">
      <nz-switch
        [ngModel]="switchSubject$ | async"
        [nzCheckedChildren]="'TRANSLATION' | translate"
        [nzUnCheckedChildren]="'SOURCE' | translate"
        (ngModelChange)="switchSubject$.next($event)"
      ></nz-switch>
    </section>
    <section>
      <ng-container *ngIf="isRecordInfo(info); else simpleInfo">
        <div *ngFor="let row of info | keyvalue">{{ row.key | titlecase }} - {{ row.value }}</div>
      </ng-container>
      <ng-template #simpleInfo>
        <span [innerHtml]="info"></span>
      </ng-template>
    </section>
  </nz-collapse-panel>
</nz-collapse>
