import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzSpaceModule } from 'ng-zorro-antd/space';

import {
  LotBidAndBiddersCounterComponent,
} from './components/lot-bid-and-bidders-counter/lot-bid-and-bidders-counter.component';
import {
  LotViewsAndFavoritesCounterComponent,
} from './components/lot-views-and-favorites-counter/lot-views-and-favorites-counter.component';

@NgModule({
  declarations: [LotBidAndBiddersCounterComponent, LotViewsAndFavoritesCounterComponent],
  imports: [CommonModule, TranslateModule, NzIconModule, NzSpaceModule],
  exports: [LotBidAndBiddersCounterComponent, LotViewsAndFavoritesCounterComponent],
})
export class LotCountersModule {}
