import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

import { LotCheckProductionDateInputComponent } from './components/lot-check-production-date-input/lot-check-production-date-input.component';
import { LotCheckProductionDateResultComponent } from './components/lot-check-production-date-result/lot-check-production-date-result.component';
import { CheckProductionDatePayloadService } from './services/check-production-date-payload.service';
import { DriverModule } from '../driver/driver.module';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';

@NgModule({
  declarations: [LotCheckProductionDateInputComponent, LotCheckProductionDateResultComponent],
  exports: [LotCheckProductionDateInputComponent, LotCheckProductionDateResultComponent],
  imports: [
    FormsModule,
    CommonModule,
    NzFormModule,
    NzAlertModule,
    NzButtonModule,
    TranslateModule,
    ReactiveFormsModule,
    NzInputModule,
    NzToolTipModule,
    DriverModule,
    NzCollapseModule,
  ],
  providers: [CheckProductionDatePayloadService],
})
export class LotCheckProductionDateModule {}
