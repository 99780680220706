import { MutationHandlingServiceV2, QueryHandlingService } from '@akebono/core';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import {
  CurrentUserMinimalGQL,
  UserUpdateGQL,
} from '../../graphql/service/graphql-auto-main-service';
import { countries } from 'countries-list';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-country-config',
  templateUrl: './country-config.component.html',
  styleUrls: ['./country-config.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountryConfigComponent {
  updating$ = of(false);
  hasEmptyCountryIso$: Observable<boolean>;
  hidden = false;
  form: FormGroup;
  constructor(
    private currentUserMinimalGQL: CurrentUserMinimalGQL,
    private qhs: QueryHandlingService,
    private mhs: MutationHandlingServiceV2,
    private userUpdateGQL: UserUpdateGQL,
    private http: HttpClient,
    private fb: FormBuilder,
  ) {
    this.hasEmptyCountryIso$ = this.qhs.fetch(this.currentUserMinimalGQL).data.pipe(
      map((x) => {
        const empty =
          x.currentUser && (x.currentUser.countryIso === null || x.currentUser.countryIso === '');
        if (empty) {
          this.getCountry();
        }
        return empty;
      }),
    );

    this.form = this.fb.group({
      country: [null],
    });
  }

  getCountry() {
    this.http.get('https://locate.akebono.world/country').subscribe((res: any) => {
      const userCountry = res.country;
      this.form.controls.country.setValue(userCountry);
    });
  }

  get countries() {
    return countries;
  }

  updateCountryIso() {
    if (this.form.valid) {
      this.updating$ = this.mhs
        .mutate(this.userUpdateGQL, {
          input: { countryIso: this.form.controls.country.value.toLowerCase() },
        })
        .loading.pipe(
          tap((c) => {
            if (!c) this.hidden = true;
          }),
        );
    }
  }
}
