<nz-content class="wrap">
  <div class="content">
    <nz-page-header>
      <nz-avatar
        nz-page-header-avatar
        [nzSrc]="imgPrefix + (queryRef.data | async)?.userChannel?.images?.[0]"
      ></nz-avatar>

      <nz-page-header-title>{{ (queryRef.data | async)?.userChannel?.path }}</nz-page-header-title>
      <nz-page-header-subtitle>{{
        (queryRef.data | async)?.userChannel?.title
      }}</nz-page-header-subtitle>

      <nz-page-header-content>
        {{ (queryRef.data | async)?.userChannel?.description }}
      </nz-page-header-content>
    </nz-page-header>

    <nz-divider></nz-divider>

    <ng-container *ngIf="(queryRef.data | async)?.userChannel?.userLotMarksLists as lists">
      <ng-container *ngFor="let list of lists">
        <ng-container *ngIf="list?.lotMarks?.length">
          <nz-row [nzGutter]="[10, 10]">
            <nz-col>
              <h3 nz-title>{{ list?.title }}</h3>
            </nz-col>
            <nz-col>
              <a nz-button [routerLink]="['/list', list.id]" nzType="link">
                {{ 'SHARED.VIEW_ALL' | translate }}
              </a>
            </nz-col>
          </nz-row>
          <nz-table
            #marks
            nzSize="small"
            [nzPageSize]="5"
            [nzBordered]="true"
            [nzData]="list?.lotMarks"
            [nzShowPagination]="false"
          >
            <thead>
              <tr>
                <th nzAlign="center" rowspan="2">{{ 'PHOTO' | translate }}</th>
                <th nzAlign="center" rowspan="2">{{ 'LOT' | translate }}</th>
                <th nzAlign="center">{{ 'LOT_INFO.AUCTION' | translate }}</th>
                <th nzAlign="center">{{ 'LOT_INFO.ENGINE_CC' | translate }}</th>
                <th nzAlign="center">{{ 'LOT_INFO.START_PRICE' | translate }}</th>
                <th nzAlign="center">{{ 'LOT_INFO.YEAR' | translate }}</th>
                <th nzAlign="center">{{ 'LOT_INFO.MILEAGE' | translate }}</th>
                <th nzAlign="center" rowspan="2">{{ 'STATUS' | translate }}</th>
              </tr>
              <tr>
                <th nzAlign="center">{{ 'LOT_INFO.AUCTION_DATE' | translate }}</th>
                <th nzAlign="center">{{ 'LOT_INFO.COLOR' | translate }}</th>
                <th nzAlign="center">{{ 'LOT_INFO.SOLD_FOR' | translate }}</th>
                <th nzAlign="center">{{ 'LOT_INFO.CHASSIS_ID' | translate }}</th>
                <th nzAlign="center">{{ 'LOT_INFO.SCORE' | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let mark of marks.data">
                <td nzAlign="center">
                  <app-lot-mini-gallery [lot]="mark.lot"></app-lot-mini-gallery>
                </td>
                <td nzAlign="center">
                  <a
                    nzSize="small"
                    target="_blank"
                    class="lot-button"
                    nz-button
                    [routerLink]="['/shared', mark.id]"
                    nzType="primary"
                  >
                    {{ mark.lot.code }}
                  </a>
                </td>
                <td nzAlign="center">
                  {{ mark.lot.auctionName }} <br />
                  {{ mark.lot.auctionDate | date: 'yyyy-MM-dd H:mm:ss zz':'+0900' }}
                </td>
                <td nzAlign="center">
                  <span nz-text nzType="danger">{{ mark.lot.transmissionEn }}</span>
                  {{ mark.lot.engineVolumeNum }} cc<br />
                  <span nz-text nzType="secondary">{{ mark.lot.colorEn }}</span>
                </td>
                <td nzAlign="center">
                  {{ mark.lot.startPriceNum | currency: 'JPY':'symbol-narrow' }}
                  <br />
                  {{ mark.lot.endPriceNum | currency: 'JPY':'symbol-narrow' }}
                </td>
                <td nzAlign="center">
                  <span style="color: #6db3eb"
                    >{{ mark.lot.companyEn }} {{ mark.lot.modelEn }}</span
                  >
                  <br />
                  <span nz-text nzType="danger">{{ mark.lot.year }}</span>
                  {{ mark.lot.modelTypeEn }}
                  <br />
                  <span nz-text nzType="secondary">{{ mark.lot.modelGradeEn }}</span>
                </td>
                <td nzAlign="center">
                  {{ mark.lot.mileageNum }}000km <br />
                  <span nz-text nzType="warning">{{ mark.lot.ratingEn }}</span>
                </td>
                <td nzAlign="center">
                  <span nz-text nzType="warning">
                    {{ 'LOT_STATUSES.' + mark.lot.status | uppercase | translate }}
                  </span>
                </td>
              </tr>
            </tbody>
          </nz-table>
          <nz-divider style="margin-bottom: 12px"></nz-divider>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</nz-content>
