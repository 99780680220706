import { MutationHandlingServiceV2 } from '@akebono/core';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  UserLotMark,
  UserLotMarkCreateGQL,
  UserLotMarkCreateInput,
  UserLotMarkDeleteGQL,
  UserLotMarkDeleteInput,
  UserLotMarkUpdateGQL,
  UserLotMarkUpdateInput,
} from 'src/app/modules/graphql/service/graphql-auto-main-service';

@Injectable()
export class LotInterestingMutationService {
  constructor(
    private mhs: MutationHandlingServiceV2,
    private userLotMarkCreateGQL: UserLotMarkCreateGQL,
    private userLotMarkUpdateGQL: UserLotMarkUpdateGQL,
    private userLotMarkDeleteGQL: UserLotMarkDeleteGQL,
  ) {}

  public create(input: UserLotMarkCreateInput, refetch = false): Observable<UserLotMark> {
    return this.mhs
      .mutate(
        this.userLotMarkCreateGQL,
        { input },
        {
          refetch,
          renderSuccess: false,
        },
      )
      .data.pipe(map((result) => result.userLotMarkCreate.userLotMark));
  }

  public update(input: UserLotMarkUpdateInput, refetch = false): Observable<UserLotMark> {
    return this.mhs
      .mutate(
        this.userLotMarkUpdateGQL,
        { input },
        {
          refetch,
          renderSuccess: false,
        },
      )
      .data.pipe(map((result) => result.userLotMarkUpdate.userLotMark));
  }

  public delete(input: UserLotMarkDeleteInput, refetch = false): Observable<UserLotMark> {
    return this.mhs
      .mutate(
        this.userLotMarkDeleteGQL,
        { input },
        {
          refetch,
          renderSuccess: false,
        },
      )
      .data.pipe(map((result) => result.userLotMarkDelete.userLotMark));
  }
}
