<button nz-button nz-dropdown [nzDropdownMenu]="languages" nzTrigger="click">
  {{ language$ | async | uppercase }}
  <i nz-icon nzType="down"></i>
</button>
<nz-dropdown-menu #languages="nzDropdownMenu">
  <ul nz-menu>
    <li *ngFor="let lang of allowedLanguages" nz-menu-item (click)="changeLanguage(lang)">
      {{ lang | uppercase }}
    </li>
  </ul>
</nz-dropdown-menu>
