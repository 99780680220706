import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mileage',
})
export class MileagePipe implements PipeTransform {
  transform(value: string): string {
    if (value?.match(/^\d+$/)) {
      const mileage = +value || 0;
      return `${mileage * 1000}km`;
    } else {
      return value;
    }
  }
}
