<div class="content">
  <nz-collapse [nzBordered]="false">
    <nz-collapse-panel [nzHeader]="'FILTERS' | translate" [nzActive]="isActiveFilters">
      <form
        nz-form
        [formGroup]="form"
        (ngSubmit)="filter(form.value)"
        *ngIf="!loading"
        [nzLayout]="'vertical'"
      >
        <nz-row [nzGutter]="40">
          <nz-col [nzXs]="24" [nzMd]="8" [nzXl]="6">
            <div>
              <nz-tag
                *ngFor="let date of dateRange"
                nzMode="checkable"
                [nzChecked]="date.checked"
                (nzCheckedChange)="selectDateAuction($event, date)"
              >
                {{ getDate(date.date) | date: 'EEE' | translate }}
              </nz-tag>
            </div>
            <nz-form-item>
              <nz-form-label
              ><strong>{{ 'COMPANY_MODEL' | translate }}</strong></nz-form-label
              >
              <nz-form-control>
                <nz-row [nzGutter]="20">
                  <nz-col [nzXs]="24">
                    <nz-select
                      formControlName="company"
                      nzShowSearch
                      nzAllowClear
                      (nzOnSearch)="search($event)"
                      (ngModelChange)="change($event)"
                      nzPlaceHolder="{{ 'SELECT_COMPANY' | translate }}"
                    >
                      <nz-option
                        *ngFor="let company of companiesList"
                        [nzValue]="company.companyId"
                        [nzLabel]="company.name"
                      ></nz-option>
                    </nz-select>
                  </nz-col>
                  <nz-col [nzXs]="24" [nzSm]="24" [nzMd]="12" [nzLg]="12" [nzXl]="12" [nzXXl]="12">
                    <nz-select
                      *ngIf="form.controls.company.value"
                      nzAllowClear
                      formControlName="model"
                      nzPlaceHolder="{{ 'SELECT_MODEL' | translate }}"
                      (nzOnSearch)="search($event)"
                      (ngModelChange)="change($event)"
                    >
                      <nz-option
                        *ngFor="let m of companies[form.controls.company.value]?.motoModels"
                        [nzValue]="m.modelId"
                        nzLabel="{{ m.name }} ({{ m.motoLotsTotal }})"
                      ></nz-option>
                    </nz-select>
                  </nz-col>
                </nz-row>
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-label
              ><strong>{{ 'LOT_INFO.AUCTION' | translate }}</strong></nz-form-label
              >
              <nz-form-control>
                <nz-select
                  formControlName="auction"
                  nzAllowClear
                  nzPlaceHolder="{{ 'SELECT_AUCTION' | translate }}"
                >
                  <nz-option [nzLabel]="'ALL' | translate" nzValue="all"></nz-option>
                  <nz-option
                    *ngFor="let option of auctions"
                    [nzLabel]="option.motoAuction.name"
                    [nzValue]="option.auctRef"
                  >
                  </nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-control>
                <label nz-checkbox formControlName="datetime">
                  <strong>{{ 'AUCTION_NOT_STARTED' | translate }}</strong>
                </label>
              </nz-form-control>
            </nz-form-item>
          </nz-col>
          <nz-col [nzXs]="24" [nzMd]="8" [nzXl]="6">
            <nz-form-item>
              <nz-form-label class="label-flex"
              ><strong>{{ 'LOT_INFO.YEAR' | translate }}</strong>
                <span
                  *ngIf="isYearFiltered"
                  (click)="removeFilter('year')"
                  nz-text
                  nzType="secondary"
                >{{ 'SEE_ALL' | translate }}</span
                >
              </nz-form-label>
              <nz-form-control>
                <nz-input-group nzCompact nzSize="small">
                  <input
                    type="number"
                    nz-input
                    formControlName="yearFrom"
                    (change)="change($event)"
                    placeholder="{{ 'FROM' | translate }}"
                    style="width: calc(50% - 15px); text-align: center"
                  />
                  <input
                    type="text"
                    disabled
                    nz-input
                    placeholder="-"
                    style="
                      width: 30px;
                      border-left: 0px;
                      pointer-events: none;
                      background-color: rgb(255, 255, 255);
                    "
                  />
                  <input
                    type="number"
                    nz-input
                    placeholder="{{ 'TO' | translate }}"
                    formControlName="yearTo"
                    (change)="change($event)"
                    style="width: calc(50% - 15px); text-align: center; border-left: 0px"
                  />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-label
              ><strong>{{ 'LOT_INFO.MILEAGE' | translate }}</strong>
                <span
                  *ngIf="isMileageFiltered"
                  (click)="removeFilter('mileage')"
                  nz-text
                  nzType="secondary"
                >{{ 'SEE_ALL' | translate }}</span
                >
              </nz-form-label>
              <nz-form-control>
                <nz-input-group nzCompact nzSize="small">
                  <input
                    type="number"
                    nz-input
                    formControlName="mileageFrom"
                    (change)="change($event)"
                    placeholder="{{ 'FROM' | translate }}"
                    style="width: calc(50% - 15px); text-align: center"
                  />
                  <input
                    type="text"
                    disabled
                    nz-input
                    placeholder="-"
                    style="
                      width: 30px;
                      border-left: 0px;
                      pointer-events: none;
                      background-color: rgb(255, 255, 255);
                    "
                  />
                  <input
                    type="number"
                    nz-input
                    placeholder="{{ 'TO' | translate }}"
                    formControlName="mileageTo"
                    (change)="change($event)"
                    style="width: calc(50% - 15px); text-align: center; border-left: 0px"
                  />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-label>
                <strong>{{ 'ENGINE_VOLUME' | translate }}</strong>
                <span
                  *ngIf="isEngineVolumeFiltered"
                  (click)="removeFilter('engineVolume')"
                  nz-text
                  nzType="secondary"
                >{{ 'SEE_ALL' | translate }}</span
                >
              </nz-form-label>
              <nz-form-control>
                <nz-input-group nzCompact nzSize="small">
                  <input
                    type="number"
                    nz-input
                    formControlName="engineVolumeFrom"
                    (change)="change($event)"
                    placeholder="{{ 'FROM' | translate }}"
                    style="width: calc(50% - 15px); text-align: center"
                  />
                  <input
                    type="text"
                    disabled
                    nz-input
                    placeholder="-"
                    style="
                      width: 30px;
                      border-left: 0px;
                      pointer-events: none;
                      background-color: rgb(255, 255, 255);
                    "
                  />
                  <input
                    type="number"
                    nz-input
                    placeholder="{{ 'TO' | translate }}"
                    formControlName="engineVolumeTo"
                    (change)="change($event)"
                    style="width: calc(50% - 15px); text-align: center; border-left: 0px"
                  />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
          </nz-col>
          <nz-col [nzXs]="24" [nzMd]="8" [nzXl]="6">
            <nz-form-item>
              <nz-form-label
              ><strong>{{ 'SEARCH_BY_BID' | translate }}</strong></nz-form-label
              >
              <nz-form-control>
                <input
                  type="text"
                  nzSize="small"
                  formControlName="bid"
                  nz-input
                  placeholder="{{ 'INPUT_BID_NUMBER' | translate }}"
                />
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-label
              ><strong>{{ 'LOT_INFO.SCORE' | translate }}</strong></nz-form-label
              >
              <nz-form-control>
                <nz-select
                  formControlName="inclusionScore"
                  nzSize="small"
                  nzMode="multiple"
                  nzAllowClear
                  nzPlaceHolder="{{ 'PLEASE_SELECT' | translate }}"
                  [nzDisabled]="!data.scoresEnOptions"
                >
                  <nz-option
                    *ngFor="let option of data.scoresEnOptions"
                    [nzLabel]="option.scoresEn"
                    [nzValue]="option.scoresEn"
                  ></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-label
              ><strong>{{ 'LOT_INFO.COLOR' | translate }}</strong></nz-form-label
              >
              <nz-form-control>
                <nz-select
                  formControlName="inclusionColor"
                  nzSize="small"
                  nzMode="multiple"
                  nzAllowClear
                  nzPlaceHolder="Please select"
                  [nzDisabled]="!data.colorEnOptions"
                >
                  <nz-option
                    *ngFor="let option of data.colorEnOptions"
                    [nzLabel]="option.colorEn"
                    [nzValue]="option.colorEn"
                  ></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </nz-col>
        </nz-row>
        <div nz-row>
          <div nz-col [nzSpan]="24" class="search-area">
            <button nz-button nzType="primary" (click)="scrollToTable()">
              {{ 'SEARCH' | translate }}
            </button>
            <button nz-button (click)="resetFilter()">{{ 'CLEAR' | translate }}</button>
          </div>
        </div>
      </form>
    </nz-collapse-panel>
  </nz-collapse>

  <nz-divider></nz-divider>

  <div nz-row nzJustify="center" class="purchase-statistics">
    <app-user-purchase-statistics></app-user-purchase-statistics>
  </div>

  <nz-table
    #table
    nzTitle="{{ 'TOTAL' | translate }}: {{ data.total }}"
    [nzTotal]="data.total"
    [nzData]="data.nodes"
    [nzPageIndex]="page"
    [nzPageSize]="pageSize"
    [nzLoading]="loadingResult"
    [nzFrontPagination]="false"
    [nzBordered]="true"
    nzPaginationPosition="both"
    nzShowSizeChanger
    class="table"
    (nzPageIndexChange)="loadData($event)"
    (nzPageSizeChange)="changePageSize($event)"
  >
    <thead (nzSortChange)="sortEvent($event)" nzSingleSort>
    <tr>
      <th nzAlign="center">{{ 'PHOTO' | translate }}</th>
      <th nzAlign="center">
        <app-sort-component
          (nzSortChange)="sortEvent($event)"
          [sortKey]="'bid'"
          [title]="'LOT' | translate"
        >
        </app-sort-component>
      </th>
      <th nzAlign="center">
        <app-sort-component
          (nzSortChange)="sortEvent($event)"
          [sortKey]="'auctionRef'"
          [title]="'LOT_INFO.AUCTION' | translate"
        ></app-sort-component>
        /
        <app-sort-component
          (nzSortChange)="sortEvent($event)"
          [sortKey]="'date'"
          [title]="'DATE' | translate"
        >
        </app-sort-component>
      </th>
      <th nzAlign="center">
        <app-sort-component
          (nzSortChange)="sortEvent($event)"
          [sortKey]="'modelYearEn'"
          [title]="'LOT_INFO.YEAR' | translate"
        >
        </app-sort-component>
        <!-- /
        <app-sort-component (nzSortChange)="sortEvent($event)" [sortKey]="'modelTypeEn'"
          [title]="'LOT_INFO.CHASSIS_ID' | translate"></app-sort-component> -->
      </th>
      <th nzAlign="center" [ngClass]="'hide-column-small'">
        <app-sort-component
          (nzSortChange)="sortEvent($event)"
          [sortKey]="'displacementNum'"
          [title]="'LOT_INFO.ENGINE_CC' | translate"
        ></app-sort-component>
      </th>
      <th nzAlign="center" [ngClass]="'hide-column-small'">
        <app-sort-component
          (nzSortChange)="sortEvent($event)"
          [sortKey]="'mileageNum'"
          [title]="'LOT_INFO.MILEAGE' | translate"
        ></app-sort-component>
        /
        <app-sort-component
          (nzSortChange)="sortEvent($event)"
          [sortKey]="'scoresEn'"
          [title]="'LOT_INFO.SCORE' | translate"
        >
        </app-sort-component>
      </th>
      <th nzAlign="center">
        <app-sort-component
          (nzSortChange)="sortEvent($event)"
          [sortKey]="'startPriceNum'"
          [title]="'LOT_INFO.START_PRICE' | translate"
        ></app-sort-component>
        /
        <app-sort-component
          (nzSortChange)="sortEvent($event)"
          [sortKey]="'endPriceNum'"
          [title]="'LOT_INFO.SOLD_FOR' | translate"
        ></app-sort-component>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let item of table.data">
      <td nzAlign="center">
        <app-lot-mini-gallery [lot]="item"></app-lot-mini-gallery>
      </td>
      <td nzAlign="center">
        {{ item.companyEn }} {{ item.modelNameEn }}<br />
        <app-lots-list-lot-link
          [lot]="item"
          [lotType]="'moto'"
          link="/moto/lot/{{ item.id }}"
          [lotData]="(lotListData$ | async)?.get(item.id)"
        ></app-lots-list-lot-link>
      </td>
      <td nzAlign="center">
        <app-lot-auction-name [auctionName]="item.motoAuction.name"></app-lot-auction-name>
        <br />
        {{ item.time | date: 'yyyy-MM-dd H:mm:ss zz':'+0900' }}
      </td>
      <td nzAlign="left">
        <span nz-text nzType="danger">{{ item.year }}</span> <br *ngIf="item.year" />
        <span nz-text nzType="secondary">{{ item.frame }} {{ item.modelGrade }}</span>
      </td>
      <td nzAlign="left" [ngClass]="'hide-column-small'">
        {{ item.engineVolume }} cc<br />
        <span nz-text nzType="secondary">{{ item.colorEn }}</span>
        <!-- {{ item.equip }} -->
      </td>
      <td nzAlign="right" [ngClass]="'hide-column-small'">
        {{ item.mileage }}
        <span nz-text nzType="warning">{{ item.rate }}</span>
      </td>
      <td nzAlign="right">
        {{ item.startPrice | currency: currentCurrency.name:'symbol-narrow' }}
        <br />
        {{ item.finishPrice | currency: currentCurrency.name:'symbol-narrow' }}
        <nz-tag *ngIf="item.result" style="margin: 0px">
          {{ item.result | translate }}
        </nz-tag>
      </td>
    </tr>
    </tbody>
  </nz-table>
</div>
