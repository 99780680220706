<main nz-row [nzGutter]="[0, 20]">
  <section nz-col [nzSpan]="24">
    <nz-list nzItemLayout="vertical" nzSize="small" [nzLoading]="loading$ | async">
      <nz-list-item *ngFor="let review of reviews$ | async">
        <nz-list-item-meta [nzAvatar]="avatar">
          <ng-template #avatar>
            <nz-avatar
              [nzSize]="36"
              nzSize="small"
              nzIcon="user"
              [ngStyle]="avatarStyles[review.owner.rating]"
            ></nz-avatar>
          </ng-template>
          <nz-list-item-meta-title>
            <a [href]="review.owner.link" target="_blank">
              {{ review.carInfo.maker }}
              {{ review.carInfo.mark }}
              {{ review.carInfo.year }}
            </a>
          </nz-list-item-meta-title>
        </nz-list-item-meta>

        {{ review.feedback }}

        <ul nz-list-item-actions>
          <nz-list-item-action>
            <nz-rate [nzCount]="5" [ngModel]="review.evaluation" [nzDisabled]="true"></nz-rate>
          </nz-list-item-action>
          <nz-list-item-action>
            <i nz-icon nzType="car" nzTheme="outline"></i>
            {{ review.owner.purchase | number }}
          </nz-list-item-action>
          <nz-list-item-action>
            <i nz-icon nzType="clock-circle" nzTheme="outline"></i>
            {{ review.createdAt | date: 'dd.MM.yyyy' }}
          </nz-list-item-action>
        </ul>
        <nz-list-item-extra></nz-list-item-extra>
      </nz-list-item>
      <nz-list-empty *ngIf="isEmpty$ | async"></nz-list-empty>
    </nz-list>
  </section>
  <section nz-col [nzSpan]="24">
    <nz-pagination
      nzSize="small"
      nzShowSizeChanger
      [nzTotal]="total$ | async"
      [nzPageIndex]="page$ | async"
      [nzPageSize]="pageSize$ | async"
      (nzPageIndexChange)="page$.next($event)"
      (nzPageSizeChange)="pageSize$.next($event)"
    ></nz-pagination>
  </section>
</main>
