<form nz-form [formGroup]="form">
  <nz-form-item>
    <nz-form-label [nzSpan]="labelSpan">Model</nz-form-label>
    <nz-form-control [nzSpan]="controlSpan">
      <input nz-input formControlName="model" />
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzSpan]="labelSpan">Mod.</nz-form-label>
    <nz-form-control [nzSpan]="controlSpan">
      <input nz-input formControlName="mod" />
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzSpan]="labelSpan">Auction date</nz-form-label>
    <nz-form-control [nzSpan]="controlSpan">
      <nz-date-picker formControlName="auctionDate"></nz-date-picker>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzSpan]="labelSpan">Auction</nz-form-label>
    <nz-form-control [nzSpan]="controlSpan">
      <input nz-input formControlName="auction" />
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzSpan]="labelSpan">Lot no.</nz-form-label>
    <nz-form-control [nzSpan]="controlSpan">
      <input nz-input formControlName="lotNo" />
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzSpan]="labelSpan">Grade</nz-form-label>
    <nz-form-control [nzSpan]="controlSpan">
      <input nz-input formControlName="grade" />
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzSpan]="labelSpan">Registr. year</nz-form-label>
    <nz-form-control [nzSpan]="controlSpan" [nzExtra]="registYearType">
      <nz-date-picker
        formControlName="registrYear"
        [nzMode]="form.value.registrYearType"
      ></nz-date-picker>
      <ng-template #registYearType>
        <nz-radio-group class="date-radio" formControlName="registrYearType" nzSize="small">
          <label nz-radio-button nzValue="date">Exact date</label>
          <label nz-radio-button nzValue="year">Only year</label>
        </nz-radio-group>
      </ng-template>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzSpan]="labelSpan">Product. year</nz-form-label>
    <nz-form-control [nzSpan]="controlSpan" [nzExtra]="productYearType">
      <nz-date-picker
        formControlName="productYear"
        [nzMode]="form.value.productYearType"
      ></nz-date-picker>
      <ng-template #productYearType>
        <nz-radio-group class="date-radio" formControlName="productYearType" nzSize="small">
          <label nz-radio-button nzValue="date">Exact date</label>
          <label nz-radio-button nzValue="year">Only year</label>
        </nz-radio-group>
      </ng-template>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzSpan]="labelSpan">Chassis</nz-form-label>
    <nz-form-control [nzSpan]="controlSpan">
      <input nz-input formControlName="chassis" />
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzSpan]="labelSpan">VIN</nz-form-label>
    <nz-form-control [nzSpan]="controlSpan">
      <input nz-input formControlName="vin" />
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzSpan]="labelSpan">Start, ¥</nz-form-label>
    <nz-form-control [nzSpan]="controlSpan">
      <nz-input-number formControlName="start" [nzStep]="5000" [nzMin]="0"></nz-input-number>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzSpan]="labelSpan">Final, ¥</nz-form-label>
    <nz-form-control [nzSpan]="controlSpan">
      <nz-input-number formControlName="final" [nzStep]="5000" [nzMin]="0"></nz-input-number>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzSpan]="labelSpan">Negotiations, ¥</nz-form-label>
    <nz-form-control [nzSpan]="controlSpan">
      <nz-input-number formControlName="negotiations" [nzStep]="5000" [nzMin]="0"></nz-input-number>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzSpan]="labelSpan">Status</nz-form-label>
    <nz-form-control [nzSpan]="controlSpan">
      <nz-select formControlName="status" nzAllowClear nzShowSearch>
        <nz-option
          *ngFor="let status of lotStatuses"
          [nzLabel]="status"
          [nzValue]="status"
        ></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzSpan]="labelSpan">Engine</nz-form-label>
    <nz-form-control [nzSpan]="controlSpan">
      <input nz-input formControlName="engine" />
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzSpan]="labelSpan">Odometer (km)</nz-form-label>
    <nz-form-control [nzSpan]="controlSpan">
      <nz-input-number formControlName="odometer" [nzMin]="0"></nz-input-number>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzSpan]="labelSpan">Color</nz-form-label>
    <nz-form-control [nzSpan]="controlSpan">
      <input nz-input formControlName="color" />
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzSpan]="labelSpan">Transmission</nz-form-label>
    <nz-form-control [nzSpan]="controlSpan">
      <input nz-input formControlName="transmission" />
    </nz-form-control>
  </nz-form-item>

  <nz-form-item [nzGutter]="5">
    <nz-form-label [nzSpan]="labelSpan">L\W\H</nz-form-label>
    <nz-form-control [nzSpan]="6">
      <nz-input-number formControlName="length" [nzMin]="0"></nz-input-number>
    </nz-form-control>
    <nz-form-control [nzSpan]="6">
      <nz-input-number formControlName="width" [nzMin]="0"></nz-input-number>
    </nz-form-control>
    <nz-form-control [nzSpan]="6">
      <nz-input-number formControlName="height" [nzMin]="0"></nz-input-number>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzSpan]="labelSpan">Recycle fee, ¥</nz-form-label>
    <nz-form-control [nzSpan]="controlSpan">
      <nz-input-number formControlName="recycleFee" [nzMin]="0"></nz-input-number>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzSpan]="labelSpan">Auction list</nz-form-label>
    <nz-form-control [nzSpan]="controlSpan">
      <app-photo-selector
        formControlName="mainPhotoUrl"
        [photoUrls]="photoUrls"
      ></app-photo-selector>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzSpan]="labelSpan">Additional photo 1</nz-form-label>
    <nz-form-control [nzSpan]="controlSpan">
      <app-photo-selector
        formControlName="firstAdditionalPhotoUrl"
        [photoUrls]="photoUrls"
      ></app-photo-selector>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzSpan]="labelSpan">Additional photo 2</nz-form-label>
    <nz-form-control [nzSpan]="controlSpan">
      <app-photo-selector
        formControlName="secondAdditionalPhotoUrl"
        [photoUrls]="photoUrls"
      ></app-photo-selector>
    </nz-form-control>
  </nz-form-item>
</form>

<app-purchase-report-template
  #reportTemplate
  [reportData]="form.value"
></app-purchase-report-template>

<div *nzModalFooter class="footer-buttons">
  <button nz-button nzType="primary" (click)="print()">Print</button>
  <button nz-button (click)="close()">Cancel</button>
</div>
