import { QueryHandlingService } from '@akebono/core';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, pluck, shareReplay } from 'rxjs/operators';

import {
  Lot,
  LotListDataGQL,
  LotSource,
  LotTypeEnum,
} from '../../graphql/service/graphql-auto-main-service';
import { AutoLot } from '../../graphql/service/graphql-cars-default-service';

@Injectable()
export class LotListDataService {
  constructor(private qhs: QueryHandlingService, private lotListDataGQL: LotListDataGQL) {}

  public fetchData(
    lotIds: (string | { id: string })[],
    lotType: LotTypeEnum,
    lotSource: LotSource,
  ): Observable<Map<string, Lot>> {
    let ids: string[] = [];
    if (typeof lotIds[0] === 'string') {
      ids = lotIds as string[];
    } else {
      ids = lotIds.map((lot: AutoLot) => lot.id);
    }

    return this.qhs
      .fetch(
        this.lotListDataGQL,
        {
          lotIds: ids,
          lotSource,
          subgroup: lotType,
        },
        'network-only',
      )
      .data.pipe(
        pluck('lots', 'nodes'),
        map((lots) => new Map(lots.map((lot) => [lot.id, lot]))),
        shareReplay(1),
      );
  }
}
