import { MutationHandlingServiceV2, SingleMutationRef } from '@akebono/core';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  Lot,
  LotSource,
  LotTypeEnum,
  UserLotMark,
  UserLotMarkCreateGQL,
  UserLotMarkCreateMutation,
  UserLotMarkEnum,
} from 'src/app/modules/graphql/service/graphql-auto-main-service';
import { environment } from 'src/environments/environment';

export type LotShareResult = {
  loading$: Observable<boolean>;
  link$: Observable<string>;
};

@Injectable({
  providedIn: 'root',
})
export class LotShareService {
  constructor(
    private readonly mhs: MutationHandlingServiceV2,
    private readonly userLotMarkCreateGQL: UserLotMarkCreateGQL,
  ) {}

  share(savedLot: Lot, lotType: LotTypeEnum, lotSource: LotSource): LotShareResult {
    const existingMark = this.findSharedMark(savedLot);

    if (existingMark) {
      return {
        loading$: of(false),
        link$: of(this.buildLink(savedLot, existingMark.id)),
      };
    } else {
      const mutationRef = this.createSharedLotMark(savedLot, lotType, lotSource);

      return {
        loading$: mutationRef.loading,
        link$: mutationRef.data.pipe(
          map((data) => data.userLotMarkCreate.userLotMark.id),
          map((markId) => this.buildLink(savedLot, markId)),
        ),
      };
    }
  }

  getLink(lot: Lot): string | undefined {
    const existingMark = this.findSharedMark(lot);
    return existingMark ? this.buildLink(lot, existingMark.id) : undefined;
  }

  private findSharedMark(lot: Lot): UserLotMark | undefined {
    return lot.currentUserLotMarks?.find((mark) => mark.mark === UserLotMarkEnum.Shared);
  }

  private buildLink(lot: Lot, markId: string): string {
    return `https://${environment.domain}/shared/${markId} ${lot.auctionName} ${lot.code}`;
  }

  private createSharedLotMark(
    lot: Lot,
    lotType: LotTypeEnum,
    lotSource: LotSource,
  ): SingleMutationRef<UserLotMarkCreateMutation> {
    return this.mhs.mutate(
      this.userLotMarkCreateGQL,
      {
        input: {
          lotId: lot.id,
          lotType,
          lotSource,
          mark: UserLotMarkEnum.Shared,
        },
      },
      {
        renderSuccess: false,
      },
    );
  }
}
