import { MutationHandlingServiceV2 } from '@akebono/core';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { of } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { UserFilterPresetCreateGQL } from 'src/app/modules/graphql/service/graphql-auto-main-service';

@Component({
  selector: 'app-create-user-filter-preset-modal',
  templateUrl: './create-user-filter-preset-modal.component.html',
  styleUrls: ['./create-user-filter-preset-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateUserFilterPresetModalComponent implements OnInit {
  @Input() presetContent: any;

  title: string;
  notificationsEnabled = false;

  creating$ = of(false);

  constructor(
    public modal: NzModalRef,
    private mhs: MutationHandlingServiceV2,
    private userFilterPresetCreateGQL: UserFilterPresetCreateGQL,
  ) {
    this.title = `FILTER ${new Date().toISOString()}`;
  }

  ngOnInit(): void {
    console.log(this.presetContent);
  }

  save() {
    this.creating$ = this.mhs
      .mutate(
        this.userFilterPresetCreateGQL,
        {
          input: {
            content: JSON.stringify(this.presetContent),
            title: this.title,
            compilationEmailEnabled: false,
          },
        },
        {
          refetch: true,
        },
      )
      .loading.pipe(finalize(() => this.modal.destroy()));
  }
}
