import { QueryHandlingService } from '@akebono/core';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Observable, ReplaySubject, Subject, combineLatest, iif, of, timer } from 'rxjs';
import { map, shareReplay, switchMap, takeUntil } from 'rxjs/operators';
import { AutoLotView } from 'src/app/const';
import { BidAmountScoreGQL } from 'src/app/modules/graphql/service/graphql-auto-main-service';
import { StatsBidScoreGQL } from 'src/app/modules/graphql/service/graphql-stats-auto-service';

@Component({
  selector: 'app-bid-amount-score',
  templateUrl: './bid-amount-score.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BidAmountScoreComponent implements OnInit, OnChanges, OnDestroy {
  @Input() lot: AutoLotView;
  @Input() amount: number;
  @Output() bidMakeSense = new EventEmitter<boolean>();

  changes$ = new ReplaySubject<{ lot: AutoLotView; amount: number }>(1);
  score$: Observable<number>;
  loading$: Observable<boolean>;
  dots$: Observable<string>;

  private destroy$ = new Subject<void>();

  constructor(
    private bidAmountScoreGQL: BidAmountScoreGQL,
    private qhs: QueryHandlingService,
    private statsBidScoreGQL: StatsBidScoreGQL,
  ) {}

  ngOnInit(): void {
    const queryRef$ = this.changes$.pipe(
      map(({ lot, amount }) => {
        const variables = {
          amount: amount + 20000, // bidding over limit
          companyEn: lot.company,
          modelEn: lot.modelName,
          modelTypeEn: lot.frame,
          year: Number(lot.year),
          mileage: Number(lot.mileage),
          ratingEn: lot.rate || '',
        };
        return {
          company: this.qhs.fetch(this.bidAmountScoreGQL, variables, 'cache-first'),
          stats: this.qhs.fetch(this.statsBidScoreGQL, variables, 'cache-first'),
        };
      }),
      shareReplay(1),
    );

    this.dots$ = this.changes$.pipe(
      switchMap(() => timer(0, 500)),
      map((n) => '...'.slice(0, 1 + (n % 3))),
    );

    this.loading$ = queryRef$.pipe(
      switchMap((refs) =>
        combineLatest([refs.company.loading, refs.stats.loading]).pipe(map(([l1, l2]) => l1 || l2)),
      ),
    );
    this.score$ = queryRef$.pipe(
      switchMap((refs) => combineLatest([refs.company.data, refs.stats.data])),
      map(([res1, res2]) => {
        const bidScore = res1.bidScore || res2.bidScore;
        if (bidScore != null) {
          return Math.round(bidScore * 5) * 20;
        }
        return null;
      }),
    );

    this.score$
      .pipe(
        map((score) => (score === null ? true : score > 60)),
        takeUntil(this.destroy$),
      )
      .subscribe((makeSense) => this.bidMakeSense.emit(makeSense));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.amount.currentValue != changes.amount.previousValue) {
      this.changes$.next({
        lot: this.lot,
        amount: this.amount,
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
