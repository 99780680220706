import { ConfigService } from '@akebono/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { en_US, mn_MN, NzI18nService, ru_RU } from 'ng-zorro-antd/i18n';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageSelectorComponent {
  language$: Observable<string>;

  allowedLanguages: string[];

  constructor(private config: ConfigService, private i18n: NzI18nService) {
    this.allowedLanguages = this.config.languageList.map((lang) => lang.localeId);
    this.language$ = this.config.getCurrentLanguage$().pipe(map((lang) => lang.localeId));
  }

  changeLanguage(lang: string): void {
    this.config.setCurrentLanguage(lang);
    switch (lang) {
      case 'ru':
        this.i18n.setLocale(ru_RU);
        break;
      case 'mn':
        this.i18n.setLocale(mn_MN);
      default:
        this.i18n.setLocale(en_US);
    }
  }
}
