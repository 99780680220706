import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: any;
  /** The `Long` scalar type represents non-fractional signed whole 64-bit numeric values. Long can represent values between -(2^63) and 2^63 - 1. */
  Long: any;
};

export type Query = {
  __typename?: 'Query';
  article?: Maybe<Article>;
  articles?: Maybe<ArticlesCollectionSegment>;
  block?: Maybe<Block>;
  blocks?: Maybe<BlocksCollectionSegment>;
  categories?: Maybe<CategoriesCollectionSegment>;
  category?: Maybe<Category>;
  news?: Maybe<NewsCollectionSegment>;
  newsEntry?: Maybe<News>;
};


export type QueryArticleArgs = {
  order?: Maybe<Array<ArticleSortInput>>;
  where?: Maybe<ArticleFilterInput>;
};


export type QueryArticlesArgs = {
  order?: Maybe<Array<ArticleSortInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  unread?: Maybe<Scalars['Boolean']>;
  where?: Maybe<ArticleFilterInput>;
};


export type QueryBlockArgs = {
  id: Scalars['Long'];
};


export type QueryBlocksArgs = {
  order?: Maybe<Array<BlockSortInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BlockFilterInput>;
};


export type QueryCategoriesArgs = {
  order?: Maybe<Array<CategorySortInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CategoryFilterInput>;
};


export type QueryCategoryArgs = {
  id: Scalars['Long'];
};


export type QueryNewsArgs = {
  order?: Maybe<Array<NewsSortInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  unread?: Maybe<Scalars['Boolean']>;
  where?: Maybe<NewsFilterInput>;
};


export type QueryNewsEntryArgs = {
  id: Scalars['Long'];
};

export type ArticleSortInput = {
  content?: Maybe<TranslatedContentSortInput>;
  country?: Maybe<SortEnumType>;
  createdAt?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  page?: Maybe<SortEnumType>;
  publishedAt?: Maybe<SortEnumType>;
  site?: Maybe<SiteSortInput>;
  siteId?: Maybe<SortEnumType>;
  title?: Maybe<TranslatedContentSortInput>;
  updatedAt?: Maybe<SortEnumType>;
  viewsCount?: Maybe<SortEnumType>;
};

export type TranslatedContentSortInput = {
  cn?: Maybe<SortEnumType>;
  en?: Maybe<SortEnumType>;
  jp?: Maybe<SortEnumType>;
  kr?: Maybe<SortEnumType>;
  ru?: Maybe<SortEnumType>;
};

export enum SortEnumType {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type SiteSortInput = {
  description?: Maybe<SortEnumType>;
  domain?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  tgBotName?: Maybe<SortEnumType>;
  tgChannelPostUrl?: Maybe<TranslatedContentSortInput>;
  tgMessageType?: Maybe<SortEnumType>;
  title?: Maybe<SortEnumType>;
};

export type ArticleFilterInput = {
  and?: Maybe<Array<ArticleFilterInput>>;
  categories?: Maybe<ListFilterInputTypeOfCategoryFilterInput>;
  content?: Maybe<TranslatedContentFilterInput>;
  country?: Maybe<StringOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  or?: Maybe<Array<ArticleFilterInput>>;
  page?: Maybe<StringOperationFilterInput>;
  publishedAt?: Maybe<DateTimeOperationFilterInput>;
  site?: Maybe<SiteFilterInput>;
  siteId?: Maybe<LongOperationFilterInput>;
  title?: Maybe<TranslatedContentFilterInput>;
  updatedAt?: Maybe<DateTimeOperationFilterInput>;
  viewsCount?: Maybe<LongOperationFilterInput>;
};

export type ListFilterInputTypeOfCategoryFilterInput = {
  all?: Maybe<CategoryFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
  none?: Maybe<CategoryFilterInput>;
  some?: Maybe<CategoryFilterInput>;
};

export type CategoryFilterInput = {
  and?: Maybe<Array<CategoryFilterInput>>;
  articles?: Maybe<ListFilterInputTypeOfArticleFilterInput>;
  children?: Maybe<ListFilterInputTypeOfCategoryFilterInput>;
  country?: Maybe<StringOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  or?: Maybe<Array<CategoryFilterInput>>;
  parent?: Maybe<CategoryFilterInput>;
  parentId?: Maybe<LongOperationFilterInput>;
  site?: Maybe<SiteFilterInput>;
  siteId?: Maybe<LongOperationFilterInput>;
  title?: Maybe<TranslatedContentFilterInput>;
  updatedAt?: Maybe<DateTimeOperationFilterInput>;
  weight?: Maybe<LongOperationFilterInput>;
};

export type ListFilterInputTypeOfArticleFilterInput = {
  all?: Maybe<ArticleFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
  none?: Maybe<ArticleFilterInput>;
  some?: Maybe<ArticleFilterInput>;
};

export type StringOperationFilterInput = {
  and?: Maybe<Array<StringOperationFilterInput>>;
  contains?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  eq?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  ncontains?: Maybe<Scalars['String']>;
  nendsWith?: Maybe<Scalars['String']>;
  neq?: Maybe<Scalars['String']>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  nstartsWith?: Maybe<Scalars['String']>;
  or?: Maybe<Array<StringOperationFilterInput>>;
  startsWith?: Maybe<Scalars['String']>;
};

export type DateTimeOperationFilterInput = {
  eq?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  neq?: Maybe<Scalars['DateTime']>;
  ngt?: Maybe<Scalars['DateTime']>;
  ngte?: Maybe<Scalars['DateTime']>;
  nin?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  nlt?: Maybe<Scalars['DateTime']>;
  nlte?: Maybe<Scalars['DateTime']>;
};

export type LongOperationFilterInput = {
  eq?: Maybe<Scalars['Long']>;
  gt?: Maybe<Scalars['Long']>;
  gte?: Maybe<Scalars['Long']>;
  in?: Maybe<Array<Maybe<Scalars['Long']>>>;
  lt?: Maybe<Scalars['Long']>;
  lte?: Maybe<Scalars['Long']>;
  neq?: Maybe<Scalars['Long']>;
  ngt?: Maybe<Scalars['Long']>;
  ngte?: Maybe<Scalars['Long']>;
  nin?: Maybe<Array<Maybe<Scalars['Long']>>>;
  nlt?: Maybe<Scalars['Long']>;
  nlte?: Maybe<Scalars['Long']>;
};

export type SiteFilterInput = {
  and?: Maybe<Array<SiteFilterInput>>;
  articles?: Maybe<ListFilterInputTypeOfArticleFilterInput>;
  blocks?: Maybe<ListFilterInputTypeOfBlockFilterInput>;
  categories?: Maybe<ListFilterInputTypeOfCategoryFilterInput>;
  description?: Maybe<StringOperationFilterInput>;
  domain?: Maybe<StringOperationFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  news?: Maybe<ListFilterInputTypeOfNewsFilterInput>;
  or?: Maybe<Array<SiteFilterInput>>;
  tgBotName?: Maybe<StringOperationFilterInput>;
  tgChannelPostUrl?: Maybe<TranslatedContentFilterInput>;
  tgMessageType?: Maybe<StringOperationFilterInput>;
  title?: Maybe<StringOperationFilterInput>;
};

export type ListFilterInputTypeOfBlockFilterInput = {
  all?: Maybe<BlockFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
  none?: Maybe<BlockFilterInput>;
  some?: Maybe<BlockFilterInput>;
};

export type BlockFilterInput = {
  and?: Maybe<Array<BlockFilterInput>>;
  content?: Maybe<TranslatedContentFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  or?: Maybe<Array<BlockFilterInput>>;
  page?: Maybe<StringOperationFilterInput>;
  site?: Maybe<SiteFilterInput>;
  siteId?: Maybe<LongOperationFilterInput>;
  updatedAt?: Maybe<DateTimeOperationFilterInput>;
};

export type TranslatedContentFilterInput = {
  and?: Maybe<Array<TranslatedContentFilterInput>>;
  cn?: Maybe<StringOperationFilterInput>;
  en?: Maybe<StringOperationFilterInput>;
  jp?: Maybe<StringOperationFilterInput>;
  kr?: Maybe<StringOperationFilterInput>;
  or?: Maybe<Array<TranslatedContentFilterInput>>;
  ru?: Maybe<StringOperationFilterInput>;
};

export type ListFilterInputTypeOfNewsFilterInput = {
  all?: Maybe<NewsFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
  none?: Maybe<NewsFilterInput>;
  some?: Maybe<NewsFilterInput>;
};

export type NewsFilterInput = {
  and?: Maybe<Array<NewsFilterInput>>;
  content?: Maybe<TranslatedContentFilterInput>;
  country?: Maybe<StringOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  image?: Maybe<StringOperationFilterInput>;
  isImportant?: Maybe<BooleanOperationFilterInput>;
  or?: Maybe<Array<NewsFilterInput>>;
  publishedAt?: Maybe<DateTimeOperationFilterInput>;
  site?: Maybe<SiteFilterInput>;
  siteId?: Maybe<LongOperationFilterInput>;
  tgBotPublishedAt?: Maybe<TranslatedContentFilterInput>;
  tgChannelPublishedAt?: Maybe<TranslatedContentFilterInput>;
  title?: Maybe<TranslatedContentFilterInput>;
  updatedAt?: Maybe<DateTimeOperationFilterInput>;
  viewsCount?: Maybe<LongOperationFilterInput>;
};

export type BooleanOperationFilterInput = {
  eq?: Maybe<Scalars['Boolean']>;
  neq?: Maybe<Scalars['Boolean']>;
};

export type Article = {
  __typename?: 'Article';
  categories: Array<Category>;
  content?: Maybe<TranslatedContent>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Long'];
  isRead: Scalars['Boolean'];
  page?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  site: Site;
  siteId: Scalars['Long'];
  title?: Maybe<TranslatedContent>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  viewsCount?: Maybe<Scalars['Long']>;
};

export type Category = {
  __typename?: 'Category';
  articles: Array<Article>;
  children: Array<Category>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Long'];
  parent?: Maybe<Category>;
  parentId?: Maybe<Scalars['Long']>;
  site: Site;
  siteId: Scalars['Long'];
  title?: Maybe<TranslatedContent>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weight?: Maybe<Scalars['Long']>;
};

export type Site = {
  __typename?: 'Site';
  articles: Array<Article>;
  blocks: Array<Block>;
  categories: Array<Category>;
  description?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  id: Scalars['Long'];
  news: Array<News>;
  tgBotName?: Maybe<Scalars['String']>;
  tgChannelPostUrl?: Maybe<TranslatedContent>;
  tgMessageType?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type Block = {
  __typename?: 'Block';
  content?: Maybe<TranslatedContent>;
  createdAt: Scalars['DateTime'];
  id: Scalars['Long'];
  page?: Maybe<Scalars['String']>;
  site: Site;
  siteId: Scalars['Long'];
  updatedAt: Scalars['DateTime'];
};

export type TranslatedContent = {
  __typename?: 'TranslatedContent';
  cn?: Maybe<Scalars['String']>;
  en?: Maybe<Scalars['String']>;
  jp?: Maybe<Scalars['String']>;
  kr?: Maybe<Scalars['String']>;
  ru?: Maybe<Scalars['String']>;
};

export type News = {
  __typename?: 'News';
  content?: Maybe<TranslatedContent>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Long'];
  image?: Maybe<Scalars['String']>;
  isImportant?: Maybe<Scalars['Boolean']>;
  isRead: Scalars['Boolean'];
  publishedAt?: Maybe<Scalars['DateTime']>;
  site: Site;
  siteId: Scalars['Long'];
  tgBotPublishedAt?: Maybe<TranslatedContent>;
  tgChannelPublishedAt?: Maybe<TranslatedContent>;
  title?: Maybe<TranslatedContent>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  viewsCount?: Maybe<Scalars['Long']>;
};

/** A segment of a collection. */
export type ArticlesCollectionSegment = {
  __typename?: 'ArticlesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Article>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

/** Information about the offset pagination. */
export type CollectionSegmentInfo = {
  __typename?: 'CollectionSegmentInfo';
  /** Indicates whether more items exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean'];
  /** Indicates whether more items exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean'];
};

export type BlockSortInput = {
  content?: Maybe<TranslatedContentSortInput>;
  createdAt?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  page?: Maybe<SortEnumType>;
  site?: Maybe<SiteSortInput>;
  siteId?: Maybe<SortEnumType>;
  updatedAt?: Maybe<SortEnumType>;
};

/** A segment of a collection. */
export type BlocksCollectionSegment = {
  __typename?: 'BlocksCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Block>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type CategorySortInput = {
  country?: Maybe<SortEnumType>;
  createdAt?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  parent?: Maybe<CategorySortInput>;
  parentId?: Maybe<SortEnumType>;
  site?: Maybe<SiteSortInput>;
  siteId?: Maybe<SortEnumType>;
  title?: Maybe<TranslatedContentSortInput>;
  updatedAt?: Maybe<SortEnumType>;
  weight?: Maybe<SortEnumType>;
};

/** A segment of a collection. */
export type CategoriesCollectionSegment = {
  __typename?: 'CategoriesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Category>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type NewsSortInput = {
  content?: Maybe<TranslatedContentSortInput>;
  country?: Maybe<SortEnumType>;
  createdAt?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  image?: Maybe<SortEnumType>;
  isImportant?: Maybe<SortEnumType>;
  publishedAt?: Maybe<SortEnumType>;
  site?: Maybe<SiteSortInput>;
  siteId?: Maybe<SortEnumType>;
  tgBotPublishedAt?: Maybe<TranslatedContentSortInput>;
  tgChannelPublishedAt?: Maybe<TranslatedContentSortInput>;
  title?: Maybe<TranslatedContentSortInput>;
  updatedAt?: Maybe<SortEnumType>;
  viewsCount?: Maybe<SortEnumType>;
};

/** A segment of a collection. */
export type NewsCollectionSegment = {
  __typename?: 'NewsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<News>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  userArticleViewCreate: UserArticleViewCreatePayload;
  userNewsViewCreate: UserNewsViewCreatePayload;
};


export type MutationUserArticleViewCreateArgs = {
  input: UserArticleViewCreateInput;
};


export type MutationUserNewsViewCreateArgs = {
  input: UserNewsViewCreateInput;
};

export type UserArticleViewCreateInput = {
  id: Scalars['Long'];
};

export type UserArticleViewCreatePayload = {
  __typename?: 'UserArticleViewCreatePayload';
  mutationError?: Maybe<MutationError>;
};

export type MutationError = {
  __typename?: 'MutationError';
  code: Scalars['String'];
  message: Scalars['String'];
};

export type UserNewsViewCreateInput = {
  id: Scalars['Long'];
};

export type UserNewsViewCreatePayload = {
  __typename?: 'UserNewsViewCreatePayload';
  mutationError?: Maybe<MutationError>;
};

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
  Validation = 'VALIDATION'
}

export type NewsCardDataFragment = { __typename?: 'News', id: any, isRead: boolean, publishedAt?: Maybe<any>, title?: Maybe<{ __typename?: 'TranslatedContent', ru?: Maybe<string>, en?: Maybe<string> }>, content?: Maybe<{ __typename?: 'TranslatedContent', ru?: Maybe<string>, en?: Maybe<string> }> };

export type UserNewsViewCreateMutationVariables = Exact<{
  input: UserNewsViewCreateInput;
}>;


export type UserNewsViewCreateMutation = { __typename?: 'Mutation', userNewsViewCreate: { __typename?: 'UserNewsViewCreatePayload', mutationError?: Maybe<{ __typename?: 'MutationError', code: string, message: string }> } };

export type ArticleQueryVariables = Exact<{
  where?: Maybe<ArticleFilterInput>;
  order?: Maybe<Array<ArticleSortInput> | ArticleSortInput>;
}>;


export type ArticleQuery = { __typename?: 'Query', article?: Maybe<{ __typename?: 'Article', id: any, createdAt?: Maybe<any>, publishedAt?: Maybe<any>, updatedAt?: Maybe<any>, title?: Maybe<{ __typename?: 'TranslatedContent', ru?: Maybe<string>, en?: Maybe<string> }>, content?: Maybe<{ __typename?: 'TranslatedContent', ru?: Maybe<string>, en?: Maybe<string> }> }> };

export type NewsCounterQueryVariables = Exact<{
  domain: Scalars['String'];
  limitDate: Scalars['DateTime'];
  country: Scalars['String'];
}>;


export type NewsCounterQuery = { __typename?: 'Query', unreadNewsCount?: Maybe<{ __typename?: 'NewsCollectionSegment', totalCount: number }> };

export type NewsEntryQueryVariables = Exact<{
  newsId: Scalars['Long'];
}>;


export type NewsEntryQuery = { __typename?: 'Query', newsEntry?: Maybe<{ __typename?: 'News', id: any, isRead: boolean, publishedAt?: Maybe<any>, title?: Maybe<{ __typename?: 'TranslatedContent', ru?: Maybe<string>, en?: Maybe<string> }>, content?: Maybe<{ __typename?: 'TranslatedContent', ru?: Maybe<string>, en?: Maybe<string> }> }> };

export type NewsListQueryVariables = Exact<{
  offset?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  filter?: Maybe<NewsFilterInput>;
}>;


export type NewsListQuery = { __typename?: 'Query', news?: Maybe<{ __typename?: 'NewsCollectionSegment', totalCount: number, items?: Maybe<Array<{ __typename?: 'News', id: any, isRead: boolean, publishedAt?: Maybe<any>, title?: Maybe<{ __typename?: 'TranslatedContent', ru?: Maybe<string>, en?: Maybe<string> }>, content?: Maybe<{ __typename?: 'TranslatedContent', ru?: Maybe<string>, en?: Maybe<string> }> }>> }> };

export const NewsCardDataFragmentDoc = gql`
    fragment NewsCardData on News {
  id
  isRead
  publishedAt
  title {
    ru
    en
  }
  content {
    ru
    en
  }
}
    `;
export const UserNewsViewCreateDocument = gql`
    mutation userNewsViewCreate($input: UserNewsViewCreateInput!) {
  userNewsViewCreate(input: $input) {
    mutationError {
      code
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserNewsViewCreateGQL extends Apollo.Mutation<UserNewsViewCreateMutation, UserNewsViewCreateMutationVariables> {
    document = UserNewsViewCreateDocument;
    client = 'content';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ArticleDocument = gql`
    query article($where: ArticleFilterInput, $order: [ArticleSortInput!]) {
  article(where: $where, order: $order) {
    id
    title {
      ru
      en
    }
    content {
      ru
      en
    }
    createdAt
    publishedAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ArticleGQL extends Apollo.Query<ArticleQuery, ArticleQueryVariables> {
    document = ArticleDocument;
    client = 'content';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const NewsCounterDocument = gql`
    query newsCounter($domain: String!, $limitDate: DateTime!, $country: String!) {
  unreadNewsCount: news(unread: true, where: {site: {domain: {eq: $domain}}, publishedAt: {gte: $limitDate}, country: {in: ["all", $country]}}) {
    totalCount
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class NewsCounterGQL extends Apollo.Query<NewsCounterQuery, NewsCounterQueryVariables> {
    document = NewsCounterDocument;
    client = 'content';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const NewsEntryDocument = gql`
    query newsEntry($newsId: Long!) {
  newsEntry(id: $newsId) {
    id
    isRead
    publishedAt
    title {
      ru
      en
    }
    content {
      ru
      en
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class NewsEntryGQL extends Apollo.Query<NewsEntryQuery, NewsEntryQueryVariables> {
    document = NewsEntryDocument;
    client = 'content';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const NewsListDocument = gql`
    query newsList($offset: Int, $first: Int, $filter: NewsFilterInput) {
  news(skip: $offset, take: $first, where: $filter, order: {id: DESC}) {
    items {
      ...NewsCardData
    }
    totalCount
  }
}
    ${NewsCardDataFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class NewsListGQL extends Apollo.Query<NewsListQuery, NewsListQueryVariables> {
    document = NewsListDocument;
    client = 'content';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    