import { Currency, CurrencyService, FetchQueryRef, QueryHandlingService } from '@akebono/core';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { QueryRef } from 'apollo-angular';
import { DateTime } from 'luxon';
import { Observable, of, Subscription } from 'rxjs';
import {
  LotCountersFragment,
  UserLotMarkEnum,
  UserLotMarksGQL,
  UserLotMarksQuery,
} from 'src/app/modules/graphql/service/graphql-auto-main-service';
import {
  LotSource,
  OnepriceLotsListFiltersGQL,
  OnepriceLotsListFiltersQuery,
  OnepriceLotsListFiltersQueryVariables,
  OnepriceLotsListGQL,
  OnepriceLotsListQuery,
  OnepriceLotsListQueryVariables,
} from 'src/app/modules/graphql/service/graphql-cars-default-service';

import { DateRange, getDaysRange, getTypeSort, simpleNameSort, toLocalIsoString, toNumber } from '../../../../const';
import { LotTypeEnum } from '../../../graphql/service/graphql-auto-main-service';
import { LotCountersService } from '../../../shared-components/lot-counters/lot-counters.service';

@Component({
  selector: 'app-oneprice-lots-list',
  templateUrl: './oneprice-lots-list.component.html',
  styleUrls: ['./oneprice-lots-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ListComponent implements OnInit, OnDestroy {
  data: any = {
    total: 0,
    nodes: [],
  };
  filtersData: any;
  pageSize = 20;
  page = 1;
  loadingResult = true;
  loadingFilters = true;
  total = 0;

  years: number[] = [];
  minYear = 1985;

  form: FormGroup;

  companies: any;
  trucks: any[];
  japanCompaniesList: any[];
  otherCompaniesList: any[];
  mainCompaniesList: any[];
  mainCompaniesNames = ['toyota', 'nissan', 'honda'];
  auctRefOptions: any[];
  currentCompany;
  isYearFiltered = false;
  isMileageFiltered = false;
  isEngineVolumeFiltered = false;
  isProductionDateFiltered = false;
  isActiveFilters = true;

  inclusionFilterFields = {
    frame: 'modelTypeEn',
    score: 'scoresEn',
    auction: 'auctRef',
    color: 'colorEn',
    transmission: 'transmissionEn',
    date: 'date',
    result: 'result',
  };
  lastDay: Date;
  dateRange: DateRange[] = [];
  selectedDays: string[] = [];
  currentCurrency: Currency;
  dumpVersion: string;
  outdated = false;
  lotsListQueryRef: QueryRef<OnepriceLotsListQuery, OnepriceLotsListQueryVariables>;
  lotsListFiltersQueryRef: QueryRef<
    OnepriceLotsListFiltersQuery,
    OnepriceLotsListFiltersQueryVariables
  >;
  userLotMarks$: FetchQueryRef<UserLotMarksQuery> | null = null;
  sub = new Subscription();

  countersMap: Observable<Map<string, LotCountersFragment>> = of(new Map());

  constructor(
    private title: Title,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private lotCountersService: LotCountersService,
    private listLotsGQL: OnepriceLotsListGQL,
    private listLotsFiltersGQL: OnepriceLotsListFiltersGQL,
    private currencyService: CurrencyService,
    private qhs: QueryHandlingService,
    private userLotMarksGQL: UserLotMarksGQL,
  ) {
    this.userLotMarks$ = this.qhs.fetch(this.userLotMarksGQL, {
      filter: {
        userLotMark: {
          eq: UserLotMarkEnum.Shared,
        },
      },
    });

    this.form = this.fb.group({
      company: this.fb.control(''),
      model: this.fb.control(''),
      truck: this.fb.control([]),
      bid: this.fb.control(null),
      yearFrom: this.fb.control(null),
      yearTo: this.fb.control(null),
      mileageFrom: this.fb.control(null),
      mileageTo: this.fb.control(null),
      engineVolumeFrom: this.fb.control(null),
      engineVolumeTo: this.fb.control(null),
      inclusionFrame: this.fb.control([]),
      inclusionScore: this.fb.control([]),
      inclusionAuction: this.fb.control([]),
      inclusionColor: this.fb.control([]),
      inclusionStatus: this.fb.control([]),
      inclusionTransmission: this.fb.control([]),
      productionDate: this.fb.control([]),
      datetime: this.fb.control(false),
    });
    this.route.queryParams.subscribe((params) => {
      this.loadingResult = true;
      this.page = toNumber(params.page) || 1;
      this.pageSize = toNumber(params.pageSize) || this.pageSize;
      const filter = this.getQueryFilter(params);
      this.form.patchValue(this.getFilterFormState(params));
      this.initDate();
      const sort = {};
      if (params.sort && params.sortType) {
        sort[params.sort] = {
          direction: params.sortType,
        };
      }
      const queryFilters = {
        companyId: params.company || 0,
        modelId: params.model || 0,
        withCompany: typeof params.company !== 'undefined',
        withModel: typeof params.model !== 'undefined',
        first: this.pageSize,
        offset: (this.page - 1) * this.pageSize,
        filter,
        sort,
      };
      if (!this.lotsListQueryRef) {
        this.lotsListQueryRef = this.listLotsGQL.watch(queryFilters, {
          fetchPolicy: 'network-only',
        });
        this.lotsListQueryRef.valueChanges.subscribe(
          (result) => {
            this.loadingResult = false;
            this.data = result.data.lots;
            this.total = result.data.onepriceLots.total;
            this.checkOutdated(result.data.dumpVersion);
            this.countersMap = this.lotCountersService.getCountersForLots(
              result.data.lots.nodes,
              LotTypeEnum.Oneprice,
              LotSource.Aleado,
            );
          },
          (error) => {
            console.log(error);
            this.loadingResult = false;
          },
        );
      } else {
        this.lotsListQueryRef.refetch(queryFilters).finally(() => {
          this.loadingResult = false;
        });
      }

      if (!this.lotsListFiltersQueryRef) {
        this.lotsListFiltersQueryRef = this.listLotsFiltersGQL.watch(queryFilters, {
          fetchPolicy: 'network-only',
        });
        this.lotsListFiltersQueryRef.valueChanges.subscribe(
          (result) => {
            this.loadingFilters = result.loading;
            this.filtersData = result.data.lotsFiltersData;
            this.currentCompany = result.data.autoCompany;
            this.checkOutdated(result.data.dumpVersion);

            this.auctRefOptions = result.data.lotsFiltersData.auctRefOptions
              .map((op) => op.autoAuction)
              .sort(simpleNameSort);
            this.japanCompaniesList = result.data.autoCompanies.nodes
              .filter((c) => c.country === 'JAPAN')
              .sort(simpleNameSort);
            this.otherCompaniesList = result.data.autoCompanies.nodes
              .filter((c) => c.country !== 'JAPAN')
              .sort(simpleNameSort);
            this.mainCompaniesList = result.data.autoCompanies.nodes
              .filter((c) => this.mainCompaniesNames.includes(c.name.toLocaleLowerCase()))
              .sort(simpleNameSort)
              .reverse();

            this.trucks = result.data.autoTrucks.nodes;
            this.companies = result.data.autoCompanies.nodes.reduce((carry, company) => {
              carry[company.companyId] = company;
              return carry;
            }, {});
            this.loadingFilters = false;
          },
          (error) => {
            console.log(error);
            this.loadingFilters = false;
          },
        );
      } else {
        this.lotsListFiltersQueryRef.refetch(queryFilters).finally(() => {
          this.loadingFilters = false;
        });
      }
    });

    for (let year = new Date().getFullYear(); year >= this.minYear; year--) {
      this.years.push(year);
    }
  }

  ngOnInit() {
    this.title.setTitle('ECar JP Lots');

    this.currencyService.currentCurrency$.subscribe((item) => {
      this.currentCurrency = item;
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  private toUTCDate(date: Date) {
    date.setUTCDate(date?.getDate());
    return date;
  }

  private checkOutdated(dumpVersion) {
    if (!this.dumpVersion) {
      this.dumpVersion = dumpVersion;
    } else if (this.dumpVersion !== dumpVersion) {
      this.outdated = true;
    }
  }

  filter(value) {
    this.router.navigate(['oneprice'], {
      queryParams: {
        r: Math.floor(Math.random() * 100),
        page: 1,
        company: value.company,
        model: value.model,
        truck: value.truck,
        bid: value.bid ? value.bid : undefined,
        yearFrom: value.yearFrom ? value.yearFrom : undefined,
        yearTo: value.yearTo ? value.yearTo : undefined,
        mileageFrom: value.mileageFrom ? value.mileageFrom : undefined,
        mileageTo: value.mileageTo ? value.mileageTo : undefined,
        engineVolumeFrom: value.engineVolumeFrom ? value.engineVolumeFrom : undefined,
        engineVolumeTo: value.engineVolumeTo ? value.engineVolumeTo : undefined,
        productionDateFrom: value.productionDate[0]
          ? this.toUTCDate(value.productionDate[0]).toISOString()
          : undefined,
        productionDateTo: value.productionDate[1]
          ? this.toUTCDate(value.productionDate[1]).toISOString()
          : undefined,
        frame: value.inclusionFrame.length > 0 ? value.inclusionFrame : undefined,
        score: value.inclusionScore.length > 0 ? value.inclusionScore : undefined,
        auction: value.inclusionAuction.length > 0 ? value.inclusionAuction : undefined,
        color: value.inclusionColor.length > 0 ? value.inclusionColor : undefined,
        status: value.inclusionStatus.length > 0 ? value.inclusionStatus : undefined,
        transmission:
          value.inclusionTransmission.length > 0 ? value.inclusionTransmission : undefined,
        date: this.selectedDays.length > 0 ? this.selectedDays : undefined,
        datetime: value.datetime
          ? DateTime.local().setZone('Asia/Tokyo').plus({ minutes: 15 }).toISO()
          : undefined,
      },
      queryParamsHandling: 'merge',
    });
  }

  scrollToTable() {
    document.querySelector('.table').scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }

  getQueryFilter(params) {
    const inclusionFilter = [];
    Object.keys(this.inclusionFilterFields).forEach((key) => {
      if (params[key] && params[key].length > 0) {
        inclusionFilter.push({
          fieldName: this.inclusionFilterFields[key],
          eq: [].concat(params[key]),
        });
      }
    });
    const mFrom = Number(params.mileageFrom);
    const mTo = Number(params.mileageTo);

    return {
      companyRef: {
        eq: params.company,
      },
      modelNameRef: {
        eq: params.model,
      },
      truckRef: {
        eq: params.truck,
      },
      year: {
        gte: Number(params.yearFrom),
        lte: Number(params.yearTo),
      },
      mileage: {
        gte: mFrom ? mFrom / 1000 : null,
        lte: mTo ? mTo / 1000 : null,
      },
      engineVolume: {
        gte: Number(params.engineVolumeFrom),
        lte: Number(params.engineVolumeTo),
      },
      productionDate: {
        gte: params.productionDateFrom,
        lte: params.productionDateTo,
      },
      bid: {
        eq: params.bid,
      },
      inclusion:
        inclusionFilter.length > 0
          ? {
              columns: inclusionFilter,
            }
          : undefined,
      datetime: {
        gt: params.datetime,
      },
    };
  }

  getFilterFormState(params) {
    this.isYearFiltered = (params.yearFrom || params.yearTo) as boolean;
    this.isMileageFiltered = (params.mileageFrom || params.mileageTo) as boolean;
    this.isEngineVolumeFiltered = (params.engineVolumeFrom || params.engineVolumeTo) as boolean;
    this.selectedDays = params.date ? [].concat(params.date) : [];
    const productionDate = [];
    if (params.productionDateFrom) {
      productionDate[0] = params.productionDateFrom;
    }
    if (params.productionDateTo) {
      productionDate[1] = params.productionDateTo;
    }
    return {
      company: params.company || 'any',
      model: params.model || 'any',
      bid: params.bid || null,
      yearFrom: params.yearFrom ? Number(params.yearFrom) : null,
      yearTo: params.yearTo ? Number(params.yearTo) : null,
      mileageFrom: params.mileageFrom || null,
      mileageTo: params.mileageTo || null,
      engineVolumeFrom: params.engineVolumeFrom || null,
      engineVolumeTo: params.engineVolumeTo || null,
      productionDate,
      inclusionFrame: params.frame ? [].concat(params.frame) : [],
      inclusionAuction: params.auction ? [].concat(params.auction) : [],
      inclusionScore: params.score ? [].concat(params.score) : [],
      inclusionColor: params.color ? [].concat(params.color) : [],
      inclusionStatus: params.status ? [].concat(params.status) : [],
      inclusionTransmission: params.transmission ? [].concat(params.transmission) : [],
      datetime: params.datetime || false,
    };
  }

  resetFilter() {
    this.selectedDays = [];
    this.router.navigate(['oneprice']);
    this.outdated = false;
    return false;
  }

  loadData(page) {
    this.router.navigate(['oneprice'], {
      queryParams: {
        page,
      },
      queryParamsHandling: 'merge',
    });
  }

  changePageSize(pageSize) {
    this.loadingResult = true;

    this.router.navigate(['oneprice'], {
      queryParams: {
        pageSize,
      },
      queryParamsHandling: 'merge',
    });
  }

  onBack() {
    this.router.navigateByUrl('');
  }

  sortEvent(sort: { key: string; value: string }): void {
    this.loadingResult = true;

    this.router.navigate(['oneprice'], {
      queryParams: {
        sort: sort.key,
        sortType: getTypeSort(sort.value),
      },
      queryParamsHandling: 'merge',
    });
  }

  removeFilter(filter) {
    if (filter === 'year') {
      this.form.get('yearFrom').setValue(null);
      this.form.get('yearTo').setValue(null);
    }
    if (filter === 'engineVolume') {
      this.form.get('engineVolumeFrom').setValue(null);
      this.form.get('engineVolumeTo').setValue(null);
    }
    if (filter === 'productionDate') {
      this.form.get('productionDate').setValue([]);
    }
    if (filter === 'mileage') {
      this.form.get('mileageFrom').setValue(null);
      this.form.get('mileageTo').setValue(null);
    }
    this.filter(this.form.value);
  }

  search(event) {}

  change(event) {
    if (!this.loadingResult) {
      this.filter(this.form.value);
    }
  }

  selectDateAuction(event, date: DateRange) {
    const dateJapan = toLocalIsoString(
      this.getDate(date.date.toLocaleString('en-US', { timeZone: 'Asia/Tokyo' })),
    );
    date.checked = event;
    if (event) {
      this.selectedDays.push(dateJapan);
    } else {
      const day = this.selectedDays.indexOf(dateJapan);
      if (day > -1) {
        this.selectedDays.splice(day, 1);
      }
    }
    this.filter(this.form.value);
  }

  initDate() {
    this.lastDay = new Date();
    this.lastDay.setDate(this.lastDay.getDate() + 7);
    if (this.dateRange.length === 0) {
      const dates = getDaysRange(new Date(), new Date(this.lastDay));
      dates.map((item) => {
        this.dateRange.push({
          date: item,
          checked: this.selectedDays.indexOf(toLocalIsoString(this.getDate(item))) > -1,
        });
      });
    } else {
      this.dateRange.map((item) => {
        item.checked = this.selectedDays.indexOf(toLocalIsoString(this.getDate(item.date))) > -1;
      });
    }
  }

  getDate(string = null): Date {
    if (string) {
      return new Date(string);
    }
    return new Date();
  }

  getPrice(price: number) {
    return this.currencyService.getPrice(price, this.currentCurrency);
  }
}
