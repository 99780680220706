import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  LotSource,
  LotTypeEnum,
  LotUpdateGQL,
} from '../../../../graphql/service/graphql-auto-main-service';
import { AutoLotView } from 'src/app/const';
import { MutationHandlingServiceV2 } from '@akebono/core';
import { of } from 'rxjs';
import { finalize, shareReplay } from 'rxjs/operators';

@Component({
  selector: 'app-lot-edit-rating',
  templateUrl: './lot-edit-rating.component.html',
  styleUrls: ['./lot-edit-rating.component.scss'],
})
export class LotEditRatingComponent implements OnInit {
  @Input() lot: AutoLotView;
  @Input() lotType: LotTypeEnum;
  @Input() lotSource: LotSource;
  @Output() actionComplete = new EventEmitter<void>();

  showPopover = false;
  rating: string;
  saving = of(false);

  constructor(private mhs: MutationHandlingServiceV2, private lotUpdateGQL: LotUpdateGQL) {}

  ngOnInit() {
    this.rating = this.lot.rate ?? '-';
  }

  save(): void {
    this.saving = this.mhs
      .mutate(
        this.lotUpdateGQL,
        {
          input: {
            lotId: this.lot.id,
            lotType: this.lotType,
            lotSource: this.lotSource,
            ratingEn: this.rating,
          },
        },
        { refetch: true },
      )
      .loading.pipe(
        finalize(() => {
          this.actionComplete.emit();
          this.showPopover = false;
        }),
        shareReplay(),
      );
  }
}
