import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, combineLatest, iif, of, Subject } from 'rxjs';
import { map, startWith, switchMap, tap } from 'rxjs/operators';
import {
  AutoLotView,
  BoundaryStatus,
  getCalcData,
  getLotSourceFromParams,
  mergeSavedLotToAutoLotView,
  ReactiveLotData,
} from 'src/app/const';
import {
  LotTypeEnum,
  UserLotGQL,
  UserLotQuery,
} from 'src/app/modules/graphql/service/graphql-auto-main-service';
import { LotSource } from 'src/app/modules/graphql/service/graphql-cars-default-service';
import {
  JpCenterAutoLotGQL,
  JpCenterAutoLotQuery,
} from 'src/app/modules/graphql/service/graphql-stats-auto-service';

import { environment } from '../../../../../environments/environment';
import { hasCurrentLanguageTranslation } from '../../../../const';

@Component({
  selector: 'app-auto-lot',
  templateUrl: './auto-lot.component.html',
  styleUrls: ['./auto-lot.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotComponent implements OnInit {
  data$: ReactiveLotData = of({});
  loading$ = new BehaviorSubject<boolean>(true);
  refetch$ = new Subject<void>();

  activeSlide: number = 1;

  LotSource = LotSource;
  BoundaryStatus = BoundaryStatus;

  constructor(
    private title: Title,
    private route: ActivatedRoute,
    private autoLotGQL: JpCenterAutoLotGQL,
    private userLotGQL: UserLotGQL,
  ) {}

  ngOnInit(): void {
    this.data$ = combineLatest([
      this.route.params,
      this.refetch$.asObservable().pipe(startWith(true)),
    ]).pipe(
      tap(() => this.loading$.next(true)),
      map(([params]) => ({
        lotId: params.lot,
        lotType: LotTypeEnum.Auto,
        lotSource: getLotSourceFromParams(params),
      })),
      switchMap((initData) =>
        combineLatest([
          this.autoLotGQL.fetch({ id: initData.lotId || 0 }, { fetchPolicy: 'network-only' }),
          this.userLotGQL.fetch(
            {
              id: initData.lotId,
              lotSource: initData.lotSource,
              subgroup: initData.lotType,
            },
            { fetchPolicy: 'network-only' },
          ),
        ]).pipe(
          map(([lotResult, userResult]) => ({
            initData,
            lotResult,
            userResult,
          })),
        ),
      ),
      switchMap((data) =>
        iif(
          () => Boolean(data.lotResult.data.lot) || Boolean(data.userResult.data.lot),
          of(data).pipe(
            map((data) => ({
              ...data,
              lot: this.compileAutoLotView('0', data.lotResult.data.lot, data.userResult.data.lot),
            })),
            map((data) => ({
              ...data.initData,
              lot: data.lot,
              savedLot: data.userResult.data?.lot,
              currentUser: data.userResult.data.currentUser,
              icons: data.userResult.data?.lot?.icons ?? [],
              currentUserLastBid: data.userResult.data?.lot?.currentUserLastBid,
              bestBid: data.userResult.data?.lot?.bestBid,
              hasBestBid: data.userResult.data?.lot?.hasBestBid,
              currentUserLotMarks: data.userResult.data?.lot?.currentUserLotMarks || [],
              translations: data.userResult.data?.lot?.translations,
              fullVin: Boolean(data.lotResult.data?.lot?.vin),
              hasTranslations: hasCurrentLanguageTranslation(
                data.userResult.data?.lot?.translations,
                data.userResult.data.currentUser,
              ),
              isUsingSavedImages: Boolean(data.userResult.data.lot),
              calcData: getCalcData(
                data.lot,
                'auto',
                data.userResult.data?.lot?.currentUserLastBid?.amount,
              ),
            })),
            tap((data) => {
              this.title.setTitle(`ECar JP Lot #${data.lot.bid}`);
            }),
          ),
          of(data.initData),
        ),
      ),
      tap(() => this.loading$.next(false)),
    );
  }

  compileAutoLotView(
    dumpVersion: string,
    autoLot?: JpCenterAutoLotQuery['lot'],
    savedLot?: UserLotQuery['lot'],
  ): AutoLotView {
    let lot: AutoLotView;
    if (autoLot) {
      lot = {
        ...autoLot,
        time: autoLot.time,
        images: autoLot.images
          .split('#')
          .filter((url) => !!url)
          .map((url) => url.replace('https://p3.aleado.com/', environment.imageProxy)),
        auctionName: autoLot.auctionName || 'MISSING',
        companyId: autoLot.companyRef,
        modelId: autoLot.modelNameRef,
        year: String(autoLot.year),
        dumpVersion,
        vin: autoLot.vin || (autoLot.frame ? autoLot.frame + '-' : ''),
        transmission: autoLot.transmissionEn,
      };
    }

    if (savedLot) {
      lot = this.updateAutoLotViewBySavedData(lot, savedLot);
    }
    return lot;
  }

  updateAutoLotViewBySavedData(lot: AutoLotView, savedLot?: UserLotQuery['lot']): AutoLotView {
    try {
      lot = mergeSavedLotToAutoLotView(lot, savedLot);
    } catch (e) {
      console.error(e);
    }

    lot.productionDate = savedLot.productionDate;
    lot.vin = savedLot.vin || lot?.vin;
    lot.images = lot?.images?.length > savedLot?.images?.length ? lot?.images : savedLot?.images;
    lot.negotiationsPrice = savedLot.negotiationsPrice;
    return lot;
  }

  goToFirstSlide(isUsingSavedImages: boolean): void {
    if (!isUsingSavedImages) {
      this.activeSlide = 1;
    }
  }

  scrollToTranslations(): void {
    document.getElementById('lotTranslations').scrollIntoView({ behavior: 'smooth' });
  }
}
