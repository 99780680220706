import { MutationHandlingServiceV2, NotificationService } from '@akebono/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateTime } from 'luxon';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzUploadFile, NzUploadXHRArgs } from 'ng-zorro-antd/upload';
import { of, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { validateAllFormFields } from 'src/app/const';
import { LotCreateManualGQL, LotTypeEnum } from 'src/app/modules/graphql/service/graphql-auto-main-service';
import { S3FileUploaderService } from 'src/app/services/s3-file-uploader.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-arai-kenki-lot-modal',
  templateUrl: './arai-kenki-lot-modal.component.html',
  styleUrls: ['./arai-kenki-lot-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AraiKenkiLotModalComponent {
  loading$ = of(false);
  lotTypes = Object.keys(LotTypeEnum);
  form: FormGroup;
  fileList: NzUploadFile[] = [];
  s3Keys: string[] = [];

  constructor(
    private fb: FormBuilder,
    public modal: NzModalRef,
    private mhs: MutationHandlingServiceV2,
    private notification: NotificationService,
    private lotCreateManualGQL: LotCreateManualGQL,
    private s3FileUploaderService: S3FileUploaderService,
  ) {
    this.form = this.fb.group({
      lotType: ['Auto', [Validators.required]],
      date: [
        DateTime.now()
          .setZone('Asia/Tokyo')
          .plus({ day: 1 })
          .startOf('day')
          .minus({ hour: 1 })
          .toISO(),
        [Validators.required],
      ],
      auctionName: [null, [Validators.required]],
      code: [null, [Validators.required]],
      company: [null, [Validators.required]],
      modelName: [null, [Validators.required]],
      frame: [null, []],
      year: [null, []],
      endPrice: [null, []],
      startPrice: [null, [Validators.required]],
      engineVolume: [null, []],
      color: [null, []],
      mileage: [null, []],
      score: [null, []],
    });
  }

  lotCreateManual(): void {
    validateAllFormFields(this.form);

    if (this.form.valid) {
      this.loading$ = this.mhs
        .mutate(
          this.lotCreateManualGQL,
          {
            input: {
              lotType: LotTypeEnum[this.form.value.lotType],
              code: this.form.value.code.toString(),
              auctionName: this.form.value.auctionName.trim(),
              auctionDatetime: this.form.value.date,
              company: this.form.value.company,
              model: this.form.value.modelName,
              modelType: this.form.value.frame,
              year: this.form.value.year,
              startPrice: this.form.value.startPrice,
              endPrice: this.form.value.endPrice,
              images: this.s3Keys,
              displacementNum: +this.form.value.engineVolume,
              color: this.form.value.color,
              mileage: +this.form.value.mileage,
              scores: this.form.value.score,
            },
          },
          {
            successTranslationKey: 'LOT_BIDDING_MODAL.SUCCESS_CREATE_BID',
            failureTranslationKey: 'LOT_BIDDING_MODAL.FAIL_CREATE_BID',
            refetch: true,
          },
        )
        .loading.pipe(finalize(() => this.modal.close()));
    } else {
      this.notification.renderError('LOT_BIDDING_MODAL.FILL_ALL_FIELDS');
    }
  }

  uploadFiles = (item: NzUploadXHRArgs): Subscription => {
    this.s3FileUploaderService.upload(item).subscribe(
      (key) => {
        this.s3Keys.push(`https://img.${environment.domain}/${key}`);
        this.fileList.find((f) => (item.file = f)).key = key;
      },
      (error) => {
        console.error(error);
      },
    );

    return Subscription.EMPTY;
  };

  removeFiles = (): boolean => {
    this.s3Keys = [];
    this.fileList = [];
    return true;
  };
}
