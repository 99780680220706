<button
  nz-button
  nzSize="small"
  nz-popover
  nzPopoverTrigger="click"
  [(nzPopoverVisible)]="showPopover"
  [nzPopoverTitle]="changeAuctionDatetimePopoverTitle"
  [nzPopoverContent]="changeAuctionDatetimePopoverBody"
  (nzPopoverVisibleChange)="showPopover = $event"
>
  <i nz-icon nzType="edit" nzTheme="outline"></i>
</button>

<ng-template #changeAuctionDatetimePopoverTitle>
  <nz-row nzJustify="space-between">
    <nz-col>{{ 'LOT_INFO.AUCTION_DATE' | translate }}</nz-col>
    <nz-col>
      <i nz-icon class="pointer" nzType="close" nzTheme="outline" (click)="showPopover = false"></i>
    </nz-col>
  </nz-row>
</ng-template>

<ng-template #changeAuctionDatetimePopoverBody>
  <section>
    <nz-date-picker
      nzShowTime
      [(ngModel)]="auctionDatetime"
      nzFormat="yyyy-MM-dd HH:mm:ss"
    ></nz-date-picker>
  </section>
  <section>
    <button
      nz-button
      nzSize="small"
      nzType="primary"
      [nzLoading]="saving | async"
      (click)="saveStartPrice()"
    >
      {{ 'SAVE' | translate }}
    </button>
  </section>
</ng-template>
