import { EventEmitter, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { defer, EMPTY, iif, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { CheckAgentDebtsGQL } from '../modules/graphql/service/graphql-shared-familiar-service';

@Injectable()
export class SpecialAlertsService {
  constructor(
    private translate: TranslateService,
    private modalService: NzModalService,
    private checkAgentDebtsGQL: CheckAgentDebtsGQL,
  ) {}

  public checkAgentDebts(): Observable<void> {
    return this.checkAgentDebtsGQL.fetch({}, { fetchPolicy: 'network-only' }).pipe(
      map((response) => response?.data?.checkAgentDebts ?? null),
      switchMap((hasDebts) =>
        iif(
          () => typeof hasDebts === 'boolean' && hasDebts,
          defer(
            () =>
              this.modalService.info({
                nzTitle: this.translate.instant('ATTENTION'),
                nzContent: this.translate.instant('SPECIAL_ALERTS.HAS_DEBTS'),
                nzAfterClose: new EventEmitter(),
              }).afterClose,
          ),
          of(EMPTY),
        ),
      ),
    );
  }
}
