import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MutationHandlingServiceV2 } from '@akebono/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import QRCodeStyling from 'qr-code-styling';
import {
  NotifyBotInfo,
  SubscribeUserToTelegramGQL,
  User,
} from 'src/app/modules/graphql/service/graphql-shared-familiar-service';

@Component({
  selector: 'lib-subscribe-telegram-modal',
  templateUrl: './subscribe-telegram-modal.component.html',
  styleUrls: ['subscribe-telegram-modal.component.scss'],
})
export class SubscribeTelegramModalComponent implements OnInit {
  @Input() currentUser: User = null;
  @Input() bot: NotifyBotInfo = null;
  qrCode = null;
  uuid = null;
  isLoading = true;
  showButton = false;

  @ViewChild('canvas', { static: true }) canvas: ElementRef;

  constructor(
    private modal: NzModalRef,
    private mhs: MutationHandlingServiceV2,
    private subscribeUserToTelegramGQL: SubscribeUserToTelegramGQL,
  ) {}

  ngOnInit(): void {
    this.mhs
      .handle(this.subscribeUserToTelegramGQL, {
        input: {
          botId: this.bot?.id,
        },
      })
      .subscribe((data) => {
        this.uuid = data?.subscribeUserToTelegram?.uuid;
        if (this.uuid) {
          this.isLoading = false;
          this.generateQrCode();
        }
      });
  }

  generateQrCode() {
    this.qrCode = new QRCodeStyling({
      width: 280,
      height: 280,
      data: this.getLink(),
      margin: 0,
      qrOptions: {
        typeNumber: 0,
        mode: 'Byte',
        errorCorrectionLevel: 'Q',
      },
      dotsOptions: {
        type: 'extra-rounded',
        color: '#6a1a4c',
        gradient: {
          type: 'radial',
          rotation: 0.12217304763960307,
          colorStops: [
            { offset: 0, color: '#00b3ff' },
            { offset: 1, color: '#d90889' },
          ],
        },
      },
      backgroundOptions: { color: '#ffffff', gradient: null },
      cornersSquareOptions: {
        type: 'extra-rounded',
        color: '#000000',
        gradient: {
          type: 'linear',
          rotation: 0,
          colorStops: [
            { offset: 0, color: '#194de6' },
            { offset: 1, color: '#d90889' },
          ],
        },
      },
      cornersDotOptions: { type: 'dot', color: '#194de6', gradient: null },
    });
    setTimeout(() => {
      this.qrCode.append(this.canvas.nativeElement);
      this.showButton = true;
    }, 1000);
  }

  destroyModal(): void {
    this.modal.destroy();
  }

  getLink(): string {
    return `https://t.me/${this.bot?.name}?start=${this.uuid}`;
  }
}
