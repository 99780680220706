<ng-container *ngIf="loaded$ | async">
  <div class="main-button">
    <a
      nzSize="small"
      target="_blank"
      nz-button
      nzType="primary"
      [class.has-bid]="hasBid"
      [disabled]="isLotSanctioned$ | async"
      [routerLink]="link"
    >
      {{ lot.bid }}
    </a>

    <i
      *ngIf="isLotSanctioned$ | async"
      nz-icon
      [style.color]="'red'"
      nzType="warning"
      nzTheme="fill"
      nz-tooltip
      [nzTooltipTitle]="'SANCTIONED_LOT_NOT_ALLOWED' | translate"
    ></i>
  </div>

  <ng-container *ngIf="isLotNotSanctioned$ | async">
    <div>
      <app-lot-bid-and-bidders-counter
        [counter]="lotData"
        tooltipPosition="right"
      ></app-lot-bid-and-bidders-counter>
    </div>

    <div>
      <app-lot-views-and-favorites-counter
        [counter]="lotData"
      ></app-lot-views-and-favorites-counter>
    </div>

    <nz-row [nzGutter]="[5, 5]" nzJustify="center" nzAlign="middle">
      <nz-col>
        <app-lot-interesting-status
          [lot]="lot"
          [lotType]="lotType"
          [marks]="marks"
        ></app-lot-interesting-status>
      </nz-col>
      <nz-col>
        <app-shared-icon [marks]="marks" [lotId]="lot.id"></app-shared-icon>
      </nz-col>
    </nz-row>
  </ng-container>
</ng-container>
