import { MutationHandlingServiceV2 } from '@akebono/core';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, mapTo } from 'rxjs/operators';
import { UserFilterPresetUpdateGQL } from 'src/app/modules/graphql/service/graphql-auto-main-service';

@Component({
  selector: 'app-preset-unsubscribe',
  templateUrl: './preset-unsubscribe.component.html',
  styleUrls: ['./preset-unsubscribe.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PresetUnsubscribeComponent implements OnInit {
  loading$ = of(true);
  success$: Observable<boolean | null> = of(null);

  constructor(
    private route: ActivatedRoute,
    private mhs: MutationHandlingServiceV2,
    private userFilterPresetUpdateGQL: UserFilterPresetUpdateGQL,
  ) {}

  ngOnInit(): void {
    const ref = this.mhs.mutate(this.userFilterPresetUpdateGQL, {
      input: {
        id: this.route.snapshot.paramMap.get('id'),
        compilationEmailEnabled: false,
      },
    });

    this.loading$ = ref.loading;
    this.success$ = ref.data.pipe(
      mapTo(true),
      catchError(() => of(false)),
    );
  }
}
