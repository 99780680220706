<nz-modal
  [nzVisible]="(hasEmptyCountryIso$ | async) && !hidden"
  (nzOnCancel)="hidden = true"
  (nzOnOk)="updateCountryIso()"
  [nzOkText]="'SAVE' | translate"
  [nzOkLoading]="updating$ | async"
>
  <ng-container *nzModalContent>
    <form nz-form nzLayout="vertical" [formGroup]="form" style="margin-top: 40px">
      <nz-form-item>
        <nz-form-label> {{ 'Please select your country' | translate }} </nz-form-label>
        <nz-form-control>
          <nz-input-group>
            <nz-select
              formControlName="country"
              id="country"
              name="country"
              nz-input
              nzSize="large"
              style="width: 100%"
              nzShowSearch
              [nzCustomTemplate]="defaultTemplate"
              tabindex="6"
            >
              <nz-option
                *ngFor="let country of countries | keyvalue"
                [nzLabel]="country.value.name"
                [nzValue]="country.key"
                nzCustomContent
              >
                <span>{{ country.value.emoji }}</span
                >&nbsp;
                <span class="country-name">{{ country.value.name }} </span>
              </nz-option>
            </nz-select>
            <ng-template #defaultTemplate let-selected>
              <span> {{ countries[selected.nzValue]?.emoji }} </span>&nbsp;
              <span class="country-name">{{ countries[selected.nzValue]?.name }} </span>
            </ng-template>
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
    </form>
  </ng-container>
</nz-modal>
