import { AkebonoCoreModule } from '@akebono/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

import { LotAuctionNameComponent } from './lot-auction-name.component';

@NgModule({
  declarations: [LotAuctionNameComponent],
  imports: [
    CommonModule,
    TranslateModule,
    AkebonoCoreModule,
    NzIconModule,
    NzGridModule,
    NzToolTipModule,
  ],
  exports: [LotAuctionNameComponent],
})
export class LotAuctionNameModule {}
