import { MutationHandlingServiceV2, QueryHandlingService, WatchQueryRef } from '@akebono/core';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { of } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AutoLotView } from 'src/app/const';

import {
  CurrentLotMarksListsGQL,
  CurrentLotMarksListsQuery,
  CurrentLotMarksListsQueryVariables,
  LotSource,
  LotTypeEnum,
  UserLotMark,
  UserLotMarkCreateGQL,
  UserLotMarkEnum,
  UserLotMarksListCreateGQL,
} from '../../graphql/service/graphql-auto-main-service';

@Component({
  selector: 'app-lot-shared-add-list-button',
  templateUrl: './lot-shared-add-list-button.component.html',
  styleUrls: ['./lot-shared-add-list-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotSharedAddListButtonComponent implements OnInit {
  @Input() lot: AutoLotView;
  @Input() lotType: LotTypeEnum;
  @Input() lotSource: LotSource;
  @Input() userLotMarks: UserLotMark[];

  markCreating$ = of(false);
  listCreating$ = of(false);
  title = '';
  userLotMarksListId: string = '';
  queryRef?: WatchQueryRef<CurrentLotMarksListsQuery, CurrentLotMarksListsQueryVariables>;

  constructor(
    private qhs: QueryHandlingService,
    private mhs: MutationHandlingServiceV2,
    private currentLotMarksListsGQL: CurrentLotMarksListsGQL,
    private userLotMarkCreateGQL: UserLotMarkCreateGQL,
    private userLotMarksListCreateGQL: UserLotMarksListCreateGQL,
  ) {}

  ngOnInit(): void {
    if (this.queryRef) {
      this.queryRef.refetch();
    } else {
      this.queryRef = this.qhs.watch(this.currentLotMarksListsGQL, {});
    }
  }

  userLotMarkCreate(): void {
    this.markCreating$ = this.mhs.mutate(
      this.userLotMarkCreateGQL,
      {
        input: {
          lotId: this.lot.id,
          lotType: this.lotType,
          lotSource: this.lotSource,
          mark: UserLotMarkEnum.Shared,
          userLotMarksListId: this.userLotMarksListId,
        },
      },
      {
        refetch: true,
        successTranslationKey: 'MUTATION_SUCCEEDED',
        failureTranslationKey: 'MUTATION_FAILED',
      },
    ).loading;
  }

  userLotMarksListCreate(): void {
    this.listCreating$ = this.mhs
      .mutate(
        this.userLotMarksListCreateGQL,
        {
          input: {
            title: this.title,
            publishedAt: new Date().toISOString(),
          },
        },
        {
          refetch: true,
          successTranslationKey: 'MUTATION_SUCCEEDED',
          failureTranslationKey: 'MUTATION_FAILED',
        },
      )
      .loading.pipe(finalize(() => (this.title = '')));
  }
}
