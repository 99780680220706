import { AkebonoCoreModule } from '@akebono/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzModalModule } from 'ng-zorro-antd/modal';

import { LotResetButtonComponent } from './lot-reset-button.component';

@NgModule({
  declarations: [LotResetButtonComponent],
  imports: [
    CommonModule,
    BrowserModule,
    AkebonoCoreModule,
    TranslateModule,
    NzModalModule,
    NzButtonModule,
  ],
  exports: [LotResetButtonComponent],
})
export class LotResetButtonModule {}
