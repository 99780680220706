import { ContentChildren, Directive, QueryList } from '@angular/core';
import { DriveStep } from 'driver.js';

import { DriverService } from '../driver.service';
import { DriverStepDirective } from './driver-step.directive';

@Directive({
  selector: '[appDriverGuide]',
})
export class DriverGuideDirective {
  @ContentChildren(DriverStepDirective)
  readonly stepDirectives: QueryList<DriverStepDirective>;

  constructor(private readonly driverService: DriverService) {}

  registerStep(
    stepDirective: DriverStepDirective,
    step: DriveStep,
    overrideStepIndex?: number,
  ): void {
    if (typeof overrideStepIndex === 'number') {
      this.driverService.addStep(overrideStepIndex, step);
    } else {
      const stepIndex = this.stepDirectives.toArray().findIndex((sd) => sd === stepDirective);
      this.driverService.addStep(stepIndex, step);
    }
  }

  removeStep(stepDirective: DriverStepDirective): void {
    this.driverService.removeStep(stepDirective);
  }
}
