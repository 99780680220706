import { NotAcceptedAgreementsComponent, PermissionsGuard } from '@akebono/core';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProtectedGuard } from 'ngx-auth';

import { ArticleComponent } from './components/article/article.component';
import { BidsComponent } from './components/bids/bids.component';
import { BoundaryLotsComponent } from './components/boundary-lots/boundary-lots.component';
import { ForbiddenComponent } from './components/forbidden/forbidden.component';
import { LayoutComponent } from './components/layout/layout.component';
import { MainComponent } from './components/main/main.component';
import { NotificationTabComponent } from './components/notification-tab/notification-tab.component';
import { PresetUnsubscribeComponent } from './components/preset-unsubscribe/preset-unsubscribe.component';
import { SelectionsLotsComponent } from './components/selections-lots/selections-lots.component';
import { PERMISSION_BOUNDARY_LOTS, PERMISSION_LOTS } from './const';
import { AuctionsAraiKenkiLotsRoutingModule } from './modules/auctions-arai-kenki-lots/auctions-arai-kenki-lots-routing.module';
import { AuctionsAutoStatsRoutingModule } from './modules/auctions-auto-stats/auctions-auto-stats-routing.module';
import { AuctionsAutoRoutingModule } from './modules/auctions-auto/auctions-auto-routing.module';
import { AuctionsMotoRoutingModule } from './modules/auctions-moto/auctions-moto-routing.module';
import { AuctionsOnepriceRoutingModule } from './modules/auctions-oneprice/auctions-oneprice-routing.module';
import { AuthRoutingModule } from './modules/auth/auth-routing.module';
import { ImportBidsComponent } from './modules/import-bids/import-bids.component';
import { NewsRoutingModule } from './modules/news/news-routing.module';
import { SharedPagesRoutingModule } from './modules/shared-components/shared-pages/shared-pages-routing.module';

const haveLotsAccess = {
  canActivate: [PermissionsGuard, ProtectedGuard],
  data: { permissions: [PERMISSION_LOTS] },
};

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        component: MainComponent,
        ...haveLotsAccess,
      },
      {
        path: 'bids',
        component: BidsComponent,
        ...haveLotsAccess,
      },
      {
        path: 'bids/import',
        component: ImportBidsComponent,
        ...haveLotsAccess,
      },
      {
        path: 'selections',
        component: SelectionsLotsComponent,
        ...haveLotsAccess,
      },
      {
        path: 'article/:page',
        component: ArticleComponent,
        ...haveLotsAccess,
      },
      {
        path: '',
        loadChildren: () => NewsRoutingModule,
        ...haveLotsAccess,
      },
      {
        path: 'boundary-lots',
        component: BoundaryLotsComponent,
        ...haveLotsAccess,
        data: {
          permissions: [PERMISSION_LOTS, PERMISSION_BOUNDARY_LOTS],
        },
      },
      {
        path: 'preset-unsubscribe/:id',
        component: PresetUnsubscribeComponent,
        ...haveLotsAccess,
      },
      {
        path: 'agreements',
        component: NotAcceptedAgreementsComponent,
        ...haveLotsAccess,
      },
      {
        path: 'notifications',
        component: NotificationTabComponent,
        ...haveLotsAccess,
      },
    ],
  },
  {
    path: '403',
    component: ForbiddenComponent,
    canActivate: [ProtectedGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
    AuthRoutingModule,
    SharedPagesRoutingModule,
    AuctionsAutoRoutingModule,
    AuctionsAutoStatsRoutingModule,
    AuctionsMotoRoutingModule,
    AuctionsOnepriceRoutingModule,
    AuctionsAraiKenkiLotsRoutingModule,
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
