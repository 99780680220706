import { MutationHandlingServiceV2 } from '@akebono/core';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AutoLotView } from '../../../const';
import {
  LotPhotoBotRequestInput,
  LotPhotoRequestGQL,
  LotPhotoRequestInput,
  LotSource,
  LotTypeEnum,
} from '../../graphql/service/graphql-auto-main-service';

@Component({
  selector: 'app-lot-get-photo-request-button',
  templateUrl: './lot-get-photo-request-button.component.html',
  styleUrls: ['./lot-get-photo-request-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotGetPhotoRequestButtonComponent implements OnInit {
  @Input() lot: AutoLotView;
  @Input() lotType: LotTypeEnum;
  @Input() lotSource: LotSource;
  @Input() fullSize = false;
  @Output() sendComplete = new EventEmitter<void>();

  @HostBinding('style.display') display: 'none' | 'inline-block' = 'none';
  private supportedAuctionParts = ['TAA', 'USS'];

  loading$ = of(false);
  complete = false;

  constructor(
    private mhs: MutationHandlingServiceV2,
    private lotPhotoRequestGQL: LotPhotoRequestGQL,
  ) {}

  ngOnInit(): void {
    this.display = this.supportedAuctionParts.some((sap) => this.lot.auctionName.includes(sap))
      ? 'inline-block'
      : 'none';
  }

  sendGetPhotosRequest(): void {
    const input: LotPhotoRequestInput | LotPhotoBotRequestInput = {
      lotId: this.lot.id,
      lotType: this.lotType,
      lotSource: this.lotSource,
    };

    this.loading$ = this.mhs
      .mutate(
        this.lotPhotoRequestGQL,
        { input },
        {
          successTranslationKey: 'LOT_PHOTO_REQUEST.SUCCESS',
          failureTranslationKey: 'LOT_PHOTO_REQUEST.FAIL',
        },
      )
      .loading.pipe(
        tap({
          complete: () => {
            this.complete = true;
            this.sendComplete.emit();
          },
        }),
      );
  }
}
