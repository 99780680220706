import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { QueryRef } from 'apollo-angular';
import { Subscription } from 'rxjs';
import {
  JpcenterAuctLotsFull3SortInput,
  JpCenterLotsListFiltersQuery,
  JpCenterLotsListFiltersQueryVariables,
  JpCenterLotsListGQL,
  JpCenterLotsListQuery,
  JpCenterLotsListQueryVariables,
  SortEnumType,
} from 'src/app/modules/graphql/service/graphql-stats-auto-service';
import { QueryFilterService } from 'src/app/modules/shared-components/auto-stats-lots-filter/services/query-filter.service';

import { DateRange, getTypeSort, toNumber } from '../../../../const';

@Component({
  selector: 'app-auto-lots-list',
  templateUrl: './auto-lots-list.component.html',
  styleUrls: ['./auto-lots-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ListComponent implements OnInit, OnDestroy {
  data: any[] = [];
  filtersData: any;
  pageSize = 20;
  page = 1;
  loadingResult = true;
  loadingFilters = true;
  total = 0;

  years: number[] = [];
  minYear = 1985;

  form: FormGroup;

  companies: any;
  japanCompaniesList: any[];
  otherCompaniesList: any[];
  mainCompaniesList: any[];
  mainCompaniesNames = ['toyota', 'nissan', 'honda'];
  auctRefOptions: any[];
  trucks: any[];
  currentCompany;
  isYearFiltered = false;
  isMileageFiltered = false;
  isEngineVolumeFiltered = false;
  isProductionDateFiltered = false;
  isActiveFilters = true;

  inclusionFilterFields = {
    frame: 'modelTypeEn',
    score: 'scoresEn',
    auction: 'auctRef',
    color: 'colorEn',
    transmission: 'transmissionEn',
    date: 'date',
    result: 'result',
  };
  lastDay: Date;
  dateRange: DateRange[] = [];
  selectedDays: string[] = [];
  dumpVersion: string;
  outdated = false;
  lotsListQueryRef: QueryRef<JpCenterLotsListQuery, JpCenterLotsListQueryVariables>;
  lotsListFiltersQueryRef: QueryRef<
    JpCenterLotsListFiltersQuery,
    JpCenterLotsListFiltersQueryVariables
  >;
  sub = new Subscription();

  constructor(
    private title: Title,
    private route: ActivatedRoute,
    private router: Router,
    private listLotsGQL: JpCenterLotsListGQL,
    private queryFilter: QueryFilterService,
  ) {
    this.route.queryParams.subscribe((params) => {
      this.loadingResult = true;
      this.page = toNumber(params.page) || 1;
      this.pageSize = toNumber(params.pageSize) || this.pageSize;
      const filter = this.queryFilter.getQueryFilter(params, this.inclusionFilterFields);
      let sort: JpcenterAuctLotsFull3SortInput = {
        companyEn: SortEnumType.Asc,
        modelNameEn: SortEnumType.Asc,
        modelYearEn: SortEnumType.Desc,
        mileageNum: SortEnumType.Asc,
      };
      if (params.sort && params.sortType) {
        sort = {};
        sort[params.sort] = params.sortType == 'asc' ? SortEnumType.Asc : SortEnumType.Desc;
      }
      const queryFilters = {
        first: this.pageSize,
        offset: (this.page - 1) * this.pageSize,
        filter,
        sort,
      };
      if (!this.lotsListQueryRef) {
        this.lotsListQueryRef = this.listLotsGQL.watch(queryFilters, {
          fetchPolicy: 'network-only',
        });
        this.lotsListQueryRef.valueChanges.subscribe(
          (result) => {
            this.loadingResult = false;
            this.data = result.data.lots.items;
            this.total = result.data.lots.totalCount;
            this.checkOutdated(0);
          },
          (error) => {
            console.log(error);
            this.loadingResult = false;
          },
        );
      } else {
        this.lotsListQueryRef.refetch(queryFilters).finally(() => {
          this.loadingResult = false;
        });
      }
    });
  }

  ngOnInit() {
    this.title.setTitle('ECar JP Lots');
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  private checkOutdated(dumpVersion) {
    if (!this.dumpVersion) {
      this.dumpVersion = dumpVersion;
    } else if (this.dumpVersion !== dumpVersion) {
      this.outdated = true;
    }
  }

  scrollToTable() {
    document.querySelector('.table').scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }

  loadData(page) {
    this.router.navigate(['stats-auto'], {
      queryParams: {
        page,
      },
      queryParamsHandling: 'merge',
    });
  }

  changePageSize(pageSize) {
    this.loadingResult = true;

    this.router.navigate(['stats-auto'], {
      queryParams: {
        pageSize,
      },
      queryParamsHandling: 'merge',
    });
  }

  onBack() {
    this.router.navigateByUrl('');
  }

  sortEvent(sort: { key: string; value: string }): void {
    this.loadingResult = true;

    this.router.navigate(['stats-auto'], {
      queryParams: {
        sort: sort.key,
        sortType: getTypeSort(sort.value),
      },
      queryParamsHandling: 'merge',
    });
  }

  getDate(string = null): Date {
    if (string) {
      return new Date(string);
    }
    return new Date();
  }

  getPrice(price: number) {
    return price;
  }
}
