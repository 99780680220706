import { MutationHandlingServiceV2 } from '@akebono/core';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { of } from 'rxjs';
import { finalize, shareReplay } from 'rxjs/operators';

import { AutoLotView } from '../../../../../const';
import {
  LotSource,
  LotTypeEnum,
  LotUpdateGQL,
} from '../../../../graphql/service/graphql-auto-main-service';

@Component({
  selector: 'app-lot-edit-engine-volume',
  templateUrl: './lot-edit-engine-volume.component.html',
  styleUrls: ['./lot-edit-engine-volume.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotEditEngineVolumeComponent implements OnInit {
  @Input() lot: AutoLotView;
  @Input() lotType: LotTypeEnum;
  @Input() lotSource: LotSource;
  @Output() actionComplete = new EventEmitter<void>();

  showPopover = false;
  engineVolumeValue = 0;
  saving = of(false);

  constructor(private mhs: MutationHandlingServiceV2, private lotUpdateGQL: LotUpdateGQL) {}

  ngOnInit(): void {
    this.engineVolumeValue = this.lot.engineVolume ?? 0;
  }

  saveEngineVolume(): void {
    this.saving = this.mhs
      .mutate(this.lotUpdateGQL, {
        input: {
          lotId: this.lot.id,
          lotType: this.lotType,
          lotSource: this.lotSource,
          engineVolumeNum: this.engineVolumeValue,
        },
      })
      .loading.pipe(
        finalize(() => {
          this.actionComplete.emit();
          this.showPopover = false;
        }),
        shareReplay(),
      );
  }
}
