<div style="text-align: justify">
  <p>
    Уважаемые клиенты, перед тем как принять решение привезти автомобиль методом «конструктор» -
    «распил», просим Вас ознакомиться с нашими правилами:
  </p>
  <p>
    При разборе - распиле автомобиля, детали мешающие распилу кузова снимаются, упаковываются и
    аккуратно складываются в салон авто. Но существуют определенные нюансы, при которых не
    представляется возможным сохранить целостность некоторых элементов и деталей кузова, поэтому при
    принятии решения, о покупке автомобиля данные обстоятельства нужно учитывать. Например, при
    снятии или перевозке стекол, они часто лопаются, соответственно ответственность за сохранность
    стекол мы не несем. Так же существуют детали, которые от времени утрачивают свою прочность
    (например пластик и резина), вследствие чего при разборе он часто ломается, эти нюансы зависят
    от модели и года выпуска автомобиля. Так же следует учитывать естественный процесс коррозии
    элементов ходовой части, что не всегда позволяет корректно произвести разбор. Конечно же, мы
    прикладываем все усилия, чтобы минимизировать эти повреждения, но, к сожалению не всегда это
    удаётся. Далее, на территории СВХ производится досмотр и взвешивание груза таможенными органами,
    в процессе чего по не зависящим от нас причинам может быть нанесено повреждение грузу.
  </p>
  <p>
    На основании вышеизложенного клиент (заказчик), осознает и принимает риски, связанные с покупкой
    и транспортировкой автомобиля методом «конструктор», «разбор-распил».
  </p>
</div>
<footer *nzModalFooter>
  <button nz-button (click)="modal.destroy()">
    {{ 'CANCEL' | translate }}
  </button>
</footer>
