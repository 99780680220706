import { Injectable } from '@angular/core';
import { iif, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { LETTERS } from '../const';
import { CurrentUser } from '../modules/graphql/service/graphql-auto-main-service';
import { TimeToShipGQL, TimeToShipQuery } from '../modules/graphql/service/graphql-directory-service';
import { AuctionService } from './auction.service';

export type CurrentUserWithManagerStatus = CurrentUser & {
  isManager: boolean;
};

export type ExtendedCurrentUser = CurrentUser | CurrentUserWithManagerStatus;

@Injectable()
export class BiddingRulesService {
  constructor(private auctionService: AuctionService, private getTimeToShipGQL: TimeToShipGQL) {}

  public getTimeToShip(auctionName: string): Observable<TimeToShipQuery | null> {
    return this.auctionService.getByName(auctionName).pipe(
      switchMap((auction) =>
        iif(
          () => Boolean(auction),
          this.getTimeToShipGQL.fetch({ auctionId: auction?.id }, { fetchPolicy: 'cache-first' }),
          of(null),
        ),
      ),
      map((result) => result?.data),
    );
  }

  public getAllowedLetters(currentUser: CurrentUser): string[] {
    const groups = currentUser.bidGroups.nodes;
    return LETTERS.filter((letter) => !groups.some((group) => group.letter === letter));
  }

  public checkIfCanBidWithGroupsToAuction(auctionName: string): Observable<boolean> {
    return this.auctionService.getByName(auctionName).pipe(
      map((targetAuction) => {
        return !targetAuction?.auctionData?.isNoGroup;
      }),
    );
  }
}
