<div
  [title]="
    'LOT_COUNTERS.TOOLTIP'
      | translate
        : {
            bidders: this.bidders,
            bids: this.bids,
            biddersEnding:
              getCorrectWordEnding(bidders, [
                'LOT_COUNTERS.BIDDERS_ENDING_1',
                'LOT_COUNTERS.BIDDERS_ENDING_2',
                'LOT_COUNTERS.BIDDERS_ENDING_5'
              ]) | translate,
            makeEnding:
              getCorrectWordEnding(bidders, [
                'LOT_COUNTERS.MAKE_ENDING_1',
                'LOT_COUNTERS.MAKE_ENDING_2',
                'LOT_COUNTERS.MAKE_ENDING_2'
              ]) | translate,
            bidsEnding:
              getCorrectWordEnding(bids, [
                'LOT_COUNTERS.BIDS_ENDING_1',
                'LOT_COUNTERS.BIDS_ENDING_2',
                'LOT_COUNTERS.BIDS_ENDING_5'
              ]) | translate
          }
  "
>
  <i nz-icon>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1em" height="1em">
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        d="M4 22a8 8 0 1 1 16 0H4zm8-9c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z"
      />
    </svg>
  </i>
  {{ bidders }},

  <i nz-icon>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1em" height="1em">
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        d="M14 20v2H2v-2h12zM14.586.686l7.778 7.778L20.95 9.88l-1.06-.354L17.413 12l5.657 5.657-1.414 1.414L16 13.414l-2.404 2.404.283 1.132-1.415 1.414-7.778-7.778 1.415-1.414 1.13.282 6.294-6.293-.353-1.06L14.586.686z"
      />
    </svg>
  </i>
  {{ bids }}
</div>
