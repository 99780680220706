import { MutationHandlingServiceV2 } from '@akebono/core';
import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';

import { LotIncrementViewsCountGQL, LotSource, LotTypeEnum } from '../modules/graphql/service/graphql-auto-main-service';

@Injectable({
  providedIn: 'root',
})
export class LotViewsIncrementService {
  private subscriptions = new Map<string, Subscription>();

  constructor(
    private mhs: MutationHandlingServiceV2,
    private incrementViewsCountGQL: LotIncrementViewsCountGQL,
  ) {}

  public increment(lotId: string, lotType: LotTypeEnum, lotSource: LotSource): void {
    const key = this.createKey(lotId, lotType, lotSource);

    if (!this.subscriptions.has(key)) {
      const subscription = this.subscribe(lotId, lotType, lotSource);
      this.subscriptions.set(key, subscription);
    }
  }

  public release(lotId: string, lotType: LotTypeEnum, lotSource: LotSource): void {
    const key = this.createKey(lotId, lotType, lotSource);

    if (this.subscriptions.has(key)) {
      this.subscriptions.get(key).unsubscribe();
    }
  }

  private subscribe(lotId: string, lotType: LotTypeEnum, lotSource: LotSource): Subscription {
    return this.mhs
      .mutate(
        this.incrementViewsCountGQL,
        {
          input: {
            lotId,
            lotType,
            lotSource,
          },
        },
        {
          refetch: false,
          renderError: false,
          renderSuccess: false,
        },
      )
      .data.subscribe();
  }

  private createKey(lotId: string, lotType: LotTypeEnum, lotSource: LotSource) {
    return `${lotId}-${lotType}-${lotSource}`;
  }
}
