<nz-comment
  *ngFor="let comment of bid.comments"
  [nzAuthor]="getCommentAuthor(comment)"
  [nzDatetime]="comment.createdAt | date: 'dd.MM.yyyy HH:mm'"
>
  <nz-avatar
    nz-comment-avatar
    nz-tooltip
    [nzTooltipTitle]="(comment.isManager ? 'BID_COMMENTS.MANAGER' : 'BID_COMMENTS.YOU') | translate"
    [nzIcon]="comment.isManager ? 'customer-service' : 'user'"
    [class]="comment.isManager ? 'manager-avatar' : 'user-avatar'"
  ></nz-avatar>
  <nz-comment-content>
    <p>{{ comment.content }}</p>
  </nz-comment-content>
</nz-comment>

<nz-comment>
  <nz-avatar nz-comment-avatar nzIcon="user" class="user-avatar"></nz-avatar>
  <nz-comment-content>
    <form nz-form [formGroup]="form">
      <nz-form-item>
        <nz-form-control [nzErrorTip]="'BID_COMMENTS.ENTER_COMMENT' | translate">
          <textarea
            nz-input
            formControlName="content"
            [placeholder]="'BID_COMMENTS.PLACEHOLDER' | translate"
            rows="4"
          ></textarea>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <button
          nz-button
          nzType="primary"
          [disabled]="form.invalid"
          [nzLoading]="saving | async"
          (click)="save()"
        >
          {{ 'BID_COMMENTS.SEND' | translate }}
        </button>
      </nz-form-item>
    </form>
  </nz-comment-content>
</nz-comment>
