import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { BehaviorSubject, combineLatest, merge, Observable, Subject } from 'rxjs';
import {
  distinctUntilChanged,
  first,
  map,
  mapTo,
  shareReplay,
  switchMap,
  takeUntil,
} from 'rxjs/operators';

import { BidService } from '../../services/bid.service';
import { LotFetcherService } from '../../services/lot-fetcher.service';
import { ExcelBidParsingResult } from '../../types/excel-bid-parse-result.type';
import { ExcelLot } from '../../types/excel-lot.type';
import { TargetBid } from '../../types/target-bid.type';
import { ImportBidsPlaceModalComponent } from '../import-bids-place-modal/import-bids-place-modal.component';

@Component({
  selector: 'app-import-bids-dashboard',
  templateUrl: './import-bids-dashboard.component.html',
  styleUrls: ['./import-bids-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImportBidsDashboardComponent implements OnDestroy {
  @Input()
  set parsingResult(parsingResult: ExcelBidParsingResult) {
    if (parsingResult !== this.parsingResultSubject.getValue()) {
      this.parsingResultSubject.next(parsingResult);
    }
  }
  @Output() resetParsingResult = new EventEmitter<void>();

  loading$ = new BehaviorSubject<boolean>(false);
  notFoundLotInfos$: Observable<ExcelLot[]>;
  bids$: Observable<TargetBid[]>;
  biddableBids$: Observable<TargetBid[]>;
  biddableBidsCount$: Observable<number>;

  readonly selectedLotIds = new Set<string>();
  readonly parsingResultSubject = new BehaviorSubject<ExcelBidParsingResult | null>(null);

  private readonly destroy$ = new Subject<void>();

  constructor(
    bidService: BidService,
    lotFetcherService: LotFetcherService,
    private readonly cdr: ChangeDetectorRef,
    private readonly translate: TranslateService,
    private readonly modalService: NzModalService,
  ) {
    const excelTargetBids$ = this.parsingResultSubject.pipe(map((result) => result?.bids || []));
    const fetchLotsResult$ = excelTargetBids$.pipe(
      map((excelBids) => excelBids.map((bid) => bid.lot)),
      switchMap((lotInfos) => lotFetcherService.fetch(lotInfos)),
      shareReplay(1),
    );

    const foundLots$ = fetchLotsResult$.pipe(map((result) => result.foundLots));
    this.notFoundLotInfos$ = fetchLotsResult$.pipe(map((result) => result.notFoundLots));
    this.bids$ = combineLatest([excelTargetBids$, foundLots$]).pipe(
      map(([bids, lots]) => bidService.createAll(bids, lots)),
      shareReplay(1),
    );
    this.biddableBids$ = this.bids$.pipe(
      map((bids) =>
        bids.map((bid) =>
          bid.status.isBiddable$.pipe(map((isBiddable) => (isBiddable ? bid : null))),
        ),
      ),
      switchMap((bids) => combineLatest(bids)),
      map((bids) => bids.filter((bid) => !!bid)),
    );
    this.biddableBidsCount$ = this.biddableBids$.pipe(map((bids) => bids.length));

    merge(this.parsingResultSubject.pipe(mapTo(true)), foundLots$.pipe(mapTo(false)))
      .pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe({
        next: (loading) => this.loading$.next(loading),
        error: () => this.loading$.next(false),
      });
  }

  reset(): void {
    this.resetParsingResult.emit();
    this.parsingResultSubject.next(null);
  }

  bidIdentity(_: number, bid: TargetBid): string {
    return bid.lot.id;
  }

  selectBid(bid: TargetBid, checked: boolean): void {
    const lotId = bid.lot.id;
    if (checked) {
      this.selectedLotIds.add(lotId);
    } else {
      this.selectedLotIds.delete(lotId);
    }
  }

  async placeSelectedBids(): Promise<void> {
    let bids = await this.biddableBids$.pipe(first()).toPromise();
    bids = bids.filter((bid) => this.selectedLotIds.has(bid.lot.id));

    this.openPlaceBidsModal(bids);
  }

  async placeAvailableBids(): Promise<void> {
    const bids = await this.biddableBids$.pipe(first()).toPromise();

    this.openPlaceBidsModal(bids);
  }

  private openPlaceBidsModal(bids: TargetBid[]): void {
    const modal = this.modalService.create({
      nzTitle: this.translate.instant('IMPORT_BIDS.PLACING'),
      nzContent: ImportBidsPlaceModalComponent,
      nzClosable: false,
      nzMaskClosable: false,
      nzCentered: true,
      nzFooter: null,
      nzWidth: '300px',
      nzComponentParams: {
        bids,
      },
    });

    modal.afterClose.pipe(first()).subscribe(() => {
      this.selectedLotIds.clear();
      this.cdr.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
