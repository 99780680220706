<section>
  <h3 nz-title>{{ 'IMPORT_BIDS.TITLE' | translate }}</h3>
</section>

<section>
  <nz-alert [nzMessage]="alertContent">
    <ng-template #alertContent>
      <span [innerHTML]="'IMPORT_BIDS.ALERT' | translate"></span>
    </ng-template>
  </nz-alert>
</section>

<section *ngIf="!parsingResult">
  <app-import-file-upload (parsingComplete)="parsingResult = $event"></app-import-file-upload>
</section>

<section *ngIf="parsingResult">
  <app-import-bids-dashboard
    [parsingResult]="parsingResult"
    (resetParsingResult)="parsingResult = null"
  ></app-import-bids-dashboard>
</section>
