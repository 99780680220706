import { ObservableHandlingService, ObservableRef, QueryHandlingService } from '@akebono/core';
import { Injectable } from '@angular/core';
import { iif, Observable, of } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { AlertsCountry } from 'src/app/const';

import { AuctionService } from '../../../services/auction.service';
import {
  Alert,
  AlertFragment,
  AlertsGQL,
  AlertTargetEnum,
  UserCountryIsoGQL,
} from '../../graphql/service/graphql-auto-main-service';

@Injectable()
export class AlertService {
  private readonly alertsCountry$: Observable<AlertsCountry>;

  constructor(
    private qhs: QueryHandlingService,
    private obs: ObservableHandlingService,
    private auctionService: AuctionService,
    private alertsGQL: AlertsGQL,
    private userCountryIsoGQL: UserCountryIsoGQL,
  ) {
    this.alertsCountry$ = this.qhs.fetch(this.userCountryIsoGQL).data.pipe(
      map((data) => this.countryIsoToAlertsCountry(data.currentUser?.countryIso)),
      shareReplay(1),
    );
  }

  public getAlerts(
    auctionName?: string,
    target?: AlertTargetEnum,
    lotCode?: string | number,
    isSanctioned?: boolean,
  ): Observable<Alert[]> {
    const auctionId$ = of(auctionName ?? null).pipe(
      switchMap((auctionName) =>
        iif(
          () => Boolean(auctionName),
          this.auctionService.getByName(auctionName).pipe(map((auction) => auction?.id)),
          of(null),
        ),
      ),
    );

    return auctionId$.pipe(
      switchMap((auctionId) =>
        this.alertsCountry$.pipe(map((alertsCountry) => [auctionId, alertsCountry])),
      ),
      switchMap(
        ([auctionId, alertsCountry]) =>
          this.qhs.fetch(
            this.alertsGQL,
            {
              target,
              auctionId,
              lotCode: Number(lotCode),
              isSanctioned,
              countryIso: alertsCountry,
            },
            'cache-first',
          ).data,
      ),
      map(({ globalAlerts, countryAlerts }) => [globalAlerts ?? [], countryAlerts ?? []].flat()),
    );
  }

  public getAlertsRef(
    auctionName?: string,
    target?: AlertTargetEnum,
    lotCode?: string | number,
  ): ObservableRef<Alert[]> {
    return this.obs.toReference(this.getAlerts(auctionName, target, lotCode));
  }

  public filterByCountryIso(
    alerts: AlertFragment[],
    userCountryIso: string | null,
  ): AlertFragment[] {
    const alertsCountry = this.countryIsoToAlertsCountry(userCountryIso);
    return alerts.filter(({ countryIso }) =>
      countryIso ? countryIso.toLowerCase() === alertsCountry : true,
    );
  }

  private countryIsoToAlertsCountry(countryIso: string | null): AlertsCountry {
    return countryIso?.toLowerCase() === 'ru' ? AlertsCountry.Ru : AlertsCountry.Another;
  }
}
