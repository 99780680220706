import { Clipboard } from '@angular/cdk/clipboard';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NzButtonSize } from 'ng-zorro-antd/button';
import { NzMessageService } from 'ng-zorro-antd/message';
import { of, Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { LotShareService } from 'src/app/services/lot-share.service';

import { Lot, LotSource, LotTypeEnum } from '../../../../graphql/service/graphql-auto-main-service';

@Component({
  selector: 'app-share-button',
  templateUrl: './lot-share-button.component.html',
  styleUrls: ['./lot-share-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotShareButtonComponent implements OnInit, OnDestroy {
  @Input() savedLot: Lot;
  @Input() lotType: LotTypeEnum;
  @Input() lotSource: LotSource;
  @Input() nzSize: NzButtonSize = 'large';
  @Input() fullSize = false;
  @Input() shareLotLink: string | null = null;
  @Output() lotShared = new EventEmitter<void>();

  loading$ = of(false);
  popoverVisible = false;

  private readonly destroy$ = new Subject<void>();

  constructor(
    private clipboard: Clipboard,
    private cdr: ChangeDetectorRef,
    private message: NzMessageService,
    private translate: TranslateService,
    private lotShareService: LotShareService,
  ) {}

  ngOnInit(): void {
    const existingLink = this.lotShareService.getLink(this.savedLot);
    this.shareLotLink = existingLink || null;
  }

  share(): void {
    if (this.shareLotLink) {
      return;
    }

    const result = this.lotShareService.share(this.savedLot, this.lotType, this.lotSource);

    this.loading$ = result.loading$;
    result.link$.pipe(first(), takeUntil(this.destroy$)).subscribe((link) => {
      this.shareLotLink = link;
      this.copyLink();
      this.popoverVisible = true;
      this.lotShared.emit();
      this.cdr.markForCheck();
    });
  }

  async copyLink(): Promise<void> {
    this.clipboard.copy(this.shareLotLink);
    this.message.create('success', this.translate.instant('SHARED.COPY_SHARED_SUCCESS'));
  }

  get popoverTrigger(): 'click' | null {
    return this.shareLotLink ? 'click' : null;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
