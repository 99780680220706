import { MutationHandlingServiceV2, NotificationService } from '@akebono/core';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateTime } from 'luxon';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { of } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { UserUpdateGQL } from 'src/app/modules/graphql/service/graphql-auto-main-service';

import { validateAllFormFields } from '../../../../../const';

@Component({
  selector: 'app-set-birthdate-modal',
  templateUrl: './set-birthdate-modal.component.html',
  styleUrls: ['./set-birthdate-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SetBirthdateModalComponent {
  @Input() currentUserId: string;

  form: FormGroup;

  loading$ = of(false);

  constructor(
    private fb: FormBuilder,
    private modal: NzModalRef,
    private notification: NotificationService,
    private mhs: MutationHandlingServiceV2,
    private userUpdateGQL: UserUpdateGQL,
  ) {
    this.form = this.fb.group({
      birthdate: this.fb.control(null, Validators.required),
    });
  }

  setBirthdate(): void {
    validateAllFormFields(this.form);

    if (this.form.valid) {
      if (this.form.value.birthdate > DateTime.now().minus({ years: 10 }).toJSDate()) {
        this.notification.renderError('BIRTHDATE_MODAL.INVALID_DATE');
        return;
      }

      this.loading$ = this.mhs
        .mutate(this.userUpdateGQL, {
          input: {
            birthdate: DateTime.fromJSDate(this.form.value.birthdate).toFormat('yyyy-MM-dd'),
          },
        })
        .loading.pipe(finalize(() => this.modal.destroy(true)));
    }
  }
}
