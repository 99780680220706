<nz-upload
  nzType="drag"
  [nzMultiple]="false"
  [nzBeforeUpload]="excelImportBids"
  nzFileType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
>
  <p class="ant-upload-drag-icon">
    <i nz-icon nzType="inbox"></i>
  </p>
  <p class="ant-upload-text">
    {{ 'IMPORT_BIDS.UPLOAD_TIP' | translate }}
  </p>
</nz-upload>
