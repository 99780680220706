<ng-container *ngIf="auction$ | async as auction">
  <main *ngIf="auction?.rating?.evaluationCount" nz-row [nzGutter]="[0, 5]">
    <section nz-col [nzSpan]="24">
      <nz-rate [nzCount]="5" [nzDisabled]="true" [ngModel]="auction.rating.rating"></nz-rate>
      <nz-badge
        nzSize="small"
        nzStandalone
        [nzCount]="auction.rating.evaluationCount"
        [nzStyle]="{ backgroundColor: '#fff', color: '#999', boxShadow: '0 0 0 1px #d9d9d9 inset' }"
      ></nz-badge>
    </section>
    <section *ngIf="showComments" nz-col [nzSpan]="24">
      <button nz-button nzSize="small" (click)="openReviews()">
        <i nz-icon nzType="message" nzTheme="outline"></i>
        {{ 'AUCTION_RATING.REVIEWS' | translate }}
      </button>
    </section>
  </main>
</ng-container>
